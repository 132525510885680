import {Injectable} from '@angular/core';
import {UtilsService} from './utils.service';
import {ConfirmModalService} from './confirm-modal.service';
import {AppConfigService} from './app-config.service';
import {DataCenterService} from './data-center.service';
import {AutoInsertService} from './auto-insert.service';
import * as _sh from "lodash";
import {UserInfoService} from './user-info.service';

@Injectable({
    providedIn: 'root'
})
export class DetailActionService {


    constructor(private util: UtilsService,
                private confirmModel: ConfirmModalService,
                private appConfig: AppConfigService,
                private userInfoService: UserInfoService,
                private userInfo: UserInfoService,
                private dataCenterService: DataCenterService,
                private autoService: AutoInsertService) {
    }


    saveOrModify(pBtn, parentData, mainForm, prvForms) {
        // 存在多个mainForm情况需要循环result
        let flag = true;
        for (let i = 0; i < mainForm['_results'].length; i++) {
            const form = mainForm['_results'][i];
            if (form['_validateForm']['status'] === 'PENDING') {
                flag = false;
                break;
            }
            if (form['_validateForm']['invalid']) {
                for (const key in form['_validateForm']['controls']) {
                    form['_validateForm']['controls'][key].markAsDirty();
                    form['_validateForm']['controls'][key].updateValueAndValidity();
                }
            }
            flag = form['_validateForm']['valid'] && flag;
        }
        if (flag) {
            let pass = true;
            // 深度拷贝后隔绝开。避免数据乱掉
            let forms = this.util.DeepCopy(parentData.data.detailColumns);
            _sh.each(forms, (form) => {
                if (form['type'] === 'tablelist' && form['require']) {
                    if (!form['data'] || !form['data'].length) {
                        this.confirmModel.show('error', {
                            title: '温馨提示',
                            content: form['title'] + '不能为空！'
                        });
                        pass = false;
                        return;
                    }
                }
            });
            if (pass) {
                let tmpJson = '';
                if (parentData.modeType === 'add') {
                    tmpJson = this.autoService.AdapterInsert(forms);
                } else {
                    tmpJson = this.autoService.AdapterUpdate(forms);
                }
                console.log("detail-action.service.ts 02", tmpJson);
                this.autoService.ExecJson(tmpJson).subscribe(response => {
                    if (response['state'] === 'success') {
                        this.confirmModel.show('success', {
                            'title': '温馨提示',
                            'content': '执行成功'
                        });
                        this.updateDataLog('update', parentData, prvForms).subscribe(response => {
                            this.transformData('listForm', parentData);
                        });
                    } else {
                        this.confirmModel.show('error', {
                            'title': '温馨提示',
                            'content': '保存失败'
                        });
                    }
                    return;
                });
            }
        }
    }

    doAction(pBtn, parentData, mainForm, prvForms) {
        switch (pBtn.ACTION_VALUE) {
            case'save':
                this.saveOrModify(pBtn, parentData, mainForm, prvForms);
                break;
            default: // 不是通用按钮
                this.gotoProject(pBtn, parentData, mainForm, prvForms);
        }
    }

    // 新增修改日志,记录修改了哪些字段,并记录从什么修改成什么
    updateDataLog(logType, parentData, prvForms) {
        const that = this;
        let logTable = '';
        let logPK = '';
        let logContent = '';
        let i = 0;
        _sh.each(parentData['data']['detailColumns'], (Oneform) => {
            if (i === 0) {
                logTable = Oneform['formname'];
            }
            // 只记录主表变动的日志
            if (that.util.Base_compare(Oneform['type'], 'normal')) {
                let j = 0;
                _sh.each(Oneform['columns'], (OneColumn) => {
                    // 获取记录主键
                    if (i === 0 && OneColumn['pk_column']) {
                        logPK = OneColumn['model'];
                    }
                    // 只获取显示的字段
                    if (OneColumn['visible']) {
                        // 判断字段
                        let curValue = OneColumn['model']; // 原值
                        let prvValue = prvForms['data']['detailColumns'][i].columns[j].model; // 现有值
                        if (!that.util.Base_compare(prvValue, curValue)) { // 当两个值不想等,表明值已经修改过
                            if (_sh.includes(['select', 'poplist', 'inputDataFilter'], OneColumn['component'])) { // 下拉框和弹框取值方式不一样
                                _sh.each(OneColumn['itemArray'], (oneItem) => {
                                    if (that.util.Base_compare(oneItem['code'], curValue)) { // 将字段转换成汉字
                                        curValue = oneItem['name'];
                                    }
                                    if (that.util.Base_compare(oneItem['code'], prvValue)) {
                                        prvValue = oneItem['name'];
                                    }
                                });
                            }
                            logContent += OneColumn['lable'] + ':由[' + prvValue + ']修改为[' + curValue + '] ; ';
                        }
                    }
                    j++;
                });
            }
            i++;
        });

        if (logContent === '') {
            logContent = '直接保存,未修改数据!';
        }

        const templateJson = {
            'LIST': [
                {
                    'TABLE': 'system_edit_log',
                    'ACTION': 'Insert',
                    'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
                    'WHERE': [{}],
                    'COLUMNS': [
                        {'COLUMN': 'ID'},
                        {'COLUMN': 'USER_ID'},
                        {'COLUMN': 'USER_NAME'},
                        {'COLUMN': 'USER_REL_NAME'},
                        {'COLUMN': 'UPD_TABLE'},
                        {'COLUMN': 'UPD_TYPE'},
                        {'COLUMN': 'UPD_PK'},
                        {'COLUMN': 'UPD_CONTENT'}
                    ],
                    'RECORDS': [
                        {
                            'ID': 'SYS-UUID',
                            'USER_ID': this.userInfoService.getUserInfo().USER_ID,
                            'USER_NAME': this.userInfoService.getUserInfo().USER_NAME,
                            'USER_REL_NAME': this.userInfoService.getUserInfo().USERNAME,
                            'UPD_TABLE': logTable,
                            'UPD_TYPE': logType,
                            'UPD_PK': logPK,
                            'UPD_CONTENT': logContent
                        }
                    ]
                }
            ]
        };
        return this.autoService.ExecJson(templateJson);
    }


    transformData(pFormType, parentData) {
        // 重新覆盖 datacenter 数据
        parentData.data = {};
        parentData.conditionColumns = {};
        parentData.formType = pFormType;
        parentData.actionArray = [];
        // 覆盖当前数据到data-center;
        _sh.each(this.dataCenterService._dataObject.list, (item) => {
            if (item['id'] === parentData.id) {
                item = _sh.extend(item, parentData);
            }
        });
        this.dataCenterService.refreshDataCenterInStorage();
    }

    /**
     * 非通用按钮,去项目的按钮组件
     *
     * @param pBtn
     */
    gotoProject(pBtn, parentData, mainForm, prvForms): void {
        switch (this.appConfig.config.sql_path) {
            case'dfmc':
                break;
        }
    }
}
