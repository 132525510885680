import {Component, OnInit, Input} from '@angular/core';
import {AppConfigService} from './../../service/app-config.service';
import {UtilsService} from './../../service/utils.service';
import {ConfirmModalService} from './../../service/confirm-modal.service';
import {DataCenterService} from './../../service/data-center.service';
import {UserInfoService} from './../../service/user-info.service';
import {RequestDataService} from './../../service/request-data.service';
import * as _sh from 'lodash';


@Component({
    selector: 'app-menu-btn',
    templateUrl: './menu-btn.component.html',
    styleUrls: ['./menu-btn.component.css']
})
export class MenuBtnComponent implements OnInit {
    public listMenuBtn: any = [];//当前选中菜单下的按钮
    public menubtnvisible: any;//控制添加按钮和全选显示
    public btnvisible: any;//控制删除按钮显示
    public helpUrl: any;//邦助文档地址
    public menuArray: any = [];
    public checkUniq: any;
    @Input() parentData: any;
    public uniqIndex: any = 0;
    public isVisible: any = false;
    public listAction: any;
    public searchMenu: string;//过滤菜单
    public searchAction: string;//新增按钮弹出框筛选值
    public checkBtnArray: any = [];
    public menuId: any = "";//选中的菜单id
    constructor(private utils: UtilsService,
                private confirmModal: ConfirmModalService,
                private dataCenter: DataCenterService,
                private appConfigService: AppConfigService,
                private userInfo: UserInfoService,
                private requestData: RequestDataService) {
    }

    //查询菜单按钮
    doCheckMenu(pOneMenu) {
        this.checkUniq = false;
        let menuCondition = {
            "MENU_ID": pOneMenu.MENU_ID
        }
        this.requestData.getDataByCondition(this.appConfigService.config.sql_path + '/system/system_menu_action.json', menuCondition).subscribe((response) => {
            this.listMenuBtn = response["data"];
            _sh.each(this.listMenuBtn, (OneBtn) => {
                OneBtn["selected"] = true;
            });
            this.menubtnvisible = true;
            // ('通过 ajax 得到选中的菜单有的权限按钮',vm.listMenuBtn);
            if (response["data"].length === 0) {
                this.btnvisible = false;
            } else if (response["data"].length !== 0) {
                this.btnvisible = true;
            }

        }, (error) => {
            this.utils.Base_Errored(error);
        });
    }

    //全选／反选而
    selectAll(checked) {
        _sh.each(this.listMenuBtn, oneData => {
            oneData["selected"] = checked;
        })
    }

    //删除
    actionDel() {
        this.confirmModal.show('confirm', {
            title: '是否确定删除',
            suc: () => {
                _sh.each(this.listMenuBtn, (OneBtn) => {
                    if (OneBtn["selected"] === true) {
                        this.listMenuBtn = _sh.without(this.listMenuBtn, OneBtn);
                    }
                });
            }
        })
    }


    //保存
    roleSave() {
        let that = this
        let menuId = that.menuId;
        let actionsArray = [];
        _sh.each(that.listMenuBtn, (OneBtn) => {
            if (OneBtn["selected"]) {
                if (that.utils.Base_HasValue(OneBtn["UNIQ_INDEX"]) && !that.checkUniq) {
                    that.uniqIndex = OneBtn["UNIQ_INDEX"];
                }
                let actionObject = {
                    "actionId": OneBtn["ACTION_ID"],
                    "actionOrder": OneBtn["ACTION_ORDER"],
                    "actionType": OneBtn["ACTION_TYPE"]
                };
                actionsArray.push(actionObject);
            }
        });
        if (!that.utils.Base_HasValue(menuId)) {
            that.confirmModal.show('error', {title: "请选择菜单",})
            return;
        }
        if (that["checkUniq"]) {
            if (that.uniqIndex > 0) {
                that.uniqIndex = 0;
            } else {
                that.uniqIndex = 1;
            }
        }

        let dataObject = {
            "dataObject": {
                "menuId": menuId,
                "actionIds": actionsArray,
                "uniqIndex": that.uniqIndex,
                "comId": that.userInfo.getUserInfo().COM_ID
            }
        };

        this.requestData.doActionByHdbsthor('assignRightController', 'actionToMenu', null, dataObject, true, 'hdbsthor_right', 'appToken').subscribe(response => {
                if (response["suc"] === 'success') {
                    that.checkUniq = true;
                    that.utils.Base_Checking_Java("执行成功");
                } else {
                    that.checkUniq = false;
                    that.utils.Base_JAVA_ERROR(response);
                }
            },
            (error) => {
                that.utils.Base_JAVA_ERROR(error);
            }
        );
    }

    //将新增选中的按钮放入数组中。
    log(value: any): void {
        this.checkBtnArray = value;
    }

    checkMenuBtn(pData) {
        pData.selected = !pData.selected;
    }

    // 精简套装
    streamlinedSuit() {
        if (this.utils.Base_HasValue(this.listMenuBtn)) {
            return;
        }
        this.requestData.getDataByCondition(this.appConfigService.config.sql_path + '/system/system_action.json', {'ACTION_TEXT': "公共"}).subscribe(values => {
            let actionList = values["data"];
            _sh.each(actionList, oneData => {
                if (oneData['ACTION_VALUE'] === 'add' || oneData['ACTION_VALUE'] === 'edit' || oneData['ACTION_VALUE'] === 'delete' || oneData['ACTION_VALUE'] === 'save') {
                    oneData["selected"] = true;
                    this.listMenuBtn.push(oneData);
                }
            })
        })
    }

    //添加
    showModal(): void {
        this.isVisible = true;
        this.requestData.getDataByCondition(this.appConfigService.config.sql_path + '/system/system_unselect_action.json').subscribe(values => {
            this.listAction = values["data"];
        })
    }

    handleOk(): void {
        let actionArr = [];
        let that = this;
        _sh.each(that.checkBtnArray, oneActionId => {
            let iCount = 0;
            _sh.each(that.listMenuBtn, oneBtn => {
                if (oneActionId === oneBtn["ACTION_ID"]) {
                    iCount++;
                }
            });
            if (iCount < 1) {
                _sh.each(that.listAction, oneAction => {
                    if (oneActionId === oneAction["ACTION_ID"]) {
                        oneAction["selected"] = true;
                        that.listMenuBtn.push(oneAction);
                    }
                })
            }
        })
        this.isVisible = false;
        this.searchAction = null;
    }

    handleCancel(): void {
        this.isVisible = false;
        this.searchAction = null;
    }

    ngOnInit() {
        // 获取当前菜单并设置帮助URL
        let currentMenu = this.utils.Base_getApplist(this.dataCenter._dataObject.nowMenu.MENU_ID);
        if (this.utils.Base_HasValue(currentMenu)) {
            this.helpUrl = currentMenu.HELP_URL;
        }

        // 获取二级菜单
        let menuConditon = {"MENU_LV": 2};
        this.requestData.getDataByCondition(this.appConfigService.config.sql_path + '/system/system_app_menu.json', menuConditon).subscribe(values => {
            let listMenu = values["data"];
            let menuList = _sh.groupBy(listMenu, oneData => oneData["UP_ID"]);
            _sh.each(menuList, (value, key) => {
                this.menuArray.push({value});
            });

            // 获取三级菜单
            let subMenuCondition = {"MENU_LV": 3};
            this.requestData.getDataByCondition(this.appConfigService.config.sql_path + '/system/system_app_menu.json', subMenuCondition).subscribe(subValues => {
                let listSubMenu = subValues["data"];
                let subMenuList = _sh.groupBy(listSubMenu, subData => subData["UP_ID"]);

                // 将三级菜单添加到相应的二级菜单中
                this.menuArray.forEach(menu => {
                    menu.value.forEach(menuItem => {
                        menuItem.subMenus = subMenuList[menuItem.MENU_ID] || [];
                    });
                });
            });
        });
        console.log(this.menuArray)
    }
}
