import {Component, Input, OnInit, Pipe, PipeTransform, ViewChild} from '@angular/core';
import {NzModalService} from 'ng-zorro-antd/modal';
import {AppConfigService} from '../../../service/app-config.service';
import {DataCenterService} from '../../../service/data-center.service';
import {UtilsService} from '../../../service/utils.service';
import {UploadService} from '../../../service/upload.service';
import {RequestDataService} from '../../../service/request-data.service';
import {AjaxService} from '../../../service/ajax.service';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {UserInfoService} from '../../../service/user-info.service';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import {forkJoin as observableForkJoin} from 'rxjs';
import {FormModalComponent} from "../../../basicComponent/form-modal/form-modal.component";
import * as _sh from "lodash";
@Component({
    selector: 'app-assets-contract',
    templateUrl: './assets-contract.component.html',
    styleUrls: ['./assets-contract.component.css']
})
export class AssetsContractComponent implements OnInit {

    @Input() parentData: any;
    @ViewChild('childForm', {static: false}) childForm;
    public user: any;

    con_id = null;   // 公司ID
    template_id = null;   // 保存上一个合同模板id
    con_type: String = '';   // 'normalContract':租赁物业合同, 'openContract':开口合同
    earnestMoney = 0 ;
    condition = {};
    selectAssetTabTitle = "租赁资产及价格条款";
    // 附件信息
    appendixTable = []
    cus_id = ""
    // 租赁资产及价格条款
    isRent = false;
    isProperty = false;
    depositList: any = [];
    marginAmount: any = 0;
    constructor(private modalService: NzModalService,
                private config: AppConfigService,
                private dataCenterService: DataCenterService,
                private utils: UtilsService,
                private uploadService: UploadService,
                private requestService: RequestDataService,
                private ajaxService: AjaxService,
                private notification: NzNotificationService,
                private userInfoService: UserInfoService,
                private confirmModal: ConfirmModalService,
    ) {
        this.user = userInfoService.getUserInfo();
        let otherArray = _sh.split("A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z", ",");

        _sh.each(otherArray, oneLt => {
            let obj = {
                "column": oneLt,
                "name": oneLt + "_NAME",
                "lableSpan": 5,
                "itemSpan": 19,
                "customize": 24,
                "component": "textarea",
                "lable": "加载中",
                "comment": oneLt,
                "model": null,
                "placeholder": null,
                "visible": true,
                "require": false,
                "show": true
            }
            this.otherForm['columns'].push(obj);
        })

    }

    checkInputItem() {
        let bHasValue = true;
        if (this.con_type == 'normalContract') {
            bHasValue = this.assetList.length > 0;
            if (!bHasValue) {
                this.confirmModal.show('error', {title: '租赁资产内容不能为空。'});
            } else {
                _sh.each(this.assetList, oneAsset => {
                    _sh.each(oneAsset['columns'], oneColumn => {
                        if (bHasValue && oneColumn['require']) {
                            bHasValue = this.utils.Base_HasValue(oneColumn['model']) || this.utils.Base_HasValue(oneColumn['insertValue']);
                            if (!bHasValue) {
                                this.confirmModal.show('error', {title: '请填写必填信息。', content: oneColumn['lable']});
                            }
                        }
                    })
                });
            }
        }
        return bHasValue;
    }

    checkOther() {
        let bHasValue = true;
        _sh.each(this.otherForm['columns'], oneColumn => {
            if (bHasValue && oneColumn['require']) {
                bHasValue = this.utils.Base_HasValue(oneColumn['model']) || this.utils.Base_HasValue(oneColumn['insertValue']);
                if (!bHasValue) {
                    this.confirmModal.show('error', {title: '请填写其他条款的必填信息。', content: oneColumn['lable']});
                }
            }
        })
        return bHasValue;
    }

    checkInputMain() {
        let bHasValue = true;
        _sh.each(this.baseForm['columns'], oneColumn => {
            if (bHasValue && oneColumn['require']) {
                bHasValue = this.utils.Base_HasValue(oneColumn['model']) || this.utils.Base_HasValue(oneColumn['insertValue']);
                if (!bHasValue) {
                    this.confirmModal.show('error', {title: '请填写必填信息。', content: oneColumn['lable']});
                }
            }
        })
        return bHasValue;
    }

    //检查经营项目备注
    checkConNote() {
        let bHasValue = true;
        if(this.findValue(this.baseForm,"BUSINESS_ITEMS") == 'OTHER' && (this.findValue(this.baseForm,"CON_NOTE") == "" || this.findValue(this.baseForm,"CON_NOTE") == null || this.findValue(this.baseForm,"CON_NOTE") == "无")){
            this.confirmModal.show('error', {title: '请填写经营项目备注。'});
            bHasValue = false;
        }
        return bHasValue;
    }

    formatToEntity(datas) {
        let entity: any = {};
        _sh.each(datas, (item) => {
            const column = _sh.camelCase(_sh.get(item, 'column'));
            const value = _sh.get(item, 'model');
            entity[column] = value;
            let columnGroup = _sh.get(item, 'columnGroup', null);
            _sh.each(columnGroup, (oneGroupColumn) => {
                const itemColumn = _sh.camelCase(_sh.get(oneGroupColumn, 'column'));
                const itemValue = _sh.get(oneGroupColumn, 'model');
                entity[itemColumn] = itemValue;
            });
        });
        return entity;
    }

    formatToOtherEntity(datas) {
        let entity: any = [];
        _sh.each(datas, (item, index) => {
            if (_sh.get(item, 'visible')) {
                let et = {};
                const column = _sh.camelCase(_sh.get(item, 'column'));
                const name = _sh.get(item, 'lable');
                const en_name = _sh.get(item, 'name').split('_')[0];
                const value = _sh.get(item, 'model');
                if (value) {
                    et['conOdId'] = column;
                    et['clauseName'] = name;
                    et['clauseEnName'] = en_name;
                    et['clauseText'] = value;
                    et['otherOrder'] = index;
                    entity.push(et);
                }
            }
        });
        return entity;
    }

    doSave(pJump: Boolean): void {
        console.log(1)
        if (this.checkInputMain() && this.checkInputItem() && this.checkOther() && this.checkConNote()) {
            let conContractsDetailList = [];
            if (this.con_type == 'normalContract') {
                // 如果是租赁物业合同，要规范租赁物业卡片的格式
                _sh.each(this.assetList, oneAsset => {
                    let type = this.findValue(this.baseForm, 'CON_TYPE')
                    let oneData = null;
                    if (type != 'PROPERTY') { // RENT , RENT_AND_PROPERTY
                        oneData = this.formatToEntity(_sh.get(oneAsset, ['formRent', 'columns']));
                        oneData['expenceType'] = 'RENT'; // 预防老数据方面的问题
                        conContractsDetailList.push(oneData);
                    }
                    if (type != 'RENT') { // PROPERTY , RENT_AND_PROPERTY
                        oneData = this.formatToEntity(_sh.get(oneAsset, ['formProperty', 'columns']));
                        oneData['expenceType'] = 'PROPERTY'; // 预防老数据方面的问题
                        conContractsDetailList.push(oneData);
                    }
                });
            }

            if (this.parentData.modeType == 'add') {
                // 如果是添加状态，增加一个UUID
                _sh.each(this.baseForm['columns'], oneColumn => {
                    if (oneColumn['column'] == 'ID') {
                        oneColumn['model'] = this.utils.Base_getUuid();
                    }
                });
            }
            let queryData = {
                'userId': this.user.USER_ID,
            };
            const bodyData = {
                'conContracts': this.formatToEntity(this.baseForm['columns']),
                'conContractsOtherList': this.formatToOtherEntity(this.otherForm['columns']),
                'conContractsDetailList': conContractsDetailList
            };
            this.requestService.doActionByHdbsthor('AppApi/ConContractAuditController', 'doInputContracts', queryData, bodyData, false, 'hdbsthor_assets', 'appToken').subscribe(response => {
                let status = _sh.get(response, ['status'], 200);
                if (status == 200) {
                    this.con_id = _sh.get(response, ['contractInerface', 'conContracts', 'id'], this.con_id);
                    _sh.set(this.parentData, ['modeType'],'modify');

                    this.doSearch();
                    this.confirmModal.show('success', {'title': '执行成功'});
                } else if (status == 500) {
                    this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
                }
            });
        }
    }

    // 返回
    gotoBack(): void {
        this.parentData.data = {};
        this.parentData.conditionColumns = {};
        this.parentData.formType = 'listForm';
        this.parentData.actionArray = [];
        // 覆盖当前数据到data-center;
        _sh.each(this.dataCenterService._dataObject.list, (item) => {
            if (item['id'] === this.parentData.id) {
                item = _sh.extend(item, this.parentData);
            }
        });
        this.dataCenterService.refreshDataCenterInStorage();
    }

    assetModalVisible: Boolean = false;
    modifyAssetModalVisible: Boolean = false;

    // 多选点击确定按钮事件
    selectMulti() {
        _sh.each(_sh.filter(this.dataSet, {'checked': true}), oneAsset => {

            let findData = _sh.filter(this.assetList, {'AS_ID': oneAsset['ID']});
            if (this.utils.Base_HasValue(findData)) {
                let content = _sh.chain(findData).map('AS_NAME').join('');
                content = "这些资产已经被选中，请确认后再操作。" + content;
                this.confirmModal.show('error', {title: '监察重复项。', content: content});
            } else {
                let cust_id = this.findValue(this.baseForm, ['CUST_ID']);
                //请求查询前期保证金的合计是多少
                const search = observableForkJoin(
                    //json path, 参数为：资产ID，客户ID
                    this.requestService.getDataByCondition('assets/con_contracts_add/con_bill_rent_deposit_sum.json',{'AS_ID':oneAsset['ID'],'CUST_ID':cust_id})
                )
                search.subscribe(
                    values => {
                        //将查询到的值返回给oneAsset的一个字段
                        oneAsset["MARGIN_AMOUNT_BEFORE"] = _sh.get(values, [0,'data',0,'name'],0);
                        console.log(oneAsset["MARGIN_AMOUNT_BEFORE"]);
                        let tempObj = {
                            'AS_ID': oneAsset['ID'],
                            'LINK_NAME': oneAsset['LINK_NAME'],
                            'AS_CODE': oneAsset['AS_CODE'],
                            'AS_NAME': oneAsset['AS_NAME'],
                            'formRent': _sh.cloneDeep(this.createNewAssetWithRent(oneAsset, 'add')),
                            'formProperty': _sh.cloneDeep(this.createNewAssetWithProperty(oneAsset, 'add'))
                        };
                        this.assetList.push(tempObj);
                        this.assetModalVisible = false;
                    }
                )


            }
        })
    }

    doCancel() {
        this.assetModalVisible = false;
    }
    doModifyCancel() {
        this.modifyAssetModalVisible = false;
    }

    // 绑定当前table的数组数据
    public dataSet: any = [];
    public allChecked = false;
    public indeterminate = false;
    public conditions;
    // 总条数checkAll
    public total: any = 1;
    // 分页下标
    public pageIndex: any = 1;
    // 每页显示数据
    public pageSize: any = 5;
    public nzPageSizeOptions: any = [5, 10, 20, 50, 100, 200];

    // 当前table的表头
    public showColumns: any = [
        {
            "column": "ID",
            "title": "主键",
            "visible": false,
            "pk_column": true,
            "iscondition": true,
            "conditionColumn": "ID",
            "type": "head",
            "class": ""
        },
        {
            "column": "UP_AS_CODE",
            "title": "资产卡片编码",
            "visible": true,
            "export": true,
            "type": "head",
            "class": ""
        },
        {
            "column": "UP_AS_FINANCE_NAME",
            "title": "资产卡片名称",
            "visible": true,
            "export": true,
            "type": "head",
            "class": ""
        },
        {
            "column": "AS_CODE",
            "title": "经营性资产编码",
            "visible": true,
            "export": true,
            "type": "head",
            "class": ""
        },
        {
            "column": "AS_NAME",
            "title": "经营性资产名称",
            "visible": true,
            "export": true,
            "type": "head",
            "class": ""
        },
        {
            "column": "AS_FLOOR",
            "title": "楼层",
            "visible": true,
            "export": true,
            "type": "head",
            "class": ""
        },
        {
            "column": "AS_FLOOR_CODE",
            "title": "楼层编码",
            "visible": true,
            "export": true,
            "type": "head",
            "class": ""
        },
        {
            "column": "AS_ROOM_NUMBER",
            "title": "房间编码",
            "visible": true,
            "export": true,
            "type": "head",
            "class": ""
        },
        {
            "column": "AS_USABLE_AREA",
            "title": "租赁面积（㎡）",
            "visible": true,
            "export": true,
            "type": "head",
            "class": ""
        }
    ];

    // 查询对象
    public conditionColumns: any = {
        "columns": [
            {
                "column": "AS_CODE",
                "name": "AS_CODE_NAME",
                "component": "input",
                "lable": "资产编号",
                "comment": "资产编号",
                "placeholder": "请输入资产编号",
                "model": null,
                "visible": true
            },
            {
                "column": "AS_NAME",
                "name": "AS_NAME_NAME",
                "component": "input",
                "lable": "资产名称",
                "comment": "资产名称",
                "placeholder": "请输入资产名称",
                "model": null,
                "visible": true
            },
            {
                "column": "AS_FLOOR",
                "name": "AS_FLOOR_NAME",
                "component": "poplist",
                "lable": "楼层",
                "comment": "楼层",
                "placeholder": "请输入楼层",
                "insertValue": null,
                "model": null,
                "visible": true,
                "dictionary": "assets/basic/dict/BUILDING_FLOOR.json",
                "dictionaryPF": null
            },
            {
                "column": "OPERATING_ID",
                "name": "OPERATING_ID_NAME",
                "component": "poplistedit",
                "lable": "管理片区",
                "comment": "管理片区",
                "placeholder": "请选择管理片区",
                "insertValue": null,
                "model": null,
                "visible": true,
                "dictionaryInput": "ID",
                "dictionaryInputPopListEdit": "ORG_ID",
                "dictionary": "assets/basic/dict/POST_ORG_PROJECTDEPARTMENT_POPLIST.json",
                "dictionaryPF": null
            }
        ]
    };


    searchFilter(reset: boolean = true) {
        this.condition = this.makeConditions();
        this.searchData(reset);
    }

    checkAll(value: boolean): void {
        this.dataSet.forEach(data => {
            if (!data.disabled) {
                data.checked = value;
            }
        });
        this.refreshStatus();
    }

    // checkbox 选中或未选中之后刷新总checkbox的状态
    refreshStatus(data?): void {
        const validData = this.dataSet.filter(value => !value.disabled);
        const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
        const allUnChecked = validData.every(value => !value.checked);
        this.allChecked = allChecked;
        this.indeterminate = (!allChecked) && (!allUnChecked);
    }

    searchData(reset: boolean = false): void {
        if (reset) {
            this.pageIndex = 1;
        }
        this.condition['COM_ID'] = this.findValue(this.baseForm, 'COM_ID')
        this.condition['OPERATING_ID'] = this.findValue(this.baseForm, 'OPERATING_ID')
        this.requestService.getPaginationData('assets/con_con/assets_room_list.json', this.condition || {}, this.pageIndex, this.pageSize).subscribe((data: any) => {
            this.transformResult(data);
        });
    }

    // 查询后的数据进行封装
    transformResult(data) {
        let dataSet = data.data || [];
        this.dataSet = dataSet;
        this.refreshStatus();
        this.total = data.page.rowCount;
        this.pageIndex = data.page.pageNum;
    }

    resetFilter() {
        _sh.each(this.conditionColumns['columns'], oneColum => {
            oneColum['model'] = null;
        })
        this.searchFilter();
    }


    changeColumn(columnName, key, value) {
        _sh.each(this.assetList, oneAsset => {
            _sh.each(oneAsset['form']['columns'], oneColumn => {
                if (oneColumn['column'] === columnName) {
                    oneColumn[key] = value
                }
            })
        })
    }

    assetIndex = 0;
    assetIndex2 = 0;


    baseForm: any = {
        "tablename": "MS_CUST_ORDERS",
        "formname": "MS_CUST_ORDERS",
        "title": "客户订单基本信息",
        'type': 'normal',
        'columns': [
            {
                'column': 'ID',
                'iscondition': true,
                'pk_column': true,
                'name': 'ID_NAME',
                'component': 'input',
                'lable': '主键',
                'comment': '主键',
                'placeholder': '主键',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'insertValue': 'SYS-ACTION-UUID',
                'model': null,
                'visible': false,
                'require': true
            },
            {
                'column': 'CON_STATE',
                'name': 'CON_STATE_NAME',
                'component': 'select',
                'lable': '合同状态',
                'comment': '合同状态',
                'placeholder': '请输入合同状态',
                'insertValue': null,
                'model': 'INIT',
                'visible': false,
                'require': false,
                'dictionary': 'assets/basic/dict/CON_STATE.json',
                'dictionaryPF': null
            },
            {
                'column': 'HR',
                'component': 'hr',
                'lable': '合同基础信息',
                'customize': 24,
                'comment': '合同基础信息',
                'visible': true,
                'wechatVisable': true,
                'line': false,
                'commitPoint': [
                    'detail'
                ],
                'placeholder': '合同基础信息',
                'model': null
            },
            {
                'column': 'CON_CODE',
                'name': 'CON_CODE_NAME',
                'component': 'input',
                'lable': '合同编号',
                'comment': '合同编号',
                'placeholder': '请输入合同编号',
                'lableSpan': 3,
                'itemSpan': 21,
                'customize': 24,
                'insertValue': null,
                'model': null,
                'visible': true,
                'require': false,
                'disable':true
            },
            {
                'column': 'CON_NAME',
                'name': 'CON_NAME_NAME',
                'component': 'input',
                'lable': '合同名称',
                'comment': '合同名称',
                'placeholder': '请输入合同名称',
                'lableSpan': 3,
                'itemSpan': 21,
                'customize': 24,
                'insertValue': null,
                'model': null,
                'visible': true,
                'require': false,
                'disable':true
            },
            {
                'column': 'CON_MAIN_TYPE',
                'name': 'CON_MAIN_TYPE_NAME',
                'component': 'select',
                'lable': '合同主要类型',
                'comment': '合同主要类型',
                'placeholder': '请输入合同主要类型',
                'lableSpan': 6,
                'itemSpan': 18,
                'customize': 12,
                'insertValue': null,
                'model': 'RENT',
                'visible': false,
                'require': true,
                'dictionary': 'assets/basic/dict/CON_MAIN_TYPE.json',
                'dictionaryPF': null,
                'disable':true
            },
            {
                'column': 'CON_TYPE',
                'name': 'CON_TYPE_NAME',
                'component': 'select',
                'lable': '合同类型',
                'comment': '合同类型',
                'placeholder': '请输入合同类型',
                'lableSpan': 6,
                'itemSpan': 18,
                'customize': 12,
                'insertValue': null,
                'model': null,
                'visible': true,
                'require': true,
                'dictionary': 'assets/basic/dict/CON_NORMAL_TYPE.json',
                'dictionaryPF': null
            },
            {
                'column': 'TEMPLATE_ID',
                'name': 'TEMPLATE_ID_NAME',
                'component': 'select',
                'lable': '合同模板',
                'comment': '合同模板',
                'placeholder': '请输入合同模板',
                'lableSpan': 6,
                'itemSpan': 18,
                'customize': 12,
                'insertValue': null,
                'model': null,
                'visible': true,
                'require': true,
                'dictionary': 'wordConfig/word_config_con_contract_poplist.json',
                'dictionaryPF': null,
                'columnGroup': [
                    {
                        'column': 'TEMPLATE_NAME',
                        'name': 'TEMPLATE_NAME_NAME',
                        'component': 'input',
                        'lable': '合同模板名称',
                        'comment': '合同模板名称',
                        'placeholder': '合同模板名称',
                        'fromValue': 'name',
                        'model': null,
                        'visible': false,
                        'require': true
                    }
                ]
            },
            {
                'column': 'COM_ID',
                'name': 'COM_ID_NAME',
                'component': 'poplist',
                'lable': '我方名称',
                'comment': '我方名称',
                'placeholder': '请选择我方名称',
                'lableSpan': 3,
                'itemSpan': 21,
                'customize': 24,
                'insertValue': null,
                'model': null,
                'visible': true,
                'require': true,
                'dictionary': 'assets/basic/dict/COM_POPLIST.json', //  CON_CONTRACTS_ORG_POPLIST
                'dictionaryPF': null,
                'columnGroup': [
                    {
                        'column': 'COM_NAME',
                        'name': 'COM_NAME_NAME',
                        'component': 'input',
                        'lable': '部门名称',
                        'comment': '部门名称',
                        'placeholder': '部门名称',
                        'fromValue': 'name',
                        'model': null,
                        'visible': false,
                        'require': false,
                        'disable': [
                            'add',
                            'modify'
                        ]
                    }
                ]
            },
            {
                'column': 'FOLLOW_USER_ID',
                'name': 'FOLLOW_USER_ID_NAME',
                'component': 'poplist',
                'lable': '我方签约人',
                'comment': '我方签约人',
                'placeholder': '请选择我方签约人',
                'lableSpan': 6,
                'itemSpan': 18,
                'customize': 12,
                'insertValue': null,
                'model': null,
                'visible': false,
                'require': false,
                'dictionary': 'assets/basic/dict/SYSTEM_USER_POPLIST.json',
                'dictionaryPF': null,
                'columnGroup': [
                    {
                        'column': 'FOLLOW_USER_NAME',
                        'name': 'FOLLOW_USER_NAME_NAME',
                        'component': 'input',
                        'lable': '我方签约人',
                        'comment': '我方签约人',
                        'placeholder': '我方签约人',
                        'fromValue': 'name',
                        'model': null,
                        'visible': false,
                        'require': false,
                        'disable': [
                            'add',
                            'modify'
                        ]
                    }
                ]
            },
            {
                'column': 'CUST_ID',
                'name': 'CUST_ID_NAME',
                'component': 'poplist',
                'lable': '相关方名称',
                'comment': '相关方名称',
                'placeholder': '请选择相关方名称',
                'lableSpan': 6,
                'itemSpan': 18,
                'customize': 12,
                'insertValue': null,
                'model': null,
                'visible': true,
                'require': true,
                'dictionary': 'assets/basic/dict/INFO_CUST_CONTRACTS_POPLIST.json',
                'dictionaryPF': null,
                'columnGroup': [
                    {
                        'column': 'CUST_NAME',
                        'name': 'CUST_NAME_NAME',
                        'component': 'input',
                        'lable': '相关方签约人',
                        'comment': '相关方签约人',
                        'placeholder': '请输入相关方签约人',
                        'lableSpan': 6,
                        'itemSpan': 18,
                        'customize': 12,
                        'fromValue': 'CUST_NAME',
                        'model': null,
                        'visible': true,
                        'require': false
                    },
                    {
                        'column': 'CUST_TYPE',
                        'name': 'CUST_TYPE_NAME',
                        'component': 'input',
                        'lable': '客户类型',
                        'comment': '客户类型',
                        'placeholder': '客户类型',
                        'fromValue': 'CUST_TYPE',
                        'model': null,
                        'visible': false,
                        'require': false,
                        'disable': [
                            'add',
                            'modify'
                        ]
                    },
                    {
                        'column': 'CUST_COM_NAME',
                        'name': 'CUST_COM_NAME_NAME',
                        'component': 'input',
                        'lable': '相关方名称',
                        'comment': '相关方名称',
                        'placeholder': '相关方名称',
                        'fromValue': 'name',
                        'model': null,
                        'visible': false,
                        'require': false,
                        'disable': [
                            'add',
                            'modify'
                        ]
                    }
                ]
            },
            {
                'column': 'SIGNING_DATE',
                'name': 'SIGNING_DATE_NAME',
                'component': 'date',
                'lable': '合同签约时间',
                'comment': '合同签约时间',
                'placeholder': '请输入合同签约时间',
                'lableSpan': 6,
                'itemSpan': 18,
                'customize': 12,
                'insertValue': null,
                'model': null,
                'visible': false,
                'require': false
            },
            {
                "column": "OPERATING_ID",
                "name": "OPERATING_ID_NAME",
                "component": "poplistedit",
                "lable": "管理片区",
                "comment": "管理片区",
                "placeholder": "请选择管理片区",
                'lableSpan': 6,
                'itemSpan': 18,
                'customize': 12,
                "insertValue": null,
                "model": null,
                "visible": true,
                "require": true,
                "dictionaryInput": "ID",
                "dictionaryInputPopListEdit": "ORG_ID",
                "dictionary": "assets/basic/dict/POST_ORG_PROJECTDEPARTMENT_POPLIST.json",
                "dictionaryPF": null,
                "columnGroup": [
                    {
                        "column": "OPERATING_NAME",
                        "name": "OPERATING_NAME_NAME",
                        "component": "input",
                        "lable": "经营管理部门",
                        "comment": "经营管理部门",
                        "placeholder": "经营管理部门",
                        "fromValue": "name",
                        "model": null,
                        "visible": false,
                        "require": false,
                        "disable": [
                            "add",
                            "modify"
                        ]
                    }
                ]
            },
            {
                'column': 'MARGIN_AMOUNT',
                'name': 'MARGIN_AMOUNT_NAME',
                'component': 'input',
                'lable': '保证金余额',
                'comment': '保证金余额',
                'placeholder': '请输入保证金余额',
                'insertValue': null,
                'model': 0,
                'visible': true,
                'require': false,
                'disable': true
            },
            {
                'column': 'BUSINESS_ITEMS',
                'name': 'BUSINESS_ITEMS_NAME',
                'component': 'select',
                'lable': '经营项目',
                'comment': '经营项目',
                'placeholder': '请输入经营项目',
                'lableSpan': 6,
                'itemSpan': 18,
                'customize': 12,
                'insertValue': null,
                'model': null,
                'visible': true,
                'require': true,
                'dictionary': 'assets/con_con/dict/BUSINESS_ITEM_NODE.json',
                'dictionaryPF': null,
            },
            {
                "column": "CON_NOTE",
                "name": "CON_NOTE_NAME",
                "component": "input",
                "lable": "经营项目备注",
                "comment": "经营项目备注",
                "placeholder": "请输入经营项目备注",
                'lableSpan': 6,
                'itemSpan': 18,
                'customize': 12,
                "insertValue": null,
                "model": '无',
                "visible": true,
                "require": true,
                "dictionaryPF": null
            },
            {
                'column': 'HR',
                'component': 'hr',
                'lable': '合同条款',
                'customize': 24,
                'comment': '合同条款',
                'visible': true,
                'wechatVisable': true,
                'line': false,
                'commitPoint': [
                    'detail'
                ],
                'placeholder': '合同条款',
                'model': null
            },
            {
                'column': 'PAY_TYPE',
                'name': 'PAY_TYPE_NAME',
                'component': 'select',
                'lable': '缴费方式',
                'comment': '缴费方式',
                'placeholder': '请输入缴费方式',
                'insertValue': null,
                'model': null,
                'visible': true,
                'require': true,
                'dictionary': 'assets/basic/dict/PAY_TYPE.json',
                'dictionaryPF': null
            },
            {
                'column': 'START_DATE',
                'name': 'START_DATE_NAME',
                'component': 'date',
                'lable': '合同开始日期',
                'comment': '合同开始日期',
                'placeholder': '请输入合同开始日期',
                'insertValue': null,
                'model': null,
                'visible': true,
                'require': true
            },
            {
                'column': 'END_DATE',
                'name': 'END_DATE_NAME',
                'component': 'date',
                'lable': '合同结束日期',
                'comment': '合同结束日期',
                'placeholder': '请输入合同结束日期',
                'insertValue': null,
                'model': null,
                'visible': true,
                'require': true
            },
            {
                'column': 'ARAP_DATE',
                'name': 'ARAP_DATE_NAME',
                'component': 'input',
                'lable': '账单日期',
                'comment': '账单日期',
                'placeholder': '请输入账单日期',
                'insertValue': null,
                'model': null,
                'visible': true,
                'require': true
            },
            {
                'column': 'HR',
                'component': 'hr',
                'lable': '汇总信息',
                'customize': 24,
                'comment': '汇总信息',
                'visible': true,
                'wechatVisable': true,
                'line': false,
                'commitPoint': [
                    'detail'
                ],
                'placeholder': '汇总信息',
                'model': null
            },
            {
                'column': 'CONTRACT_PERIOD',
                'name': 'CONTRACT_PERIOD_NAME',
                'component': 'input',
                'type': 'number',
                'lable': '合同周期（月）',
                'comment': '合同周期（月）',
                'placeholder': '请输入合同周期（月）',
                'insertValue': null,
                'model': null,
                'visible': true,
                'require': false,
                'disable': true
            },
            {
                'column': 'CONTRACT_AMOUNT',
                'name': 'CONTRACT_AMOUNT_NAME',
                'component': 'input',
                'type': 'number',
                'lable': '合同金额合计',
                'comment': '合同金额合计',
                'placeholder': '请输入合同金额合计',
                'insertValue': null,
                'model': null,
                'visible': true,
                'require': false,
                'disable': true
            },
            {
                'column': 'LEASE_QUANTITY',
                'name': 'LEASE_QUANTITY_NAME',
                'component': 'input',
                'lable': '租赁面积（㎡）',
                'comment': '租赁面积（㎡）',
                'placeholder': '请输入租赁面积（㎡）',
                'insertValue': null,
                'model': null,
                'visible': true,
                'require': false,
                'disable': true
            },
            {
                'column': 'MONTHLY_AMOUNT',
                'name': 'MONTHLY_AMOUNT_NAME',
                'component': 'input',
                'lable': '月租赁金额',
                'comment': '月租赁金额',
                'placeholder': '请输入月租赁金额',
                'insertValue': null,
                'model': null,
                'visible': true,
                'require': false,
                'disable': true
            },

            {
                'column': 'HR',
                'component': 'hr',
                'lable': '附件',
                'customize': 24,
                'comment': '附件',
                'visible': true,
                'wechatVisable': true,
                'line': false,
                'commitPoint': [
                    'detail'
                ],
                'placeholder': '附件',
                'model': null
            },
            {
                'column': 'CON_TEXT',
                'name': 'CON_TEXT_NAME',
                'component': 'file',
                'lable': '空白合同附件',
                'comment': '空白合同附件',
                'placeholder': '请输入空白合同附件',
                'insertValue': null,
                'model': null,
                'visible': true,
                'require': false
            },
            {
                'column': 'CON_TEXT_PDF',
                'name': 'CON_TEXT_PDF_NAME',
                'component': 'file',
                'lable': '空白合同附件（pdf）版',
                'comment': '空白合同附件（pdf）版',
                'placeholder': '请输入空白合同附件（pdf）版',
                'insertValue': null,
                'model': null,
                'visible': true,
                'require': false
            },
            {
                'column': 'NEGOTIATION_RECORD',
                'name': 'NEGOTIATION_RECORD_NAME',
                'component': 'file',
                'lable': '洽谈记录',
                'comment': '洽谈记录',
                'placeholder': '请输入洽谈记录',
                'insertValue': null,
                'model': null,
                'visible': true,
                'require': true
            },
            {
                'column': 'ACCESS_REVIEW',
                'name': 'ACCESS_REVIEW_NAME',
                'component': 'file',
                'lable': '准入审核',
                'comment': '准入审核',
                'placeholder': '请输入准入审核',
                'insertValue': null,
                'model': null,
                'visible': true,
                'require': true
            },
            {
                'column': 'CON_GUARANTOR',
                'name': 'CON_GUARANTOR_NAME',
                'component': 'file',
                'lable': '担保书',
                'comment': '担保书',
                'placeholder': '请输入担保书',
                'insertValue': null,
                'model': null,
                'visible': true,
                'require': false
            },
            {
                'column': 'CON_GUARANTOR_IDCARD',
                'name': 'CON_GUARANTOR_IDCARD_NAME',
                'component': 'file',
                'lable': '担保人身份证复印件',
                'comment': '担保人身份证复印件',
                'placeholder': '请输入担保人身份证复印件',
                'insertValue': null,
                'model': null,
                'visible': true,
                'require': false
            },
            {
                'column': 'OTHER_ATTACHMENTS',
                'name': 'OTHER_ATTACHMENTS_NAME',
                'component': 'file',
                'lable': '其他附件',
                'comment': '其他附件',
                'placeholder': '请输入其他附件',
                'insertValue': null,
                'model': null,
                'visible': true,
                'require': false
            },
            {
                'column': 'UNIT_PRICE_POINT',
                'name': 'UNIT_PRICE_POINT_NAME',
                'component': 'input',
                'type': 'number',
                'lable': '单价保留小数点',
                'comment': '单价保留小数点',
                'placeholder': '请输入单价保留小数点',
                'insertValue': null,
                'model': 2,
                'visible': false,
                'require': true
            },
            {
                'column': 'CALCULATION_POINT',
                'name': 'CALCULATION_POINT_NAME',
                'component': 'input',
                'type': 'number',
                'lable': '计算精度 结果保留小数点',
                'comment': '计算精度 结果保留小数点',
                'placeholder': '请输入计算精度 结果保留小数点',
                'insertValue': null,
                'model': 2,
                'visible': false,
                'require': true
            },
            {
                'column': 'CANCELLATION_REASON',
                'name': 'CANCELLATION_REASON_NAME',
                'component': 'inputMulti',
                'lable': '合同作废原因',
                'comment': '合同作废原因',
                'placeholder': '请输入合同作废原因',
                'insertValue': null,
                'model': null,
                'visible': false,
                'require': false
            },
            {
                'column': 'CON_CHANGE_ID',
                'name': 'CON_CHANGE_ID_NAME',
                'component': 'input',
                'lable': '合同变更表ID',
                'comment': '合同变更表ID',
                'placeholder': '请输入合同变更表ID',
                'insertValue': null,
                'model': null,
                'visible': false,
                'require': false
            },
            {
                'column': 'ADD_USERID',
                'name': 'ADD_USERID_NAME',
                'component': 'input',
                'lable': '添加人账户ID',
                'comment': '添加人账户ID',
                'placeholder': '请输入添加人账户ID',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'insertValue': 'SYS_USER_ID',
                'model': null,
                'visible': false,
                'require': false
            },
            {
                'column': 'ADD_TIME',
                'name': 'ADD_TIME_NAME',
                'component': 'input',
                'lable': '添加时间',
                'comment': '添加时间',
                'placeholder': '添加时间',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'insertValue': 'SYS-DATETIME',
                'disable': [
                    'add',
                    'modify'
                ],
                'commitPoint': [
                    'add'
                ],
                'model': null,
                'visible': false
            },
            {
                'column': 'ADD_NAME',
                'name': 'ADD_NAME_NAME',
                'component': 'input',
                'lable': '添加人',
                'comment': '添加人',
                'placeholder': '请输入添加人姓名',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'insertValue': 'SYS_USER_NAME',
                'disable': [
                    'add',
                    'modify'
                ],
                'commitPoint': [
                    'add'
                ],
                'model': null,
                'visible': false
            },
            {
                'column': 'UPD_USERID',
                'name': 'UPD_USERID_NAME',
                'component': 'input',
                'lable': '修改人',
                'comment': '修改人',
                'placeholder': '修改人',
                'insertValue': 'SYS_USER_ID',
                'model': null,
                'visible': false,
                'disable': [
                    'add',
                    'modify'
                ],
                'commitPoint': [
                    'modify'
                ]
            },
            {
                'column': 'UPD_NAME',
                'name': 'UPD_NAME_NAME',
                'component': 'input',
                'lable': '修改人',
                'comment': '修改人',
                'placeholder': '修改人',
                'insertValue': 'SYS_USER_NAME',
                'model': null,
                'visible': false,
                'disable': [
                    'add',
                    'modify'
                ],
                'commitPoint': [
                    'modify'
                ]
            },
            {
                'column': 'UPD_TIME',
                'name': 'UPD_TIME_NAME',
                'component': 'input',
                'lable': '更新时间',
                'comment': '更新时间',
                'placeholder': '更新时间',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'insertValue': 'SYS-DATETIME',
                'disable': [
                    'add',
                    'modify'
                ],
                'commitPoint': [
                    'modify'
                ],
                'model': null,
                'visible': false
            },
            {
                'column': 'U_TIME',
                'name': 'U_TIME_NAME',
                'component': 'input',
                'lable': '更新时间',
                'comment': '更新时间',
                'placeholder': '请选择更新时间',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'insertValue': 'SYS-DATETIME',
                'disable': [
                    'add',
                    'modify'
                ],
                'commitPoint': [
                    'add'
                ],
                'model': null,
                'visible': false
            },
            {
                'column': 'U_DELETE',
                'name': 'U_DELETE_NAME',
                'component': 'input',
                'lable': '删除表识',
                'comment': '删除表识',
                'placeholder': '请输入删除表识',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'model': 1,
                'visible': false,
                'require': true
            }
        ]
    };

    otherForm = {
        "type": "normal",
        "columns": []
    };


    assetList: any = [];

    billList: any = [];

    closeAsset(pOneAsset) {
        this.confirmModal.show('confirm', {
            title: '是否将该资产，从租赁列表之中删除',
            suc: () => {
                this.assetList = _sh.without(this.assetList, pOneAsset);
            }
        });
    }


    modalCancel() {
        this.assetModalVisible = false;
    }


    findValue(pForm, pColumnName) {
        let bFind = false;
        let value = null;
        _sh.each(pForm['columns'], oneColumn => {
            if ((oneColumn['column'] == pColumnName) && (!bFind)) {
                value = oneColumn['model'];
                bFind = true;
            }
        });
        return value;
    }

    changeColumns(pForm, pColumnName, keyList, valueList) {

        let bFind = false;
        _sh.each(pForm['columns'], oneColumn => {
            if ((oneColumn['column'] == pColumnName) && (!bFind)) {
                for (let i = 0; i < keyList.length; i++) {
                    oneColumn[keyList[i]] = valueList[i]
                }
                bFind = true;
            }
        });
        return;
    }

    //获取保证金余额
    findEarnestMoney(){
        let custId = this.findValue(this.baseForm,'CUST_ID');
        console.log(custId);

    }

    fillTheTemplate() {
        if (this.checkInputMain() && this.checkInputItem()) {
            let fileName = this.findValue(this.baseForm, 'CON_CODE');
            let wordConfigId = this.findValue(this.baseForm, 'TEMPLATE_ID');
            let pdfCallBackUrl = this.config.config.hdbsthor_assets.getway_callBack + "/AppApi/WordProcessController/writeContractPdf?appToken=" + this.config.config.hdbsthor_assets.accessToken;
            const search = observableForkJoin(
                this.requestService.execWordProcess(wordConfigId, this.con_id, fileName, 'con_contracts', pdfCallBackUrl, "assets/autoJson/con_contracts_update.json")
            );
            search.subscribe(
                values => {
                    let result = _sh.get(values, [0, 'result']);
                    if (result) {
                        this.doSearch();
                        this.confirmModal.show('success', {'title': '执行填充合同成功'});
                    } else {
                        this.confirmModal.show('error', {'title': '执行错误', 'content': _sh.get(values, [0, 'error'])});
                    }
                }
            );
        }
    }


    makeConditions() {
        let conditions = {};
        _sh.each(this.conditionColumns['columns'], oneColum => {
            if (this.utils.Base_HasValue(oneColum['model'])) conditions[oneColum['column']] = oneColum['model'];
        })
        // 过滤我方资产
        conditions['COM_ID'] = '';
        if (this.utils.Base_HasValue(conditions)) {
            return conditions;
        } else {
            return null;
        }
    }

    // 监听『租赁资产及价格条款』选项卡的选中
    selectAssetTab() {
        if (this.checkInputMain()) {
            // this.changeShowForConType();
        }

        let type = this.findValue(this.baseForm, 'CON_TYPE')
        this.isRent = type != "PROPERTY" ? true : false       // "租赁"、"租赁+物业"时显示
        this.isProperty = type != "RENT" ? true : false   // "物业"、"租赁+物业"时显示
        _sh.each(this.assetList, oneAsset => {
            this.incrementSchemeChange(oneAsset)
        })

    }

    otherFormHasValue() {
        let bHasValue = false;
        _sh.each(this.otherForm['columns'], oneColumns=>{
            if (!bHasValue) {
                if (this.utils.Base_HasValue(oneColumns.model)) bHasValue = true
            }
        })
        return bHasValue;
    }

    // 监听『其他条款』选项卡的选中
    templateChange() {
        // 1 获取模板ID
        let modeType = _sh.get(this.parentData, ['modeType']);
        let templateId = this.findValue(this.baseForm, 'TEMPLATE_ID')
        if (templateId) {
            if(templateId != this.template_id){
                // 1.1 有ID，且更换了模板，则根据ID查询该模板下的其他条款
                this.findContractsOther(templateId, modeType);
                this.template_id = templateId
            }
        } else {
            // 1.2 无ID，则警告
            this.confirmModal.show('warning', {title: '选择合同模板后方能填写其他条款'});
        }
    }


    //获取保证金列表
    getMarginAmountList(){
        //查询第一个页面我方名称，相关方名称，管理片区字段是否已填
        let comId = this.findValue(this.baseForm,'COM_ID');
        let operatingId = this.findValue(this.baseForm,'OPERATING_ID');
        let custId = this.findValue(this.baseForm,'CUST_ID');
        let checkMargin = true;
        if(!this.utils.Base_HasValue(comId)){
            this.confirmModal.show('warning', {title: '请填写我方名称'});
            checkMargin = false;
        }
        if(!this.utils.Base_HasValue(custId)){
            this.confirmModal.show('warning', {title: '请填写相关方名称'});
            checkMargin = false;
        }
        if(!this.utils.Base_HasValue(operatingId)){
            this.confirmModal.show('warning', {title: '请填写管理片区'});
            checkMargin = false;
        }
        if(checkMargin){
            const search = observableForkJoin(
                this.requestService.getDataByCondition('assets/con_con/con_bill_manual_detail.json', {'CUST_ID': custId,'COM_ID':comId,'OPERATING_ID':operatingId})
            );
            search.subscribe(value => {
                console.log(value)
                this.depositList = _sh.get(value,[0,'data'],[]);
                console.log(this.depositList)
            })
        }
    }


    // 查询并创建『其他条款』
    findContractsOther(templateId, modeType) {
        const search = observableForkJoin(
            this.requestService.getDataByCondition('assets/con_con/con_contracts_other_definition_list.json', {'WORD_CONFIG_ID': templateId}), // 0:根据合同模板ID查询定义的条款
            this.requestService.getDataByCondition('assets/descriptions/con_contracts_other_descri.json', {'ID': this.con_id})                           // 1:根据合同ID查询填充了的其他条款
        );
        search.subscribe(values => {
            // 2 判断有无『其他条款』
            if (values[0]['data']) {
                // 2.1 若有『其他条款』，生成表单
                this.otherForm = _sh.cloneDeep(this.createOtherForm(values[0]['data'], 'add'));
                if (modeType == "add") {
                    // 3.1 新增模式
                } else if (modeType == "modify") {
                    // 3.2 编辑模式：填充数据
                    let oldDatas = _sh.get(values, [1, 'data']);
                    _sh.each(oldDatas, oneData => {
                        _sh.each(this.otherForm['columns'], oneColumn => {
                            if (oneColumn['lable'] == oneData['CLAUSE_NAME']) {
                                oneColumn['model'] = oneData['CLAUSE_TEXT'];
                            }
                        })
                    })
                }
            } else {
                // 2.2 若无『其他条款』，清空表单
                this.otherForm = _sh.cloneDeep({});
            }
        });
    }

    addNewAsset() {
        this.assetModalVisible = true;
        this.searchFilter(true);
    }

    createOtherForm(pData, pMode) {
        let form = {
            "type": "normal",
            "columns": [
                {
                    "column": "ID",
                    "iscondition": true,
                    "pk_column": true,
                    "name": "ID_NAME",
                    "component": "input",
                    'lable': "id",
                    'comment': "id",
                    "model": null,
                    "visible": false,
                    "require": false,
                    "show": true,
                    "step": 1,
                    "lableSpan": 9,
                    "itemSpan": 15
                }
            ]
        }
        _sh.each(pData, oneData => {
            let obj = {
                "column": oneData['ID'],
                "iscondition": false,
                "pk_column": false,
                "name": oneData['CLAUSE_EN_NAME'] + "_NAME",
                'lableSpan': 5,
                'itemSpan': 19,
                'customize': 24,
                "component": "textarea",
                'lable': oneData['CLAUSE_NAME'],
                'comment': oneData['CLAUSE_NAME'],
                "model": oneData['CLAUSE_TEXT'],
                'placeholder': '请输入' + oneData['CLAUSE_NAME'],
                "visible": true,
                "require": oneData['CON_REQUIRE'] == 'YES',
                "show": true
            }
            // @ts-ignore
            form['columns'].push(obj);
        });
        return form;
    }

    ƒ

    /**
     * 创建资产明细,租金 卡片
     * @param pData
     */
    createNewAssetWithRent(pData, pMode) {
        console.log(pData,pMode);
        let MARGIN_TYPE = '';
        let MARGIN_TYPE_SHOW = '';
        let RENTAL_PRICE = 0;
        let MARGIN_AMOUNT = 0;
        let INCREMENT_AMOUNT = 0;
        let RENT_FREE_PERIOD = 0;
        let PRICING_AMOUNT = 0;
        let MARGIN_AMOUNT_BEFORE = 0;
        if (pMode == 'add') {
            // RENTAL_PRICE 写建议价格
            RENTAL_PRICE = _sh.get(pData, ['PRICING_AMOUNT'], 0);
            PRICING_AMOUNT = _sh.get(pData, ['PRICING_AMOUNT'], 0);
            INCREMENT_AMOUNT = _sh.get(pData, ['INCREMENT_AMOUNT'], 0);
            MARGIN_AMOUNT_BEFORE = pData['MARGIN_AMOUNT_BEFORE'];
            MARGIN_TYPE = 'MONTHLY';
            MARGIN_TYPE_SHOW = 'MONTHLY';
        } else if (pMode == 'modify') {

            RENTAL_PRICE = pData['RENTAL_PRICE'];
            MARGIN_AMOUNT = pData['MARGIN_AMOUNT'];
            MARGIN_AMOUNT_BEFORE = pData['MARGIN_AMOUNT_BEFORE'];
            INCREMENT_AMOUNT = pData['INCREMENT_AMOUNT']
            RENT_FREE_PERIOD = pData['RENT_FREE_PERIOD'];
            PRICING_AMOUNT = pData['PRICING_AMOUNT'];
            MARGIN_TYPE = pData['MARGIN_TYPE'];
            MARGIN_TYPE_SHOW = pData['MARGIN_TYPE_SHOW'];
        }

        let form = {
            'type': 'normal',
            'columns': [
                {
                    'column': 'ID',
                    'iscondition': true,
                    'pk_column': true,
                    'name': 'ID_NAME',
                    'component': 'input',
                    'model': this.utils.Base_getUuid(),
                    'visible': false,
                    'require': true
                },
                {
                    'column': 'EXPENCE_TYPE',
                    'name': 'EXPENCE_TYPE_NAME',
                    'component': 'input',
                    'lable': '费用类型',
                    'comment': '费用类型',
                    'placeholder': '请输入费用类型',
                    'insertValue': null,
                    'model': 'RENT',
                    'visible': false,
                    'require': false
                },
                {
                    'column': 'AS_ID',
                    'iscondition': true,
                    'name': 'AS_ID_NAME',
                    'model': pData['AS_ID'],
                    'visible': false,
                    'require': true
                },
                {
                    'column': 'CONTRACTS_ID',
                    'name': 'CONTRACTS_ID_NAME',
                    'component': 'input',
                    'lable': '合同ID',
                    'comment': '合同ID',
                    'placeholder': '请输入合同ID',
                    'insertValue': null,
                    'model': null,
                    'visible': false,
                    'require': false
                },
                {
                    'column': 'LINK_NAME',
                    'name': 'LINK_NAME_NAME',
                    'component': 'input',
                    'lable': '资产名称',
                    'comment': '资产名称',
                    'lableSpan': 3,
                    'itemSpan': 21,
                    'customize': 24,
                    'placeholder': '请选择资产名称',
                    'insertValue': null,
                    'model': pData['LINK_NAME'],
                    'visible': true,
                    'require': false,
                    'disable': true
                },
                {
                    'column': 'AS_CODE',
                    'name': 'AS_CODE_NAME',
                    'component': 'input',
                    'lableSpan': 3,
                    'itemSpan': 21,
                    'customize': 24,
                    'lable': '资产编码',
                    'comment': '资产编码',
                    'placeholder': '请输入资产编码',
                    'fromValue': 'AS_CODE',
                    'model': pData['AS_CODE'],
                    'visible': true,
                    'require': false,
                    'disable': true
                },
                {
                    'column': 'MARGIN_TYPE_SHOW',
                    'name': 'MARGIN_TYPE_SHOW_NAME',
                    'component': 'select',
                    'lable': '租金支付方式',
                    'comment': '租金支付方式',
                    'placeholder': '请输入租金支付方式',
                    'insertValue': null,
                    'model': MARGIN_TYPE_SHOW,
                    'dictionary': 'assets/basic/dict/MARGIN_TYPE_SHOW.json',
                    'dictionaryPF': null,
                    'visible': true,
                    'require': true,
                    "columnGroup": [
                        {
                            "column": "MARGIN_TYPE",
                            "name": "MARGIN_TYPE_NAME",
                            "component": "input",
                            "lable": "支付方式",
                            "comment": "支付方式",
                            "placeholder": "支付方式",
                            "fromValue": "DICT_ITEM_DESC",
                            'model': MARGIN_TYPE,
                            "visible": false,
                            "require": false
                        }
                    ]
                },
                {
                    'column': 'RENTAL_PRICE',
                    'name': 'RENTAL_PRICE_NAME',
                    'lableSpan': 5,
                    'itemSpan': 7,
                    'customize': 16,
                    'component': 'input',
                    'type': 'number',
                    'lable': '租赁价格',
                    'comment': '租赁价格',
                    'placeholder': '请输入租赁价格',
                    'model': RENTAL_PRICE,
                    'visible': true,
                    'require': true
                },
                {
                    'column': 'MARGIN_AMOUNT_BEFORE',
                    'name': 'MARGIN_AMOUNT_BEFORE_NAME',
                    'component': 'input',
                    'type': 'number',
                    'lable': '前期保证金',
                    'comment': '前期保证金',
                    'pipe': {
                        'type': 'currency',
                        'symbol': '￥',
                        'display': true,
                        'digitInfo': '.1-2'
                    },
                    'model': MARGIN_AMOUNT_BEFORE,
                    'visible': false,
                    'require': false,
                    'disable': true
                },
                {
                    'column': 'MARGIN_AMOUNT',
                    'name': 'MARGIN_AMOUNT_NAME',
                    'component': 'input',
                    'type': 'number',
                    'lable': '本期保证金',
                    'comment': '本期保证金',
                    'placeholder': '请输入本期保证金',
                    'pipe': {
                        'type': 'currency',
                        'symbol': '￥',
                        'display': true,
                        'digitInfo': '.1-2'
                    },
                    'model': MARGIN_AMOUNT,
                    'visible': false,
                    'require': true
                },
                {
                    'column': 'RENT_FREE_PERIOD',
                    'name': 'RENT_FREE_PERIOD_NAME',
                    'component': 'input',
                    'type': 'number',
                    'lable': '免租期',
                    'comment': '免租期',
                    'placeholder': '请输入免租期',
                    'model': RENT_FREE_PERIOD,
                    'visible': true,
                    'require': true
                },
                {
                    'column': 'PRICING_TYPE',
                    'name': 'PRICING_TYPE_NAME',
                    'component': 'select',
                    'lableSpan': 3,
                    'itemSpan': 21,
                    'customize': 16,
                    'lable': '不足月定价方式',
                    'comment': '不足月定价方式',
                    'placeholder': '不足月定价方式',
                    'dictionary': 'assets/basic/dict/PRICING_TYPE.json',
                    'dictionaryPF': null,
                    'model': 'OVER15_COUNT',
                    'visible': true,
                    'require': true,
                    'disable': false
                },
                {
                    'column': 'PRICING_AREA_TYPE',
                    'name': 'PRICING_AREA_TYPE_NAME',
                    'component': 'select',
                    'lable': '用何种面积定价',
                    'comment': '用何种面积定价',
                    'placeholder': '请输入用何种面积定价',
                    'insertValue': null,
                    'model': pData['PRICING_AREA_TYPE'],
                    'dictionary': 'assets/basic/dict/PRICING_AREA_TYPE.json',
                    'dictionaryPF': null,
                    'visible': true,
                    'require': true
                },
                {
                    'column': 'AS_CONSTRUCTION_AREA',
                    'name': 'AS_CONSTRUCTION_AREA_NAME',
                    'component': 'input',
                    'lable': '资产建筑面积',
                    'comment': '资产建筑面积',
                    'placeholder': '资产建筑面积',
                    'model': pData['AS_CONSTRUCTION_AREA'],
                    'visible': true,
                    'require': false,
                    'disable': true
                },
                {
                    'column': 'AS_USABLE_AREA',
                    'name': 'AS_USABLE_AREA_NAME',
                    'component': 'input',
                    'lable': '资产租赁面积',
                    'comment': '资产租赁面积',
                    'placeholder': '资产租赁面积',
                    'model': pData['AS_USABLE_AREA'],
                    'visible': true,
                    'require': false,
                    'disable': true
                },
                {
                    'column': 'PRICING_AMOUNT',
                    'name': 'PRICING_AMOUNT_NAME',
                    'component': 'input',
                    'lable': '月合计',
                    'comment': '月合计',
                    'placeholder': '月合计',
                    'model': PRICING_AMOUNT,
                    'visible': true,
                    'require': false,
                    'disable': true
                },
                {
                    'column': 'AS_ADDRESS',
                    'name': 'AS_ADDRESS_NAME',
                    'component': 'textarea',
                    'lableSpan': 3,
                    'itemSpan': 21,
                    'customize': 24,
                    'lable': '资产地址',
                    'comment': '资产地址',
                    'placeholder': '资产地址',
                    'model': pData['AS_ADDRESS'],
                    'visible': true,
                    'require': false,
                    'disable': true
                },
                {
                    'column': 'INCREMENT_SCHEME',
                    'name': 'INCREMENT_SCHEME_NAME',
                    'component': 'select',
                    'lable': '递增方案',
                    'comment': '递增方案',
                    'placeholder': '请选择递增方案',
                    'insertValue': null,
                    'model': pData['INCREMENT_SCHEME'],
                    'dictionary': 'assets/basic/dict/INCREMENT_SCHEME.json',
                    'dictionaryPF': null,
                    'visible': false,
                    'require': false,
                    'disable': false
                },
                {
                    'column': 'INCREMENT_METHOD',
                    'name': 'INCREMENT_METHOD_NAME',
                    'component': 'select',
                    'lable': '递增方式',
                    'comment': '递增方式',
                    'placeholder': '请选择递增方式',
                    'insertValue': null,
                    'model': pData['INCREMENT_METHOD'],
                    'dictionary': 'assets/basic/dict/INCREMENT_METHOD.json',
                    'dictionaryPF': null,
                    'visible': false,
                    'require': false,
                    'disable': false
                },
                {
                    'column': 'INCREMENT_AMOUNT',
                    'name': 'INCREMENT_AMOUNT_NAME',
                    'component': 'input',
                    'type': 'number',
                    'step': 0.1,
                    'lable': '递增额',
                    'comment': '递增额',
                    'placeholder': '请输入递增额',
                    'model': INCREMENT_AMOUNT,
                    'visible': false,
                    'require': false,
                    'disable': false
                }
            ]
        };
        return form;
    }

    /**
     * 创建资产明细,物业 卡片
     * @param pData
     */
    createNewAssetWithProperty(pData, pMode) {
        let MARGIN_TYPE = '';
        let MARGIN_TYPE_SHOW = '';
        let RENTAL_PRICE = 0;
        let RENT_FREE_PERIOD = 0;
        let PRICING_AMOUNT = 0;
        if (pMode == 'add') {
            // RENTAL_PRICE 写建议价格
            RENTAL_PRICE = _sh.get(pData, ['PRICING_PROPERTY'], 0);
            PRICING_AMOUNT = _sh.get(pData, ['PRICING_PROPERTY'], 0);
            MARGIN_TYPE = 'MONTHLY';
        } else if (pMode == 'modify') {
            RENTAL_PRICE = pData['RENTAL_PRICE'];
            RENT_FREE_PERIOD = pData['RENT_FREE_PERIOD'];
            PRICING_AMOUNT = pData['RENT_FREE_PERIOD'];
            MARGIN_TYPE = pData['MARGIN_TYPE'];
            MARGIN_TYPE_SHOW = pData['MARGIN_TYPE_SHOW'];
        }

        let form = {
            'type': 'normal',
            'columns': [
                {
                    'column': 'ID',
                    'iscondition': true,
                    'pk_column': true,
                    'name': 'ID_NAME',
                    'component': 'input',
                    'model': this.utils.Base_getUuid(),
                    'visible': false,
                    'require': true
                },
                {
                    'column': 'EXPENCE_TYPE',
                    'name': 'EXPENCE_TYPE_NAME',
                    'component': 'input',
                    'lable': '费用类型',
                    'comment': '费用类型',
                    'placeholder': '请输入费用类型',
                    'insertValue': null,
                    'model': 'PROPERTY',
                    'visible': false,
                    'require': false
                },
                {
                    'column': 'AS_ID',
                    'iscondition': true,
                    'name': 'AS_ID_NAME',
                    'model': pData['AS_ID'],
                    'visible': false,
                    'require': true
                },
                {
                    'column': 'CONTRACTS_ID',
                    'name': 'CONTRACTS_ID_NAME',
                    'component': 'input',
                    'lable': '合同ID',
                    'comment': '合同ID',
                    'placeholder': '请输入合同ID',
                    'insertValue': null,
                    'model': null,
                    'visible': false,
                    'require': false
                },
                {
                    'column': 'LINK_NAME',
                    'name': 'LINK_NAME_NAME',
                    'component': 'input',
                    'lable': '资产名称',
                    'comment': '资产名称',
                    'lableSpan': 3,
                    'itemSpan': 21,
                    'customize': 24,
                    'placeholder': '请选择资产名称',
                    'insertValue': null,
                    'model': pData['LINK_NAME'],
                    'visible': true,
                    'require': false,
                    'disable': true
                },
                {
                    'column': 'AS_CODE',
                    'name': 'AS_CODE_NAME',
                    'component': 'input',
                    'lableSpan': 3,
                    'itemSpan': 21,
                    'customize': 24,
                    'lable': '资产编码',
                    'comment': '资产编码',
                    'placeholder': '请输入资产编码',
                    'fromValue': 'AS_CODE',
                    'model': pData['AS_CODE'],
                    'visible': true,
                    'require': false,
                    'disable': true
                },
                {
                    'column': 'MARGIN_TYPE_SHOW',
                    'name': 'MARGIN_TYPE_SHOW_NAME',
                    'component': 'select',
                    'lable': '物业费支付方式',
                    'comment': '物业费支付方式',
                    'placeholder': '请输入物业费支付方式',
                    'insertValue': null,
                    'model': MARGIN_TYPE_SHOW,
                    'dictionary': 'assets/basic/dict/MARGIN_TYPE_SHOW.json',
                    'dictionaryPF': null,
                    'visible': true,
                    'require': true,
                    "columnGroup": [
                        {
                            "column": "MARGIN_TYPE",
                            "name": "MARGIN_TYPE_NAME",
                            "component": "input",
                            "lable": "支付方式",
                            "comment": "支付方式",
                            "placeholder": "支付方式",
                            "fromValue": "DICT_ITEM_DESC",
                            'model': MARGIN_TYPE,
                            "visible": false,
                            "require": false
                        }
                    ]
                },
                {
                    'column': 'RENTAL_PRICE',
                    'name': 'RENTAL_PRICE_NAME',
                    'lableSpan': 5,
                    'itemSpan': 7,
                    'customize': 16,
                    'component': 'input',
                    'type': 'number',
                    'lable': '物业费 /（㎡）/ 月',
                    'comment': '物业费 /（㎡）/ 月',
                    'placeholder': '请输入物业费 /（㎡）/ 月',
                    'model': RENTAL_PRICE,
                    'visible': true,
                    'require': true
                },
                {
                    'column': 'RENT_FREE_PERIOD',
                    'name': 'RENT_FREE_PERIOD_NAME',
                    'component': 'input',
                    'type': 'number',
                    'lable': '免租期',
                    'comment': '免租期',
                    'placeholder': '请输入免租期',
                    'model': RENT_FREE_PERIOD,
                    'visible': true,
                    'require': true
                },
                {
                    'column': 'PRICING_TYPE',
                    'name': 'PRICING_TYPE_NAME',
                    'component': 'select',
                    'lableSpan': 3,
                    'itemSpan': 21,
                    'customize': 16,
                    'lable': '不足月定价方式',
                    'comment': '不足月定价方式',
                    'placeholder': '不足月定价方式',
                    'dictionary': 'assets/basic/dict/PRICING_TYPE.json',
                    'dictionaryPF': null,
                    'model': 'OVER15_COUNT',
                    'visible': true,
                    'require': true,
                    'disable': false
                },
                {
                    'column': 'PRICING_AREA_TYPE',
                    'name': 'PRICING_AREA_TYPE_NAME',
                    'component': 'select',
                    'lable': '用何种面积定价',
                    'comment': '用何种面积定价',
                    'placeholder': '请输入用何种面积定价',
                    'insertValue': null,
                    'model': pData['PRICING_AREA_TYPE'],
                    'dictionary': 'assets/basic/dict/PRICING_AREA_TYPE.json',
                    'dictionaryPF': null,
                    'visible': true,
                    'require': true
                },
                {
                    'column': 'AS_CONSTRUCTION_AREA',
                    'name': 'AS_CONSTRUCTION_AREA_NAME',
                    'component': 'input',
                    'lable': '资产建筑面积',
                    'comment': '资产建筑面积',
                    'placeholder': '资产建筑面积',
                    'model': pData['AS_CONSTRUCTION_AREA'],
                    'visible': true,
                    'require': false,
                    'disable': true
                },
                {
                    'column': 'AS_USABLE_AREA',
                    'name': 'AS_USABLE_AREA_NAME',
                    'component': 'input',
                    'lable': '资产租赁面积',
                    'comment': '资产租赁面积',
                    'placeholder': '资产租赁面积',
                    'model': pData['AS_USABLE_AREA'],
                    'visible': true,
                    'require': false,
                    'disable': true
                },
                {
                    'column': 'PRICING_AMOUNT',
                    'name': 'PRICING_AMOUNT_NAME',
                    'component': 'input',
                    'lable': '资产物业限价',
                    'comment': '资产物业限价',
                    'placeholder': '资产物业限价',
                    'model': PRICING_AMOUNT,
                    'visible': true,
                    'require': false,
                    'disable': true
                },
                {
                    'column': 'AS_ADDRESS',
                    'name': 'AS_ADDRESS_NAME',
                    'component': 'textarea',
                    'lableSpan': 3,
                    'itemSpan': 21,
                    'customize': 24,
                    'lable': '资产地址',
                    'comment': '资产地址',
                    'placeholder': '资产地址',
                    'model': pData['AS_ADDRESS'],
                    'visible': true,
                    'require': false,
                    'disable': true
                }
            ]
        };
        return form;
    }


    doSearch() {
        function makeUp(pOneAssetBillList) {
            // 3.1 获取该资产下所有账单
            let rdLen = 0; // 保证金分期
            let rdSum = 0; // 保证金总金额
            let rLen = 0;  // 租赁分期
            let rSum = 0;  // 租赁总金额
            let pLen = 0;  // 物业分期
            let pSum = 0;  // 物业总金额
            _sh.each(pOneAssetBillList, oneBill => {
                if (oneBill['EXPENCE_TYPE'] == 'RENT_DEPOSIT') {
                    rdLen += 1;
                    rdSum += oneBill['BILL_AMOUNT']
                } else if (oneBill['EXPENCE_TYPE'] == 'RENT') {
                    rLen += 1;
                    rSum += oneBill['BILL_AMOUNT']
                } else if (oneBill['EXPENCE_TYPE'] == 'PROPERTY') {
                    pLen += 1;
                    pSum += oneBill['BILL_AMOUNT']
                }
            })
            let compiled = _sh.template("保证金：<%= rdLen %> 期，共计：￥ <%= rdSum %>；  租金：<%= rLen %> 期，共计：￥ <%= rSum %> ；物业费：<%= pLen %> 期，共计：￥ <%= pSum %> ");
            let compliObj = {
                'rdLen': rdLen,
                'rdSum': rdSum.toFixed(2),
                'rLen': rLen,
                'rSum': rSum.toFixed(2),
                'pLen': pLen,
                'pSum': pSum.toFixed(2)
            }
            const msgText = compiled(compliObj);
            return msgText;
        }

        let that = this;
        const search = observableForkJoin(
            this.requestService.getDataByCondition('assets/con_contracts_add/con_contracts.json', {'ID': that.con_id}), // 0
            this.requestService.getDataByCondition('assets/con_contracts_add/con_contracts_detail.json', {'CONTRACTS_ID': that.con_id}), // 1
            this.requestService.getDataByCondition('assets/con_contracts_add/con_bill.json', {'CON_ID': that.con_id}), // 2
            this.requestService.getDataByCondition('assets/con_contracts_add/con_contracts_assets_list.json', {'CONTRACTS_ID': that.con_id}), // 3
        );
        search.subscribe(
            values => {
                // 0：获取合同详情
                that.backfillData(that.baseForm, values[0]['data']);
                // 3：获取合同下所有资产
                that.assetList = [];
                _sh.each(_sh.get(values, [3, 'data'], []), oneAsset => {
                    let tempAsset = {
                        'ID': oneAsset['ID'],
                        'LINK_NAME': oneAsset['LINK_NAME'],
                        'AS_ID': oneAsset['AS_ID'],
                        'AS_CODE': oneAsset['AS_CODE'],
                        'AS_NAME': oneAsset['AS_NAME'],
                        'formRent': null,
                        'formProperty': null,
                        'billSum': '',
                        'billList': ''
                    }
                    that.assetList.push(tempAsset);
                })

                // 遍历每一种资产，获取其明细
                _sh.each(that.assetList, oneAsset => {
                    let conContractsDetailList = _sh.get(values, [1, 'data'], []);
                    let conBillList = _sh.get(values, [2, 'data'], []);

                    let oneRentDetail = _sh.filter(conContractsDetailList, oneDetail => {
                        return (oneAsset['AS_ID'] == oneDetail['AS_ID']) && (oneDetail['EXPENCE_TYPE'] == 'RENT');
                    })
                    if (that.utils.Base_HasValue(oneRentDetail)) {
                        oneAsset['formRent'] = _sh.cloneDeep(this.createNewAssetWithRent(oneRentDetail[0], 'modify'));
                    }

                    let onePropertyDetail = _sh.filter(conContractsDetailList, oneDetail => {
                        return (oneAsset['AS_ID'] == oneDetail['AS_ID']) && (oneDetail['EXPENCE_TYPE'] == 'PROPERTY');
                    })
                    if (that.utils.Base_HasValue(onePropertyDetail)) {
                        oneAsset['formProperty'] = _sh.cloneDeep(this.createNewAssetWithProperty(onePropertyDetail[0], 'modify'));
                    }
                    let oneAssetBill = _sh.filter(conBillList, oneBill => {
                        return oneAsset['AS_ID'] == oneBill['AS_ID'];
                    })

                    if (that.utils.Base_HasValue(oneAssetBill)) {
                        _sh.each(oneAssetBill, oneBill => {
                            let msgText = null;
                            if (_sh.includes(['RENT', 'PROPERTY'], oneBill['EXPENCE_TYPE'])) {
                                let compiled = _sh.template("<%= START_DATE %> 至 <%= END_DATE %>；（<%= PAY_DAY %> / <%= TRUE_DAY %> ）");
                                let compliObj = {
                                    'START_DATE': oneBill['START_DATE'],
                                    'END_DATE': oneBill['END_DATE'],
                                    'PAY_DAY': oneBill['TRUE_DAY'] - oneBill['FREE_DAY'],
                                    'TRUE_DAY': oneBill['TRUE_DAY'],
                                }
                                msgText = compiled(compliObj);
                            } else {
                                let compiled = _sh.template("<%= START_DATE %> 至 <%= END_DATE %>");
                                let compliObj = {
                                    'START_DATE': oneBill['START_DATE'],
                                    'END_DATE': oneBill['END_DATE']
                                }
                                msgText = compiled(compliObj);
                            }
                            oneBill['msgText'] = msgText;
                        })

                        oneAsset['billList'] = oneAssetBill;
                        oneAsset['billSum'] = makeUp(oneAssetBill);
                    }
                })

                // 查询其他条款
                let modeType = _sh.get(this.parentData, ['modeType']);
                let templateId = values[0]['data'][0]['TEMPLATE_ID']
                this.findContractsOther(templateId, modeType);

                // 获取相关方名称，以便查询附件信息
                that.cus_id = values[0]['data'][0]['CUST_ID']
                this.getAppendix(that.cus_id)

                // 获取合同类型，以便控制租赁、物业卡片的显示
                let contract_type = values[0]['data'][0]['CON_TYPE']
                that.isRent = contract_type != "PROPERTY" ? true : false   // "租赁"、"租赁+物业"时显示
                that.isProperty = contract_type != "RENT" ? true : false   // "物业"、"租赁+物业"时显示


                // this.changeShowForConType();
            }
        );
    }

    //获取附件信息
    getAppendix(id) {
        const search = observableForkJoin(
            this.requestService.getDataByCondition('assets/con_contracts_add/con_appendix.json', {'CUST_ID': id})
        );
        search.subscribe(values => {
            let that = this;
            let appendixList = []
            let type = _sh.get(values, [0, 'data', 0, 'CUST_TYPE'], null)
            if (type == 'enterprise') {
                appendixList.push({
                    'name': '营业执照',
                    'type': type,
                    'download': _sh.get(values, [0, 'data', 0, 'CUST_PIC'], null)
                })
            }
            // 可在此处展示需要的附件
            appendixList.push({
                'name': '身份证正面',
                'type': type,
                'download': _sh.get(values, [0, 'data', 0, 'SFZ_FRONT'], null)
            }, {
                'name': '身份证反面',
                'type': type,
                'download': _sh.get(values, [0, 'data', 0, 'SFZ_BACK'], null)
            }, {
                'name': '信用查询报告',
                'type': type,
                'download': _sh.get(values, [0, 'data', 0, 'CREDIT_REPORT'], null)
            })
            that.appendixTable = appendixList
        })

    }

    // 附件刷新(存在相关方ID才能刷新)
    doAppendixRefresh() {
        let cust_id = this.findValue(this.baseForm, ['CUST_ID']);
        this.cus_id = cust_id
        if (cust_id) {
            this.getAppendix(cust_id)
        } else {
            this.confirmModal.show('warning', {title: '填写相关方后方能查询'});
        }
    }


    // 开口合同：账单列表——新增账单
    doAddOpenBill() {
        let addOpenBillFormData = [
            {
                "column": "COM_ID",
                "name": "COM_ID_NAME",
                "component": "input",
                "customize": 24,
                "lable": "COM_ID",
                "comment": "COM_ID",
                "placeholder": "请选择COM_ID",
                "insertValue": null,
                "model": this.findValue(this.baseForm, 'COM_ID'),
                "visible": false,
                "require": false,
                "dictionaryPF": null,
                "fromValue": "SELECT.0.COM_ID"
            },
            {
                "column": "AS_ID",
                "name": "AS_ID_NAME",
                "component": "poplist",
                "customize": 24,
                "lable": "资产名称",
                "comment": "资产名称",
                "placeholder": "请选择资产名称",
                "insertValue": null,
                "model": null,
                "visible": true,
                "require": true,
                "dictionary": "assets/basic/dict/CON_OPEN_ASSET_POPLIST.json",
                "dictionaryPF": null,
                "dictionaryParam": [
                    {
                        "column": "COM_ID",
                        "value": this.findValue(this.baseForm, 'COM_ID'),
                        "errormsg": "请先选择转出公司"
                    },
                    {
                        "column": "OPERATING_ID",
                        "value": this.findValue(this.baseForm, 'OPERATING_ID'),
                        "errormsg": "请先选择转出公司"
                    }
                ]
            },
            {
                "column": "CON_OPEN_BILL_AMOUNT",
                "name": "CON_OPEN_BILL_AMOUNT_NAME",
                "component": "input",
                "type": "number",
                "customize": 24,
                "lable": "账单金额",
                "comment": "账单金额",
                "placeholder": "请输入账单金额",
                "model": null,
                "visible": true,
                "require": true,
                "dictionaryPF": null
            },
            {
                "column": "CON_OPEN_START_DATE",
                "name": "CON_OPEN_START_DATE_NAME",
                "customize": 24,
                "component": "date",
                "lable": "开始日期",
                "comment": "开始日期",
                "placeholder": "请输入开始日期",
                "model": null,
                "visible": true,
                "require": true,
                "dictionaryPF": null
            },
            {
                "column": "CON_OPEN_END_DATE",
                "name": "CON_OPEN_BILL_END_DATE",
                "customize": 24,
                "component": "date",
                "lable": "结束日期",
                "comment": "结束日期",
                "placeholder": "请输入结束日期",
                "model": null,
                "visible": true,
                "require": true,
                "dictionaryPF": null
            },
            {
                "column": "CON_OPEN_BILL_DATE",
                "name": "CON_OPEN_BILL_DATE_NAME",
                "customize": 24,
                "component": "date",
                "lable": "账单日期",
                "comment": "账单日期",
                "placeholder": "请输入账单日期",
                "model": null,
                "visible": true,
                "require": true,
                "dictionaryPF": null
            },
            {
                "column": "CON_OPEN_EXPENCE_TYPE",
                "name": "CON_OPEN_EXPENCE_TYPE_NAME",
                "component": "select",
                "customize": 24,
                "lable": "费用类型",
                "comment": "费用类型",
                "placeholder": "请选择费用类型",
                "insertValue": null,
                "model": null,
                "visible": true,
                "require": true,
                "dictionary": "assets/basic/dict/EXPENCE_TYPE.json",
                "dictionaryPF": null
            },
            {
                "column": "CON_OPEN_BILL_TYPE",
                "name": "CON_OPEN_BILL_TYPE_NAME",
                "component": "select",
                "customize": 24,
                "lable": "账单类型",
                "comment": "账单类型",
                "placeholder": "请选择账单类型",
                "insertValue": null,
                "model": null,
                "visible": true,
                "require": true,
                "dictionary": "assets/basic/dict/BILL_TYPE.json",
                "dictionaryPF": null
            }];
        this.modalService.create({
            nzTitle: '新增账单',
            nzContent: FormModalComponent,
            nzWidth: 600,
            nzComponentParams: {
                modalFormData: {
                    'columns': addOpenBillFormData
                }
            },
            nzOnOk: (res) => {
                const data = res.modalFormData.columns;

                // 1 表单校验
                let requireCheck = true;
                _sh.each(data, oneColumn => {
                    if (!this.utils.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
                        requireCheck = false;
                    }
                })
                if (!requireCheck) {
                    this.confirmModal.show('error', {title: '请填写必填信息。'});
                    return false
                }

                // 2 参数准备
                let queryData = {
                    'userId': this.user.USER_ID,
                    'modeType': this.parentData.modeType
                }

                let entity = {}
                for (let i = 0; i < data.length; i++) {
                    let c = data[i]['column']
                    let v = data[i]['model']
                    switch (c) {
                        case "AS_ID":
                            entity['asId'] = v;
                            break;
                        case "CON_OPEN_BILL_AMOUNT":
                            entity['billAmount'] = v;
                            break;
                        case "CON_OPEN_START_DATE":
                            entity['startDate'] = v;
                            break;
                        case "CON_OPEN_END_DATE":
                            entity['endDate'] = v;
                            break;
                        case "CON_OPEN_BILL_DATE":
                            entity['arapDate'] = v;
                            break;
                        case "CON_OPEN_EXPENCE_TYPE":
                            entity['expenceType'] = v;
                            break;
                        case "CON_OPEN_BILL_TYPE":
                            entity['billType'] = v;
                            break;
                    }
                }
                let bodyData = {
                    'ids':[{id:this.con_id}],
                    'conBillList':[entity]
                }
                if(this.parentData.modeType == "add"){   // 如果是添加状态，增加一个UUID
                    if (this.checkInputMain() && this.checkInputItem() && this.checkOther()) {
                        _sh.each(this.baseForm['columns'], oneColumn => {
                            if (oneColumn['column'] == 'ID') {
                                oneColumn['model'] = this.utils.Base_getUuid();
                                this.con_id = oneColumn['model'];
                            }
                        });
                        bodyData['conContracts'] = this.formatToEntity(this.baseForm['columns']);
                        bodyData['conContractsOtherList'] = this.formatToOtherEntity(this.otherForm['columns']);
                        bodyData['conContractsDetailList'] = [];
                    }
                }
                // 3 保存账单
                this.requestService.doActionByHdbsthor('AppApi/ConContractAuditController', 'doOpenContractBillCreate', queryData, bodyData, false, 'hdbsthor_assets', 'appToken').subscribe(response => {
                    let status = _sh.get(response, ['status'], 200);
                    if (status == 200) {
                        this.confirmModal.show('success', {'title': '执行账单新增成功'});
                        this.con_id = _sh.get(response, ['contractInerface', 'conContracts', 'id'], this.con_id);
                        this.parentData.modeType = "modify"; // 因为保存过了，直接把模式变成修改模式。
                        this.doSearch()
                    } else if (status == 500) {
                        this.confirmModal.show('error', {'title': '执行账单新增错误如下', 'content': response.error.message});
                    }
                });
            }
        })
    }


    backfillData(pForm, pDataArray) {
        _sh.each(pDataArray, oneData => {
            _sh.each(pForm['columns'], oneColumn => {
                oneColumn['model'] = oneData[oneColumn['column']];
                if (_sh.includes(['poplist', 'poplistedit'], oneColumn['component'])) {
                    oneColumn['modelName'] = oneData[oneColumn['column'] + '_DICT'];
                }

                if (oneColumn['component'] == 'file') {
                    let url = oneData[oneColumn['column']];
                    let fileName = this.utils.Base_getFileNameOnly(url);
                    oneColumn['fileList'] = [];
                    oneColumn['fileList'].push(
                        {
                            uid: this.utils.Base_getUuid(),
                            name: fileName,
                            status: 'done',
                            url: url
                        }
                    )

                }

                _sh.each(oneColumn['columnGroup'], oneColumnGroup => {
                    oneColumnGroup['model'] = oneData[oneColumnGroup['column']];
                    if (_sh.includes(['poplist', 'poplistedit'], oneColumnGroup['component'])) {
                        oneColumnGroup['modelName'] = oneData[oneColumnGroup['column'] + '_DICT'];
                    }
                })

            })
        })
    }

    // 监听递增方案，更改相应字段
    incrementSchemeChange(oneAsset) {
        if (oneAsset['formRent']) {
            let incrementScheme = this.findValue(oneAsset['formRent'], ['INCREMENT_SCHEME'])
            if (incrementScheme == 'NOINCREASE') {
                // 不递增，则不填递增方式、递增金额
                this.changeColumns(oneAsset['formRent'], 'INCREMENT_METHOD', ['disable', 'require', 'model'], [true, false, null])
                this.changeColumns(oneAsset['formRent'], 'INCREMENT_AMOUNT', ['disable', 'require', 'model'], [true, false, null])
            } else {
                // 有递增，则显示递增方式、递增金额
                this.changeColumns(oneAsset['formRent'], 'INCREMENT_METHOD', ['disable', 'require'], [false, true])
                this.changeColumns(oneAsset['formRent'], 'INCREMENT_AMOUNT', ['disable', 'require'], [false, true])
            }
        }

    }

    // 动态监听租赁表单变化
    rentChange(i) {
        this.incrementSchemeChange(this.assetList[i]);   // 监听递增方案
    }

    // 动态监听物业表单变化
    propertyChange(i) {
    }

    // 删除账单
    deleteBill(billId){
        this.confirmModal.show('confirm', {
            title: '是否删除该账单',
            suc: () => {
                // 2 参数准备
                let queryData = {
                    'userId': this.user.USER_ID,
                    'conBillId': billId
                }
                this.requestService.doActionByHdbsthor('AppApi/ConContractAuditController', 'doOpenContractBillDelete', queryData, null, false, 'hdbsthor_assets', 'appToken').subscribe(response => {
                    let status = _sh.get(response, ['status'], 200);
                    if (status == 200) {
                        this.confirmModal.show('success', {'title': '执行成功'});
                        this.doSearch()
                    } else if (status == 500) {
                        this.confirmModal.show('error', {'title': '执行错误如下', 'content': response.error.message});
                    }
                });
            }
        });

    }

    ngOnInit() {
        let that = this;
        // 判断合同类型
        let btnName = _sh.get(this.parentData, ['btnName']).split('.')[3];
        let normalContractsBtn = ['newRentContract', 'modifyRentContract']
        let openContractsBtn = ['openContract']
        let otherContractsBtn = ['otherContract']
        if (normalContractsBtn.includes(btnName)) {
            that.con_type = 'normalContract'
        } else if (openContractsBtn.includes(btnName)) {
            that.con_type = 'openContract'
            that.baseForm['columns'][5]['dictionary'] = 'assets/basic/dict/CON_OPEN_TYPE.json'; // 修改合同类型
            // that.baseForm['columns'] = _.dropWhile(that.baseForm['columns'], {'column':'ARAP_DATE'});
            let findColumns = _sh.filter(that.baseForm['columns'], oneColumn => {
                return _sh.includes(['PAY_TYPE', 'ARAP_DATE'], oneColumn['column']);
            })
            _sh.each(findColumns, oneColumn => {
                that.baseForm['columns'] = _sh.without(that.baseForm['columns'], oneColumn);
            })
        } else if (otherContractsBtn.includes(btnName)){
            that.con_type = 'otherContract'
        }
        // 判断模式
        that.baseForm.modalModeType = that.parentData.modeType; // "modify";
        let modeType = _sh.get(this.parentData, ['modeType']);
        if (modeType == 'modify') {
            that.con_id = _sh.cloneDeep(_sh.get(this.parentData, ['id']));
            that.doSearch();
        }

    }

   public rentModifyForm= {
            'type': 'normal',
            'columns': [
                {
                    'column': 'MARGIN_TYPE_SHOW',
                    'name': 'MARGIN_TYPE_SHOW_NAME',
                    'component': 'select',
                    'lable': '租金支付方式',
                    'comment': '租金支付方式',
                    'placeholder': '请输入租金支付方式',
                    'model': null,
                    'dictionary': 'assets/basic/dict/MARGIN_TYPE_SHOW.json',
                    'dictionaryPF': null,
                    'visible': true,
                    'require': true,
                    "columnGroup": [
                        {
                            "column": "MARGIN_TYPE",
                            "name": "MARGIN_TYPE_NAME",
                            "component": "input",
                            "lable": "支付方式",
                            "comment": "支付方式",
                            "placeholder": "支付方式",
                            "fromValue": "DICT_ITEM_DESC",
                            'model': null,
                            "visible": false,
                            "require": false
                        }
                    ]
                },
                {
                    'column': 'RENTAL_PRICE',
                    'name': 'RENTAL_PRICE_NAME',
                    'component': 'input',
                    'type': 'number',
                    'lable': '租赁价格',
                    'comment': '租赁价格',
                    'placeholder': '请输入租赁价格',
                    'model': null,
                    'visible': true,
                    'require': true
                },
                {
                    'column': 'MARGIN_AMOUNT',
                    'name': 'MARGIN_AMOUNT_NAME',
                    'component': 'input',
                    'type': 'number',
                    'lable': '本期保证金',
                    'comment': '本期保证金',
                    'placeholder': '请输入本期保证金',
                    'pipe': {
                        'type': 'currency',
                        'symbol': '￥',
                        'display': true,
                        'digitInfo': '.1-2'
                    },
                    'model': 0,
                    'visible': false,
                    'require': true
                },
                {
                    'column': 'RENT_FREE_PERIOD',
                    'name': 'RENT_FREE_PERIOD_NAME',
                    'component': 'input',
                    'type': 'number',
                    'lable': '免租期',
                    'comment': '免租期',
                    'placeholder': '请输入免租期',
                    'model': 0,
                    'visible': true,
                    'require': true
                },
                {
                    'column': 'PRICING_AREA_TYPE',
                    'name': 'PRICING_AREA_TYPE_NAME',
                    'component': 'select',
                    'lable': '用何种面积定价',
                    'comment': '用何种面积定价',
                    'placeholder': '请输入用何种面积定价',
                    'model': 'AS_USABLE_AREA',
                    'dictionary': 'assets/basic/dict/PRICING_AREA_TYPE.json',
                    'dictionaryPF': null,
                    'visible': true,
                    'require': true
                },
                {
                    'column': 'PRICING_TYPE',
                    'name': 'PRICING_TYPE_NAME',
                    'component': 'select',
                    'lableSpan': 3,
                    'itemSpan': 21,
                    'customize': 16,
                    'lable': '不足月定价方式',
                    'comment': '不足月定价方式',
                    'placeholder': '不足月定价方式',
                    'dictionary': 'assets/basic/dict/PRICING_TYPE.json',
                    'dictionaryPF': null,
                    'model': 'OVER15_COUNT',
                    'visible': true,
                    'require': true,
                    'disable': false
                }
            ]
        }

    public propertyModifyForm = {
        'type': 'normal',
        'columns': [
            {
                'column': 'MARGIN_TYPE_SHOW',
                'name': 'MARGIN_TYPE_SHOW_NAME',
                'component': 'select',
                'lable': '物业费支付方式',
                'comment': '物业费支付方式',
                'placeholder': '请输入物业费支付方式',
                'model': null,
                'dictionary': 'assets/basic/dict/MARGIN_TYPE_SHOW.json',
                'dictionaryPF': null,
                'visible': true,
                'require': true,
                "columnGroup": [
                    {
                        "column": "MARGIN_TYPE",
                        "name": "MARGIN_TYPE_NAME",
                        "component": "input",
                        "lable": "支付方式",
                        "comment": "支付方式",
                        "placeholder": "支付方式",
                        "fromValue": "DICT_ITEM_DESC",
                        'model': null,
                        "visible": false,
                        "require": false
                    }
                ]
            },
            {
                'column': 'RENTAL_PRICE',
                'name': 'RENTAL_PRICE_NAME',
                'component': 'input',
                'type': 'number',
                'lable': '物业费 /（㎡）/ 月',
                'comment': '物业费 /（㎡）/ 月',
                'placeholder': '请输入物业费 /（㎡）/ 月',
                'model': null,
                'visible': true,
                'require': true
            },
            {
                'column': 'RENT_FREE_PERIOD',
                'name': 'RENT_FREE_PERIOD_NAME',
                'component': 'input',
                'type': 'number',
                'lable': '免租期',
                'comment': '免租期',
                'placeholder': '请输入免租期',
                'model': 0,
                'visible': true,
                'require': true
            },
            {
                'column': 'PRICING_AREA_TYPE',
                'name': 'PRICING_AREA_TYPE_NAME',
                'component': 'select',
                'lable': '用何种面积定价',
                'comment': '用何种面积定价',
                'placeholder': '请输入用何种面积定价',
                'model': 'AS_USABLE_AREA',
                'dictionary': 'assets/basic/dict/PRICING_AREA_TYPE.json',
                'dictionaryPF': null,
                'visible': true,
                'require': true
            },
            {
                'column': 'PRICING_TYPE',
                'name': 'PRICING_TYPE_NAME',
                'component': 'select',
                'lableSpan': 3,
                'itemSpan': 21,
                'customize': 16,
                'lable': '不足月定价方式',
                'comment': '不足月定价方式',
                'placeholder': '不足月定价方式',
                'dictionary': 'assets/basic/dict/PRICING_TYPE.json',
                'dictionaryPF': null,
                'model': 'OVER15_COUNT',
                'visible': true,
                'require': true,
                'disable': false
            }
        ]
    }

    modifyAsset() {
        this.modifyAssetModalVisible = true;
    }

    ok() {
        _sh.each(this.assetList,oneAsset=>{
            _sh.each(_sh.get(oneAsset['formRent'],['columns']),oneData=>{
                _sh.each(_sh.get(this.rentModifyForm,['columns']),onePut=>{
                    if(oneData['column']==onePut['column']){
                        oneData['model'] = onePut['model']
                    }
                })

            });
            _sh.each(_sh.get(oneAsset['formProperty'],['columns']),oneData=>{
                _sh.each(_sh.get(this.propertyModifyForm,['columns']),onePut=>{
                    if(oneData['column']==onePut['column']){
                        oneData['model'] = onePut['model']
                    }
                })
            })
        });
        this.modifyAssetModalVisible = false;
    }

    allMarginChecked = false;
    marginIndeterminate = false;

    checkMarginAll() {
        _sh.each(this.depositList,oneData=>{
            oneData.checked = this.allMarginChecked
        });
        this.checkSingle();
    }

    checkSingle() {
        const validData = this.depositList.filter(value => !value.disabled);
        const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
        const allUnChecked = validData.every(value => !value.checked);
        this.marginIndeterminate = (!allChecked) && (!allUnChecked);
        this.getMarginAmount();
    }
    getMarginAmount(){
        let amount = 0;
        _sh.each(this.depositList,oneData=>{
            if(oneData.checked==true){
                amount = amount+oneData['BILL_AMOUNT'];
            }
        })
        this.marginAmount = amount;
        _sh.each(this.baseForm['columns'],oneColumn=>{
            if(oneColumn['column'] == 'MARGIN_AMOUNT'){
                oneColumn['model'] = this.marginAmount
            }
        })
    }
}
