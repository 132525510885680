import {Injectable} from '@angular/core';
// import {JieliService} from "../project/jieli/jieli.service";
import * as _sh from 'lodash';
import {ConfirmModalService} from "./confirm-modal.service";
import {RequestDataService} from "./request-data.service";
import {UserInfoService} from "./user-info.service";

@Injectable({
  providedIn: 'root'
})
export class ServiceCenterService {

  constructor(private confirmModal: ConfirmModalService,
              private requestService: RequestDataService,
              private userInfoService: UserInfoService,
  ) {
  }

  doChangeListAction(pForms, pExecList, pServicePath) {
    let serviceArray = _sh.split(pServicePath, '.');
    let serviceName = _sh.get(serviceArray, [0], null);
    let methodName = _sh.get(serviceArray, [1], null);

    //统计保证金
    if (serviceName == 'assetsService') {
      const userId = this.userInfoService.getUserInfo().USER_ID;
      if (methodName == 'getCustBond') {
        console.log(pForms);
        console.log(pExecList);
        const custId = _sh.get(pExecList,[0,'data',0,'CUST_ID'], null);
        console.log("客户名称",custId);
        this.requestService.doActionByHdbsthor('AppApi/BasicCustomerController', 'getEarnestMoneyEveryBasicCustomer', { 'userId':userId,'custId':custId }, {}, false, 'hdbsthor_assets', 'appToken').subscribe(response => {
          if(response.status != 500){
            _sh.set(pForms,[0,'columns',25,'model'],response);
          }else {
            _sh.set(pForms,[0,'columns',25,'model'],0);
          }
        });
      } else if(methodName == 'getCustBondOpen'){
        console.log(pForms);
        console.log(pExecList);
        const custId = _sh.get(pExecList,[0,'data',0,'CUST_ID'], null);
        console.log("客户名称",custId);
        this.requestService.doActionByHdbsthor('AppApi/BasicCustomerController', 'getEarnestMoneyEveryBasicCustomer', { 'userId':userId,'custId':custId}, {}, false, 'hdbsthor_assets', 'appToken').subscribe(response => {
          if(response.status != 500){
            _sh.set(pForms,[0,'columns',23,'model'],response);
          }else {
            _sh.set(pForms,[0,'columns',23,'model'],0);
          }
        });
      }else if(methodName == 'getCustBondOtherRent'){
        console.log(pForms);
        console.log(pExecList);
        const custId = _sh.get(pExecList,[0,'data',0,'CUST_ID'], null);
        console.log("客户名称",custId);
        this.requestService.doActionByHdbsthor('AppApi/BasicCustomerController', 'getEarnestMoneyEveryBasicCustomer', { 'userId':userId,'custId':custId}, {}, false, 'hdbsthor_assets', 'appToken').subscribe(response => {
          if(response.status != 500){
            _sh.set(pForms,[0,'columns',23,'model'],response);
          }else {
            _sh.set(pForms,[0,'columns',23,'model'],0);
          }
        });
      } else if(methodName == 'getCustBondOther'){
        console.log(pForms);
        console.log(pExecList);
        const custId = _sh.get(pExecList,[0,'data',0,'CUST_ID'], null);
        console.log("客户名称",custId);
        this.requestService.doActionByHdbsthor('AppApi/BasicCustomerController', 'getEarnestMoneyEveryBasicCustomer', { 'userId':userId,'custId':custId}, {}, false, 'hdbsthor_assets', 'appToken').subscribe(response => {
          if(response.status != 500){
            _sh.set(pForms,[0,'columns',21,'model'],response);
          }else {
            _sh.set(pForms,[0,'columns',21,'model'],0);
          }
        });
      }else {
        this.confirmModal.show('error', {title: methodName + '：serviceName 不存在'});
      }
    } else {
      this.confirmModal.show('error', {title: serviceName + '：methodName 不存在'});
    }
  }
}
