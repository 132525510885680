import {Component, Input, OnInit} from '@angular/core';
import * as _sh from 'lodash';
import {UtilsService} from "../../service/utils.service";
import {RequestDataService} from "../../service/request-data.service";
import * as _ from "underscore";
import {AppConfigService} from "../../service/app-config.service";
import {DataCenterService} from "../../service/data-center.service";
import {UserInfoService} from "../../service/user-info.service";
import {AjaxService} from "../../service/ajax.service";
import {saveAs} from 'file-saver';
import * as moment from "moment";


@Component({
    selector: 'app-special-export',
    templateUrl: './special-export.component.html',
    styleUrls: ['./special-export.component.scss']
})
export class SpecialExportComponent implements OnInit {


    @Input() parentData: any;
    isVisible: Boolean = false;
    columnList = [];
    imgColumnList = [];
    checkedAll: Boolean = true;
    checkedImgAll: Boolean = true;
    exportState = false;//true为导出中
    constructor(
        private util: UtilsService,
        private dataCenterService: DataCenterService,
        private userInfoService: UserInfoService,
        private ajaxService: AjaxService,
        private appConfigService: AppConfigService
    ) {
    }

    ngOnInit() {


    }

    doShowExport() {
        this.columnList = [];
        this.imgColumnList = [];
        console.log(this.parentData);

        let showColumnList = this.parentData.data.showColumns.columns;
        _sh.each(showColumnList,oneColumn=>{
            console.log(oneColumn);
            const exportSet = _sh.get(oneColumn,['exportSet'], ["data"]); // 获取数据的时候，如果没有，那么默认值就是放置第一页
            if (oneColumn['export'] == true && _sh.includes(exportSet,"data")) // 如果需要出现在第一页签
            {
                this.columnList.push(oneColumn); // 增加第一页签的数据
            }
            if (oneColumn['export'] == true && _sh.includes(exportSet,"img") && oneColumn['component'] == 'img') // 增加第二页签的数据
            {
                this.imgColumnList.push(oneColumn); // 增加第一页签的数据
            }
        });
        this.updateColumnLstChecked();
        this.checkedImgAll = false; // 默认图片不导
        this.updateImgColumnLstChecked();
        this.isVisible = true;
    }

    handleCancel() {
        console.log("exec handleCancel")
        this.isVisible = false;
    }

    handleOk() {
        this.isVisible = false;
        this.exportState = true;
        console.log("exec handleOk")
        this.export();
    }

    updateColumnLstChecked() {
        this.columnList.forEach(item => item.checked = this.checkedAll);
        this.parentData.data.showColumns.columns.forEach(oneColumn =>{
            if(oneColumn['export'] == true && oneColumn['component'] != 'img'){
                oneColumn.checked = this.checkedAll;
            }
        })
    }

    updateImgColumnLstChecked() {
        this.imgColumnList.forEach(item => item.imgChecked = this.checkedImgAll);
        this.parentData.data.showColumns.columns.forEach(oneColumn =>{
            if(oneColumn['export'] == true && oneColumn['component'] == 'img'){
                oneColumn.imgChecked = this.checkedImgAll;
            }
        })
    }

    updateChecked(column) {
        this.parentData.data.showColumns.columns.forEach(oneColumn =>{
            if(oneColumn.column == column.column){
                oneColumn.checked = column.checked;
            }
        })
    }

    updateImgChecked(column) {
        this.parentData.data.showColumns.columns.forEach(oneColumn =>{
            if(oneColumn.column == column.column){
                oneColumn.imgChecked = column.imgChecked;
            }
        })
    }

    export() {
        this.getExcel(null, 'all').subscribe(rep => {
            if (rep['state'] === 'success') {
                const fileName = `${this.parentData.title}-${moment().format("YYYYMMDDHHmmss")}-导出数据.xlsx`;
                saveAs(rep['text'],fileName);
                this.util.Base_doShowConfirm('success', '导出成功');
                this.exportState = false;
            } else {
                this.util.Base_doShowConfirm('error', rep || '导出失败');
                this.exportState = false;
            }
        });
    }
    getExcel(pageNum, pType) {
        const tmpUrl = this.appConfigService.getServer().excelServlet + '?random=' + Math.random();
        const myParam = this.makeParam(this.dataCenterService._dataObject.nowMenu['MENU_ID'], pageNum, null);
        if (pType === 'all') {
            myParam['pageNum'] = null;
            myParam['pageCount'] = null;
        }
        return this.ajaxService.ajaxPost(tmpUrl, myParam);
    }
    makeParam(pMENU_ID?, pageNum?, pPageType?, jsonFile?, initData?, pageCount?, orderBy?) {
        const menuData = this.dataCenterService._dataObject.nowMenu;
        if (this.util.Base_HasValue(pMENU_ID)) {
            pMENU_ID = menuData.MENU_ID;
        }
        let SEARCH_ID = jsonFile;
        const conditons = {};
        // 如果这个没有传入值来，那么就采用数据库的配置。
        if (!this.util.Base_HasValue(initData)) {
            //增加一个 initData 的查询参数默认查询出所有的值，数据库之中 ， MENU_MENU
            // MENU表的该字段控制是否在第一次进入的时候加载数据；"getData"代表需要在首次就加载数据, "noData" 不需要加载数据
            if (this.util.Base_HasValue(menuData.MENU_MENU)) {
                if (menuData.MENU_MENU === 'getData') {
                    initData = 'getData';
                } else if (menuData.MENU_MENU === 'noData') {
                    initData = 'noData';
                }
            } else {
                //如果数据没有配置，那么就默认为必须查询出来
                initData = 'getData';
            }
        }
        let MENU_SEARCH_ID = this.appConfigService.config['MysqlSearchPF'] || null;
        if (this.util.Base_HasValue(pMENU_ID)) {
            const dataObject = this.dataCenterService._dataObject;
            const nowData = dataObject.list[dataObject.index];
            // conditons = nowData.searchCondition;
            if (this.util.Base_HasValue(nowData.searchCondition)) {
                _sh.each(nowData.searchCondition, (item) => {
                    // 如果是daterange 类型需要将字段从bindColumns中取出
                    if (_sh.includes(['dateRange', 'dateTimeRange'], item['component'])) {
                        _sh.each(item['code'], (value, index) => {
                            const column = item['bindColumns'][index];
                            conditons[column] = value;
                        });
                    } else {
                        conditons[item['column']] = item['code'];
                    }
                });
            }
            const RIGHT_ID = this.util.Base_getMenulist(pMENU_ID).RIGHT_ID;
            if (this.util.Base_HasValue(this.util.Base_getMenulist(pMENU_ID).MENU_SEARCH_ID)) { //是否设置了独立数据源
                MENU_SEARCH_ID = this.util.Base_getMenulist(pMENU_ID).MENU_SEARCH_ID;
            }
            //强制转为字符型进行比较,以免出现类型不对等的BUG
            // if (this.utils.Base_compare(RIGHT_ID, 1)) {
            //   let paramconditions = this.utils.Base_getParamCondtions(this.utils.Base_getParamConditions(), pMENU_ID);
            //   if (!_sh.isEmpty(paramconditions)) {
            //     _sh.each(_sh.keys(paramconditions), function (OneParam) {
            //       conditons[OneParam] = paramconditions[OneParam];
            //     });
            //   }
            // }
            SEARCH_ID = this.util.Base_getAppListSearchId(pMENU_ID); //如果按照menu_id 找到 SEARCH_id , 覆盖传入的值
        }
        let tmpPageNum = this.appConfigService.config.pageNum; // 取默认值。
        let tmpPageCount = this.appConfigService.config.pageCount;
        if (this.util.Base_HasValue(pageCount)) {
            tmpPageCount = pageCount;
        }
        if (this.util.Base_HasValue(pageNum)) {
            tmpPageNum = pageNum;
        }

        let pageType = pPageType;
        if (!this.util.Base_HasValue(pageType)) {
            pageType = 'this_page';
        }

        let comId = this.userInfoService.getUserInfo().COM_ID;
        if (this.util.Base_HasValue(comId) && comId === 'public') {
            comId = null;
        }
        let exportList = _sh.cloneDeep(this.parentData.data.showColumns.columns);
        const myParam = {
            'PF': this.util.Base_HasValue(MENU_SEARCH_ID) ? MENU_SEARCH_ID : this.appConfigService.config.MysqlPF,
            'sqlfile': SEARCH_ID, 'comId': comId,
            'userId': this.userInfoService.getUserInfo().USER_ID,
            'empId': this.userInfoService.getUserInfo().EMP_ID,
            'userName': this.userInfoService.getUserInfo().USERNAME,
            'pageNum': tmpPageNum,
            'pageCount': tmpPageCount,
            'pageType': pageType,
            'initData': initData,
            'conditions': conditons,
            'exportList': exportList
        };
        if (this.util.Base_HasValue(orderBy) && orderBy.length) {
            myParam['orderBy'] = orderBy;
        }
        return myParam;
    }



}
