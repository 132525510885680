import {Component, Input, OnInit} from '@angular/core';
import {forkJoin as observableForkJoin} from "rxjs";
import {NzModalService} from "ng-zorro-antd/modal";
import {AppConfigService} from "../../service/app-config.service";
import {DataCenterService} from "../../service/data-center.service";
import {UtilsService} from "../../service/utils.service";
import {UploadService} from "../../service/upload.service";
import {RequestDataService} from "../../service/request-data.service";
import {AjaxService} from "../../service/ajax.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {UserInfoService} from "../../service/user-info.service";
import {ConfirmModalService} from "../../service/confirm-modal.service";
import {JumpCenterService} from "../../service/jump-center.service";
import * as _sh from 'lodash';

@Component({
    selector: 'app-todo-center',
    templateUrl: './todo-center.component.html',
    styleUrls: ['./todo-center.component.scss']
})
export class TodoCenterComponent implements OnInit {

    @Input() parentData: any;

    public user: any;              // 用户信息

    timer: any;                      // 定时器
    deadlineConfig = 120;            // 定时2min
    countDownTimer: any;
    countDown = new Date('2021-01-01 00:02:00');   // 刷新倒计时
    refreshTime = new Date();                      // 数据刷新时间
    searchText:any;
    selectedIndex = 0;


    public awaitListPage = {
        pageIndex: 1, // 每页显示数据
        pageSize: 5,
        total: 1,// 总条数
        pageCount: 5,
        nzPageSizeOptions: [5, 10, 20, 30, 50, 100, 200]
    }

    public refuseListPage = {
        pageIndex: 1, // 每页显示数据
        pageSize: 5, // 总条数
        total: 1,
        pageCount: 5,
        nzPageSizeOptions: [5, 10, 20, 30, 50, 100, 200]
    }

    public conListPage = {
        pageIndex: 1, // 每页显示数据
        pageSize: 5, // 总条数
        total: 1,
        pageCount: 5,
        nzPageSizeOptions: [5, 10, 20, 30, 50, 100, 200]
    }

    public conBillListPage = {
        pageIndex: 1, // 每页显示数据
        pageSize: 5, // 总条数
        total: 1,
        pageCount: 5,
        nzPageSizeOptions: [5, 10, 20, 30, 50, 100, 200]
    }

    public mypassedPage = {
        pageIndex: 1, // 每页显示数据
        pageSize: 5, // 总条数
        total: 1,
        pageCount: 5,
        nzPageSizeOptions: [5, 10, 20, 30, 50, 100, 200]
    }

    constructor(private modalService: NzModalService,
                private config: AppConfigService,
                public dataCenterService: DataCenterService,
                private utils: UtilsService,
                private uploadService: UploadService,
                private requestService: RequestDataService,
                private ajaxService: AjaxService,
                private notification: NzNotificationService,
                private userInfoService: UserInfoService,
                private jumpCenterService: JumpCenterService,
                private appConfigService: AppConfigService,
                private confirmModal: ConfirmModalService) {
        this.user = userInfoService.getUserInfo();
    }

    ngOnInit() {
        this.getAllData(true);
    }

    goTab(_index) {
        this.selectedIndex = _index;
    }



    getAwaitList() {
        let condition = {};
        if (this.utils.Base_HasValue(this.searchText)) {
            condition['MORE_INFO'] = this.searchText;
        }
        const searchAll = observableForkJoin(
            this.requestService.getPaginationData('assets/workflow/wf_user_await_data.json', condition, this.awaitListPage.pageIndex, this.awaitListPage.pageSize),          // 4
        );
        searchAll.subscribe(values => {
            this.dataCenterService._todoCenter.awaitList = _sh.get(values, [0, 'data'], []);                     // 查询全部待办数据
            this.awaitListPage['total'] = _sh.get(values, [0, 'page', 'rowCount'], 0);
            this.awaitListPage['pageCount'] = _sh.get(values, [0, 'page', 'nowCount'], 0);
            _sh.each(this.dataCenterService._todoCenter.awaitList,oneData=>{
                this.getUpUrgent(oneData);
            })
        });
    }

    getrefuseList() {
        let condition = {};
        if (this.utils.Base_HasValue(this.searchText)) {
            condition['MORE_INFO'] = this.searchText;
        }
        const searchAll = observableForkJoin(
            this.requestService.getPaginationData('assets/workflow/wf_await_refuse_list.json',condition, this.refuseListPage.pageIndex, this.refuseListPage.pageSize),            // 8
        );
        searchAll.subscribe(values => {
            this.dataCenterService._todoCenter.refuseList = _sh.get(values, [0, 'data'], []);                    // 我发起的，但是被拒绝的。

            this.refuseListPage['total'] = _sh.get(values, [0, 'page', 'rowCount'], 0);
            this.refuseListPage['pageCount'] = _sh.get(values, [0, 'page', 'nowCount'], 0);
        });
    }


    getMyPassedList() {
        let condition = {};
        if (this.utils.Base_HasValue(this.searchText)) {
            condition['MORE_INFO'] = this.searchText;
        }
        const searchAll = observableForkJoin(
            this.requestService.getPaginationData('assets/workflow/wf_await_mypassed.json',condition, this.mypassedPage.pageIndex, this.mypassedPage.pageSize),            // 8
        );
        searchAll.subscribe(values => {
            this.dataCenterService._todoCenter.mypassedList = _sh.get(values, [0, 'data'], []);                    // 我发起的，但是被拒绝的。

            this.mypassedPage['total'] = _sh.get(values, [0, 'page', 'rowCount'], 0);
            this.mypassedPage['pageCount'] = _sh.get(values, [0, 'page', 'nowCount'], 0);
        });
    }

    getConList(){
        let condition = {};
        if (this.utils.Base_HasValue(this.searchText)) {
            condition['CON_CODE'] = this.searchText;
        }
        const searchAll = observableForkJoin(
            this.requestService.getPaginationData('assets/workflow/wf_await_interimCon_list.json', condition, this.conListPage.pageIndex, this.conListPage.pageSize),          // 4
        );
        searchAll.subscribe(values => {
            this.dataCenterService._todoCenter.conList = _sh.get(values, [0, 'data'], []);                     // 查询全部待办数据

            this.conListPage['total'] = _sh.get(values, [0, 'page', 'rowCount'], 0);
            this.conListPage['pageCount'] = _sh.get(values, [0, 'page', 'nowCount'], 0);

        });
    }

    getConBillList(){
        let condition = {};
        if (this.utils.Base_HasValue(this.searchText)) {
            condition['CON_CODE'] = this.searchText;
        }
        const searchAll = observableForkJoin(
            this.requestService.getPaginationData('assets/workflow/wf_await_interimConBill_list.json', condition, this.conBillListPage.pageIndex, this.conBillListPage.pageSize),          // 4
        );
        searchAll.subscribe(values => {
            this.dataCenterService._todoCenter.conBillList = _sh.get(values, [0, 'data'], []);                     // 查询全部待办数据

            this.conBillListPage['total'] = _sh.get(values, [0, 'page', 'rowCount'], 0);
            this.conBillListPage['pageCount'] = _sh.get(values, [0, 'page', 'nowCount'], 0);

        });
    }

    // 获取全部数据
    getAllData(pStartTimer?) {
        const searchAll = observableForkJoin(
            this.requestService.getDataByCondition('assets/workflow/wf_user_await_count.json'),         // 0
            this.requestService.getDataByCondition('assets/workflow/wf_user_await_timeout.json'),       // 1
            this.requestService.getDataByCondition('assets/workflow/wf_user_await_willtimeout.json'),   // 2
            this.requestService.getDataByCondition('assets/workflow/wf_user_await_urgen.json'),         // 3
            this.requestService.getPaginationData('assets/workflow/wf_user_await_data.json', {}, this.awaitListPage.pageIndex, this.awaitListPage.pageSize),          // 4

            this.requestService.getDataByCondition('assets/workflow/wf_await_refuse_count.json'),            // 5
            this.requestService.getPaginationData('assets/workflow/wf_await_refuse_list.json',{}, this.refuseListPage.pageIndex, this.refuseListPage.pageSize),            // 6
            this.requestService.getDataByCondition('assets/workflow/wf_await_mypassed_count.json'),              //7
            this.requestService.getDataByCondition('assets/workflow/wf_await_interimCon_list.json'),       //8
            this.requestService.getDataByCondition('assets/workflow/wf_await_interimConBill.json'),     //9
            this.requestService.getPaginationData('assets/workflow/wf_await_interimCon_list.json', {}, this.conListPage.pageIndex, this.conListPage.pageSize),          // 10
            this.requestService.getPaginationData('assets/workflow/wf_await_interimConBill_list.json', {}, this.conBillListPage.pageIndex, this.conBillListPage.pageSize),          // 11
            this.requestService.getPaginationData('assets/workflow/wf_await_mypassed.json', {}, this.mypassedPage.pageIndex, this.mypassedPage.pageSize),          // 12

        );
        searchAll.subscribe(values => {
            this.dataCenterService._todoCenter.todoSum = _sh.get(values, [0, 'data', 0, 'ACOUNT'], 0);           // 查询全部待办
            this.dataCenterService._todoCenter.todoOverSum = _sh.get(values, [1, 'data', 0, 'ACOUNT'], 0);       // 查询已超时
            this.dataCenterService._todoCenter.todoWillOverSum = _sh.get(values, [2, 'data', 0, 'ACOUNT'], 0);   // 查询即将超时
            this.dataCenterService._todoCenter.todoUrgeSum = _sh.get(values, [3, 'data', 0, 'ACOUNT'], 0);       // 查询催办
            this.dataCenterService._todoCenter.awaitList = _sh.get(values, [4, 'data'], []);                     // 查询全部待办数据
            console.log(this.dataCenterService._todoCenter.awaitList);
            this.awaitListPage['total'] = _sh.get(values, [4, 'page', 'rowCount'], 0);
            this.awaitListPage['pageCount'] = _sh.get(values, [4, 'page', 'nowCount'], 0);

            this.dataCenterService._todoCenter.refuseSum = _sh.get(values, [5, 'data', 0, 'ACOUNT'], 0);         // 我发起的，但是被拒绝的。
            this.dataCenterService._todoCenter.refuseList = _sh.get(values, [6, 'data'], []);                    // 我发起的，但是被拒绝的。

            this.refuseListPage['total'] = _sh.get(values, [6, 'page', 'rowCount'], 0);
            this.refuseListPage['pageCount'] = _sh.get(values, [6, 'page', 'nowCount'], 0);

            this.dataCenterService._todoCenter.conCount = _sh.get(values,[8, 'page','rowCount'],0);              //临期合同数
            this.dataCenterService._todoCenter.conBillCount = _sh.get(values,[11, 'page','rowCount'],0);          //临期账单数
            this.dataCenterService._todoCenter.conList = _sh.get(values,[10, 'data'],[]);                        //临期合同列表

            this.conListPage['total'] = _sh.get(values, [10, 'page', 'rowCount'], 0);
            this.conListPage['pageCount'] = _sh.get(values, [10, 'page', 'nowCount'], 0);

            this.dataCenterService._todoCenter.conBillList = _sh.get(values,[11, 'data'],[]);                        //临期账单列表
            this.conBillListPage['total'] = _sh.get(values, [11, 'page', 'rowCount'], 0);
            this.conBillListPage['pageCount'] = _sh.get(values, [11, 'page', 'nowCount'], 0);

            this.dataCenterService._todoCenter.myconCount = _sh.get(values,[7, 'data', 0, 'ACOUNT'], 0);             //经手合同数
            this.dataCenterService._todoCenter.mypassedList = _sh.get(values, [12, 'data'], []);                    // 经手合同列表
            this.mypassedPage['total'] = _sh.get(values, [12, 'page', 'rowCount'], 0);
            this.mypassedPage['pageCount'] = _sh.get(values, [12, 'page', 'nowCount'], 0);

            _sh.each(this.dataCenterService._todoCenter.awaitList,oneData=>{
                this.getUpUrgent(oneData);
            })
        });
        if (pStartTimer) {
            this.doRestTime()
        }
    }

    getUpUrgent(oneData){
        const documentId = _sh.get(oneData,'DOCUMENT_ID',null);
        const searchAll = observableForkJoin(
            this.requestService.getDataByCondition("assets/descriptions/con_con_wf_user_await_descriptions_finish.json",{'ID':documentId})
        );
        searchAll.subscribe(value => {
            //console.log(value);
            const data = _sh.get(value,[0,'data'],[]);
            const URGE_FLAG = _sh.get(data,[data.length-1,'URGE_FLAG'],'GENERALLY');
            console.log(URGE_FLAG);
            _sh.set(oneData,'URGE_FLAG',URGE_FLAG);
            console.log(oneData);
        })
    }

    // 定时刷新
    doRestTime() {
        this.timer = setInterval(() => {
            this.getAllData(false);
            this.refreshTime = new Date();
            this.countDown = new Date('2021-01-01 00:02:00');
        }, this.deadlineConfig * 1000);

        this.countDownTimer = setInterval(() => {
            this.countDown = new Date(this.countDown.getTime() - 1000);
        }, 1000)

    }

    // 手动刷新
    doRefreshTodoCenter() {
        clearInterval(this.timer);
        clearInterval(this.countDownTimer);
        this.refreshTime = new Date();
        this.countDown = new Date('2021-01-01 00:02:00');
        this.getAllData(true);
    }

    // 销毁
    ngOnDestroy() {
        if (this.timer) {
            clearInterval(this.timer);
        }
        if (this.countDownTimer) {
            clearInterval(this.countDownTimer);
        }
    }

    gotoDetail(pData, pFormClass) {
        const documentId = pData['DOCUMENT_ID'];
        const wfTitel = pData['WF_TITEL'];
        const wfConfigId = pData['WF_CONFIG_ID'];

        let tabIndex = _sh.findIndex(this.dataCenterService._dataObject.list, (item) => {
            return item['parentDataId'] === documentId;
        });
        if (tabIndex > 0) {
            this.dataCenterService._dataObject.index = tabIndex;
            this.dataCenterService._dataObject.nowMenu = pData['DOCUMENT_ID'];
            this.dataCenterService.refreshDataCenterInStorage();
        } else {
            const searchAll = observableForkJoin(
                this.requestService.getDataByCondition('workflow/wf_config_list.json', {'ID': wfConfigId})
            );
            searchAll.subscribe(
                values => {
                    const searchId = _sh.get(values, [0, 'data', 0, 'MENU_ID_DICT_ARRAY', 'SEARCH_ID']);
                    this.dataCenterService.newTab({
                        id: documentId, // 唯一标识
                        parentDataId: documentId, // 唯一标识
                        nowMenu: _sh.get(values, [0, 'data', 0, 'MENU_ID_DICT_ARRAY']),
                        searchId: searchId,
                        title: wfTitel, // 菜单名称 用户tab展示
                        formType: 'readOnlyForm', // 菜单类型 用于tab过滤显示模板
                        formClass: pFormClass, // 工作流审批页面
                        visibleBtn: {
                            "back": false,
                            "print": false
                        },
                        data: {}, // 查询返回数据
                        detailCondition: {
                            'ID': documentId
                        },
                        workflowData: {
                            "wfUserAwaitId": pData['ID']
                        }
                    });
                }
            );


        }
    }


    doJumpToContract(oneData:any) {
        let conId = _sh.get(oneData,['ID']);
        let conName = _sh.get(oneData,['CON_NAME']);
        let conCode = _sh.get(oneData,['CON_CODE']);
        let that = this;
        const searchAll = observableForkJoin(
            that.requestService.getDataByCondition('assets/con_con/con_contracts_con_main_type.json', {'ID': conId})
        );
        searchAll.subscribe(
            values => {
                let menuList = {
                    "RENT": "D70E379D26E94E7EE737E95B7424389A",
                    "OTHER_RENT": "30DEDDF2E635710CDDBE789ABE3CD726",
                    "OPEN": "119A028633C4CC9B090BAA18B8A27798",
                    "OTHER": "9763231D9F3F304476E6A5AAE5656F36"
                }
                let menuId = menuList[_sh.get(values, [0, 'data',0,'CON_MAIN_TYPE'])];
                let id = conId + menuId;
                let tabIndex = _sh.findIndex(this.dataCenterService._dataObject.list, (item) => {
                    return item['parentDataId'] === id;
                });

                if (tabIndex > 0) {
                    this.dataCenterService._dataObject.index = tabIndex;
                    this.dataCenterService._dataObject.nowMenu = id;
                    this.dataCenterService.refreshDataCenterInStorage();
                } else {
                    const searchAll = observableForkJoin(
                        that.requestService.getDataByCondition(that.appConfigService.config.sql_path + '/menu/system_app_list.json', {'LIST_ID': menuId}),
                        that.requestService.getDataByCondition(that.appConfigService.config.sql_path + '/menu/menu_name.json', {'MENU_ID': menuId})
                    );
                    searchAll.subscribe(
                        values => {
                            let nowMenu = _sh.get(values, [1, 'data', 0], {});
                            const searchId = _sh.get(values, [0, 'data', 0, 'SEARCH_ID']);
                            that.dataCenterService.newTab({
                                id: id, // 主键
                                parentDataId: id, // 唯一标识
                                nowMenu: nowMenu,
                                searchId: searchId,
                                title: conName+conCode, // 菜单名称 用户tab展示
                                formType: 'readOnlyForm',
                                formClass: 'readOnlyForm',
                                data: {}, // 查询返回数据
                                detailCondition: {
                                    'ID': conId // 传入主键
                                }
                            });
                        });
                }

            });


    }

    doJumpToConBill(oneData:any) {
        let billId = _sh.get(oneData,['ID']);
        let billType = _sh.get(oneData,['EXPENCE_TYPE']);
        let that = this;
        const searchAll = observableForkJoin(
            that.requestService.getDataByCondition('assets/con_con/con_contracts_con_bill_expence_type.json', {'ID': billId})
        );
        searchAll.subscribe(
            values => {
                let menuList = {
                    "RENT_DEPOSIT": "997B0462624C56526C02D6707404D040",
                    "RENT": "98595E42088D79E9373E6F301ADE975C",
                    "PROPERTY": "FFC137AF393D5F7B980C61452BB7EE27"
                }
                let menuId = menuList[_sh.get(values, [0, 'data',0,'EXPENCE_TYPE'])];
                let id = billId + menuId;
                let tabIndex = _sh.findIndex(this.dataCenterService._dataObject.list, (item) => {
                    return item['parentDataId'] === id;
                });

                if (tabIndex > 0) {
                    this.dataCenterService._dataObject.index = tabIndex;
                    this.dataCenterService._dataObject.nowMenu = id;
                    this.dataCenterService.refreshDataCenterInStorage();
                } else {
                    const searchAll = observableForkJoin(
                        that.requestService.getDataByCondition(that.appConfigService.config.sql_path + '/menu/system_app_list.json', {'LIST_ID': menuId}),
                        that.requestService.getDataByCondition(that.appConfigService.config.sql_path + '/menu/menu_name.json', {'MENU_ID': menuId})
                    );
                    searchAll.subscribe(
                        values => {
                            let nowMenu = _sh.get(values, [1, 'data', 0], {});
                            const searchId = _sh.get(values, [0, 'data', 0, 'SEARCH_ID']);
                            that.dataCenterService.newTab({
                                id: id, // 主键
                                parentDataId: id, // 唯一标识
                                nowMenu: nowMenu,
                                searchId: searchId,
                                title: _sh.get(values,[1,'data',0,'MENU_NAME']), // 菜单名称 用户tab展示
                                formType: 'readOnlyForm',
                                formClass: 'readOnlyForm',
                                data: {}, // 查询返回数据
                                detailCondition: {
                                    'ID': billId // 传入主键
                                }
                            });
                        });
                }

            });


    }


}
