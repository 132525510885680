import {Injectable} from '@angular/core';
import {UtilsService} from './utils.service';
import {ConfirmModalService} from './confirm-modal.service';
import {AppConfigService} from './app-config.service';
import {DataCenterService} from './data-center.service';
import {AutoInsertService} from './auto-insert.service';
import {RequestDataService} from './request-data.service';

import * as _sh from 'lodash';

import {NzDrawerService, NzModalService} from 'ng-zorro-antd';
import {ImportComponent} from '../basicComponent/import/import.component';
import {UserInfoService} from './user-info.service';
import {forkJoin as observableForkJoin, from, Observable} from "rxjs";
import {FormModalComponent} from '../basicComponent/form-modal/form-modal.component';
import {AssetsService} from "../project/assets/assets.service";
import {DrawerBtnComponent} from '../basicForm/drawer-btn/drawer-btn.component';
import * as moment from 'moment';
import {Md5} from "ts-md5";
import {JumpCenterService} from "./jump-center.service";
import {UUID} from "angular2-uuid";
import {uuid} from "@tinymce/tinymce-angular/utils/Utils";
import {ServiceCenterService} from "./service-center.service";

@Injectable({
    providedIn: 'root'
})
export class ListActionService {

    private actionArray: any;

    private showColumns: any;

    constructor(
        private util: UtilsService,
        private appConfig: AppConfigService,
        private requestService: RequestDataService,
        private dataCenterService: DataCenterService,
        private autoService: AutoInsertService,
        private modalService: NzModalService,
        private confirmModal: ConfirmModalService,
        private userInfo: UserInfoService,
        private assetsService: AssetsService,
        private drawerService: NzDrawerService,
        private jumpCenterService: JumpCenterService,
        private serviceCenterService: ServiceCenterService
    ) {
    }

    selectOneData(pListData): number {
        let iCount = 0;
        _sh.each(pListData, oneData => {
            if (oneData['checked']) {
                iCount++;
            }
        });
        // tslint:disable-next-line:triple-equals
        if (iCount == 0) {
            this.confirmModal.show('warning', {
                'title': '温馨提示',
                'content': '请至少选择一条纪录！'
            });
            console.error('this action must select only one record ');
            return iCount;
        } else if (iCount > 1) {
            this.confirmModal.show('warning', {
                'title': '温馨提示',
                'content': '请只选择一条纪录！'
            });
            console.error('this action must select only one record ');
            return iCount;
        }
        return iCount;
    }

    selectMore(pListData, multiple?): number {
        //计数，有多少条记录被勾选了。
        let iCount = 0;
        _sh.each(pListData, oneData => {
            if (oneData['checked']) {
                iCount++;
            }
        });

        // tslint:disable-next-line:triple-equals
        if (multiple == true) {
            if (iCount === 0) {
                this.confirmModal.show('warning', {
                    'title': '温馨提示',
                    'content': '请至少选择一条纪录！'
                });
                return iCount;
            }
        } else {
            if (iCount === 0) {
                this.confirmModal.show('warning', {
                    'title': '温馨提示',
                    'content': '请选择一条纪录！'
                });
                return iCount;
            } else if (iCount > 1) {
                this.confirmModal.show('warning', {
                    'title': '温馨提示',
                    'content': '只能选择一条纪录！'
                });
                return iCount;
            }
        }
        return iCount;
    }

    mackConditon(pParentData): void {
        function setDetailConditons(pData) {
            function isCondtion(OneColumn) {
                let bFind = false;
                if (_sh.hasIn(OneColumn, 'iscondition')) {
                    bFind = OneColumn.iscondition;
                }
                return bFind;
            }

            let conData = {};
            _sh.each(pData, function (oneData) {
                if (oneData['checked']) {
                    conData = oneData;
                }
            });
            let tmp = {};
            _sh.each(pParentData.data.showColumns['columns'], function (OneColumn) {
                //只将 iscondition 为 true 的作为条件传入详情页面。
                if (isCondtion(OneColumn)) {
                    tmp[OneColumn['conditionColumn']] = _sh.get(conData, [OneColumn['column']]);// conData[];
                }
            });
            return tmp;
        }

        pParentData.detailCondition = setDetailConditons(pParentData.data.data);
    }

    deleteData(parentData) {
        const pListData = parentData.data.data;
        if (this.selectMore(pListData, true) >= 1) {
            this.confirmModal.show('confirm', {
                'title': '温馨提示',
                'content': '您确定删除选中的数据?',
                suc: () => {
                    let tmpJson = this.autoService.AdapterLogicDelete(parentData.data);
                    this.autoService.ExecJson(tmpJson).subscribe(response => {
                        if (response['state'] === 'success') {
                            this.confirmModal.show('success', {
                                'title': '温馨提示',
                                'content': '执行成功'
                            });
                            parentData.needSearch = true;
                        } else {
                            this.confirmModal.show('error', {
                                'title': '温馨提示',
                                'content': '保存失败'
                            });
                        }
                    });
                }
            });
        }
    }

    export() {
        this.requestService.getExcel(null, 'all').subscribe(rep => {
            if (rep['state'] === 'success') {
                window.open(rep['text'], '_blank', '');
                this.util.Base_doShowConfirm('success', '导出成功');
            } else {
                this.util.Base_doShowConfirm('error', rep || '导出失败');
            }
        });
    }

    formatValue(oneOneColum, oneValue) {
        let result = oneValue;
        const format = _sh.get(oneOneColum, ['format'], null);
        if (format == 'md5') {
            result = Md5.hashStr(oneValue);
        }
        return result;
    }

    findOneColumnValue(pColumns, pColumnName) {
        const oneOneColum = _sh.find(pColumns, {'column': pColumnName});
        let oneValue = null;
        if (this.util.Base_HasValue(oneOneColum)) {
            oneValue = _sh.get(oneOneColum, 'model');
            oneValue = this.formatValue(oneOneColum, oneValue);
        } else {
            _sh.each(pColumns, oneColumn => {
                _sh.each(oneColumn['columnGroup'], oneGroup => {
                    if (oneGroup['column'] === pColumnName) {
                        oneValue = _sh.get(oneGroup, 'model');
                        oneValue = this.formatValue(oneOneColum, oneValue);
                    }
                })
            })
        }

        return oneValue;
    }


    doActionOperate(pBtn, pParentData, pInputForm, pReadOnlyData) {
        let that = this;

        var allObservable = Observable.create(
            function (allObserver) {


                function doActionOperateExec(buttonConfig, queryData, bodyData) {
                    let oneAjax = that.requestService.doActionByHdbsthor(
                        buttonConfig['thorConfig']['controller'],
                        buttonConfig['thorConfig']['method'],
                        queryData, bodyData,
                        buttonConfig['thorConfig']['hasPF'],
                        buttonConfig['thorConfig']['thorConfig'],
                        buttonConfig['thorConfig']['appToken']);
                    return oneAjax;
                }

                function doActionOperateExecForJava(buttonConfig, queryData, bodyData) {
                    let param = [];
                    _sh.each(_sh.keys(bodyData), oneKey => {
                        let valueText = bodyData[oneKey];
                        if (_sh.isArray(valueText)) {
                            valueText = that.util.getArrayByPath(valueText, oneKey);
                            valueText = _sh.join(valueText, ',');
                        }
                        let text = oneKey + ':\'' + valueText + '\'';
                        param.push(text);
                    })
                    let bodyText = _sh.join(param, ',');
                    let oneAjax = that.requestService.doActionByJavaPlatform(
                        buttonConfig['javaConfig']['controller'],
                        buttonConfig['javaConfig']['method'],
                        bodyText)
                    return oneAjax;
                }

                function doActionOperateExecForHulk(buttonConfig, queryData, bodyData) {
                    let oneAjax = that.requestService.doActionByHdbsHulk(
                        buttonConfig['hulkConfig']['controller'],
                        buttonConfig['hulkConfig']['method'],
                        queryData, bodyData);
                    return oneAjax;
                }

                function checkHasValue(pRecords, pColumn) {
                    let count = 0;
                    _sh.each(pRecords, oneRecord => {
                        if (that.util.Base_HasValue(_sh.get(oneRecord, [pColumn], null))) {
                            count++;
                        }
                    });
                    return count > 0;
                }

                function nullDeleteColumns(pList, pColumn) {
                    _sh.each(pList['COLUMNS'], oneColumn => {
                        if (oneColumn['COLUMN'] == pColumn) {
                            pList['COLUMNS'] = _sh.without(pList['COLUMNS'], oneColumn);
                        }
                    })
                }

                function doActionOperateExecForAuto(buttonConfig, pModalData) {
                    let autoJsonList = _sh.get(buttonConfig, ['autoJson', 'LIST'], []);
                    _sh.each(autoJsonList, oneAction => {
                        let records = [];
                        let recordsDatasConfig = _sh.get(oneAction, ['RECORDS_DATAS'], null);
                        if (that.util.Base_HasValue(recordsDatasConfig)) {
                            let rows = that.dataCenterService.getSpecialValue(recordsDatasConfig, pParentData, pModalData, pReadOnlyData, null);
                            _sh.each(rows, oneRow => {
                                let oneRecord = _sh.cloneDeep(oneAction['RECORDS'][0]);
                                let oneRecordKeys = _sh.keys(oneRecord);
                                _sh.each(oneRecordKeys, oneKey => {
                                    let oneRecordoneKey = oneRecord[oneKey];
                                    let value = that.dataCenterService.getSpecialValue(oneRecordoneKey, pParentData, pModalData, pReadOnlyData, oneRow);
                                    oneRecord[oneKey] = value;
                                })
                                records.push(oneRecord);
                            })
                            oneAction['RECORDS'] = records;
                        }
                    });
                    let autoTemplate = {
                        "LIST": autoJsonList
                    }
                    _sh.each(autoTemplate['LIST'], oneList => {
                        _sh.each(oneList['NULL_DELETE_COLUMNS'], oneColumn => {
                            let hasValue = checkHasValue(oneList['RECORDS'], oneColumn);
                            if (!hasValue) {
                                nullDeleteColumns(oneList, oneColumn);
                            }
                        });
                    });
                    let oneAjax = that.autoService.ExecJson(autoTemplate);
                    return oneAjax;
                }


                /**
                 * 组织 query 参数的数据
                 * @param buttonConfig
                 * @param selectList
                 * @param data
                 */
                function getQueryData(buttonConfig, pModalData) {
                    let queryData = {};
                    _sh.each(_sh.keys(buttonConfig['queryData']), oneKey => {
                        let value = buttonConfig['queryData'][oneKey];
                        queryData[oneKey] = that.dataCenterService.getSpecialValue(value, pParentData, pModalData, pReadOnlyData);
                    });
                    return queryData;
                }

                /**
                 * 组织 body 参数的数据
                 * @param buttonConfig
                 * @param selectList
                 * @param data
                 */
                function getBodyData(buttonConfig, pModalData) {
                    let bodyData = {};
                    _sh.each(_sh.keys(buttonConfig['bodyData']), oneKey => {
                        let value = buttonConfig['bodyData'][oneKey];
                        let isArray = _sh.get(value, ['isArray'], false);
                        if (isArray) { // 如果按照数组的模式获取数据。
                            let keys = _sh.keys(value['columns']);
                            const selectList = _sh.filter(pParentData.data.data, (item) => {
                                return item['checked'];
                            });
                            let tempArray = [];
                            _sh.each(selectList, oneData => {
                                let tempObj = {};
                                _sh.each(keys, oneArrayKey => {
                                    let tempValue = value['columns'][oneArrayKey];
                                    tempValue = that.dataCenterService.getSpecialValue(tempValue, pParentData, pModalData, pReadOnlyData, oneData);
                                    tempObj[oneArrayKey] = tempValue
                                })
                                tempArray.push(tempObj);
                            })
                            bodyData[oneKey] = tempArray;
                        } else {
                            bodyData[oneKey] = that.dataCenterService.getSpecialValue(value, pParentData, pModalData, pReadOnlyData);
                        }
                    });
                    let bodyDataCopy01 = _sh.cloneDeep(bodyData);
                    _sh.each(_sh.keys(bodyDataCopy01), oneParam => {
                        let paramLv = _sh.split(oneParam, '.').length;
                        if (paramLv == 1) {
                            delete bodyDataCopy01[oneParam]
                        }
                    });
                    let otherParam = {}
                    _sh.each(_sh.keys(bodyDataCopy01), oneParam => {
                        _sh.set(otherParam, oneParam, bodyDataCopy01[oneParam])
                    });

                    let bodyDataCopy02 = _sh.cloneDeep(bodyData);
                    _sh.each(_sh.keys(bodyDataCopy02), oneParam => {
                        let paramLv = _sh.split(oneParam, '.').length;
                        if (paramLv > 1) {
                            delete bodyDataCopy02[oneParam]
                        }
                    });
                    bodyData = _sh.assignIn(bodyDataCopy02, otherParam);
                    return bodyData;
                }

                /**
                 *
                 * @param pParentData
                 * @param buttonConfig 按钮的配置文件
                 * @param selectList 选中的数据
                 * @param modalData 弹出框的数据
                 */
                function execInterface(buttonConfig, pModalData, pListDatas?) {
                    var observable = Observable.create(function (observer) {
                        let queryData = getQueryData(buttonConfig, pModalData);
                        let bodyData = getBodyData(buttonConfig, pModalData);
                        let interfaceType = _sh.get(buttonConfig, ['interfaceType'], "thor");
                        if (interfaceType == "thor") {
                            observer.next(doActionOperateExec(buttonConfig, queryData, bodyData));
                        } else if (interfaceType == "hulk") {
                            observer.next(doActionOperateExecForHulk(buttonConfig, queryData, bodyData));
                        } else if (interfaceType == "auto") {
                            observer.next(doActionOperateExecForAuto(buttonConfig, pModalData));
                        } else if (interfaceType == "java") {
                            observer.next(doActionOperateExecForJava(buttonConfig, queryData, bodyData));
                        } else if (interfaceType == "service") {
                            observer.next(that.serviceCenterService.doChangeListAction(pListDatas, null, buttonConfig['servicePath']));
                        }
                        observer.complete();
                    })
                    return observable;
                }

                function execJumpPage(buttonConfig, pModalData) {
                    var observable = Observable.create(function (observer) {
                        that.mackConditon(pParentData); // 创建传入值条件。
                        that.jumpCenterService.execJumpPage(buttonConfig['jumpConfig'], pParentData, pModalData, pReadOnlyData);
                        observer.complete();
                    })
                    return observable;
                }

                let actionOperate = _sh.get(pBtn, ['ACTION_OPERATE'], null);
                const search = observableForkJoin(
                    that.requestService.getDataByJson(actionOperate)
                );
                search.subscribe(
                    values => {
                        const buttonConfig = values[0] || {};
                        let checked = false;

                        let needSelect = _sh.get(buttonConfig, ['needSelect'], true);
                        if (_sh.includes(['readOnlyForm'], pInputForm)) {
                            // 如果按钮是内页按钮：只读页, 不校验选择数据条数
                            needSelect = false;
                        }
                        if (needSelect) {
                            const selectCount = that.selectMore(pParentData.data.data, buttonConfig['selectMore']);
                            if (buttonConfig['selectMore']) { // true 可多选，
                                checked = selectCount >= 1;
                            } else { // false 只可单选，
                                checked = selectCount == 1;
                            }
                        } else {
                            checked = true;
                        }
                        if (checked) {
                            if (that.dataCenterService.doCheck(buttonConfig, null, "beforeOpenModal", pInputForm, pParentData, pReadOnlyData, true)) {
                                checked = true;
                            } else {
                                checked = false;
                            }
                        }
                        // 数据展开前，赋予默认值
                        let modalColumns = _sh.get(buttonConfig, ['modalConfig', 'nzComponentParams', 'modalFormData', 'columns'], [])
                        let defValueList = _sh.filter(modalColumns, oneColumn => {
                            return that.util.Base_HasValue(_sh.get(oneColumn, ['fromValue'], null));
                        })
                        let defModelNameValeList = _sh.filter(modalColumns, oneColumn => {
                            return that.util.Base_HasValue(_sh.get(oneColumn, ['modelNameFromValue'], null));
                        })
                        let modalData = _sh.get(buttonConfig, ['modalData']);
                        let modalDataAft = {};
                        if (that.util.Base_HasValue(modalData)) {
                            let modalDataList = _sh.keys(modalData);
                            _sh.each(modalDataList, oneModalConfig => {
                                let originalDatas = [];
                                if (modalData[oneModalConfig]['fromDataCenter'] == "SELECT") {
                                    originalDatas = _sh.filter(pParentData.data.data, (item) => {
                                        return item['checked'];
                                    });
                                }
                                if (modalData[oneModalConfig]['aggregateFunction'] == "sum") {
                                    if (that.util.Base_HasValue(originalDatas)) {
                                        let sum = 0;
                                        _sh.each(originalDatas, oneData => {
                                            let findLink = _sh.chain(modalData[oneModalConfig]['fromValue']).split(".").drop().value();
                                            let value = _sh.get(oneData, findLink, 0);
                                            sum = sum + value;
                                        })
                                        modalDataAft[oneModalConfig] = sum;
                                    } else {
                                        modalDataAft[oneModalConfig] = 0;
                                    }
                                }
                                if (modalData[oneModalConfig]['aggregateFunction'] == "count") {
                                    if (that.util.Base_HasValue(originalDatas)) {
                                        modalDataAft[oneModalConfig] = originalDatas.length;
                                    } else {
                                        modalDataAft[oneModalConfig] = 0;
                                    }
                                }

                            })
                        }

                        if (that.util.Base_HasValue(defValueList)) {
                            _sh.each(defValueList, oneColumn => {
                                const fromValue = oneColumn['fromValue'];
                                const value = that.dataCenterService.getSpecialValue(fromValue, pParentData, null, pReadOnlyData, modalDataAft);
                                oneColumn['model'] = value;
                            })
                        }
                        if (that.util.Base_HasValue(defModelNameValeList)) {
                            _sh.each(defValueList, oneColumn => {
                                const fromValue = oneColumn['modelNameFromValue'];
                                const value = that.dataCenterService.getSpecialValue(fromValue, pParentData, null, pReadOnlyData, modalDataAft);
                                oneColumn['modelName'] = value;
                            })
                        }
                        const actionType = _sh.get(buttonConfig, 'actionType', 'execInterface');
                        if (checked) {
                            // list 列表页面，选择的数据。
                            let modalType = buttonConfig['modalType'];
                            let modalConfig = buttonConfig['modalConfig'];
                            if (modalType == 'yes_or_no') { // 是否类型
                                if (that.dataCenterService.doCheck(buttonConfig, null, "afterOpenModal", pInputForm, pParentData, pReadOnlyData, true)) {
                                    that.confirmModal.show('confirm', {
                                        title: modalConfig['nzTitle'],
                                        content: modalConfig['nzContent'],
                                        suc: () => {
                                            if (actionType == 'execInterface') {
                                                var observable = execInterface(buttonConfig, null); // 是否类型，因为没有自定义输入框，不纯在 modalData，
                                                allObserver.next(observable);
                                            } else if (actionType == 'jumpPage') {
                                                var observable = execJumpPage(buttonConfig, null);
                                                allObserver.next(observable);
                                            }
                                        }
                                    });
                                }
                            } else if (modalType == 'noModal') { // 无弹窗型，直接选择
                                if (that.dataCenterService.doCheck(buttonConfig, null, "afterOpenModal", pInputForm, pParentData, pReadOnlyData, true)) {
                                    if (actionType == 'execInterface') {
                                        var observable = execInterface(buttonConfig, null); // 是否类型，因为没有自定义输入框，不纯在 modalData，
                                        allObserver.next(observable);
                                    } else if (actionType == 'jumpPage') {
                                        var observable = execJumpPage(buttonConfig, null);
                                        allObserver.next(observable);
                                    }
                                }
                            } else if (modalType == 'custom') { // 自定义类型
                                that.modalService.create({
                                    nzTitle: modalConfig['nzTitle'],
                                    nzContent: FormModalComponent,
                                    nzWidth: modalConfig['nzWidth'],
                                    nzMaskClosable: _sh.get(modalConfig, ['nzMaskClosable'], false),
                                    nzWrapClassName: modalConfig['nzWrapClassName'],
                                    nzComponentParams: modalConfig['nzComponentParams'],
                                    nzOnOk: (response) => {
                                        const data = response.modalFormData.columns;
                                        let requireCheck = true;
                                        _sh.each(data, oneColumn => {
                                            if (!that.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
                                                requireCheck = false;
                                            }
                                        });
                                        if (!requireCheck) {
                                            that.confirmModal.show('error', {title: '请填写必填信息。'});
                                            return false;
                                        }
                                        if (that.dataCenterService.doCheck(buttonConfig, data, "afterOpenModal", pInputForm, pParentData, pReadOnlyData, true)) {
                                            if (actionType == 'execInterface') {
                                                var observable = execInterface(buttonConfig, data);
                                                allObserver.next(observable);
                                            } else if (actionType == 'jumpPage') {
                                                var observable = execJumpPage(buttonConfig, null);
                                                allObserver.next(observable);
                                            }
                                        }
                                    }
                                });
                            }
                        }
                    }
                )
            });

        return allObservable;

    }

    doAction(pBtn, parentData, pInputForm, pReadOnlyData?) {
        let that = this;
        console.log(pBtn, parentData, pInputForm, pReadOnlyData);
        var allObservable = Observable.create(
            function (allObserver) {
                let optBoolean = false;
                const pListData = parentData.data.data;
                // 通过json文件配置 该数据在什么状态下才能操作
                if (_sh.includes(['delete', 'edit'], pBtn.ACTION_VALUE)) {
                    // tslint:disable-next-line:triple-equals
                    if (pBtn.ACTION_VALUE === 'edit' && that.selectOneData(pListData) != 1) {
                        return;
                    }
                    if (that.btnOpt(parentData.data, pBtn.ACTION_VALUE) === 0 && pBtn.ACTION_TYPE === 'list') {
                        that.confirmModal.show('warning', {
                            'title': '温馨提示',
                            content: '当前数据锁定状态,不可进行操作'
                        });
                        return false;
                    }
                }
                let actionOperate = _sh.get(pBtn, ['ACTION_OPERATE'], null);
                let actionValue = _sh.get(pBtn, ['ACTION_VALUE'], null);
                if (that.util.Base_HasValue(actionOperate) && (actionValue !== 'list')) { // 如果是操作类型的自定义按钮
                    let obsdoActionOperate = that.doActionOperate(pBtn, parentData, pInputForm, pReadOnlyData);
                    allObserver.next(obsdoActionOperate);
                } else {
                    // 'systemCustmer'
                    let actionValueArray = _sh.split(pBtn.ACTION_VALUE, ".");
                    let actionValueType = actionValueArray[0];
                    let modeType = actionValueArray[1];
                    parentData.modeType = modeType; // 进入自定义窗口，是什么状态的，添加，还是修改
                    if (actionValueType == 'systemCustom') {
                        if (parentData.modeType == 'add') {
                            parentData['btnName'] = pBtn.ACTION_VALUE;
                            parentData['conditionColumns'] = {};
                            parentData['formType'] = 'customForm';
                            parentData['modeType'] = parentData.modeType;
                            parentData['actionArray'] = [];
                            that.dataCenterService.refreshDataCenterInStorage();
                        } else if (parentData.modeType == 'modify') {
                            if (that.selectMore(parentData.data.data, false) !== 1) {
                                return false;
                            } else {
                                const selectList = _sh.filter(parentData.data.data, (item) => {
                                    return item['checked'];
                                });
                                let ids = that.formatSelectEntity(selectList, 'ID');
                                parentData['id'] = ids[0]['id'];
                                parentData['modeType'] = 'modify';
                                parentData['btnName'] = pBtn.ACTION_VALUE;
                                parentData['conditionColumns'] = {};
                                parentData['formType'] = 'customForm';
                                parentData['actionArray'] = [];
                                that.dataCenterService.refreshDataCenterInStorage();
                            }
                        }
                    } else {
                        // 这里放置着标准的按钮
                        switch (pBtn.ACTION_VALUE) {
                            case'add':
                                parentData.detailCondition = {};
                                that.transformData('detailForm', 'add', parentData);
                                optBoolean = true;
                                break;
                            case'edit':
                                if (that.selectOneData(pListData) !== 1) {
                                    return false;
                                }
                                that.mackConditon(parentData);
                                that.transformData('detailForm', 'modify', parentData);
                                optBoolean = true;
                                break;
                            case'delete':
                                that.deleteData(parentData);
                                optBoolean = true;
                                break;
                            case'hulkImport':
                                if (!that.util.Base_HasValue(pBtn['IMPORT_JSON_FILE'])) {
                                    that.confirmModal.show('error', {title: '请先配置导入Json模版'});
                                    return false;
                                }
                                // that.modalService.create({
                                //     nzTitle: '文件导入',
                                //     nzContent: ImportComponent,
                                //     nzWidth: 1300,
                                //     nzComponentParams: pBtn,
                                //     nzWrapClassName: 'vertical-center-modal',
                                //     nzOnOk: (response) => {
                                //         parentData.needSearch = true;
                                //     }
                                // });
                                let jumpId = _sh.get(parentData, ['nowMenu', 'MENU_ID'], that.util.Base_getUuid()) + '_import';
                                let jumpConfig = {
                                    "list": {
                                        "default": {
                                            "jumpPage": {
                                                "modeType": "modify",
                                                "component": "app-import-form",
                                                "formFlag": "modifyRentOtherContract",
                                                "formType": "customForm",
                                                "visibleBtn": {
                                                    "back": true,
                                                    "print": false
                                                },
                                                "titleTemplate": _sh.get(parentData, ['nowMenu', 'MENU_NAME'], "导入") + '-导入',
                                                "menuId": _sh.get(parentData, ['nowMenu', 'MENU_ID'], that.util.Base_getUuid()),
                                                "newPage": true,
                                            },
                                            "jumpData": {
                                                "id": jumpId,
                                                "importJsonFile": pBtn['IMPORT_JSON_FILE'],
                                                "importModelUrl": pBtn['IMPORT_MODEL_URL']
                                            }
                                        }
                                    }
                                };

                                that.jumpCenterService.execJumpPage(jumpConfig, parentData, null, null);


                                break;
                            case'export':
                                that.export();
                                break;
                            case'updateRelease': // json传入hulk的队列管理 更新到发布状态
                                that.updateRelease(parentData, 'PUBLISHED');
                                break;
                            case'updateTest': // json传入hulk的队列管理 更新到测试状态
                                that.updateRelease(parentData, 'TEST');
                                break;
                            case'appAutoJson':
                                that.appAutoJson(pBtn, parentData);
                                break;
                            case 'addWorkflow': // addWorkflow
                                that.doAddWorkflow(pBtn, parentData);
                                break;
                            case 'modifyWorkflow': // modifyWorkflow
                                that.doAddWorkflow(pBtn, parentData);
                                break;
                            case 'addWordTemplate': // 新增Word文档模板管理
                                that.doAddWordTemplate(pBtn, parentData);
                                break;
                            case 'modifyWordTemplate': // 新增Word文档模板管理
                                that.doModifyWordTemplate(pBtn, parentData);
                                break;
                            default: // 不是通用按钮
                                that.gotoProject(pBtn, parentData);
                        }
                    }
                }

                // 记录操作日志
                if (optBoolean) {
                    const optData = {
                        'MENU_ID': pBtn.MENU_ID,
                        'MENU_NAME': pBtn.MENU_NAME,
                        'ROLE_ID': pBtn.ROLE_ID,
                        'OPT_CONTENT': '页面[' + pBtn.ACTION_TYPE + ']',
                        'OPT_TYPE': pBtn.ACTION_VALUE,
                        'OPT_BUTTON': pBtn.ACTION_NAME
                    };
                    that.requestService.saveOptLog(optData).subscribe(response => {
                    });
                }
                allObserver.complete();
            });
        return allObservable;
    }

    formatSelectEntity(datas, pColumn) {
        let entity: any = [];
        _sh.each(datas, (OneData) => {
            let obj: any = {};
            const keys = _sh.keys(OneData);
            _sh.each(keys, (oneKeys) => {
                if (oneKeys === pColumn) {
                    obj['id'] = OneData[oneKeys];
                }
            });
            entity.push(obj);
        });
        return entity;
    }

    doModifyWordTemplate(pBtn, pParentData) {
        if (this.selectMore(pParentData.data.data, false) !== 1) {
            return false;
        } else {
            const selectList = _sh.filter(pParentData.data.data, (item) => {
                return item['checked'];
            });
            let ids = this.formatSelectEntity(selectList, 'ID');
            pParentData['id'] = ids[0]['id'];
            pParentData['modeType'] = 'modify';
            pParentData['btnName'] = pBtn.ACTION_VALUE;
            pParentData['conditionColumns'] = {};
            pParentData['formType'] = 'customForm';
            pParentData['actionArray'] = [];
            this.dataCenterService.refreshDataCenterInStorage();
        }
    }

    doAddWorkflow(pBtn, pParentData) {
        pParentData['modeType'] = 'add';
        pParentData['btnName'] = pBtn.ACTION_VALUE;
        pParentData['conditionColumns'] = {};
        pParentData['formType'] = 'customForm';
        pParentData['actionArray'] = [];
        this.dataCenterService.refreshDataCenterInStorage();
    }

    doAddWordTemplate(pBtn, pParentData) {
        pParentData['modeType'] = 'add';
        pParentData['btnName'] = pBtn.ACTION_VALUE;
        pParentData['conditionColumns'] = {};
        pParentData['formType'] = 'customForm';
        pParentData['actionArray'] = [];
        this.dataCenterService.refreshDataCenterInStorage();
    }

    appAutoJson(pBtn, parentData) {
        parentData.data = _sh.filter(parentData.data.data, (item) => {
            return item['checked'];
        });
        parentData.btnName = pBtn.ACTION_VALUE;
        parentData.conditionColumns = {};
        parentData.formType = 'customForm';
        parentData.modeType = 'edit';
        parentData.actionArray = [];
        // 覆盖当前数据到data-center;
        _sh.each(this.dataCenterService._dataObject.list, (item) => {
            if (item['id'] === parentData.id) {
                item = _sh.extend(item, parentData);
            }
        });
        this.dataCenterService.refreshDataCenterInStorage();
    }

    updateRelease(parentData, pState) {
        if (this.selectMore(parentData.data.data, true) === 0) {
            return false;
        }
        let selectList = [];
        _sh.each(parentData.data.data, (item) => {
            if (item['checked']) {
                selectList.push(item['ID']);
            }
        });
        this.confirmModal.show('confirm', {
            title: '您确定执行此操作吗？',
            suc: () => {
                const user = this.userInfo.getUserInfo();
                let ids = selectList.join('\',\'');
                ids = '(\'' + ids + '\')';
                const templateJson = {
                    'LIST': [
                        {
                            'TABLE': 'hdbs_json_dispose',
                            'ACTION': 'Update',
                            'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
                            'WHERE': [{
                                'COLUMN': 'ID',
                                'VALUE': ids,
                                'OPERATOR': 'in',
                                'VALUE_TYPE': 'int',
                                'CONDITION_OPERATOR': 'AND',
                                'GROUP': 'GROUP_01'
                            }],
                            'COLUMNS': [
                                {'COLUMN': 'JSON_STATUS'},
                                {'COLUMN': 'UPD_NAME'},
                                {'COLUMN': 'UPD_USERID'},
                            ],
                            'RECORDS': [
                                {
                                    'JSON_STATUS': pState,
                                    'UPD_NAME': user.USERNAME,
                                    'UPD_USERID': user.USER_ID,
                                }
                            ]
                        }
                    ]
                };
                this.autoService.ExecJson(templateJson).subscribe(result => {
                    if (result['state'] === 'success') {
                        this.confirmModal.show('success', {
                            title: '操作成功',
                            suc: () => {
                                parentData.needSearch = true;
                            }
                        });
                    } else {
                        this.confirmModal.show('error', {title: '操作失败，请刷新重试！'});
                    }
                });
            }
        });
    }

    transformData(pFormType, pModeType, parentData) {
        // 重新覆盖 datacenter 数据
        parentData.data = {};
        parentData.conditionColumns = {};
        parentData.formType = pFormType;
        parentData.modeType = pModeType;
        parentData.actionArray = [];
        // 覆盖当前数据到data-center;
        _sh.each(this.dataCenterService._dataObject.list, (item) => {
            if (item['id'] === parentData.id) {
                item = _sh.extend(item, parentData);
            }
        });
        this.dataCenterService.refreshDataCenterInStorage();
    }

    /**
     * 非通用按钮,去项目的按钮组件
     *
     * @param pBtn
     */
    gotoProject(pBtn, parentData): void {
        switch (this.appConfig.config.sql_path) {
            case 'assets':
                this.assetsService.doAction(pBtn, parentData);
                break;
        }
    }

    /**
     *  判断数据在指定的状态才能操作 1 代表可以操作 0 代表不可操作
     */
    btnOpt(dataObject, type) {
        let optState = 1;
        _sh.each(dataObject.data, (OneData) => {
            let selected = this.util.Base_getJsonValue(OneData, 'checked', false);
            if (selected) {
                _sh.each(dataObject.showColumns.columns, (OneColumn) => {
                    if (this.util.Base_HasValue(OneColumn['buttonOpt'])) {
                        let dataState = OneData[OneColumn['column']];
                        if (!_sh.includes(OneColumn['buttonOpt'], dataState)) {
                            optState = 0;
                        }
                    } else if (this.util.Base_HasValue(OneColumn['stateLimit'])) {
                        let dataState = OneData[OneColumn['column']];
                        if (!_sh.includes(OneColumn['stateLimit'][type], dataState)) {
                            optState = 0;
                        }
                    }
                });
            }
        });
        return optState;
    }
}
