import {Injectable} from '@angular/core';
import {AppConfigService} from './app-config.service';
import {AjaxService} from './ajax.service';
import {UserInfoService} from './user-info.service';
import {UtilsService} from './utils.service';
import {DataCenterService} from './data-center.service';

import {AutoInsertService} from './auto-insert.service';
import * as _sh from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class RequestDataService {

    constructor(private ajaxService: AjaxService,
                private autoInsert: AutoInsertService,
                private appConfigService: AppConfigService,
                private userInfoService: UserInfoService,
                private utils: UtilsService,
                private dataCenterService: DataCenterService) {
    }

    makeParam(pMENU_ID?, pageNum?, pPageType?, jsonFile?, initData?, pageCount?, orderBy?) {
        const menuData = this.dataCenterService._dataObject.nowMenu;
        if (this.utils.Base_HasValue(pMENU_ID)) {
            pMENU_ID = menuData.MENU_ID;
        }
        let SEARCH_ID = jsonFile;
        const conditons = {};
        // 如果这个没有传入值来，那么就采用数据库的配置。
        if (!this.utils.Base_HasValue(initData)) {
            //增加一个 initData 的查询参数默认查询出所有的值，数据库之中 ， MENU_MENU
            // MENU表的该字段控制是否在第一次进入的时候加载数据；"getData"代表需要在首次就加载数据, "noData" 不需要加载数据
            if (this.utils.Base_HasValue(menuData.MENU_MENU)) {
                if (menuData.MENU_MENU === 'getData') {
                    initData = 'getData';
                } else if (menuData.MENU_MENU === 'noData') {
                    initData = 'noData';
                }
            } else {
                //如果数据没有配置，那么就默认为必须查询出来
                initData = 'getData';
            }
        }
        let MENU_SEARCH_ID = this.appConfigService.config['MysqlSearchPF'] || null;
        if (this.utils.Base_HasValue(pMENU_ID)) {
            const dataObject = this.dataCenterService._dataObject;
            const nowData = dataObject.list[dataObject.index];
            // conditons = nowData.searchCondition;
            if (this.utils.Base_HasValue(nowData.searchCondition)) {
                _sh.each(nowData.searchCondition, (item) => {
                    // 如果是daterange 类型需要将字段从bindColumns中取出
                    if (_sh.includes(['dateRange', 'dateTimeRange'], item['component'])) {
                        _sh.each(item['code'], (value, index) => {
                            const column = item['bindColumns'][index];
                            conditons[column] = value;
                        });
                    } else {
                        conditons[item['column']] = item['code'];
                    }
                });
            }
            const RIGHT_ID = this.utils.Base_getMenulist(pMENU_ID).RIGHT_ID;
            if (this.utils.Base_HasValue(this.utils.Base_getMenulist(pMENU_ID).MENU_SEARCH_ID)) { //是否设置了独立数据源
                MENU_SEARCH_ID = this.utils.Base_getMenulist(pMENU_ID).MENU_SEARCH_ID;
            }
            //强制转为字符型进行比较,以免出现类型不对等的BUG
            // if (this.utils.Base_compare(RIGHT_ID, 1)) {
            //   let paramconditions = this.utils.Base_getParamCondtions(this.utils.Base_getParamConditions(), pMENU_ID);
            //   if (!_sh.isEmpty(paramconditions)) {
            //     _sh.each(_sh.keys(paramconditions), function (OneParam) {
            //       conditons[OneParam] = paramconditions[OneParam];
            //     });
            //   }
            // }
            SEARCH_ID = this.utils.Base_getAppListSearchId(pMENU_ID); //如果按照menu_id 找到 SEARCH_id , 覆盖传入的值
        }
        let tmpPageNum = this.appConfigService.config.pageNum; // 取默认值。
        let tmpPageCount = this.appConfigService.config.pageCount;
        if (this.utils.Base_HasValue(pageCount)) {
            tmpPageCount = pageCount;
        }
        if (this.utils.Base_HasValue(pageNum)) {
            tmpPageNum = pageNum;
        }

        let pageType = pPageType;
        if (!this.utils.Base_HasValue(pageType)) {
            pageType = 'this_page';
        }

        let comId = this.userInfoService.getUserInfo().COM_ID;
        if (this.utils.Base_HasValue(comId) && comId === 'public') {
            comId = null;
        }
        const myParam = {
            'PF': this.utils.Base_HasValue(MENU_SEARCH_ID) ? MENU_SEARCH_ID : this.appConfigService.config.MysqlPF,
            'sqlfile': SEARCH_ID, 'comId': comId,
            'userId': this.userInfoService.getUserInfo().USER_ID,
            'empId': this.userInfoService.getUserInfo().EMP_ID,
            'userName': this.userInfoService.getUserInfo().USERNAME,
            'pageNum': tmpPageNum,
            'pageCount': tmpPageCount,
            'pageType': pageType,
            'initData': initData,
            'conditions': conditons
        };
        if (this.utils.Base_HasValue(orderBy) && orderBy.length) {
            myParam['orderBy'] = orderBy;
        }
        return myParam;
    }

    // 一般用于查询详情
    getFileWithData(searchId, condition?, type?) {
        const config = this.appConfigService.config;
        const baskUrl = this.appConfigService.getServer().NodeJsonFile;
        const tmpUrl = baskUrl + '?random=' + Math.random();
        const mysqlPf = config['MysqlSearchPF'] ? config['MysqlSearchPF'] : config.MysqlPF;
        const user = this.userInfoService.getUserInfo() || {};
        let comId = user.COM_ID;
        if (this.utils.Base_HasValue(comId) && comId === 'public') {
            comId = null;
        }
        const myParam = {
            'PF': mysqlPf,
            'sqlfile': searchId,
            'userId': user.USER_ID,
            'empId': user.EMP_ID,
            'userName': user.USERNAME,
            'type': type,
            'debug': config['debug'] ? true : false,
            'conditions': condition || {}
        };
        return this.ajaxService.ajaxPost(tmpUrl, myParam);
    }


    // 执行文档填充
    execWordProcess(wordConfigId, documentsId, fileName, wordProcessType, pdfCallBackUrl, pAutoJson) {
        const config = this.appConfigService.config;
        const wordProcess = this.appConfigService.config.wordProcess;
        const serverConfig = this.appConfigService.getServer();
        const sendPdfCallBackUrl = pdfCallBackUrl;
        const tmpUrl = serverConfig.execWordProcess + '?random=' + Math.random();
        const mysqlPf = config['MysqlSearchPF'] ? config['MysqlSearchPF'] : config.MysqlPF;
        const user = this.userInfoService.getUserInfo() || {};
        let comId = user.COM_ID;
        if (this.utils.Base_HasValue(comId) && comId === 'public') {
            comId = null;
        }
        const myParam = {
            'PF': mysqlPf,
            'userId': user.USER_ID,
            'wordConfigId': wordConfigId,
            'documentsId': documentsId,
            'fileName': fileName,
            'getway': wordProcess.getway + 'execWordProcess',
            'accessToken': wordProcess.accessToken,
            'wordProcessType': wordProcessType,
            'pdfCallBackUrl': sendPdfCallBackUrl,
            'autoJson': pAutoJson,
            'debug': config['debug'] ? true : false
        };
        return this.ajaxService.ajaxPost(tmpUrl, myParam);
    }

    // 没有分页的查询
    getDataByCondition(searchId, condition?) {
        const config = this.appConfigService.config;
        const serverConfig = this.appConfigService.getServer();
        const tmpUrl = serverConfig.NodeMongoSql + '?random=' + Math.random();
        const mysqlPf = config['MysqlSearchPF'] ? config['MysqlSearchPF'] : config.MysqlPF;
        const user = this.userInfoService.getUserInfo() || {};
        let comId = user.COM_ID;
        if (this.utils.Base_HasValue(comId) && comId === 'public') {
            comId = null;
        }
        const myParam = {
            'PF': mysqlPf,
            'sqlfile': searchId,
            'userId': user.USER_ID,
            'empId': user.EMP_ID,
            'noFilterAnd': true,
            'userName': user.USERNAME,
            'comId': comId,
            'debug': config['debug'] ? true : false,
            'conditions': condition || {}
        };
        return this.ajaxService.ajaxPost(tmpUrl, myParam);
    }


    getDataByConditionToPf(searchId, condition?, pf?) {
        const config = this.appConfigService.config;
        const serverConfig = this.appConfigService.getServer();
        const tmpUrl = serverConfig.NodeMongoSql + '?random=' + Math.random();
        const mysqlPf = config['MysqlSearchPF'] ? config['MysqlSearchPF'] : config.MysqlPF;
        const user = this.userInfoService.getUserInfo() || {};
        let comId = user.COM_ID;
        if (this.utils.Base_HasValue(comId) && comId === 'public') {
            comId = null;
        }
        const myParam = {
            'PF': pf ? pf : mysqlPf,
            'sqlfile': searchId,
            'userId': user.USER_ID,
            'empId': user.EMP_ID,
            'userName': user.USERNAME,
            'comId': comId,
            'conditions': condition || {}
        };
        return this.ajaxService.ajaxPost(tmpUrl, myParam);
    }

    getBuildData(searchId, condition) {
        const config = this.appConfigService.config;
        const serverConfig = this.appConfigService.getServer();
        const baskUrl = serverConfig.buildData;
        const tmpUrl = baskUrl + '?random=' + Math.random();
        const mysqlPf = config['MysqlSearchPF'] ? config['MysqlSearchPF'] : config.MysqlPF;
        const user = this.userInfoService.getUserInfo() || {};
        const myParam = {
            'PF': mysqlPf,
            'sqlfile': searchId,
            'userId': user.USER_ID,
            'conditions': condition || {}
        };
        return this.ajaxService.ajaxPost(tmpUrl, myParam);
    }

    getDataByJson(SEARCH_ID) {
        const config = this.appConfigService.config;
        const serverConfig = this.appConfigService.getServer();
        const baskUrl = serverConfig.GetJsonFile;
        const tmpUrl = baskUrl + '?random=' + Math.random();
        const mysqlPf = config['MysqlSearchPF'] ? config['MysqlSearchPF'] : config.MysqlPF;
        const user = this.userInfoService.getUserInfo() || {};
        let comId = user.COM_ID;
        if (this.utils.Base_HasValue(comId) && comId === 'public') {
            comId = null;
        }
        const myParam = {
            'PF': mysqlPf,
            'jsonfile': SEARCH_ID
        };
        return this.ajaxService.ajaxPost(tmpUrl, myParam);
    }

    //  有分页的查询
    getPaginationData(searchId, condition?, pageIndex?, pageSize?, orderBy?, reportACSelectOne ?) {
        const menuData = this.dataCenterService._dataObject.nowMenu;
        const config = this.appConfigService.config;
        const serverConfig = this.appConfigService.getServer();
        const tmpUrl = serverConfig.NodeMongoSql + '?random=' + Math.random();
        let mysqlPf = config['MysqlSearchPF'] ? config['MysqlSearchPF'] : config.MysqlPF;
        if (this.utils.Base_HasValue(menuData.MENU_SEARCH_ID)) { //是否设置了独立数据源
            mysqlPf = menuData.MENU_SEARCH_ID;
        }
        const user = this.userInfoService.getUserInfo() || {};
        let comId = user.COM_ID;
        if (this.utils.Base_HasValue(comId) && comId === 'public') {
            comId = null;
        }
        let tmpPageNum = config.pageNum; // 取默认值。
        let tmpPageCount = config.pageCount;
        if (this.utils.Base_HasValue(pageSize)) {
            tmpPageCount = pageSize;
        }
        if (this.utils.Base_HasValue(pageIndex)) {
            tmpPageNum = pageIndex;
        }
        const myParam = {
            'PF': mysqlPf,
            'sqlfile': searchId,
            'userId': user.USER_ID,
            'empId': user.EMP_ID,
            'userName': user.USERNAME,
            'pageNum': tmpPageNum,
            'pageCount': tmpPageCount,
            'pageType': 'this_page',
            'debug': config['debug'] ? true : false,
            'comId': comId,
            'conditions': condition || {},
            'reportACSelectOne': reportACSelectOne
        };
        if (this.utils.Base_HasValue(orderBy)) {
            myParam['orderBy'] = orderBy;
        }
        return this.ajaxService.ajaxPost(tmpUrl, myParam);
    }

    getFormJson(pMODEL, pParentData) {
        const menuData = this.dataCenterService._dataObject.nowMenu;
        const conditons = pParentData.detailCondition || {};

        const config = this.appConfigService.config;
        const serverConfig = this.appConfigService.getServer();
        const baskUrl = serverConfig.NodeJsonFile + '?random=' + Math.random();
        let mysqlPf = config['MysqlSearchPF'] ? config['MysqlSearchPF'] : config.MysqlPF;
        if (this.utils.Base_HasValue(menuData.MENU_SEARCH_ID)) { //是否设置了独立数据源
            mysqlPf = menuData.MENU_SEARCH_ID;
        }
        const SEARCH_ID = this.utils.Base_getAppListUpdateId(pParentData.nowMenu.MENU_ID);
        const user = this.userInfoService.getUserInfo() || {};
        let comId = user.COM_ID;
        if (this.utils.Base_HasValue(comId) && comId === 'public') {
            comId = null;
        }
        const myParam = {
            'PF': mysqlPf,
            'sqlfile': SEARCH_ID,
            'userId': user.USER_ID,
            'empId': user.EMP_ID,
            'userName': user.USERNAME,
            'comId': comId,
            'type': pMODEL,
            'conditions': conditons
        };
        return this.ajaxService.ajaxPost(baskUrl, myParam);
    }

    getServerTime() {
        if (!this.appConfigService.config['HdbssqlPF'] || !this.appConfigService.config['com_name']) {
            return this.ajaxService.ajaxGet('login.json');
        }
        const serverConfig = this.appConfigService.getServer();
        const tmpUrl = serverConfig.NodeMongoSql + '?random=' + Math.random();
        const myParam = {
            'mongoPF': this.appConfigService.config.mongoPF,
            'PF': this.appConfigService.config['HdbssqlPF'],
            'sqlfile': 'hdbs/server_time.json',
            'conditions': {
                'CUST_NAME': this.appConfigService.config['com_name']
            }
        };
        return this.ajaxService.ajaxPost(tmpUrl, myParam);
    }

    // 查询字典json
    getConditionData(searchId) {
        const config = this.appConfigService.config;
        const baskUrl = this.appConfigService.getServer().NodeJsonFile;
        const tmpUrl = baskUrl + '?random=' + Math.random();
        const mysqlPf = config['MysqlSearchPF'] ? config['MysqlSearchPF'] : config.MysqlPF;
        const user = this.userInfoService.getUserInfo() || {};
        let comId = user.COM_ID;
        if (this.utils.Base_HasValue(comId) && comId === 'public') {
            comId = null;
        }
        const myParam = {
            'PF': mysqlPf,
            'sqlfile': searchId,
            'userId': user.USER_ID,
            'empId': user.EMP_ID,
            'userName': user.USERNAME,
            'type': 'condition'
        };
        return this.ajaxService.ajaxPost(tmpUrl, myParam);
    }


    // 查询菜单按钮
    getBtnListJson(pMENU_ID) {
        const user = this.userInfoService.getUserInfo() || {};
        const comId = user.COM_ID;
        const config = this.appConfigService.config;
        const SEARCH_ID = config.btn_list;
        const mysqlPf = config['MysqlSearchPF'] ? config['MysqlSearchPF'] : config.MysqlPF;
        const baskUrl = this.appConfigService.getServer().NodeMongoSql;
        const tmpUrl = baskUrl + '?random=' + Math.random();
        const myParam = {
            'PF': mysqlPf,
            'sqlfile': SEARCH_ID,
            'userId': user.USER_ID,
            'empId': user.EMP_ID,
            'userName': user.USERNAME,
            'comId': comId,
            'conditions': {
                'MENU_ID': pMENU_ID
            }
        };
        return this.ajaxService.ajaxPost(tmpUrl, myParam);
    }

    // 查询按钮
    getBtnDetailJson(pMENU_ID) {
        const user = this.userInfoService.getUserInfo() || {};
        const comId = user.COM_ID;
        const config = this.appConfigService.config;
        const SEARCH_ID = config.btn_detail;
        const mysqlPf = config['MysqlSearchPF'] ? config['MysqlSearchPF'] : config.MysqlPF;
        const baskUrl = this.appConfigService.getServer().NodeMongoSql;
        const tmpUrl = baskUrl + '?random=' + Math.random();
        const myParam = {
            'PF': mysqlPf,
            'sqlfile': SEARCH_ID,
            'userId': user.USER_ID,
            'empId': user.EMP_ID,
            'userName': user.USERNAME,
            'comId': comId,
            'conditions': {
                'MENU_ID': pMENU_ID
            }
        };
        return this.ajaxService.ajaxPost(tmpUrl, myParam);
    }

    // 查询只读按钮
    getBtnReadOnlyJson(pMENU_ID) {
        const user = this.userInfoService.getUserInfo() || {};
        const comId = user.COM_ID;
        const config = this.appConfigService.config;
        const SEARCH_ID = config.sql_path + "/menu/user_menu_readOnly_action.json";
        const mysqlPf = config['MysqlSearchPF'] ? config['MysqlSearchPF'] : config.MysqlPF;
        const baskUrl = this.appConfigService.getServer().NodeMongoSql;
        const tmpUrl = baskUrl + '?random=' + Math.random();
        const myParam = {
            'PF': mysqlPf,
            'sqlfile': SEARCH_ID,
            'userId': user.USER_ID,
            'empId': user.EMP_ID,
            'userName': user.USERNAME,
            'comId': comId,
            'conditions': {
                'MENU_ID': pMENU_ID
            }
        };
        return this.ajaxService.ajaxPost(tmpUrl, myParam);
    }

    // 查询只读按钮
    getBtnWorkflowJson(pMENU_ID) {
        const user = this.userInfoService.getUserInfo() || {};
        const comId = user.COM_ID;
        const config = this.appConfigService.config;
        const SEARCH_ID = config.sql_path + "/menu/user_menu_workflow_action.json";
        const mysqlPf = config['MysqlSearchPF'] ? config['MysqlSearchPF'] : config.MysqlPF;
        const baskUrl = this.appConfigService.getServer().NodeMongoSql;
        const tmpUrl = baskUrl + '?random=' + Math.random();
        const myParam = {
            'PF': mysqlPf,
            'sqlfile': SEARCH_ID,
            'userId': user.USER_ID,
            'empId': user.EMP_ID,
            'userName': user.USERNAME,
            'comId': comId,
            'conditions': {
                'MENU_ID': pMENU_ID
            }
        };
        return this.ajaxService.ajaxPost(tmpUrl, myParam);
    }

    // 唯一性校验查询
    getCountJson(pTableName, pDatas, pIdArr?) {
        const tmpUrl = this.appConfigService.getServer().countServlet + '?random=' + Math.random();
        const myParam = {
            'PF': this.appConfigService.config.MysqlPF,
            'tableName': pTableName,
            'datas': pDatas,
            'idCons': pIdArr
        };
        return this.ajaxService.ajaxPost(tmpUrl, myParam);
    }

    // 导出
    getExcel(pageNum, pType) {
        const tmpUrl = this.appConfigService.getServer().excelServlet + '?random=' + Math.random();
        const myParam = this.makeParam(this.dataCenterService._dataObject.nowMenu['MENU_ID'], pageNum, null);
        if (pType === 'all') {
            myParam['pageNum'] = null;
            myParam['pageCount'] = null;
        }
        return this.ajaxService.ajaxPost(tmpUrl, myParam);
    }

    // 数据处理类，controller 后台controller名称  method 方法名，data 数据
    doActionByJavaPlatform(controller, method, data) {
        const uData = encodeURI('userId:\'' + this.userInfoService.getUserInfo().USER_ID + '\',userName:\'' + encodeURI(this.userInfoService.getUserInfo().USERNAME) + '\',empId:\'' + this.userInfoService.getUserInfo().EMP_ID + '\',PF:\'' + this.appConfigService.config.MysqlPF + '\'');
        data += ',' + uData;
        const httpUrl = this.appConfigService.config.javaPlatform;
        const actionUrl = '/' + controller + '/' + method;
        const tmpUrl = httpUrl + actionUrl + '?random=' + Math.random();
        const myParam = 'PF=' + this.appConfigService.config.MysqlPF + '&param={"request_head": {},"request_body": {' + data + '}}';
        return this.ajaxService.ajaxPostToJava(tmpUrl, myParam);
    }

    doActionByHdbsthorJson(model, controller, method, data?) {
        const httpUrl = this.appConfigService.config.hdbsthor.zuul;
        let actionUrl = '/' + model + '/' + controller + '/' + method;
        if (!this.utils.Base_HasValue(model)) {
            actionUrl = '/' + controller + '/' + method;
        }
        if (this.appConfigService.config.sql_path === 'pisa') {
            actionUrl = '/' + controller + '/' + method;
        }
        //const actionUrl ="/"+controller + "/" + method;
        const tmpUrl = httpUrl + actionUrl + '?accessToken=' + this.appConfigService.config.hdbsthor.accessToken + '&random=' + Math.random();
        return this.ajaxService.ajaxPostJson(tmpUrl, JSON.stringify(data));
    }

    doActionByHdbsHulk(pController, pMethod, pQueryData, pBodyData?) {
        const nodeJsServer = _sh.get(this.appConfigService, ['config', 'NodeJsServer']);
        const nodeJsApiVersion = _sh.get(this.appConfigService, ['config', 'NodeJsApiVersion']);
        const httpUrl = nodeJsServer + '/' + nodeJsApiVersion + '/' + pController + '/' + pMethod;
        let queryString = '';
        if (this.utils.Base_HasValue(pQueryData)) {
            const params = _sh.keys(pQueryData);
            _sh.each(params, (oneKey) => {
                queryString += '&' + oneKey + '=' + pQueryData[oneKey];
            });
        }
        let tmpUrl = httpUrl + '?' + '&random=' + Math.random() + queryString;
        return this.ajaxService.ajaxPostJson(tmpUrl, JSON.stringify(pBodyData), true);
    }

    transformMenuList(menuList) {
        // 递归函数，用于构建每个菜单项的子菜单
        function buildChildren(menuItems, parentId) {
            return menuItems
                .filter(item => item['UP_ID'] === parentId)
                .map(item => {
                    // 构建子菜单
                    const children = buildChildren(menuItems, item['MENU_ID']);
                    // 添加子菜单数量
                    return {
                        ...item,
                        children: children
                    };
                });
        }

        // 使用递归函数构建顶级菜单
        return buildChildren(menuList, null);
    }

    async getMenuListByRole(roleIdList): Promise<any> {
        try {
            const response = await this.getDataByCondition(this.appConfigService.config.sql_path + '/menu/system_role_menu.json', {'ROLE_ID': roleIdList}).toPromise();
            let menuDatas = _sh.get(response, ['data'], []);
            console.log("request-data.service.ts 01",menuDatas);
            menuDatas = _sh.uniqBy(menuDatas, 'MENU_ID');

            // Initialize a map to store submenu counts
            const submenuCountMap = {};

            // Count submenus for each menu
            _sh.each(menuDatas, oneData => {
                const upId = _sh.get(oneData, ['MENU_ID_DICT_ARRAY', 'UP_ID']);
                if (upId) {
                    submenuCountMap[upId] = (submenuCountMap[upId] || 0) + 1;
                }
            });
            let menuList = [];
            _sh.each(menuDatas, oneDatas => {
                if (_sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'MENU_TYPE'], 0) == 1) {
                    let menuId = _sh.get(oneDatas, ['MENU_ID']);
                    let menuObj = {
                        'MENU_ID': _sh.get(oneDatas, ['MENU_ID']),
                        'MENU_NAME': _sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'MENU_NAME']),
                        'MENU_URL': _sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'MENU_URL']),
                        'MENU_TYPE': _sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'MENU_TYPE']),
                        'MENU_STATE': _sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'MENU_STATE']),
                        'UP_ID': _sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'UP_ID']),
                        'RIGHT_ID': _sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'RIGHT_ID']),
                        'MENU_ORDER': _sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'MENU_ORDER']),
                        'MENU_LV': _sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'MENU_LV']),
                        'MENU_END': _sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'MENU_END']),
                        'MENU_UPDATE_URL': _sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'MENU_UPDATE_URL']),
                        'MENU_ADD': _sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'MENU_ADD']),
                        'MENU_SEARCH': _sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'MENU_SEARCH']),
                        'MENU_QRCODE': _sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'MENU_QRCODE']),
                        'MENU_MENU': _sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'MENU_MENU']),
                        'WX_URL': _sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'WX_URL']),
                        'MENU_ICON': _sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'MENU_ICON']),
                        'MENU_SEARCH_ID': _sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'MENU_SEARCH_ID']),
                        'USER_ID': _sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'USER_ID']),
                        'ROLE_ID': _sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'ROLE_ID']),
                        'COM_ID': _sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'COM_ID']),
                        'U_DELETE': _sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'U_DELETE']),
                        'SUBMENU_COUNT': submenuCountMap[menuId] || 0, // Add submenu count
                    };
                    menuList.push(menuObj);
                }
            });
            menuList = _sh.uniqBy(menuList, 'MENU_ID');

            return _sh.orderBy(menuList, ['MENU_ORDER'], ['asc']);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }


    async getMenuListByRole_back(roleIdList): Promise<any> {
        try {
            const response = await this.getDataByCondition(this.appConfigService.config.sql_path + '/menu/system_role_menu.json', {'ROLE_ID': roleIdList}).toPromise();
            console.log("menus.component.ts 02 response", response);
            let menuDatas = _sh.get(response, ['data'], []);
            menuDatas = _sh.uniqBy(menuDatas, 'MENU_ID');
            let menuList = [];
            _sh.each(menuDatas, oneDatas => {
                if (_sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'MENU_TYPE'], 0) == 1) {
                    let menuObj = {
                        'MENU_ID': _sh.get(oneDatas, ['MENU_ID']),
                        'MENU_NAME': _sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'MENU_NAME']),
                        'MENU_URL': _sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'MENU_URL']),
                        'MENU_TYPE': _sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'MENU_TYPE']),
                        'MENU_STATE': _sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'MENU_STATE']),
                        'UP_ID': _sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'UP_ID']),
                        'RIGHT_ID': _sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'RIGHT_ID']),
                        'MENU_ORDER': _sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'MENU_ORDER']),
                        'MENU_LV': _sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'MENU_LV']),
                        'MENU_END': _sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'MENU_END']),
                        'MENU_UPDATE_URL': _sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'MENU_UPDATE_URL']),
                        'MENU_ADD': _sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'MENU_ADD']),
                        'MENU_SEARCH': _sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'MENU_SEARCH']),
                        'MENU_QRCODE': _sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'MENU_QRCODE']),
                        'MENU_MENU': _sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'MENU_MENU']),
                        'WX_URL': _sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'WX_URL']),
                        'MENU_ICON': _sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'MENU_ICON']),
                        'MENU_SEARCH_ID': _sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'MENU_SEARCH_ID']),
                        'USER_ID': _sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'USER_ID']),
                        'ROLE_ID': _sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'ROLE_ID']),
                        'COM_ID': _sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'COM_ID']),
                        'U_DELETE': _sh.get(oneDatas, ['MENU_ID_DICT_ARRAY', 'U_DELETE']),
                    }
                    menuList.push(menuObj);
                }
            });
            menuList = _sh.uniqBy(menuList, 'MENU_ID');
            // 这里我需要增加一个 SUBMENU_COUNT 的属性，用来统计子菜单的数量，UP_ID 是关键字，他代表上级菜单的ID
            return _sh.orderBy(menuList, ['MENU_ORDER'], ['asc']);
        } catch (error) {
            // 处理或抛出错误
            console.error(error);
            throw error;
        }
    }

    doActionByHdbsthor(pController, pMethod, pQueryData, pBodyData?, pHasPF?, pThorConfig?, pAppToken?, pUploadFile?) {
        const httpUrl = this.appConfigService.config[pThorConfig].getway || this.appConfigService.config[pThorConfig].zuul;
        const accessToken = this.appConfigService.config[pThorConfig].accessToken;
        let actionUrl = '';
        if (pHasPF) {
            actionUrl = '/' + pController + '/' + this.appConfigService.config.MysqlPF + '/' + pMethod;
        } else {
            actionUrl = '/' + pController + '/' + pMethod;
        }
        let queryString = '';
        if (this.utils.Base_HasValue(pQueryData)) {
            const params = _sh.keys(pQueryData);
            _sh.each(params, (oneKey) => {
                queryString += '&' + oneKey + '=' + pQueryData[oneKey];
            });
        }
        let tmpUrl = httpUrl + actionUrl + '?' + pAppToken + '=' + accessToken + '&random=' + Math.random() + queryString;
        if (this.utils.Base_HasValue(pUploadFile)) {
            return this.ajaxService.ajaxUpload(tmpUrl, pBodyData, true);
        } else {
            return this.ajaxService.ajaxPostJson(tmpUrl, JSON.stringify(pBodyData), true);
        }
    }

    // 没有分页的查询
    getJsonData(url) {
        return this.ajaxService.ajaxGet(url);
    }


    // 获取表字段
    getTableColumnsThisPf(pTableName) {
        const tmpUrl = this.appConfigService.getServer().devToolsServletColumns + '?random=' + Math.random();
        const myParam = {
            'PF': this.appConfigService.config.MysqlPF,
            'tableName': pTableName
        };
        return this.ajaxService.ajaxPost(tmpUrl, myParam);
    }


    // 获取表字段
    getTableColumns(pDataSource, pTableSchema, pTableName) {
        const tmpUrl = this.appConfigService.getServer().devToolsServletColumns + '?random=' + Math.random();
        const myParam = {
            'PF': pDataSource ? pDataSource : this.appConfigService.config.MysqlPF,
            'tableName': pTableName,
            'tableSchema': pTableSchema
        };
        return this.ajaxService.ajaxPost(tmpUrl, myParam);
    }

    getSelectTextColumn(pJsonObject, pTableSchema, pPf) {
        const tmpUrl = this.appConfigService.config.NodeJsServer + '/' + this.appConfigService.config.NodeJsApiVersion + '/devToolsServlet/getSelect' + '?random=' + Math.random();
        const myParam = {
            'PF': pPf ? pPf : this.appConfigService.config.MysqlPF,
            'jsonBody': pJsonObject,
            'tableSchema': pTableSchema
        };
        return this.ajaxService.ajaxPost(tmpUrl, myParam);
    }

    getShowColumns(pJsonObject, pTableSchema, pPf) {
        const tmpUrl = this.appConfigService.config.NodeJsServer + '/' + this.appConfigService.config.NodeJsApiVersion + '/devToolsServlet/getShowColumns' + '?random=' + Math.random();
        const myParam = {
            'PF': pPf ? pPf : this.appConfigService.config.MysqlPF,
            'jsonBody': pJsonObject,
            'tableSchema': pTableSchema
        };
        return this.ajaxService.ajaxPost(tmpUrl, myParam);
    }


    detailColumnsGet(pTableName, pTableSchema, pPf) {
        const tmpUrl = this.appConfigService.config.NodeJsServer + '/' + this.appConfigService.config.NodeJsApiVersion + '/devToolsServlet/detailColumnsGet' + '?random=' + Math.random();
        const myParam = {
            'PF': pPf ? pPf : this.appConfigService.config.MysqlPF,
            'tableName': pTableName,
            'tableSchema': pTableSchema
        };
        return this.ajaxService.ajaxPost(tmpUrl, myParam);
    }

    getTables(searchText) {
        const tmpUrl = this.appConfigService.config.NodeJsServer + '/' + this.appConfigService.config.NodeJsApiVersion + '/devToolsServlet/tables' + '?random=' + Math.random();
        const myParam = {
            'PF': this.appConfigService.config.MysqlPF,
            'searchText': searchText
        };
        return this.ajaxService.ajaxPost(tmpUrl, myParam);
    }

    waitingToBeProcessedServlet(pPf?) {
        let wtbp_json = this.appConfigService.config.wtbp_json || null;
        let menu_list = this.appConfigService.config.menu_list || null;
        const user = this.userInfoService.getUserInfo() || {};
        let comId = user.COM_ID;
        if (this.utils.Base_HasValue(comId) && comId === 'public') {
            comId = null;
        }
        const tmpUrl = this.appConfigService.config.NodeJsServer + '/' + this.appConfigService.config.NodeJsApiVersion + '/waitingToBeProcessedServlet/get' + '?random=' + Math.random();
        const myParam = {
            'PF': pPf ? pPf : this.appConfigService.config.MysqlPF,
            'comId': comId,
            'userId': user.USER_ID,
            'menuJson': menu_list,
            'wtbpJson': wtbp_json
        };
        return this.ajaxService.ajaxPost(tmpUrl, myParam);
    }

    // 获取json文件列表
    getFileList(pSqlPath?) {
        const tmpUrl = this.appConfigService.config.NodeJsServer + '/' + this.appConfigService.config.NodeJsApiVersion + '/devToolsServlet/getJsonFileList' + '?random=' + Math.random();
        const myParam = {
            'PF': this.appConfigService.config.MysqlPF,
            'sqlPath': pSqlPath
        };
        return this.ajaxService.ajaxPost(tmpUrl, myParam);
    }

    saveJsonUpload(pJsonBody, pFilePath, pFileDesc) {
        const user = this.userInfoService.getUserInfo() || {};
        const tmpUrl = this.appConfigService.config.NodeJsServer + '/' + this.appConfigService.config.NodeJsApiVersion + '/devToolsServlet/saveFileUpload' + '?random=' + Math.random();
        const myParam = {
            'PF': this.appConfigService.config.MysqlPF,
            'jsonBody': pJsonBody,
            'filePath': pFilePath,
            'fileDesc': pFileDesc,
            'userId': user.USER_ID,
            'userName': user.USERNAME,
        };
        return this.ajaxService.ajaxPost(tmpUrl, myParam);
    }

    saveJsonToLocal(pUrl, pJsonBody, pFilePath) {
        const tmpUrl = pUrl + '?random=' + Math.random();
        // const tmpUrl = this.appConfigService.config.NodeJsServer + '/' + this.appConfigService.config.NodeJsApiVersion + '/devToolsServlet/fileToLocal' + '?random=' + Math.random();
        const myParam = {
            'PF': this.appConfigService.config.MysqlPF,
            'jsonBody': pJsonBody,
            'filePath': pFilePath
        };
        return this.ajaxService.ajaxPost(tmpUrl, myParam);
    }

    jsonUploadToOss(pJsonBody) {
        const user = this.userInfoService.getUserInfo() || {};
        const tmpUrl = this.appConfigService.config.NodeJsServer + '/' + this.appConfigService.config.NodeJsApiVersion + '/devToolsServlet/fileUpload' + '?random=' + Math.random();
        const myParam = {
            'PF': this.appConfigService.config.MysqlPF,
            'jsonBody': pJsonBody,
            'userId': user.USER_ID,
            'userName': user.USERNAME,
        };
        return this.ajaxService.ajaxPost(tmpUrl, myParam);
    }

    redisSet(pKey, pValue) {
        const config = this.appConfigService.config;
        const serverConfig = this.appConfigService.getServer();
        const tmpUrl = serverConfig.redisServlet + '/set' + '?random=' + Math.random();
        const myParam = {
            'key': pKey,
            'value': pValue
        };
        return this.ajaxService.ajaxPost(tmpUrl, myParam, true);
    }

    redisGet(pKey) {
        const config = this.appConfigService.config;
        const serverConfig = this.appConfigService.getServer();
        const tmpUrl = serverConfig.redisServlet + '/get' + '?random=' + Math.random();
        const myParam = {
            'key': pKey
        };
        return this.ajaxService.ajaxPost(tmpUrl, myParam, true);
    }

    getFuturePrice(symbol) {
        const tmpUrl = 'http://111.231.136.36:5000/last_price/' + symbol + '?random=' + Math.random();
        // const tmpUrl = 'http://127.0.0.1:5000/last_price/' + symbol + '?random=' + Math.random();
        return this.ajaxService.ajaxGet(tmpUrl);
    }

    baiduMapSearch(pQuery, pTag, pRegion) {
        const serverConfig = this.appConfigService.getServer();
        const tmpUrl = serverConfig.baiduMapServlet + '?random=' + Math.random();
        const myParam = {
            'query': pQuery,
            'tag': pTag,
            'region': pRegion
        };
        return this.ajaxService.ajaxPost(tmpUrl, myParam);
    }

    // 记录操作日志
    saveOptLog(optData) {   // 记录用户访问的功能
        const templateJson = {
            'LIST': [
                {
                    'TABLE': 'system_operate_log',
                    'ACTION': 'Insert',
                    'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
                    'WHERE': [{}],
                    'COLUMNS': [
                        {'COLUMN': 'OPT_ID'},
                        {'COLUMN': 'USER_ID'},
                        {'COLUMN': 'USER_NAME'},
                        {'COLUMN': 'USER_REL_NAME'},
                        {'COLUMN': 'OPT_TABLE'},
                        {'COLUMN': 'OPT_TYPE'},
                        {'COLUMN': 'OPT_PK'},
                        {'COLUMN': 'OPT_CONTENT'},
                        {'COLUMN': 'MENU_ID'},
                        {'COLUMN': 'MENU_NAME'},
                        {'COLUMN': 'ROLE_ID'},
                        {'COLUMN': 'OPT_IP'},
                        {'COLUMN': 'OPT_BUTTON'}
                    ],
                    'RECORDS': [
                        {
                            'OPT_ID': 'SYS-UUID',
                            'USER_ID': this.userInfoService.getUserInfo().USER_ID,
                            'USER_NAME': this.userInfoService.getUserInfo().USER_NAME,
                            'USER_REL_NAME': this.userInfoService.getUserInfo().USERNAME,
                            'OPT_TABLE': optData['OPT_TABLE'],
                            'OPT_TYPE': optData['OPT_TYPE'],
                            'OPT_PK': optData['OPT_PK'],
                            'OPT_CONTENT': optData['OPT_CONTENT'],
                            'MENU_ID': optData['MENU_ID'],
                            'MENU_NAME': optData['MENU_NAME'],
                            'ROLE_ID': optData['ROLE_ID'],
                            'OPT_IP': '',
                            'OPT_BUTTON': optData['OPT_BUTTON']
                        }
                    ]
                }
            ]
        };
        return this.autoInsert.ExecJson(templateJson);
    }
}
