import {Component, Input, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {NzModalService} from "ng-zorro-antd/modal";
import {HulkConfigServiceService} from "../service/hulk-config-service.service";
import {FileSaverService} from "ngx-filesaver";
import {RequestDataService} from "../service/request-data.service";
import {AppConfigService} from "../service/app-config.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {ActivatedRoute} from "@angular/router";
import {ConfirmModalService} from "../service/confirm-modal.service";
import {ClipboardService} from "ngx-clipboard";
import {UtilsService} from "../service/utils.service";
import * as _sh from 'lodash';
import * as _ from "underscore";
import {DataCenterService} from "../service/data-center.service";
import {forkJoin as observableForkJoin} from "rxjs";

@Component({
    selector: 'app-settlement-form',
    templateUrl: './settlement-form.component.html',
    styleUrls: ['./settlement-form.component.scss']
})
export class SettlementFormComponent implements OnInit {

    @Input() parentData: any;
    modeType: String = 'add';
    searchId: String = null;
    mainJson: any = {
        "mainformList": [],
        "itemformList": []
    };

    helpUrl: any;
    private menuId: any;

    constructor(
        private _httpClient: HttpClient,
        private modalService: NzModalService,
        private hulkconfigserviceservice: HulkConfigServiceService,
        private _FileSaverService: FileSaverService,
        private requestDataService: RequestDataService,
        private dataCenterService: DataCenterService,
        private appConfigService: AppConfigService,
        private notification: NzNotificationService,
        private activatedRoute: ActivatedRoute,
        private confirmModal: ConfirmModalService,
        private clipboardService: ClipboardService,
        private utils: UtilsService
    ) {
    }


    buildData(pData, pType, pRoleIdList) {
        let data = _sh.uniqBy(pData, 'ACTION_ID');
        let arr = [];
        _sh.each(data, oneData => {
            let obj = {}
            let oneColumnData = _sh.get(oneData, 'ACTION_ID_DICT_ARRAY');
            let columns = _sh.keys(oneColumnData);
            _sh.each(columns, oneColumn => {
                obj[oneColumn] = oneColumnData[oneColumn];
            });
            let actionTypeList = _sh.split(obj['ACTION_TYPE'], ',');
            if (_sh.includes(actionTypeList, pType)) {
                obj['MENU_ID'] = oneData['MENU_ID'];
                obj['MENU_NAME'] = oneData['MENU_ID_DICT'];
                obj['ROLE_ID'] = _sh.join(pRoleIdList, ',');
                arr.push(obj);
            }
        });
        return arr;
    }

    ngOnInit() {
        this.modeType = _sh.get(this.parentData, ['modeType'], 'add');
        this.searchId = _sh.get(this.parentData, ['inputData', 'jsonFile'], null);
        this.menuId = _sh.cloneDeep(this.parentData.nowMenu.MENU_ID);

        if (!this.utils.Base_HasValue(this.searchId)) {
            // todo show Error
        } else {

            const role$ = observableForkJoin(
                this.requestDataService.getDataByCondition(this.appConfigService.config.sql_path + '/menu/system_user_role.json', {}),
            );
            role$.subscribe(
                roleValues => {
                    let roleIdList = [];
                    _sh.each(roleValues[0]['data'] || [], oneRole => {
                        roleIdList.push(oneRole['ROLE_ID']);
                    })
                    const parallel$ = observableForkJoin(
                        this.requestDataService.getDataByCondition(this.appConfigService.config.sql_path + '/menu/system_role_menu_action.json', {
                            'ROLE_ID': roleIdList,
                            'MENU_ID': this.menuId
                        }),
                        this.requestDataService.getDataByJson(this.searchId)
                    );
                    parallel$.subscribe(
                        values => {
                            this.parentData.actionArray = this.buildData(_sh.get(values, ['0', 'data'], []), 'detail', roleIdList);
                            this.mainJson = _sh.get(values, ['1'], {});
                        }
                    );
                }
            );


        }
    }

    checkAll(oneForm: any, value: boolean) {
        oneForm.datas.forEach(data => {
            if (!data.disabled) {
                data.checked = value;
            }
        });
        this.refreshStatus(oneForm);
    }

    refreshStatus(oneForm): void {
        const validData = oneForm.datas.filter(value => !value.disabled);
        const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
        const allUnChecked = validData.every(value => !value.checked);
        oneForm.allChecked = allChecked;
        oneForm.indeterminate = (!allChecked) && (!allUnChecked);
    }

    gotoBack() {
        this.parentData.data = {};
        this.parentData.conditionColumns = {};
        this.parentData.formType = 'listForm';
        this.parentData.actionArray = [];
        // 覆盖当前数据到data-center;
        _sh.each(this.dataCenterService._dataObject.list, (item) => {
            if (item['id'] === this.parentData.id) {
                item = _sh.extend(item, this.parentData);
            }
        });
        this.dataCenterService.refreshDataCenterInStorage();
    }

    doAction(oneBtn: any) {

    }


    getOneValue(key, value) {
        let findValue = null;
        let that = this;
        let arr = _sh.split(value, '.');
        if (arr[0] == "mainformList") {
            let mainformIndex = arr[1];
            let columnName = arr[2];
            let formColumns = _sh.get(that.mainJson, ['mainformList', mainformIndex,'columns'], []);
            _sh.each(formColumns, oneColumn => {
                if (oneColumn['column'] == columnName) {
                    findValue = _sh.get(oneColumn,['model'],null);
                }
                if (that.utils.Base_HasValue(oneColumn['columnGroup'])) {
                    _sh.each(oneColumn['columnGroup'],oneGroupColumn=>{
                        if (oneColumn['column'] == columnName) {
                            findValue = _sh.get(oneColumn,['model'],null);
                        }
                    })
                }
            })
        }
        return findValue;
    }

    makeUpCondition(oneForm: any) {
        let that = this;
        let conditions = {}
        let conditionList = _sh.keys(_sh.get(oneForm, ['sqlFile', 'conditions'], {}));
        _sh.each(conditionList, oneCondtion => {
            let value = this.getOneValue(oneCondtion, _sh.get(oneForm, ['sqlFile', 'conditions', oneCondtion], null));
            if (that.utils.Base_HasValue(value)) {
                conditions[oneCondtion] = value;
            }
        });
        return conditions;
    }

    checkMainRequire() {
        let that = this;
        let pass = true;
        _sh.each(this.mainJson['mainformList'],oneForm=>{
            _sh.each(oneForm['columns'],oneColumn=>{
                if (oneColumn['require'] && pass) {
                    let value = _sh.get(oneColumn,['model'],null);
                    if (!that.utils.Base_HasValue(value)) {
                        pass = false;
                        this.confirmModal.show('confirm', {
                            title: oneForm['title'] + ' 的 ' + oneColumn['lable'] + '是必填项，请首先填写。'
                        });
                    }
                }
            })
        })
        return pass;
    }

    getNewItem(oneForm: any) {
        // todo 判断主表是否必填
        let that = this;
        let checkPass = false;
        let needMainRequire = _sh.get(oneForm, ['needMainRequire'], false);
        if (needMainRequire) {
            checkPass = this.checkMainRequire();
        } else {
            checkPass = true;
        }
        if (checkPass) {
            let filePath = _sh.get(oneForm, ['sqlFile', 'filePath'], null);
            // todo 判断没有填写 filePath
            let conditions = this.makeUpCondition(oneForm);
            this.requestDataService.getDataByCondition(filePath, conditions).subscribe(result => {
                oneForm['datas'] = _sh.get(result,['data'],[])
                if (!that.utils.Base_HasValue(oneForm['datas'])) {
                    this.confirmModal.show('confirm', {
                        title: '为查询到相关数据。'
                    });
                }
            });
        }
    }
}
