import {Component, ElementRef, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import {forkJoin as observableForkJoin} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {NzModalService} from "ng-zorro-antd/modal";
import {AjaxService} from "../../../service/ajax.service";
import {RequestDataService} from "../../../service/request-data.service";
import {UtilsService} from "../../../service/utils.service";
import * as _sh from "lodash";

import {ActivatedRoute} from "@angular/router";
import moment from "moment";
import {AssetsJumpServiceService} from "../assets-jump-service.service";
moment.locale('zh-cn');

@Component({
  selector: 'app-asset-control-map-list',
  templateUrl: './asset-control-map-list.component.html',
  styleUrls: ['./asset-control-map-list.component.scss']
})
export class AssetControlMapListComponent implements OnInit {

  @Input() parentData: any;
  map: any; //
  public isfull: any = false;
  sumData: any = {};
  sumLeaseData: any = {};
  groupByLeaseState: any = [];
  groupByType: any = [];
  buildingList: any = [];
  rentAsset: any = [];
  piaAsset: any = [];
  searchTextRight: any;
  searchTextLeft:any;
  pageIndex = 1;
  buildingListCount = 0;
  selectBuild:any = {};
  asId = null;
  buildingData: any = {};
  selectFloorList: any = []; // 用于Select框，所有的楼层都要查询出来
  buildingFloorList: any = []; // 楼层数据
  roomList: any = []; // 房间列表
  selectFloor = null; // 选中的楼层。
  selectFloorDict = "未选中";


  constructor(private el: ElementRef,
              private rd: Renderer2,
              private http: HttpClient,
              private modalService: NzModalService,
              private ajaxService: AjaxService,
              private requestService: RequestDataService,
              private utils: UtilsService,
              private requestDataService: RequestDataService,

              private assetsJumpServiceService: AssetsJumpServiceService,
              private activatedRoute: ActivatedRoute,

  ) { }

  getBuildItem(oneBuild) {
      let UP_AS_ID = _sh.get(oneBuild, ['ID']);
    const searchBuild = observableForkJoin(
        this.requestService.getDataByCondition('assets/map/assets_room.json', {'UP_AS_ID': UP_AS_ID}), // 房间列表
        this.requestService.getDataByCondition('assets/map/assets_other.json',{'UP_AS_ID': UP_AS_ID}) // 一般资源
    );
    searchBuild.subscribe(
        values => {
          let roomCount = _sh.get(values, [0, 'data', 0, 'ACCOUNT'], 0);
          let otherCount = _sh.get(values, [1, 'data', 0, 'ACCOUNT'], 0);
          oneBuild['ROOM_COUNT'] = roomCount;
          oneBuild['OTHER_COUNT'] = otherCount;
        })
  }


  getBuildList(pIndex?) {
    let index = pIndex ? pIndex : this.pageIndex;
    let condition = {
    }
    if (this.utils.Base_HasValue(this.searchTextLeft)) {
        condition['AS_NAME'] = this.searchTextLeft;
    }
      const search = observableForkJoin(
          this.requestService.getPaginationData(
              'assets/map/assets_top_building_list.json',
              condition,
              index, 10
          ) // 建筑物列表
      );
      search.subscribe(
          values => {
            this.buildingList = _sh.get(values, [0, 'data'], []);
            this.buildingListCount = _sh.get(values, [0, 'page', 'rowCount'], 0);
            let buildingIndex = 1;
            _sh.each(this.buildingList, oneBuild => {
              oneBuild['description'] = '资产面积：' + oneBuild['AREA'] + '（㎡）';
              oneBuild['showIndex'] = buildingIndex;
              this.getBuildItem(oneBuild);
              buildingIndex++;
            });
            if (this.utils.Base_HasValue(this.buildingList) && !this.utils.Base_HasValue(this.asId)) {
              this.doSearchRight(this.buildingList[0]);
            }
          })
  }

  loadData(pi: number): void {
    this.getBuildList(pi);
  }

  ngOnInit() {
    this.asId = this.activatedRoute.snapshot.params['ID'];
    if(this.utils.Base_HasValue(this.asId)){
      this.doSearchMain(this.asId);
      this.getBuildList();
    }
    else {
      this.getBuildList();
    }

  }

  setRentAsset() {
    let leased = _sh.get(_sh.filter(this.groupByLeaseState, {'LEASE_STATE': 'LEASED'}), [0, 'AREA'], 0);
    let notLeased = _sh.get(_sh.filter(this.groupByLeaseState, {'LEASE_STATE': 'NOT_LEASED'}), [0, 'AREA'], 0);
    let nonLeasable = _sh.get(_sh.filter(this.groupByLeaseState, {'LEASE_STATE': 'NON_LEASABLE'}), [0, 'AREA'], 0);
    const option = {
      grid: {
        top: '4%',
        bottom: '2%',
        left: '2%',
        right: '2%'
      },
      tooltip: {
        trigger: 'item',
        formatter: '{b} : {c} ({d}%)'
      },
      legend: {
        x: 'center',
        y: 'bottom',
        data: [
          {
            name: '空置中',
            textStyle: {
              fontSize: 12,
              fontWeight: 'bolder',
              color: '#333'
            },
            icon: 'circle'
          },
          {
            name: '已租赁',
            textStyle: {
              fontSize: 12,
              fontWeight: 'bolder',
              color: '#333'
            },
            icon: 'circle'
          },
          {
            name: '不可租',
            textStyle: {
              fontSize: 12,
              fontWeight: 'bolder',
              color: '#333'
            },
            icon: 'circle'
          }
        ]
      },
      series: [
        {
          name: '租控比例',
          type: 'pie',
          radius: ['25%', '50%'],
          roseType: 'radius',
          label: {
            normal: {
              show: true,
              formatter: '{b}\n{c}({d}%)'
            },
            emphasis: {
              show: true
            }
          },
          lableLine: {
            normal: {
              show: false
            },
            emphasis: {
              show: true
            }
          },
          data: [
            {
              value: notLeased,
              name: '空置中', itemStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 1,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0.2, color: '#0bb2f2' // 0% 处的颜色
                    },
                    {
                      offset: 1, color: '#293bf5' // 100% 处的颜色
                    }],
                  globalCoord: true // 缺省为 false
                }
              }
            },
            {
              value: leased,
              name: '已租赁', itemStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 1,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0.2, color: '#ffba63' // 0% 处的颜色
                    },
                    {
                      offset: 1, color: '#ff8400' // 100% 处的颜色
                    }],
                  globalCoord: true // 缺省为 false
                }
              }
            },
            {
              value: nonLeasable,
              name: '不可租', itemStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 1,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0.2, color: '#6ff7ff' // 0% 处的颜色
                    },
                    {
                      offset: 1, color: '#00e3ff' // 100% 处的颜色
                    }],
                  globalCoord: true // 缺省为 false
                }
              }
            }
          ]
        }
      ]
    };
    return option;
  }

  getColor(index) {
    let findIndex = index;
    let colorList = [
      {
        'smallColor': '#0bb2f2',
        'bigColor': '#293bf5'
      },
      {
        'smallColor': '#ffba63',
        'bigColor': '#ff8400'
      },
      {
        'smallColor': '#6ff7ff',
        'bigColor': '#00e3ff'
      }
    ];
    let count = colorList.length;
    if (index <= count) {
      findIndex = index;
    } else {
      let mod = count % index;
      findIndex = mod;
    }
    return colorList[findIndex];
  }

  setPiaAsset() {
    let titleList = [];
    _sh.each(this.groupByType, oneType => {
      let obj = {
        name: oneType['AS_TYPE_LV02_NAME'],
        textStyle: {
          fontSize: 12,
          fontWeight: 'bolder',
          color: '#333'
        },
        icon: 'circle'
      }
      titleList.push(obj);
    });
    let numList = [];
    let dataIndex = 1;
    _sh.each(this.groupByType, oneType => {
      let colorSelect = this.getColor(dataIndex);
      let obj = {
        value: oneType['AREA'], name: oneType['AS_TYPE_LV02_NAME'], itemStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 1,
            y2: 1,
            colorStops: [
              {
                offset: 0.2, color: colorSelect['smallColor'] // 0% 处的颜色
              },
              {
                offset: 1, color: colorSelect['bigColor'] // 100% 处的颜色
              }],
            globalCoord: true // 缺省为 false
          }
        }
      }
      numList.push(obj);
      dataIndex++;
    });


    const option = {
      grid: {
        top: '4%',
        bottom: '2%',
        left: '2%',
        right: '2%'
      },
      tooltip: {
        trigger: 'item',
        formatter: '{b} : {c} ({d}%)'
      },
      legend: {
        x: 'center',
        y: 'bottom',
        data: titleList
      },
      series: [
        {
          name: '租控比例',
          type: 'pie',
          radius: ['25%', '50%'],
          roseType: 'radius',
          label: {
            normal: {
              show: true,
              formatter: '{b}\n{c}({d}%)'
            },
            emphasis: {
              show: true
            }
          },
          lableLine: {
            normal: {
              show: false
            },
            emphasis: {
              show: true
            }
          },
          data: numList
        }
      ]
    };
    return option;
  }

  doSearchMain(UP_AS_ID){
    let checked = false;
    let condition = {};
    if(this.utils.Base_HasValue(UP_AS_ID)){
      condition = {
        'UP_AS_ID': UP_AS_ID
      };
      checked = true;
    }
    if (this.utils.Base_HasValue(this.searchTextRight)) {
      condition['AS_NAME'] = this.searchTextRight;
    }

    if (this.utils.Base_HasValue(this.selectFloor)) {
      condition['AS_FLOOR'] = this.selectFloor;
    }

    let conditionNoFloor = _sh.cloneDeep(condition);
    delete conditionNoFloor['AS_FLOOR'];
    delete conditionNoFloor['AS_NAME'];

    if (checked) {
      const search = observableForkJoin(
          this.requestService.getDataByCondition('assets/map/assets_room_list/main.json', condition), //0  资产地图-房间列表
          this.requestService.getDataByCondition('assets/map/assets_building_floor.json', conditionNoFloor),//1 楼层
          this.requestService.getDataByCondition('assets/map/assets_city_area_lease.json', condition), //2 , 按照类型，查询各种类型的面积
          this.requestService.getDataByCondition('assets/map/assets_city_area_type.json', condition), //3  查询资源类型分析
          this.requestService.getDataByCondition('assets/map/assets_top_building.json', condition) // 4  资产地图-楼宇的数据
      );
      search.subscribe(
          values => {
            this.roomList = _sh.get(values, [0, 'data'], []); // 某一层楼的数据。
            _sh.each(this.roomList, oneRoom => {
              if (oneRoom['LEASE_STATE'] == 'LEASED') {
                //oneRoom['showText'] = "到期：" + (oneRoom['END_DATE'] ? moment(oneRoom['START_DATE']).format('YYYY-MM-DD') : '');
                let date1 = moment().locale('zh-cn').format('YYYY-MM-DD');
                let date2 = moment(oneRoom['START_DATE']).format('YYYY-MM-DD');
                oneRoom['showText'] = "已租时间：" + (oneRoom['START_DATE'] ? moment(date1).diff(date2,'days') : '') + "天";//已租时间
              }
              if (oneRoom['LEASE_STATE'] == 'NOT_LEASED') {
                if (this.utils.Base_HasValue(oneRoom['END_DATE'])) {
                  oneRoom['showText'] = "已闲置：" + (oneRoom['END_DATE'] ? moment(oneRoom['END_DATE']).fromNow(true) : '');

                } else {
                  oneRoom['showText'] =  "已闲置：" + (oneRoom['END_DATE'] ? moment(oneRoom['END_DATE']).fromNow(true) : '长期');
                }
              }
              if (oneRoom['UNPAID_STATUS'] == 'UNPAID') {
                oneRoom['showText'] = "已欠缴：" + (oneRoom['ARAP_DATE'] ? moment(oneRoom['ARAP_DATE']).fromNow(true) : '');
              }
            })
            this.sumLeaseData = _sh.get(values, [2, 'data'], {}); // 页面上面的汇总数据
            this.groupByLeaseState = _sh.get(values, [2, 'data'], []); // 按照状态的分组数据
            this.groupByType = _sh.get(values, [3, 'data'], []); // 按照类型的分组数据

            this.selectFloorList = _sh.get(values, [1, 'data'], []); // 该楼的所有楼层
            this.buildingFloorList = _sh.get(values, [1, 'data'], []); // 查询出来后的楼层
            _sh.each(this.buildingFloorList,oneFloor=>{
              oneFloor['childData'] = _sh.filter(this.roomList,{'AS_FLOOR':oneFloor['AS_FLOOR']})
            })
            this.buildingData = _sh.get(values, [4, 'data', 0], {});

            if (this.utils.Base_HasValue(this.sumLeaseData)) {
              this.sumData['AREA'] = _sh.sumBy(this.sumLeaseData, 'AREA');
              this.sumData['ACCOUNT'] = _sh.sumBy(this.sumLeaseData, 'ACCOUNT');
              let leaseData = _sh.get(_sh.filter(this.sumLeaseData, {'LEASE_STATE': 'LEASED'}), [0]);
              if (this.utils.Base_HasValue(leaseData)) {
                this.sumData['LEASE_ACCOUNT'] = leaseData['ACCOUNT']; // 已出租数量
                this.sumData['LEASE_AREA'] = leaseData['AREA']; // 已出租面积
                if (this.sumData['AREA'] > 0) {
                  _sh.set(this.sumData, ['AREA_PERCENTAGE'], _sh.round(_sh.divide(_sh.multiply(leaseData['AREA'], 100), this.sumData['AREA']), 2));
                }

                if (this.sumData['ACCOUNT'] > 0) {
                  _sh.set(this.sumData, ['ACCOUNT_PERCENTAGE'], _sh.round(_sh.divide(_sh.multiply(leaseData['ACCOUNT'], 100), this.sumData['ACCOUNT']), 2));
                }
              } else {
                this.sumData['LEASE_ACCOUNT'] = 0;
                this.sumData['LEASE_AREA'] = 0;
                this.sumData['ACCOUNT_PERCENTAGE'] = 0;
                this.sumData['AREA_PERCENTAGE'] = 0;
              }
            } else {
              this.sumData['ACCOUNT'] = 0;
              this.sumData['AREA'] = 0;
              this.sumData['LEASE_ACCOUNT'] = 0;
              this.sumData['LEASE_AREA'] = 0;
              this.sumData['ACCOUNT_PERCENTAGE'] = 0;
              this.sumData['AREA_PERCENTAGE'] = 0;
            }
            this.rentAsset = this.setRentAsset();
            this.piaAsset = this.setPiaAsset();
          }
      );
    }
  }

  doSearchRight(oneItem?) {
    let checked = false;
    let condition = {};
    if (this.utils.Base_HasValue(oneItem)) {
      let UP_AS_ID = _sh.get(oneItem, ['ID']);
       condition = {
        'UP_AS_ID': UP_AS_ID
      };
      checked = true;
      this.selectBuild = oneItem;
    }else {
      if (this.utils.Base_HasValue(this.selectBuild)) {
        let UP_AS_ID = _sh.get(this.selectBuild, ['ID']);
        condition = {
          'UP_AS_ID': UP_AS_ID
        };
        checked = true;
      }
    }
    if (this.utils.Base_HasValue(this.searchTextRight)) {
      condition['AS_NAME'] = this.searchTextRight;
    }

    if (this.utils.Base_HasValue(this.selectFloor)) {
      condition['AS_FLOOR'] = this.selectFloor;
    }

    let conditionNoFloor = _sh.cloneDeep(condition);
    delete conditionNoFloor['AS_FLOOR'];
    delete conditionNoFloor['AS_NAME'];

    if (checked) {
      const search = observableForkJoin(
          this.requestService.getDataByCondition('assets/map/assets_room_list/main.json', condition), //0  资产地图-房间列表
          this.requestService.getDataByCondition('assets/map/assets_building_floor.json', conditionNoFloor),//1 楼层
          this.requestService.getDataByCondition('assets/map/assets_city_area_lease.json', condition), //2 , 按照类型，查询各种类型的面积
          this.requestService.getDataByCondition('assets/map/assets_city_area_type.json', condition), //3  查询资源类型分析
          this.requestService.getDataByCondition('assets/map/assets_top_building.json', condition) // 4  资产地图-楼宇的数据
      );
      search.subscribe(
          values => {
            this.roomList = _sh.get(values, [0, 'data'], []); // 某一层楼的数据。
            _sh.each(this.roomList, oneRoom => {
              if (oneRoom['LEASE_STATE'] == 'LEASED') {
                //oneRoom['showText'] = "到期：" + (oneRoom['END_DATE'] ? moment(oneRoom['START_DATE']).format('YYYY-MM-DD') : '');
                let date1 = moment().locale('zh-cn').format('YYYY-MM-DD');
                let date2 = moment(oneRoom['START_DATE']).format('YYYY-MM-DD');
                oneRoom['showText'] = "已租时间：" + (oneRoom['START_DATE'] ? moment(date1).diff(date2,'days') : '') + "天";//已租时间
              }
              if (oneRoom['LEASE_STATE'] == 'NOT_LEASED') {
                if (this.utils.Base_HasValue(oneRoom['END_DATE'])) {
                  oneRoom['showText'] = "已闲置：" + (oneRoom['END_DATE'] ? moment(oneRoom['END_DATE']).fromNow(true) : '');

                } else {
                  oneRoom['showText'] =  "已闲置：" + (oneRoom['END_DATE'] ? moment(oneRoom['END_DATE']).fromNow(true) : '长期');
                }
              }
              if (oneRoom['UNPAID_STATUS'] == 'UNPAID') {
                oneRoom['showText'] = "已欠缴：" + (oneRoom['ARAP_DATE'] ? moment(oneRoom['ARAP_DATE']).fromNow(true) : '');
              }
            })
            this.sumLeaseData = _sh.get(values, [2, 'data'], {}); // 页面上面的汇总数据
            this.groupByLeaseState = _sh.get(values, [2, 'data'], []); // 按照状态的分组数据
            this.groupByType = _sh.get(values, [3, 'data'], []); // 按照类型的分组数据

            this.selectFloorList = _sh.get(values, [1, 'data'], []); // 该楼的所有楼层
            this.buildingFloorList = _sh.get(values, [1, 'data'], []); // 查询出来后的楼层
            _sh.each(this.buildingFloorList,oneFloor=>{
              oneFloor['childData'] = _sh.filter(this.roomList,{'AS_FLOOR':oneFloor['AS_FLOOR']})
            })
            this.buildingData = _sh.get(values, [4, 'data', 0], {});

            if (this.utils.Base_HasValue(this.sumLeaseData)) {
              this.sumData['AREA'] = _sh.sumBy(this.sumLeaseData, 'AREA');
              this.sumData['ACCOUNT'] = _sh.sumBy(this.sumLeaseData, 'ACCOUNT');
              let leaseData = _sh.get(_sh.filter(this.sumLeaseData, {'LEASE_STATE': 'LEASED'}), [0]);
              if (this.utils.Base_HasValue(leaseData)) {
                this.sumData['LEASE_ACCOUNT'] = leaseData['ACCOUNT']; // 已出租数量
                this.sumData['LEASE_AREA'] = leaseData['AREA']; // 已出租面积
                if (this.sumData['AREA'] > 0) {
                  _sh.set(this.sumData, ['AREA_PERCENTAGE'], _sh.round(_sh.divide(_sh.multiply(leaseData['AREA'], 100), this.sumData['AREA']), 2));
                }

                if (this.sumData['ACCOUNT'] > 0) {
                  _sh.set(this.sumData, ['ACCOUNT_PERCENTAGE'], _sh.round(_sh.divide(_sh.multiply(leaseData['ACCOUNT'], 100), this.sumData['ACCOUNT']), 2));
                }
              } else {
                this.sumData['LEASE_ACCOUNT'] = 0;
                this.sumData['LEASE_AREA'] = 0;
                this.sumData['ACCOUNT_PERCENTAGE'] = 0;
                this.sumData['AREA_PERCENTAGE'] = 0;
              }
            } else {
              this.sumData['ACCOUNT'] = 0;
              this.sumData['AREA'] = 0;
              this.sumData['LEASE_ACCOUNT'] = 0;
              this.sumData['LEASE_AREA'] = 0;
              this.sumData['ACCOUNT_PERCENTAGE'] = 0;
              this.sumData['AREA_PERCENTAGE'] = 0;
            }
            this.rentAsset = this.setRentAsset();
            this.piaAsset = this.setPiaAsset();
          }
      );
    }


  }

  doJumpToAsset(oneRoom: any) {
    let assetId = _sh.get(oneRoom,['ID']);
    let assetName = _sh.get(oneRoom,['AS_NAME'])
    this.assetsJumpServiceService.doJumpToAsset(assetId,assetName,"90FEEB1C832C019EFDD5131253DB2A40");
  }

  doJumpToContract(oneRoom: any) {
    let conId = _sh.get(oneRoom,['CON_ID']);
    let conName = _sh.get(oneRoom,['CON_NAME'])
    this.assetsJumpServiceService.doJumpToContract(conId,conName);
  }


  showRoom(oneRoom) {
  }
}
