import {Component, Input, OnInit} from '@angular/core';

import * as _sh from 'lodash';
import {UtilsService} from "../../service/utils.service";
import {DataCenterService} from "../../service/data-center.service";
import {forkJoin as observableForkJoin} from "rxjs";
import {RequestDataService} from "../../service/request-data.service";
import {ConfirmModalService} from "../../service/confirm-modal.service";
import {JumpCenterService} from "../../service/jump-center.service";
import {AppConfigService} from "../../service/app-config.service";
import {ListActionService} from "../../service/list-action.service";
import {ReadOnlyFormComponent} from "../../basicForm/read-only-form/read-only-form.component";
import {TodoCenterComponent} from "../../basicForm/todo-center/todo-center.component";
import PubSub from 'pubsub-js';
import {MittService} from "../../service/mitt.service";

@Component({
    selector: 'app-descriptions',
    templateUrl: './descriptions.component.html',
    styleUrls: ['./descriptions.component.scss']
})
export class DescriptionsComponent implements OnInit {


    @Input() parentData: any;
    @Input() oneTab: any;
    @Input() aggregateListData: any;
    @Input() sqlFileListData: any;
    execButton: any;
    execGroup: any;

    constructor(
        private mittService: MittService,
        private utils: UtilsService,
        private dataCenterService: DataCenterService,
        private appConfigService: AppConfigService,
        private confirmModal: ConfirmModalService,
        private jumpCenterService: JumpCenterService,
        private listAction: ListActionService,
        private requestDataService: RequestDataService,
        private readOnlyFormComponent: ReadOnlyFormComponent,
        private todoCenterComponent: TodoCenterComponent
    ) {
    }

    ngOnInit() {
        PubSub.subscribe('descriptionRefresh', (msg, _id) => {
            let id = this.parentData['id'];
            if (_id == id) {
                this.execButton = null;
                this.execGroup = null;
            }
        })

        // this.mittService.emitter.on('descriptionActionRefresh', (msgObj) => _sh.throttle(this.execMsg(msgObj), 2000, {'trailing': false}))

        this.mittService.emitter.on('descriptionActionRefresh', (msgObj) => {
            // 创建一个被节流的版本的 this.execMsg
            const throttledExecMsg = _sh.throttle(() => this.execMsg(msgObj), 2000, {'trailing': false});

            // 调用被节流的函数
            throttledExecMsg();
        });
    }

    async execMsg(msgObj) {
        let id = this.parentData['id'];
        let _id = msgObj['id']
        console.log("descriptions.component.ts 01 _id", _id);
        console.log("descriptions.component.ts 02 id", id);

        if (_id == id) {
            let afterCompletionWindow = msgObj['afterCompletionWindow'];
            let afterCompletionAction = msgObj['afterCompletionAction'];
            console.log("消息。", afterCompletionWindow);
            console.log("消息。", afterCompletionAction);
            if (afterCompletionWindow == 'hold') {
                let res = await this.readOnlyFormComponent.searchData();
                console.log("消息。", res);
                this.execButton = null;
                this.confirmModal.show('success', {'title': '执行成功'});
            } else if (afterCompletionWindow == 'close') {
                this.dataCenterService.closeTab(this.parentData);
            }
            if (afterCompletionAction == 'refreshTodoCenter') {
                this.todoCenterComponent.doRefreshTodoCenter();
            }
        }
    }

    getValue(pPath, pData) {
        let result = null;
        if (this.utils.Base_HasValue(pPath)) {
            let center = pPath[0];
            let smallPath = _sh.drop(pPath);
            if (center == "sqlFileList") {
                result = _sh.get(this.sqlFileListData, smallPath, null);
            } else if (center == "aggregateList") {
                result = _sh.get(this.aggregateListData, smallPath, null);
            } else if (center == "selectData") {
                result = _sh.get(pData, smallPath, null);
            }
        }
        return result;
    }

    doJump(pOneColumn, pData) {
        pData['checked'] = true;
        let simulationParentData = {
            data: {
                data: [pData]
            }
        }
        let readOnlyData = {
            'sqlFileListData': _sh.cloneDeep(this.sqlFileListData),
            'aggregateListData': _sh.cloneDeep(this.aggregateListData)
        }

        this.jumpCenterService.execJumpPage(pOneColumn['jumpConfig'], simulationParentData, null, readOnlyData);
    }

    setHasValueRow(groupDataEdit, keyColumnDatas) {
        let index = 0;
        _sh.each(groupDataEdit, oneData => {
            let count = this.utils.getHasValuesCount([keyColumnDatas[index]]);
            oneData['checked'] = count > 0;
            index++
        })
        groupDataEdit = _sh.filter(groupDataEdit, oneData => {
            return oneData['checked'];
        })
        return groupDataEdit;
    }

    async doGroupAction() {
        console.log('doGroupAction');
        let that = this;
        let check = true;
        let keyColumns = this.utils.filterData(this.execButton['columns'], 'commitTrigger', true); // 待提交数据的字段
        let keyColumnNameList = this.utils.getArrayByPath(keyColumns, 'column'); // 变成数组
        let keyColumnDatas = this.utils.getArrayByPathArray(this.execGroup['groupDataEdit'], keyColumnNameList); // 获取关键字段的数组数据。
        let keyColumnCount = this.utils.getHasValuesCount(keyColumnDatas); // 关键数据，有数据的记录数
        let groupDataEdit = _sh.cloneDeep(this.execGroup['groupDataEdit']);
        let commitData = this.setHasValueRow(groupDataEdit, keyColumnDatas); // 等待提交的数据

        if (keyColumnCount == 0 && check) {
            check = false;
            let msg = this.utils.getArrayByPath(keyColumns, 'lable')
            msg = _sh.join(msg, " ， ")
            this.confirmModal.show('error', {title: '没有主要信息提交', content: msg});
        }

        if (keyColumnCount > 0 && check) {
            let iRow = 1
            _sh.each(commitData, oneData => {
                _sh.each(this.execButton['columns'], oneColumn => {
                    // 需要检查非空的字段
                    if (_sh.get(oneColumn, ['require'], false)) {
                        let value = _sh.get(oneData, [oneColumn['column']], null);
                        if (!this.utils.Base_HasValue(value) && check) {
                            check = false;
                            let msg = "第" + iRow + "行的，" + oneColumn['lable'] + "是必填项"
                            this.confirmModal.show('error', {title: '必填项', content: msg});
                        }
                    }

                })
                iRow++;
            })
        }
        let buttonConfig = {};
        if (check) {
            buttonConfig = await that.requestDataService.getDataByJson(this.execButton['sqlFile']).toPromise();
            let status = _sh.get(buttonConfig, ['status'], 200);
            if (status != 200) {
                check = false;
            }
        }

        if (check) {
            let data = {
                'sqlFileListData': _sh.cloneDeep(that.sqlFileListData),
                'aggregateListData': _sh.cloneDeep(that.aggregateListData)
            }
            let pOneBtn = {
                ACTION_OPERATE: this.execButton['sqlFile']
            }
            let mockdata = {
                data: {
                    data: commitData
                }
            }
            var observable = this.listAction.doAction(pOneBtn, mockdata, "readOnlyForm", data);
            observable.subscribe({
                next: lv01 => {
                    lv01.subscribe({
                        next: lv02 => {
                            lv02.subscribe({
                                next: lv03 => {
                                    lv03.subscribe({
                                        next: lv04 => {
                                            const status = _sh.get(lv04, ['status'], 200);
                                            if (status == 500) {
                                                let errorMsg = _sh.get(lv04, [0, 'error'], null);  // thur 的错误
                                                errorMsg = _sh.get(lv04, ['error', 'message'], errorMsg);
                                                if (!this.utils.Base_HasValue(errorMsg)) {
                                                    errorMsg = _sh.get(lv04, ['error'], null); // hulk 的错误
                                                }
                                                this.confirmModal.show('error', {
                                                    'title': '执行错误',
                                                    'content': errorMsg
                                                });
                                            } else if (status == 200) {
                                                let afterCompletionWindow = _sh.get(buttonConfig, ['afterCompletionWindow'], 'hold');
                                                let afterCompletionAction = _sh.get(buttonConfig, ['afterCompletionAction'], 'nothing');
                                                if (afterCompletionWindow == 'hold') {
                                                    this.confirmModal.show('success', {'title': '执行成功'});
                                                    this.readOnlyFormComponent.searchData();
                                                    this.execButton = null;
                                                } else if (afterCompletionWindow == 'close') {
                                                    this.dataCenterService.closeTab(this.parentData);
                                                }
                                                if (afterCompletionAction == 'refreshTodoCenter') {
                                                    this.todoCenterComponent.doRefreshTodoCenter();
                                                }
                                            }
                                        },
                                        error: err => console.error('something wrong occurred: ' + err),
                                        complete: () => console.log('done-04')
                                    });
                                },
                                error: err => console.error('something wrong occurred: ' + err),
                                complete: () => console.log('done-03')
                            });
                        },
                        error: err => console.error('something wrong occurred: ' + err),
                        complete: () => console.log('done-02')
                    })
                },
                error: err => console.error('something wrong occurred: ' + err),
                complete: () => console.log('done-01')
            });
        }
    }

    showGroupExecBtns(oneGroup: any, oneBtn: any) {
        console.log(this.parentData);
        let that = this;
        let checked = true;

        function doShow() {
            oneGroup['showExecButton'] = true;
            oneGroup['visible'] = _sh.get(oneBtn, ['oldGroupVisible'], false); // 将原小分组的内容隐藏
            oneGroup['columns_bak'] = _sh.cloneDeep(oneGroup['columns']);
            oneGroup['groupDataEdit'] = _sh.cloneDeep(oneGroup['groupData']); // 保护变量


            let newBtn = _sh.cloneDeep(oneBtn);
            if (_sh.get(oneBtn, ['groupEditType'], false) == 'editInOldRow') {
                let newColumnList = [];
                _sh.each(oneGroup['columns'], oneColumn => {
                    // 找到是否相同的，如果有，用 按钮之中的替换调。
                    let find = that.utils.findData(oneBtn['columns'], 'column', oneColumn['column']);
                    if (find) {
                        let tempBtnColumn = _sh.cloneDeep(that.utils.filterOneData(oneBtn['columns'], 'column', oneColumn['column']));
                        let tempGroupColumn = _sh.cloneDeep(oneColumn);
                        tempGroupColumn = _sh.defaults(tempBtnColumn, tempGroupColumn);
                        newColumnList.push(tempGroupColumn);
                    } else {
                        newColumnList.push(oneColumn);
                    }
                })
                // 经过替换之后，还发现没有的，就加入进去。
                _sh.each(oneBtn['columns'], oneColumn => {
                    let find = that.utils.findData(newColumnList, 'column', oneColumn['column']);
                    if (!find) {
                        newColumnList.push(oneColumn);
                        // oneGroup['columns'].push(oneColumn);
                    }
                })
                newBtn['columns'] = newColumnList;
            }
            that.execButton = newBtn; // 当前执行的按钮。
            that.execGroup = oneGroup;
        }

        if (that.utils.Base_HasValue(that.execButton)) {
            checked = false;
            this.confirmModal.show('error', {
                'title': '错误',
                'content': this.execButton['groupTitle'] + '，在编辑中，请继续执行，或取消之后，再来点击。' + oneBtn['actionName']
            });
        }
        if (checked) {
            let dictionaryList = this.utils.filterHasData(oneBtn['columns'], 'dictionary'); // 获取字典列表

            if (this.utils.Base_HasValue(dictionaryList)) {
                let ajaxList = [];
                _sh.each(dictionaryList, oneDictionary => {
                    ajaxList.push(this.requestDataService.getDataByCondition(oneDictionary['dictionary'], {})); // 查询字典
                })
                const search$ = observableForkJoin(
                    ajaxList
                );
                search$.subscribe(
                    values => {
                        let index = 0;
                        _sh.each(dictionaryList, oneDictionary => {
                            _sh.each(oneBtn['columns'], oneColumn => {
                                // 将字典信息，挂到字段之上。
                                if (oneColumn['column'] == oneDictionary['column']) oneColumn['itemArray'] = _sh.get(values, [index, 'data'], [])
                            })
                            index++;
                        })
                        doShow();
                    }
                );
            } else {
                doShow();
            }
        }
    }

    doGroupActionCancel(pOneGroup: any) {
        _sh.each(this.oneTab['group'], oneGroup => {
            oneGroup['showExecButton'] = false;
            oneGroup['visible'] = true; // 将内容再次显示出来
            if (oneGroup == pOneGroup) {
                oneGroup['columns'] = oneGroup['columns_bak'];
                oneGroup['columns_bak'] = null;
            }
            this.execButton = null; // 当前执行的按钮。
            this.execGroup = null;
        })
    }
}
