import {Component, OnInit} from '@angular/core';
import {NzModalRef} from 'ng-zorro-antd';

import * as _sh from "lodash";
import {UtilsService} from '../../service/utils.service';

@Component({
  selector: 'app-table-modal',
  templateUrl: './table-modal.component.html',
  styleUrls: ['./table-modal.component.css']
})
export class TableModalComponent implements OnInit {
  dataList: any;
  // [
  //       {
  //         key: 'columnId',
  //         value: '字段标识'
  //       }
  //  ]

  public nzScroll = '1000px';

  public headTitle: any = [];
  // [
  //       {
  //         columnId: 'SUBJECT_ID'
  //       },
  //       {
  //         columnId: 'SUBJECT_ID'
  //       }
  // ]
  public tableData: any = [];
  // [
  //   {
  //       "key": "columnId",
  //       "value": "字段标识",
  //       "model": null
  //     }
  //   ]
  public searchColumn: any = [];

  // 过滤
  searchFilter(){
    this.tableData = this.utils.DeepCopy(this.dataList);
    let conditions = _sh.filter(this.searchColumn, oneData => {
      return oneData['model'];
    });
    _sh.each(conditions, onedate => {
      this.tableData = _sh.filter(this.tableData, item => {
        return item[onedate['key']].indexOf(onedate['model']) != -1;
      })
    })
  }

  // 清除条件
  resetFilter() {
    _sh.each(this.searchColumn, oneData => {
      oneData['model'] = null;
    });
    this.searchFilter();
  }

  allChecked = false;
  indeterminate = false;

  constructor(private modal: NzModalRef,
              private utils: UtilsService,) {
  }


  refreshStatus(): void {
    const validData = this.tableData.filter(value => !value.disabled);
    const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
    const allUnChecked = validData.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
  }

  checkAll(value: boolean): void {
    this.tableData.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }

  ngOnInit() {
    this.dataList = this.utils.DeepCopy(this.tableData);
  }

}
