import {Component, OnInit, ViewChild, HostListener, TemplateRef, ElementRef} from '@angular/core';
import {NzModalService} from 'ng-zorro-antd';
import {UserInfoService} from './../../service/user-info.service';
import {AppConfigService} from './../../service/app-config.service';
import {StorageService} from './../../service/storage.service';
import {UtilsService} from './../../service/utils.service';
import {MenusComponent} from './../../components/menus/menus.component';
import * as _sh from 'lodash';
import {NzNotificationService} from 'ng-zorro-antd/notification';

import {DataCenterService} from '../../service/data-center.service';
import {RequestDataService} from "../../service/request-data.service";

@Component({
    selector: 'app-index',
    templateUrl: './index.component.html',
    styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {

    @ViewChild('tpl', {static: false}) tpl: TemplateRef<any>;

    @ViewChild(MenusComponent, {static: false}) menu: MenusComponent;

    public userInfo: any = {};
    public searchText: string;
    public hasLogin: boolean = false;
    public rightConfig: any = {};
    public hasWtbp: boolean = false;
    public wbtpDatas: any = [];
    public wbtpCount = 0;

    timer: any;
    deadline = Date.now() + 1000 * 60 * 5;
    deadlineConfig = 3600;
    isCollapsed: boolean = false;

    @HostListener('window:keyup', ['$event']) keyEvent(event: KeyboardEvent) {
        // enter
        if (event.keyCode === 13) {
            if (this.modalService.openModals.length) {
                _sh.each(this.modalService.openModals, modal => {
                    modal.triggerOk();
                });
            }
        }
        // esc
        if (event.keyCode === 27) {
            this.modalService.closeAll();
        }
    }


    constructor(public config: AppConfigService,
                private userInfoService: UserInfoService,
                private notificationService: NzNotificationService,
                private storage: StorageService,
                private utils: UtilsService,
                private requestDataService: RequestDataService,
                private dataCenterService: DataCenterService,
                private modalService: NzModalService) {
    }

    ngOnInit() {
        this.rightConfig = this.config.config.logoConfig;
        this.userInfo = this.userInfoService.getUserInfo();
        this.hasLogin = this.utils.Base_HasValue(this.userInfo);
        this.hasWtbp = _sh.get(this.config, ['config', 'wtbp_json'], false);
        if (this.hasWtbp) {
            this.doRefresh(true);
        }
    }


    doJump(pOneItem) {
        if (this.utils.Base_HasValue(pOneItem['menuId'])) {
            let jumpMenuId = pOneItem['menuId'];
            if (jumpMenuId == 'system.todoCenter') {
                let useTodoCenter = _sh.get(this.config.config, ['useTodoCenter'], false);
                if (useTodoCenter) {
                    let tabIndex = _sh.findIndex(this.dataCenterService._dataObject.list, (item) => {
                        return item['id'] === "todoCenterForm";
                    });
                    if (tabIndex >= 0) {
                        this.dataCenterService._dataObject.index = tabIndex;
                        this.dataCenterService.refreshDataCenterInStorage();
                    }
                }
            } else {
                let itemTabName = pOneItem['itemTabName'] || 0;
                this.dataCenterService.newTabWk(pOneItem['menuData'], itemTabName);
            }
        }
    }

    doRefresh(pStartTimer?) {
        this.requestDataService.waitingToBeProcessedServlet().subscribe(values => {
            this.wbtpDatas = values;
            this.wbtpCount = this.wbtpDatas['allCount'];
            this.deadlineConfig = values['deadline'];
            this.deadline = Date.now() + this.deadlineConfig * 1000;

            _sh.each(this.wbtpDatas['list'], oneItem => {
                if (_sh.get(oneItem, ['hasNotification'], true) && oneItem['visible']) {
                    this.notificationService.config({
                        nzPlacement: 'bottomRight'
                    });
                    this.notificationService.template(this.tpl, {
                        nzData: oneItem,
                        nzDuration: _sh.get(oneItem, ['notification_Duration'], 5) * 1000
                    });
                }
            });
            if (pStartTimer) {
                this.doRestTime();
            }
        });
    }

    logout() {
        this.userInfoService.logout();
    }

    searchMenu() {
        this.menu.filterMenuList();
    }

    ngOnDestroy() {
        clearInterval(this.timer);
    }

    doRestTime() {
        this.timer = setInterval(() => {
            this.hasWtbp = _sh.get(this.config, ['config', 'wtbp_json'], false);
            if (this.hasWtbp) {
                this.doRefresh(false);
            }
        }, this.deadlineConfig * 1000);
    }

}
