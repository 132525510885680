import {Component, ElementRef, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import * as _sh from "lodash";
import * as echarts from 'echarts';
import AMapLoader from '@amap/amap-jsapi-loader';
import {NzModalService} from "ng-zorro-antd/modal";
import {UtilsService} from "../../../service/utils.service";
import {RequestDataService} from "../../../service/request-data.service";
import * as q from "q";

import {forkJoin as observableForkJoin} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {AjaxService} from "../../../service/ajax.service";


@Component({
    selector: 'app-asset-map',
    templateUrl: './asset-map.component.html',
    styleUrls: ['./asset-map.component.scss']
})
export class AssetMapComponent implements OnInit {
    @Input() parentData: any;
    AMap: any;// 控件
    map: any; //
    public isfull: any = false;
    public chartAreaHeight: any = '203px';
    sumData: any = {};
    sumLeaseData: any = {};
    groupByLeaseState: any = [];
    groupByType: any = [];
    topBuilding: any = [];
    buildingList: any = [];
    topTenAsset: any = [];
    rentAsset: any = [];
    piaAsset: any = [];
    searchText: any;
    areaList: any = [];
    pageIndex = 1;
    buildingListCount = 0;
    infoWindowList = {};

    selectArea = '05'; // 张湾

    @ViewChild('infoWindowBtn', {static: false}) infoWindowBtn: ElementRef; // @ViewChild通过模板变量名获取

    constructor(private el: ElementRef,
                private rd: Renderer2,
                private http: HttpClient,
                private modalService: NzModalService,
                private ajaxService: AjaxService,
                private requestService: RequestDataService,
                private utils: UtilsService,
                private requestDataService: RequestDataService
    ) {

    }


    getBuildItem(oneItem) {
        let UP_AS_ID = _sh.get(oneItem, ['ID']);
        const searchItem = observableForkJoin(
            this.requestService.getDataByCondition('assets/map/assets_room.json', {'UP_AS_ID': UP_AS_ID}), // 房间列表
            this.requestService.getDataByCondition('assets/map/assets_other.json', {'UP_AS_ID': UP_AS_ID}) // 一般资源
        );
        searchItem.subscribe(
            values => {
                let roomCount = _sh.get(values, [0, 'data', 0, 'ACCOUNT'], 0);
                let otherCount = _sh.get(values, [1, 'data', 0, 'ACCOUNT'], 0);
                oneItem['ROOM_COUNT'] = roomCount;
                oneItem['OTHER_COUNT'] = otherCount;
                this.addAMapMarker(oneItem['showIndex'], oneItem);
            })
    }

    getBuildList(pIndex?) {
        let selectArea = this.selectArea;
        let index = pIndex ? pIndex : this.pageIndex;
        let condition = {
            'OPERATING_ID': selectArea
        }
        if (this.utils.Base_HasValue(this.searchText)) {
            condition['AS_NAME'] = this.searchText;
        }
        const search = observableForkJoin(
            this.requestService.getPaginationData( // 5
                'assets/map/assets_top_building_list.json',
                condition,
                index, 5
            ) // 建筑物列表
        );
        search.subscribe(
            values => {
                this.buildingList = _sh.get(values, [0, 'data'], []);
                this.buildingListCount = _sh.get(values, [0, 'page', 'rowCount'], 0);
                this.setMap(); //初始化地图
            })

    }


    public async addAMapMarker(topNumber, oneItem) {
        let that = this;
        function createInfoWindow() {
            let contentTemplate = `
                <script type="text/javascript">
                    function openNewWin(url) {
                        window.open(url,'_blank');
                    }
                </script>
                <div style="width: 680px;height: 140px;padding: 15px;background-color: #ffffff; ">
                <div style='float:left'>
                    <h4 style='margin:0 0 5px 0;'><%= AS_NAME %></h4>
                    <a id="infoWindowBtn" href="javascript:window.open('/appAssetMap/<%= AS_ID %>','_blank')">租控图</a>
                    <p style='color: rgba(0,0,0,.45);margin-top:20px;font-size:13px'>
                        <%= AS_ADDRESS %></p>
                    <p style='color: rgba(0,0,0,.45);margin-top:10px;float:bottom;font-size:13px;'>
                        <span>资产面积：<%= AREA %>（㎡）</span>
                        <span>房间数量：<%= ROOM_COUNT %>（间）</span>
                    </p>
                </div>
            </div>`;
            let complied = _sh.template(contentTemplate);
            let sContent = complied(
                {
                    'AS_ID': oneItem['ID'],
                    'AS_NAME': oneItem['AS_NAME'],
                    'AS_ADDRESS': oneItem['AS_ADDRESS'],
                    'AREA': oneItem['AREA'],
                    'ROOM_COUNT': oneItem['ROOM_COUNT']
                }
            );

            let infoWindow = new that.AMap.InfoWindow({
                content: sContent
            });
            return infoWindow
        }

        let lnglatConvert = await this.convertFrom(oneItem['AS_LONGITUDE_LATITUDE'], 'baidu');
        oneItem['AS_LONGITUDE_LATITUDE'] = lnglatConvert;
        let lnglat = _sh.split(lnglatConvert, ',');
        let title = oneItem['UP_AS_NAME'],
            number = oneItem['AREA'] + '（㎡）',
            Lng = lnglat[0],
            Lat = lnglat[1];

        let markerPoint = new this.AMap.Marker({
            anchor: 'bottom-center',
            position: new this.AMap.LngLat(Lng, Lat),
            title: title
        })

        this.map.add(
            markerPoint
        );
        let infoWindow = createInfoWindow();

        _sh.set(that.infoWindowList, [oneItem['ID']], infoWindow);

        markerPoint.on('click', function (ev) {
            let lnglat = oneItem['AS_LONGITUDE_LATITUDE'];
            lnglat = _sh.split(lnglat, ',');
            let Lng = lnglat[0],
                Lat = lnglat[1];
            _sh.each(that.infoWindowList, oneInfoWindow => {
                oneInfoWindow.close();
            })
            infoWindow.open(that.map, [Lng, Lat]);
        });
    }


    doSearch() {
        let selectArea = this.selectArea;
        let that = this;
        const search = observableForkJoin(
            this.requestService.getDataByCondition('assets/map/assets_city_area.json', {'OPERATING_ID': selectArea}), //0
            this.requestService.getDataByCondition('assets/map/assets_city_area.json', { // 1
                'OPERATING_ID': selectArea,
                'LEASE_STATE': 'LEASED'
            }), // 查询租赁情况
            this.requestService.getDataByCondition('assets/map/assets_city_area_lease.json', {
                'OPERATING_ID': selectArea //2
            }), // 查询租赁情况分析
            this.requestService.getDataByCondition('assets/map/assets_city_area_type.json', { // 3
                'OPERATING_ID': selectArea
            }), // 查询资源类型分析
            this.requestService.getDataByCondition('assets/map/assets_top_building.json', { // 4
                'OPERATING_ID': selectArea
            }) // top 5 建筑物
        );
        search.subscribe(
            values => {
                this.sumData = _sh.get(values, [0, 'data', 0], {});
                this.sumLeaseData = _sh.get(values, [1, 'data', 0], {});
                this.groupByLeaseState = _sh.get(values, [2, 'data'], []);
                this.groupByType = _sh.get(values, [3, 'data'], []);
                this.topBuilding = _sh.get(values, [4, 'data'], []);
                this.pageIndex = 1;
                this.getBuildList();
                if (!this.utils.Base_HasValue(this.sumData)) {
                    this.sumData['ACCOUNT'] = 0;
                    this.sumData['AREA'] = 0;
                }
                if (!this.utils.Base_HasValue(this.sumLeaseData)) {
                    this.sumLeaseData['ACCOUNT'] = 0;
                    this.sumLeaseData['AREA'] = 0;
                    this.sumLeaseData['ACCOUNT_PERCENTAGE'] = 0;
                    this.sumLeaseData['AREA_PERCENTAGE'] = 0;
                }
                if (this.sumLeaseData['AREA'] > 0) {
                    _sh.set(this.sumLeaseData, ['AREA_PERCENTAGE'], _sh.round(_sh.divide(_sh.multiply(this.sumLeaseData['AREA'], 100), this.sumData['AREA']), 2));
                }

                if (this.sumLeaseData['ACCOUNT'] > 0) {
                    _sh.set(this.sumLeaseData, ['ACCOUNT_PERCENTAGE'], _sh.round(_sh.divide(_sh.multiply(this.sumLeaseData['ACCOUNT'], 100), this.sumData['ACCOUNT']), 2));
                }
                let list = []
                _sh.each(this.topBuilding, oneBuilding => {
                    list.push({
                        'DISPLAY_NAME': oneBuilding['UP_AS_NAME'],
                        'DISPLAY_VALUE': oneBuilding['AREA']
                    })
                })
                this.topTenAsset = this.setTopTenAsset(list);
                this.rentAsset = this.setRentAsset();
                this.piaAsset = this.setPiaAsset();
            }
        );
    }

    loadData(pi: number): void {
        this.getBuildList(pi);
    }


    addAMapControl() {
        var toolbar = new this.AMap.ToolBar({
            position: {
                top: '110px',
                right: '40px'
            }
        });
        var controlBar = new this.AMap.ControlBar({
            position: {
                top: '10px',
                right: '10px'
            }
        });
        var scale = new this.AMap.Scale();

        var overView = new this.AMap.HawkEye();
        this.map.addControl(toolbar);
        this.map.addControl(scale);
        this.map.addControl(controlBar);
        this.map.addControl(overView);
    }

    setMap() {
        AMapLoader.load({ //首次调用 load
            key: '7d4ef5abd3fe75f2496fa1fd717a69fa',//首次load key为必填
            version: '2.0',
            plugins: ['AMap.Scale', 'AMap.ToolBar', 'AMap.DistrictSearch', 'AMap.CircleEditor', 'AMap.MarkerCluster', 'AMap.ControlBar', 'AMap.HawkEye'],
            Loca: {
                version: '2.0.0',
            }
        }).then((AMap) => {
            this.AMap = AMap;
            this.map = new AMap.Map('asset-area-map', {
                zoom: 12,
                center: [110.798921, 32.629057]
            });
            let map = this.map;
            this.addAMapControl();
            //@ts-ignore
            var loca = new Loca.Container({
                map,
            });
            let index = 1;
            _sh.each(this.buildingList, oneItem => {
                oneItem['description'] = '资产面积：' + oneItem['AREA'] + '（㎡）';
                oneItem['showIndex'] = index;
                this.getBuildItem(oneItem);
                index++;
            });

        }).catch((e) => {
            console.error(e);
        });
    }


    ngOnInit() {
        let id = this.parentData['parentDataId'];
        this.selectArea = _sh.split(id,',')[1];
        const search = observableForkJoin(
            this.requestService.getDataByCondition('assets/basic/dict/OPERATE_CODE.json')
        );
        search.subscribe(
            values => {
                this.areaList = _sh.get(values, [0, 'data']);
            }
        );
        this.doSearch();
        this.getBuildList();
    }

    ngAfterViewInit() {
    }

    // 封装数据集top5
    transformDatasetData(list) {
        let result = {
            category: [],
            value: []
        };
        _sh.each(list, (item: any) => {
            result.category.push(item['DISPLAY_NAME']);
            result.value.push(item['DISPLAY_VALUE']);
        });
        return result;
    }

    setTopTenAsset(list) {
        const data = this.transformDatasetData(list);
        const option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: {
                right: '5%',
                left: '27%',
                top: '11%',
                bottom: '3%'
            },

            xAxis: {
                show: true,
                position: 'top',
                splitNumber: 2,
                min: 100,
                axisTick: {
                    show: false
                },
                axisLine: {
                    show: false
                },
                angleAxis: 45,
                axisLabel: {
                    textStyle: {
                        fontSize: 14,
                        color: '#a7a7a7'
                    },
                    formatter: '{value}㎡'
                },
                splitLine: {
                    lineStyle: {
                        color: '#f7f8f8',
                        width: 2
                    }
                }
            },
            yAxis: {
                type: 'category',
                inverse: true,
                axisTick: {
                    show: false
                },
                axisLine: {
                    show: false
                },
                axisLabel: {
                    color: '#787878',
                    margin: 10,
                    fontSize: 12
                },
                splitLine: {
                    show: false
                },
                data: data.category
            },
            series: [
                {
                    type: 'bar',
                    barWidth: '15px',
                    data: data.value,
                    label: {
                        normal: {
                            show: false
                        }
                    },
                    itemStyle: {
                        normal: {
                            barBorderRadius: 20,
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 1, 0,
                                [
                                    {offset: 0, color: '#74B5EF'},
                                    {offset: 1, color: '#6C57EF'}
                                ]
                            )
                        }

                    }
                }
            ]
        };
        return option;
    }

    setRentAsset() {
        let leased = _sh.get(_sh.filter(this.groupByLeaseState, {'LEASE_STATE': 'LEASED'}), [0, 'AREA'], 0);
        let notLeased = _sh.get(_sh.filter(this.groupByLeaseState, {'LEASE_STATE': 'NOT_LEASED'}), [0, 'AREA'], 0);
        let nonLeasable = _sh.get(_sh.filter(this.groupByLeaseState, {'LEASE_STATE': 'NON_LEASABLE'}), [0, 'AREA'], 0);
        const option = {
            grid: {
                top: '4%',
                bottom: '2%',
                left: '2%',
                right: '2%'
            },
            tooltip: {
                trigger: 'item',
                formatter: '{b} : {c} ({d}%)'
            },
            legend: {
                x: 'center',
                y: 'bottom',
                data: [
                    {
                        name: '空置中',
                        textStyle: {
                            fontSize: 12,
                            fontWeight: 'bolder',
                            color: '#333'
                        },
                        icon: 'circle'
                    },
                    {
                        name: '已租赁',
                        textStyle: {
                            fontSize: 12,
                            fontWeight: 'bolder',
                            color: '#333'
                        },
                        icon: 'circle'
                    },
                    {
                        name: '不可租',
                        textStyle: {
                            fontSize: 12,
                            fontWeight: 'bolder',
                            color: '#333'
                        },
                        icon: 'circle'
                    }
                ]
            },
            series: [
                {
                    name: '租控比例',
                    type: 'pie',
                    radius: ['25%', '50%'],
                    roseType: 'radius',
                    label: {
                        normal: {
                            show: true,
                            formatter: '{b}\n{c}({d}%)'
                        },
                        emphasis: {
                            show: true
                        }
                    },
                    lableLine: {
                        normal: {
                            show: false
                        },
                        emphasis: {
                            show: true
                        }
                    },
                    data: [
                        {
                            value: notLeased,
                            name: '空置中', itemStyle: {
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 1,
                                    y2: 1,
                                    colorStops: [
                                        {
                                            offset: 0.2, color: '#0bb2f2' // 0% 处的颜色
                                        },
                                        {
                                            offset: 1, color: '#293bf5' // 100% 处的颜色
                                        }],
                                    globalCoord: true // 缺省为 false
                                }
                            }
                        },
                        {
                            value: leased,
                            name: '已租赁', itemStyle: {
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 1,
                                    y2: 1,
                                    colorStops: [
                                        {
                                            offset: 0.2, color: '#ffba63' // 0% 处的颜色
                                        },
                                        {
                                            offset: 1, color: '#ff8400' // 100% 处的颜色
                                        }],
                                    globalCoord: true // 缺省为 false
                                }
                            }
                        },
                        {
                            value: nonLeasable,
                            name: '不可租', itemStyle: {
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 1,
                                    y2: 1,
                                    colorStops: [
                                        {
                                            offset: 0.2, color: '#6ff7ff' // 0% 处的颜色
                                        },
                                        {
                                            offset: 1, color: '#00e3ff' // 100% 处的颜色
                                        }],
                                    globalCoord: true // 缺省为 false
                                }
                            }
                        }
                    ]
                }
            ]
        };
        return option;
    }

    getColor(index) {
        let findIndex = index;
        let colorList = [
            {
                'samllColor': '#0bb2f2',
                'bigColor': '#293bf5'
            },
            {
                'samllColor': '#ffba63',
                'bigColor': '#ff8400'
            },
            {
                'samllColor': '#6ff7ff',
                'bigColor': '#00e3ff'
            }
        ];
        let count = colorList.length;
        if (index <= count) {
            findIndex = index;
        } else {
            let mod = count % index;
            findIndex = mod;
        }
        return colorList[findIndex];
    }

    setPiaAsset() {
        let titleList = [];
        _sh.each(this.groupByType, oneType => {
            let obj = {
                name: oneType['AS_TYPE_LV02_NAME'],
                textStyle: {
                    fontSize: 12,
                    fontWeight: 'bolder',
                    color: '#333'
                },
                icon: 'circle'
            }
            titleList.push(obj);
        });
        let numList = [];
        let dataIndex = 1;
        _sh.each(this.groupByType, oneType => {
            let colorSelect = this.getColor(dataIndex);
            let obj = {
                value: oneType['AREA'], name: oneType['AS_TYPE_LV02_NAME'], itemStyle: {
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 1,
                        y2: 1,
                        colorStops: [
                            {
                                offset: 0.2, color: colorSelect['samllColor'] // 0% 处的颜色
                            },
                            {
                                offset: 1, color: colorSelect['bigColor'] // 100% 处的颜色
                            }],
                        globalCoord: true // 缺省为 false
                    }
                }
            }
            numList.push(obj);
            dataIndex++;
        });


        const option = {
            grid: {
                top: '4%',
                bottom: '2%',
                left: '2%',
                right: '2%'
            },
            tooltip: {
                trigger: 'item',
                formatter: '{b} : {c} ({d}%)'
            },
            legend: {
                x: 'center',
                y: 'bottom',
                data: titleList
            },
            series: [
                {
                    name: '租控比例',
                    type: 'pie',
                    radius: ['25%', '50%'],
                    roseType: 'radius',
                    label: {
                        normal: {
                            show: true,
                            formatter: '{b}\n{c}({d}%)'
                        },
                        emphasis: {
                            show: true
                        }
                    },
                    lableLine: {
                        normal: {
                            show: false
                        },
                        emphasis: {
                            show: true
                        }
                    },
                    data: numList
                }
            ]
        };
        return option;
    }

    // 坐标转换
    async convertFrom(lnglat, type) {
        // type : baidu gps mapbar
        //112.188496,32.122571 襄阳 东风通信技术有限公司襄阳分公司
        let deferred = q.defer();
        deferred.notify('convertFrom');
        this.AMap.convertFrom(lnglat, type, function (status, result) {
            if (result.info === 'ok') {
                let resLnglat = result.locations[0];
                deferred.resolve(resLnglat.lng + ',' + resLnglat.lat);
            } else {
                deferred.reject(result);
            }
        });
        return deferred.promise;

    }

    async showPoint(item: any) {
        _sh.each(this.buildingList, oneBuild => {
            oneBuild['checked'] = false;
        })
        item['checked'] = true;
        _sh.each(this.infoWindowList, oneInfoWindow => {
            oneInfoWindow.close();
        })
        let lnglat = item['AS_LONGITUDE_LATITUDE'];
        lnglat = _sh.split(lnglat, ',');
        let Lng = lnglat[0],
            Lat = lnglat[1];
        this.infoWindowList[item['ID']].open(this.map, [Lng, Lat]);
    }
}
