import {Component, Input, OnInit, QueryList, ViewChildren} from '@angular/core';
import {DataCenterService} from "../../../service/data-center.service";
import * as _sh from 'lodash';
import {UtilsService} from "../../../service/utils.service";
import {ConfirmModalService} from "../../../service/confirm-modal.service";
import {forkJoin as observableForkJoin} from "rxjs";
import {RequestDataService} from "../../../service/request-data.service";
import {UserInfoService} from "../../../service/user-info.service";

@Component({
    selector: 'app-plan-from',
    templateUrl: './plan-from.component.html',
    styleUrls: ['./plan-from.component.scss']
})
export class PlanFromComponent implements OnInit {
    @Input() parentData: any;
    @Input() hulkColumn: any;

    // 改变绑定对象
    @Input() bindModel: any;

    baseForm: any = {
        "tablename": "se_inspection_plan",
        "formname": "se_inspection_plan",
        "columns": [
            {
                "column": "ID",
                "iscondition": true,
                "pk_column": true,
                "name": "ID_NAME",
                "component": "input",
                "lable": "主键",
                "comment": "主键",
                "placeholder": "主键",
                "class": "col-lg-4 col-sm-6 col-xs-12",
                "insertValue": "SYS-ACTION-UUID",
                "conditionColumn": "ID",
                "model": null,
                "visible": false,
                "require": true
            },
            {
                "column": "HR",
                "component": "hr",
                "lable": "基本信息",
                "customize": 24,
                "comment": "基本信息",
                "visible": true,
                "wechatVisable": true,
                "line": false,
                "commitPoint": [
                    "detail"
                ],
                "placeholder": "基本信息",
                "model": null
            },
            {
                "column": "SF_DANGER_TYPE",
                "name": "SF_DANGER_TYPE_NAME",
                "component": "select",
                "lable": "危险源隐患类型",
                "comment": "危险源隐患类型",
                "placeholder": "危险源隐患类型",
                "insertValue": null,
                "model": "AS_ASSET",
                "visible": false,
                "require": true,
                "disable": true,
                "dictionary": "assets/basic/dict/SF_DANGER_TYPE.json",
                "dictionaryPF": null
            },
            {
                "column": "INTASK_NAME",
                "name": "INTASK_NAME_NAME",
                "component": "input",
                "lable": "巡检计划名称",
                "comment": "巡检计划名称",
                "placeholder": "请输入巡检计划名称",
                'lableSpan': 3,
                'itemSpan': 21,
                "customize": 24,
                "insertValue": null,
                "model": null,
                "visible": true,
                "require": true
            },
            {
                "column": "INTASK_TYPE",
                "name": "INTASK_TYPE_NAME",
                "component": "select",
                "lable": "巡检类型",
                "comment": "巡检类型",
                "placeholder": "请选择巡检类型",
                'lableSpan': 3,
                'itemSpan': 5,
                "customize": 24,
                "insertValue": null,
                "visible": true,
                "require": true,
                "dictionary": "assets/basic/dict/INTASK_TYPE.json",
                "dictionaryPF": null
            },
            {
                "column": "INTASK_STATE",
                "name": "INTASK_STATE_NAME",
                "component": "select",
                "lable": "计划状态",
                "comment": "计划状态",
                "placeholder": "请输入计划状态",
                "insertValue": null,
                "model": "NO_AUDIT",
                "visible": [
                    "detail"
                ],
                "require": false,
                "dictionary": "assets/basic/dict/DANGER_PLAN_STATE.json",
                "dictionaryPF": null
            },
            {
                "column": "INTASK_CODE",
                "name": "INTASK_CODE_NAME",
                "component": "input",
                "lable": "巡检计划编号",
                "comment": "巡检计划编号",
                "placeholder": "巡检计划编号",
                "insertValue": "SYS-NO-END;XJJH;4",
                "model": null,
                "visible": false,
                "require": false,
                "disable": true
            },
            {
                "column": "ORG_ID",
                "name": "ORG_ID_NAME",
                "component": "poplistedit",
                "lable": "部门名称",
                "comment": "部门名称",
                "placeholder": "请输入部门名称",
                "insertValue": null,
                "model": null,
                "visible": true,
                "require": true,
                "dictionary": "assets/basic/dict/RE_REPAIR_REQUEST_POPLIST.json",
                "dictionaryPF": null,
                "dictionaryParam": [
                    {
                        "clearValues": [
                            "SYS.se_inspection_plan.TASK_TYPE_ID",
                            "SYS.se_inspection_plan.CHECK_EMP_ID",
                            "SYS.se_inspection_plan.EMP_ID"
                        ]
                    }
                ],
                "columnGroup": [
                    {
                        "column": "ORG_NAME",
                        "name": "ORG_NAME_NAME",
                        "component": "input",
                        "lable": "部门名称",
                        "comment": "部门名称",
                        "placeholder": "部门名称",
                        "fromValue": "name",
                        "model": null,
                        "visible": false,
                        "require": false,
                        "disable": [
                            "add",
                            "modify"
                        ]
                    }
                ]
            },
            {
                "column": "TASK_TYPE_ID",
                "name": "TASK_TYPE_ID_NAME",
                "component": "poplist",
                "lable": "任务类型",
                "comment": "任务类型",
                "placeholder": "请选择任务类型",
                "insertValue": null,
                "visible": true,
                "require": true,
                "dictionary":"assets/basic/dict/TASK_TYPE_POPLIST.json",
                "dictionaryPF": null,
                "dictionaryParam": [
                    {
                        "column": "ORG_ID",
                        "value": "SYS.se_inspection_plan.ORG_ID",
                        "errormsg": "请先选择转部门"
                    }
                ],
                "columnGroup": [
                    {
                        "column": "TASK_TYPE_NAME",
                        "name": "TASK_TYPE_NAME_NAME",
                        "component": "input",
                        "lable": "任务类型名称",
                        "comment": "任务类型名称",
                        "placeholder": "任务类型名称",
                        "fromValue": "TASK_TYPE_NAME",
                        "model": null,
                        "visible": false,
                        "require": false,
                        "disable": [
                            "add",
                            "modify"
                        ]
                    }
                ]
            },
            {
                "column": "CHECK_EMP_ID",
                "name": "CHECK_EMP_ID_NAME",
                "component": "poplist",
                "lable": "巡检人",
                "comment": "巡检人",
                "class": "col-lg-4 col-sm-6 col-xs-12",
                "placeholder": "请选择巡检人",
                "model": null,
                "visible": true,
                "type": "normal",
                "require": true,
                // "dictionaryInputPopListEdit": "AS_ID",
                "dictionary": "assets/basic/dict/SYSTEM_USER_POPLIST.json",
                "dictionaryPF": null,
                "dictionaryParam": [
                    {
                        "column": "ORG_ID",
                        "value": "SYS.se_inspection_plan.ORG_ID",
                        "errormsg": "请先选择转部门"
                    }
                ],
                "columnGroup": [
                    {
                        "column": "CHECK_EMP_NAME",
                        "name": "CHECK_EMP_NAME_NAME",
                        "component": "input",
                        "lable": "巡检人",
                        "comment": "巡检人",
                        "placeholder": "巡检人",
                        "fromValue": "name",
                        "model": null,
                        "visible": false,
                        "require": false,
                        "disable": [
                            "add",
                            "modify"
                        ]
                    }
                ]
            },
            {
                "column": "INTASK_START_TIME",
                "name": "INTASK_START_TIME_NAME",
                "component": "date",
                "lable": "计划开始时间",
                "comment": "计划开始时间",
                "placeholder": "请输入计划开始时间",
                "insertValue": null,
                "model": null,
                "visible": true,
                "require": true,
                "dictionaryParam": [
                    {
                        "clearValues": [
                            "SYS.se_inspection_plan.INTASK_START_TIME"
                        ]
                    }
                ]
            },
            {
                "column": "INTASK_END_TIME",
                "name": "INTASK_END_TIME_NAME",
                "component": "date",
                "lable": "计划截止时间",
                "comment": "计划截止时间",
                "placeholder": "请输入计划截止时间",
                "insertValue": null,
                "model": null,
                "visible": true,
                "require": true,
                "dictionaryParam": [
                    {
                        "clearValues": [
                            "SYS.se_inspection_plan.INTASK_END_TIME"
                        ]
                    }
                ]
            },
            {
                "column": "PATROL_CHECK_PERIOD",
                "name": "PATROL_CHECK_PERIOD_NAME",
                "component": "input",
                "type": "number",
                "lable": "巡检周期（天）",
                "comment": "巡检周期（天）",
                "placeholder": "请输入巡检周期（天）",
                "insertValue": null,
                "model": null,
                "visible": true,
                "require": true
            },
            {
                "column": "HR",
                "component": "hr",
                "lable": "过滤信息",
                "customize": 24,
                "comment": "过滤信息",
                "visible": true,
                "wechatVisable": true,
                "line": false,
                "commitPoint": [
                    "detail"
                ],
                "placeholder": "过滤信息",
                "model": null
            },
            {
                "column": "EMP_ID",
                "name": "EMP_ID_NAME",
                "component": "poplist",
                "lable": "资产管理员",
                "comment": "资产管理员",
                "class": "col-lg-4 col-sm-6 col-xs-12",
                "placeholder": "请选择资产管理员",
                "model": null,
                "visible": true,
                "type": "normal",
                "require": true,
                "dictionary": "assets/basic/dict/SYSTEM_USER_POPLIST.json",
                "dictionaryPF": null,
                "dictionaryParam": [
                    {
                        "column": "ORG_ID",
                        "value": "SYS.se_inspection_plan.ORG_ID",
                        "errormsg": "请先选择转部门"
                    }
                ],
                "columnGroup": [
                    {
                        "column": "EMP_NAME",
                        "name": "EMP_NAME_NAME",
                        "component": "input",
                        "lable": "资产管理员",
                        "comment": "资产管理员",
                        "placeholder": "资产管理员",
                        "fromValue": "name",
                        "model": null,
                        "visible": false,
                        "require": false,
                        "disable": [
                            "add",
                            "modify"
                        ]
                    }
                ]
            },
            {
                "column": "AS_TYPE",
                "name": "AS_TYPE_NAME",
                "component": "cascader",
                "lable": "资产卡片分类",
                "comment": "资产卡片分类",
                "placeholder": "请选择资产卡片分类",
                "model": null,
                "visible": true,
                "require": false,
                "columnGroup": [
                    {
                        "type": "get",
                        "column": "AS_TYPE_LV01",
                        "name": "AS_TYPE_LV01_NAME",
                        "component": "input",
                        "lable": "一级分类",
                        "comment": "一级分类",
                        "placeholder": "请输入一级分类",
                        "fromValue": "name",
                        "model": null,
                        "dictionary": "assets/basic/dict/AS_CLASSIFICATION_CASCADER_LV1.json",
                        "dictionaryPF": null,
                        "visible": false,
                        "require": false,
                        "disable": [
                            "add",
                            "modify"
                        ]
                    },
                    {
                        "type": "get",
                        "column": "AS_TYPE_LV02",
                        "name": "AS_TYPE_LV02_NAME",
                        "component": "input",
                        "lable": "二级分类",
                        "comment": "二级分类",
                        "placeholder": "请输入二级分类",
                        "fromValue": "name",
                        "model": null,
                        "lastLv": true,
                        "dictionary": "assets/basic/dict/AS_CLASSIFICATION_CASCADER_LV2.json",
                        "dictionaryPF": null,
                        "dictionaryConditionColumn": "UP_CLASS_ID",
                        "visible": false,
                        "require": false,
                        "disable": [
                            "add",
                            "modify"
                        ]
                    }
                ]
            },
            {
                "column": "ADD_USERID",
                "name": "ADD_USERID_NAME",
                "component": "input",
                "lable": "添加人账户ID",
                "comment": "添加人账户ID",
                "placeholder": "请输入添加人账户ID",
                "class": "col-lg-4 col-sm-6 col-xs-12",
                "insertValue": "SYS_USER_ID",
                "model": null,
                "visible": false,
                "require": false
            },
            {
                "column": "ADD_TIME",
                "name": "ADD_TIME_NAME",
                "component": "input",
                "lable": "添加时间",
                "comment": "添加时间",
                "placeholder": "添加时间",
                "class": "col-lg-4 col-sm-6 col-xs-12",
                "insertValue": "SYS-DATETIME",
                "disable": [
                    "add",
                    "modify"
                ],
                "commitPoint": [
                    "add"
                ],
                "model": null,
                "visible": false
            },
            {
                "column": "ADD_NAME",
                "name": "ADD_NAME_NAME",
                "component": "input",
                "lable": "添加人",
                "comment": "添加人",
                "placeholder": "请输入添加人姓名",
                "class": "col-lg-4 col-sm-6 col-xs-12",
                "insertValue": "SYS_USER_NAME",
                "disable": [
                    "add",
                    "modify"
                ],
                "commitPoint": [
                    "add"
                ],
                "model": null,
                "visible": false
            },
            {
                "column": "UPD_USERID",
                "name": "UPD_USERID_NAME",
                "component": "input",
                "lable": "修改人",
                "comment": "修改人",
                "placeholder": "修改人",
                "insertValue": "SYS_USER_ID",
                "model": null,
                "visible": false,
                "disable": [
                    "add",
                    "modify"
                ],
                "commitPoint": [
                    "modify"
                ]
            },
            {
                "column": "UPD_NAME",
                "name": "UPD_NAME_NAME",
                "component": "input",
                "lable": "修改人",
                "comment": "修改人",
                "placeholder": "修改人",
                "insertValue": "SYS_USER_NAME",
                "model": null,
                "visible": false,
                "disable": [
                    "add",
                    "modify"
                ],
                "commitPoint": [
                    "modify"
                ]
            },
            {
                "column": "UPD_TIME",
                "name": "UPD_TIME_NAME",
                "component": "input",
                "lable": "更新时间",
                "comment": "更新时间",
                "placeholder": "更新时间",
                "class": "col-lg-4 col-sm-6 col-xs-12",
                "insertValue": "SYS-DATETIME",
                "disable": [
                    "add",
                    "modify"
                ],
                "commitPoint": [
                    "modify"
                ],
                "model": null,
                "visible": false
            },
            {
                "column": "U_TIME",
                "name": "U_TIME_NAME",
                "component": "input",
                "lable": "更新时间",
                "comment": "更新时间",
                "placeholder": "请选择更新时间",
                "class": "col-lg-4 col-sm-6 col-xs-12",
                "insertValue": "SYS-DATETIME",
                "disable": [
                    "add",
                    "modify"
                ],
                "commitPoint": [
                    "add"
                ],
                "model": null,
                "visible": false
            },
            {
                "column": "U_DELETE",
                "name": "U_DELETE_NAME",
                "component": "input",
                "lable": "删除表识",
                "comment": "删除表识",
                "placeholder": "请输入删除表识",
                "class": "col-lg-4 col-sm-6 col-xs-12",
                "model": 1,
                "visible": false,
                "require": false
            }
        ],
        'dataList': [],
        'showDataList': []
    }
    public total: any = 1;
    // 分页下标
    public pageIndex: any = 1;
    // 每页显示数据
    public pageSize: any = 5;
    public nzPageSizeOptions: any = [5, 10, 20, 50, 100, 200]
    condition = {};
    public showColumns: any = [
        {
            "column": "ID",
            "title": "主键",
            "visible": false,
            "pk_column": true,
            "iscondition": true,
            "conditionColumn": "ID",
            "type": "head",
            "class": ""
        },
        {
            "column": "AS_NAME",
            "title": "资产名称",
            "visible": true,
            "export": true,
            "type": "head",
            "class": ""
        },
        {
            "column": "AS_CODE",
            "title": "资产编号",
            "visible": true,
            "export": true,
            "type": "head",
            "class": ""
        },
        {
            "column": "AS_ADDRESS",
            "title": "地址",
            "visible": true,
            "export": true,
            "type": "head",
            "class": ""
        },
        {
            "column": "OPERATING_NAME",
            "title": "管理片区",
            "visible": true,
            "export": true,
            "type": "head",
            "class": ""
        },
        {
            "column": "AS_TYPE_LV02_NAME",
            "title": "房屋类型",
            "visible": true,
            "export": true,
            "type": "head",
            "class": ""
        }
    ];
    private user: any;
    private title = "巡检计划-新增";
    private planId: any;
    private modeType: any;
    // private check = true;
    addDisable = false;
    Cancel = true;

    constructor(
        private dataCenterService: DataCenterService,
        private utils: UtilsService,
        private confirmModal: ConfirmModalService,
        private requestService: RequestDataService,
        private userInfoService: UserInfoService
    ) {
        this.user = userInfoService.getUserInfo();
    }

    changeModeType() {
        let modeType = _sh.get(this.parentData, ['modeType']);
        if (modeType == 'modify') {
            this.title = "巡检计划-修改";
            this.planId = _sh.cloneDeep(_sh.get(this.parentData, ['id']));
            this.modeType = modeType;
        } else {
            this.title = "巡检计划-新增";
            this.modeType = modeType;
        }
    }

    ngOnInit() {
        this.changeModeType(); // 根据 this.parentData 修改 modeType
        if (this.modeType == 'modify') {
            this.doSearch();
            this.changeBtn(true);
        }
    }

    gotoBack() {
        this.parentData.data = {};
        this.parentData.conditionColumns = {};
        this.parentData.formType = 'listForm';
        this.parentData.actionArray = [];
        // 覆盖当前数据到data-center;
        _sh.each(this.dataCenterService._dataObject.list, (item) => {
            if (item['id'] === this.parentData.id) {
                item = _sh.extend(item, this.parentData);
            }
        });
        this.dataCenterService.refreshDataCenterInStorage();
    }


    backfillData(pForm, pDataArray) {
        _sh.each(pDataArray, oneData => {
            _sh.each(pForm['columns'], oneColumn => {
                oneColumn['model'] = oneData[oneColumn['column']];

                if (_sh.includes(['poplist', 'poplistedit', 'cascader'], oneColumn['component'])) {
                    oneColumn['modelName'] = oneData[oneColumn['column'] + '_DICT'];
                }
                if (oneColumn['component'] == 'file') {
                    let url = oneData[oneColumn['column']];
                    let fileName = this.utils.Base_getFileNameOnly(url);
                    oneColumn['fileList'] = [];
                    oneColumn['fileList'].push(
                        {
                            uid: this.utils.Base_getUuid(),
                            name: fileName,
                            status: 'done',
                            url: url
                        }
                    )

                }
                _sh.each(oneColumn['columnGroup'], oneColumnGroup => {
                    oneColumnGroup['model'] = oneData[oneColumnGroup['column']];
                    if (_sh.includes(['poplist', 'poplistedit', 'cascader'], oneColumnGroup['component'])) {
                        oneColumnGroup['modelName'] = oneData[oneColumnGroup['column'] + '_DICT'];
                    }
                })
            })
        })
    }

    doSearch() {
        const search = observableForkJoin(
            this.requestService.getDataByCondition('assets/se/se_daily_special_plan_list.json', {'ID': this.planId}), // 0
            this.requestService.getDataByCondition('assets/se/se_plan_detail.json', {'INSPECTION_TASK_ID': this.planId})
        );
        search.subscribe(values => {
            this.backfillData(this.baseForm, values[0]['data']);
            this.pageIndex = 1;
            this.transformResult(values[1])
        })
    }


    async doSave() {
        let check = true;
        check = this.checkMainInput();
        if (check && !this.utils.Base_HasValue(this.baseForm.dataList)) {
            check = false;
            this.confirmModal.show('error', {'title': '请添加资产'})
        }
        if (check) {
            if (this.parentData.modeType == 'add') {
                // 如果是添加状态，增加一个UUID
                _sh.each(this.baseForm['columns'], oneColumn => {
                    if (oneColumn['column'] == 'ID') {
                        oneColumn['model'] = this.utils.Base_getUuid();
                    }
                });
            }
            let planList = [];
            _sh.each(this.baseForm.dataList, oneData => {
                let template = {
                    'id': oneData['AS_ID'],
                    'asName': oneData['AS_NAME'],
                    'asCode': oneData['AS_CODE'],
                    'asAddress': oneData['AS_ADDRESS'],
                    'asFloor': oneData['AS_FLOOR'],
                    'classType': oneData['AS_TYPE_LV02_NAME'],
                    'operatingId': oneData['OPERATING_ID'],
                    'operatingName': oneData['OPERATING_NAME'],
                    'asRoomNumber': oneData['AS_ROOM_NUMBER']
                };
                planList.push(template);
            })

            let queryData = {
                'userId': this.user.USER_ID,
            };
            const bodyData = {
                'seInspectionPlanDetailList': planList,
                'seInspectionPlan': this.formatToEntity(this.baseForm['columns'])
            };
            if (this.modeType == 'modify') {
                let mainData = await this.requestService.getDataByCondition('assets/se/se_daily_special_plan_list.json', {'ID': this.planId}).toPromise();
                let AS_TYPE = _sh.get(mainData, ['data', 0, 'AS_TYPE'], null);
                _sh.each(this.baseForm.columns, oneColumn => {
                    if (oneColumn['column'] == 'AS_TYPE')
                        oneColumn['model'] = AS_TYPE;
                })
            }
            this.requestService.doActionByHdbsthor('AppApi/InspectionController', 'doSavePlan', queryData, bodyData, false, 'hdbsthor_assets', 'appToken').subscribe(response => {
                let status = _sh.get(response, ['status'], 200);
                if (status == 200) {
                    _sh.set(this.parentData, ['modeType'], 'modify');
                    _sh.set(this.parentData, ['id'], _sh.get(response, ['id']));
                    this.changeModeType();
                    this.doSearch();
                    this.confirmModal.show('success', {'title': '执行成功'});
                    this.gotoBack();
                } else if (status == 500) {
                    this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
                }
            });
        }
    }


    checkMainInput() {
        let check = true;
        _sh.each(this.baseForm['columns'], oneColumn => {
            if (oneColumn['visible'] == true && oneColumn['require'] == true) {
                if (check && oneColumn['model'] == null) {
                    check = false;
                    this.confirmModal.show('error', {title: oneColumn['lable'] + '为必填信息。'});
                }
            }
        })
        return check;
    }


    addPlan() {
        let check = true;
        check = this.checkMainInput();
        if (check) {
            this.changeBtn(true);
            this.baseForm.dataList = [];
            this.requestService.getDataByCondition('assets/as/asset.json', {
                'ASSET_ID': this.findValue(this.baseForm, 'EMP_ID'),
                'AS_TYPE': this.findValue(this.baseForm, 'AS_TYPE')
            }).subscribe((data: any) => {
                this.pageIndex = 1;
                this.transformResult(data);
                this.confirmModal.show('success', {'title': '新增成功'});
            })
        }
    }

    findValue(pForm, pColumnName) {
        let bFind = false;
        let value = null;
        _sh.each(pForm['columns'], oneColumn => {
            if ((oneColumn['column'] == pColumnName) && (!bFind)) {
                value = oneColumn['model'];
                bFind = true;
            }
        });
        return value;
    }

    formatToEntity(datas) {
        let entity: any = {};
        _sh.each(datas, (item) => {
            const column = _sh.camelCase(_sh.get(item, 'column'));
            const value = _sh.get(item, 'model');
            entity[column] = value;
            let columnGroup = _sh.get(item, 'columnGroup', null);
            _sh.each(columnGroup, (oneGroupColumn) => {
                const itemColumn = _sh.camelCase(_sh.get(oneGroupColumn, 'column'));
                const itemValue = _sh.get(oneGroupColumn, 'model');
                entity[itemColumn] = itemValue;
            });
        });
        return entity;
    }

    paginationItemData() {
        let index = _sh.cloneDeep(this.pageIndex);
        let begin = (index - 1) * this.pageSize;
        let end = index * this.pageSize;
        this.baseForm.showDataList = _sh.slice(this.baseForm.dataList, begin, end);
    }

    deleteCallBack(vm) {
        vm.total = vm.baseForm.dataList.length;
        vm.paginationItemData();
    }

    transformResult(data) {
        this.baseForm.dataList = _sh.get(data, ['data'], []);
        this.total = data.page.rowCount;
        this.paginationItemData();
    }

    cancel() {
        this.changeBtn(false);
    }

    changeBtn(pNew) {
        if (!pNew) {
            this.baseForm.dataList = [];
            this.baseForm.showDataList = [];
        }
        this.addDisable = pNew;
        this.Cancel = !pNew;
        _sh.each(this.baseForm['columns'], oneColumn => {
            oneColumn['disable'] = pNew;
        })
    }

}
