import {Component, Input, OnInit} from '@angular/core';
import {RequestDataService} from '../../service/request-data.service';
import {AutoInsertService} from '../../service/auto-insert.service';
import {StorageService} from '../../service/storage.service';
import {UserInfoService} from '../../service/user-info.service';
import {UploadService} from '../../service/upload.service';
import {HttpClient} from '@angular/common/http';
import {DataCenterService} from '../../service/data-center.service';
import {UtilsService} from '../../service/utils.service';
import {AppConfigService} from '../../service/app-config.service';
import {ConfirmModalService} from '../../service/confirm-modal.service';
import {NzFormatBeforeDropEvent, NzFormatEmitEvent} from 'ng-zorro-antd';
import {forkJoin as observableForkJoin, Observable, of} from 'rxjs';
import * as _sh from 'lodash';

@Component({
  selector: 'app-tree-form',
  templateUrl: './tree-form.component.html',
  styleUrls: ['./tree-form.component.css']
})
export class TreeFormComponent implements OnInit {

  user: any;

  @Input() parentData: any;
  nowMenu: any;
  appList: any;
  selectNode: any;
  nodes: any = [];
  isAdding: boolean = false;
  modeType = 'modify';
  defaultSelectedKeys = [];
  treeDict: any = {};
  mainForm: any = null;
  searchId: any;

  constructor(
    private autoInsert: AutoInsertService,
    private storage: StorageService,
    private userInfo: UserInfoService,
    private uploadService: UploadService,
    private httpClient: HttpClient,
    private dataCenterService: DataCenterService,
    private userInfoService: UserInfoService,
    private autoService: AutoInsertService,
    private requestDataService: RequestDataService,
    private util: UtilsService,
    private config: AppConfigService,
    private confirmModal: ConfirmModalService,
  ) {
    this.user = this.userInfo.getUserInfo();
  }

  findNode(pKey , pParentsNode?) {
    let findObj = {
      'find': false,
      'node': null
    }
    let findList = null;
    if (!this.util.Base_HasValue(pParentsNode)) {
      findList = this.nodes;
    } else {
      findList = pParentsNode;
    }
    _sh.each(findList, oneNode => {
      if (oneNode['key'] == pKey && (!findObj['find'])) {
        findObj['find'] = true;
        findObj['node'] = oneNode;
      }
      if (!findObj['find']) {
        let childNoneList = _sh.get(oneNode, ['children'], null);
        if (this.util.Base_HasValue(childNoneList)) {
          findObj = this.findNode(pKey,childNoneList);
        }
      }
    })
    return findObj
  }

  getKey(pDict) {
    let key = null;
    let find = false
    _sh.each(this.mainForm[0]['columns'] , oneColumn=>{
      if (oneColumn['column'] == this.treeDict[pDict] && (!find)) {
        find = true;
        key = oneColumn['model'];
      }
    });
    return key;
  }

  setMainFormLinkName() {
    this.setLinkNameMain(this.nodes);
    let key = this.getKey('key');
    let title = this.getKey('title');
    let findObj = this.findNode(key);
    let node = findObj['node'];
    _sh.each(this.mainForm[0]['columns'] , oneColumn=>{
      if (oneColumn['column'] == this.treeDict['linkName']) {
        let tempArray = _sh.dropRight(_sh.get(node,['linkName']));
        tempArray.push(title);
        oneColumn['model'] = _sh.join(tempArray,' / ');
      }
    });
  }

  /**
   * 保存数据
   */
  doSave(): void {
    this.setMainFormLinkName(); // 将 linkName 反写。
    let saveDatas = this.util.DeepCopy(this.mainForm);
    const data = _sh.get(saveDatas, [0, 'columns']);
    let requireCheck = true;
    _sh.each(data, oneColumn => {
      if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
        this.confirmModal.show('error', {title: oneColumn['lable'] + ':请填写必填信息。'});
        requireCheck = false;
        return false;
      }
    });
    if (requireCheck) {
      let tmpJson = '';
      if (this.modeType == 'add') {
        tmpJson = this.autoService.AdapterInsert(saveDatas);
      } else {
        tmpJson = this.autoService.AdapterUpdate(saveDatas);
      }
      this.autoService.ExecJson(tmpJson).subscribe(response => {
        if (response['state'] === 'success') {
          this.confirmModal.show('success', {
            'title': '温馨提示',
            'content': '执行成功'
          });
          this.doRefresh();
        } else {
          this.confirmModal.show('error', {
            'title': '温馨提示',
            'content': '保存失败'
          });
        }
      });
    }
  }

  /**
   * 删除数据
   */
  doDelete() {
    if (!this.util.Base_HasValue(this.selectNode)) {
      this.confirmModal.show('error', {title: '请先选择一个节点，再执行删除操作。'});
      return
    } else {
      let child = _sh.get(this.selectNode, ['children']);
      if (this.util.Base_HasValue(child)) {
        this.confirmModal.show('error', {title: '该节点之下还有子节点，必须先删除其子节点，才能删除该节点数据。'});
      } else {
        this.confirmModal.show('confirm', {
          title: '确认执行删除么？',
          suc: () => {
            let setColumn = _sh.find(this.mainForm[0]['columns'], {'column': 'U_DELETE'});
            if (!this.util.Base_HasValue(setColumn)) {
              this.confirmModal.show('error', {title: '未找到 U_DELETE 字段。'});
              return
            }
            _sh.set(setColumn, 'model', 0);
            let tmpJson = this.autoService.AdapterUpdate(this.mainForm);
            this.autoService.ExecJson(tmpJson).subscribe(response => {
              if (response['state'] === 'success') {
                this.confirmModal.show('success', {
                  'title': '温馨提示',
                  'content': '执行成功'
                });
                this.doRefresh();
              } else {
                this.confirmModal.show('error', {
                  'title': '温馨提示',
                  'content': '保存失败'
                });
              }
            });
          }
        });
      }
    }
  }

  ngOnInit() {
    this.nowMenu = this.dataCenterService._dataObject.nowMenu;
    this.appList = _sh.find(JSON.parse(this.storage.getData('appList')), {'LIST_ID': _sh.get(this.nowMenu, ['MENU_ID'])});
    this.searchId = _sh.cloneDeep(this.appList['SEARCH_ID']);
    this.doInit();
    this.doRefresh();
  }

  getChild(pNode, pParentsId) {
    let bFind: Boolean = false;
    let children = [];
    _sh.each(pNode, oneNode => {
      if (!bFind) {
        let thisChildren = oneNode['children'];
        if (oneNode['key'] == pParentsId) {
          children = thisChildren;
          bFind = true;
        } else {
          if (this.util.Base_HasValue(thisChildren)) {
            children = this.getChild(thisChildren, pParentsId);
          }
        }
      }
    })
    return children;
  }

  /**
   * 增加下级数据
   */
  doAdd() {

    let that = this;

    function doAddLv01() {
      let maxSeaialNumber = getMaxSeaialNumber();
      that.isAdding = true;
      let key = that.util.Base_getUuid();
      let linKey = [key];

      let obj = {
        title: '新增数据',
        key: key,
        isLeaf: true,
        level: 0,
        upKey: null,
        upName: null,
        upLevel: null,
        linKey: linKey,
        linKeyStr: _sh.join(linKey),
        serialNumber: maxSeaialNumber,
        expanded: true
      }
      let temp = that.util.DeepCopy(that.nodes);
      temp.push(that.util.DeepCopy(obj));
      that.nodes = temp;
      that.defaultSelectedKeys = [obj['key']]; // 设置选中的值，是新的设置。
      that.setRightData(obj);
      that.setDisabled(true); // 将其他的页面数据修改为灰色。
    }

    function doAddLv02() {
      let parentsId = that.selectNode['key'];
      let maxSeaialNumber = getMaxSeaialNumber(parentsId);
      let key = that.util.Base_getUuid();
      let linKey = [];
      let parentsLinkKey = _sh.get(that.selectNode, ['origin', 'linKeyStr']);
      linKey = _sh.includes(parentsLinkKey, [key]);
      that.isAdding = true;
      let obj = {
        title: '新增数据',
        key: key,
        isLeaf: true,
        level: that.selectNode['level'] + 1,
        upKey: parentsId,
        serialNumber: maxSeaialNumber,
        linKey: linKey,
        linKeyStr: _sh.join(linKey),
        upName: _sh.get(that.selectNode, ['origin', 'title']),
        upLevel: that.selectNode['level'],
        expanded: true
      }
      let temp = that.util.DeepCopy(that.nodes);
      that.nodes = that.setChild(parentsId, temp, obj);
      that.defaultSelectedKeys = [obj['key']]; // 设置选中的值，是新的设置。
      that.setRightData(obj);
      that.setDisabled(true); // 将其他的页面数据修改为灰色。
    }

    function getMaxSeaialNumber(pParentsId?) {
      let seaialNumber = 1;
      if (!that.util.Base_HasValue(pParentsId)) {
        // 处理一级
        let list = _sh.filter(that.nodes, (item) => {
          return item['level'] == 0;
        });
        seaialNumber = list.length + 1;
      } else {
        let list = that.getChild(that.nodes, pParentsId);
        seaialNumber = list.length + 1;
      }
      return seaialNumber;
    }

    if (!that.util.Base_HasValue(that.selectNode)) {
      doAddLv01(); // 如果没有选择，那么就增加第一层的数据。
    } else {
      if (that.selectNode['isLeaf']) {
        that.confirmModal.show('error', {title: '只有非末级节点才能增加子节点数据。'});
        return
      } else {
        doAddLv02();
      }
    }
    that.modeType = 'add';
  }

  setRightData(oneNode) {
    this.clearRight();
    let lv = oneNode['level'] + 1;
    _sh.each(this.mainForm[0]['columns'], oneColumn => {
      if (oneColumn['column'] == this.treeDict['key']) {
        oneColumn['model'] = oneNode['key'];
      } else if (oneColumn['column'] == this.treeDict['title']) {
        oneColumn['model'] = oneNode['title'];
      } else if (oneColumn['column'] == this.treeDict['level']) {
        oneColumn['model'] = lv.toString();
      } else if (oneColumn['column'] == this.treeDict['isLeaf']) {
        oneColumn['model'] = 'LAST_STAGE';
      } else if (oneColumn['column'] == this.treeDict['upKey']) {
        oneColumn['model'] = oneNode['upKey'];
        oneColumn['modelName'] = oneNode['upName'];
      } else if (oneColumn['column'] == this.treeDict['upName']) {
        oneColumn['model'] = oneNode['upName'];
      } else if (oneColumn['column'] == this.treeDict['upLevel']) {
        oneColumn['model'] = oneNode['upLevel'];
      }else if (oneColumn['column'] == this.treeDict['serialNumber']) {
        oneColumn['model'] = oneNode['serialNumber'];
      } else if (oneColumn['column'] == 'U_DELETE') {
        oneColumn['model'] = '1';
      } else if (oneColumn['column'] == this.treeDict['linKey']) {
        oneColumn['model'] = oneNode['linKeyStr'];
      }
    })
  }


  setChild(pParentsId, pNodes, pObj) {
    _sh.each(pNodes, oneNode => {
      let tempChildren = _sh.get(oneNode, ['children'], []);
      if (oneNode['key'] == pParentsId) {
        tempChildren.push(pObj);
        oneNode['children'] = tempChildren;
      } else {
        let temp = this.setChild(pParentsId, tempChildren, pObj);
        oneNode['children'] = temp;
      }
    })
    return pNodes;
  }


  clearRight() {
    _sh.each(this.mainForm[0]['columns'], oneColumn => {
      oneColumn['model'] = null;
    })
  }

  makeTreeData(pData) {
    let that = this;

    function makeUpLeftData(oneItem, pParentsNode?) {
      let linKey = _sh.split(oneItem[that.treeDict['linKey']], ',');
      let obj = {
        title: oneItem[that.treeDict['title']],
        key: oneItem[that.treeDict['key']],
        isLeaf: oneItem[that.treeDict['isLeaf']] == 'LAST_STAGE',
        level: parseInt(oneItem[that.treeDict['level']]) - 1,
        upLevel: oneItem[that.treeDict['upLevel']],
        upKey: oneItem[that.treeDict['upKey']],
        upName: oneItem[that.treeDict['upName']],
        linKey: linKey,
        linKeyStr: _sh.join(linKey),
        expanded: true,
        datas: oneItem
      }
      return obj;
    }

    /**
     * 组织一级数据
     * @param pData
     */
    function getNodeDatas(pData) {
      let nodeDatas = [];
      if (that.util.Base_HasValue(that.treeDict)) {
        let list = _sh.filter(pData, (item) => {
          let level = item[that.treeDict['level']];
          if (typeof (level) == 'number') {
            level = level.toString();
          }
          return level == '1';
        });
        _sh.each(list, oneItem => {
          let children = getChildren(pData, oneItem);
          let obj = makeUpLeftData(oneItem);
          obj['children'] = children;
          nodeDatas.push(obj);
        });
      }
      return nodeDatas;
    }

    /**
     * 组织二级，及二级一下的数据
     * @param pData
     * @param pParentsNode
     */
    function getChildren(pData?, pParentsNode?) {
      let list = _sh.filter(pData, (item) => {
        return item[that.treeDict['upKey']] == pParentsNode[that.treeDict['key']];
      });
      let nodeDatas = [];
      _sh.each(list, oneItem => {
        let obj = makeUpLeftData(oneItem, pParentsNode);
        let children = getChildren(pData, oneItem);
        obj['children'] = children;
        nodeDatas.push(obj);
      });
      return nodeDatas;
    }

    this.nodes = [];
    let nodeDatas = getNodeDatas(pData);
    this.nodes = nodeDatas;
    this.setLinkNameMain(this.nodes);
  }

  setLinkNameMain(pNodeList, pParentsNode?) {
    _sh.each(pNodeList, oneNode => {
      this.setLinkName(oneNode, pParentsNode);
      let childNoneList = _sh.get(oneNode, ['children'], null);
      if (this.util.Base_HasValue(childNoneList)) {
        this.setLinkNameMain(childNoneList, oneNode);
      }
    })
  }

  setLinkName(pOneNode, pParentsNode?) {
    if (!this.util.Base_HasValue(pParentsNode)) {
      pOneNode['linkName'] = [pOneNode['title']];
    } else {
      pOneNode['linkName'] = _sh.includes(pParentsNode['linkName'],[pOneNode['title']]);
    }
    pOneNode['linkNameStr'] = _sh.join(pOneNode['linkName'], " / ");
  }

  getOneRowData(pID) {
    const search = observableForkJoin(
      this.requestDataService.getDataByCondition(this.searchId, {'ID': pID})
    ).subscribe(
      async values => {
        return await _sh.each(this.mainForm[0]['columns'], oneColumn => {
          oneColumn['model'] = _sh.get(values, ['0', 'data', 0, oneColumn['column']]);
        })
      }
    )
  }

  nzClick(event: NzFormatEmitEvent): void {
    if (!this.isAdding) {
      let id = _sh.get(event, ['keys', 0]);
      this.selectNode = _sh.get(event, ['node']);
      this.getOneRowData(id);
      this.modeType = 'modify';
    }
  }

  setDisabled(pDisable: Boolean) {
    let nodes = this.util.DeepCopy(this.nodes);
    _sh.each(nodes, oneNode => {
      this.setOneDisabled(oneNode, pDisable);
    })
    this.nodes = nodes;
  }

  setOneDisabled(pOneNode, pDisable: Boolean) {
    pOneNode['disabled'] = pDisable;
    let children = _sh.get(pOneNode, ['children'], null);
    _sh.each(children, oneNode => {
      if (this.util.Base_HasValue(children)) {
        _sh.each(children, oneChildNode => {
          this.setOneDisabled(oneChildNode, pDisable);
        })
      }
    })
  }

  doInit() {
    const search = observableForkJoin(
      this.requestDataService.getDataByJson(this.searchId)
    );
    search.subscribe(
      values => {
        this.mainForm = values[0]['detailColumns'];
        this.treeDict = values[0]['treeDict'];
      }
    );
  }

  doRefresh() {
    const search = observableForkJoin(
      this.requestDataService.getDataByCondition(this.searchId, {})
    );
    search.subscribe(
      values => {
        this.makeTreeData(values[0]['data']);
        this.clearRight(); // 清除左边的数据
        this.isAdding = false;
        this.selectNode = null;
        this.defaultSelectedKeys = [];
      }
    );
  }

  // nzEvent(event: NzFormatEmitEvent): void {
  //   this.isAdding = true;
  //   this.modeType = 'modify';
  //   this.setDisabled(true);
  //   let previousSerialNumber = _sh.get(event, ['node', 'origin', 'datas', this.treeDict['serialNumber']], 0) + 1;
  //   let level = _sh.get(event, ['node', 'parentNode', 'level'], -1) + 1;
  //   let upKey = _sh.get(event, ['node', 'parentNode', 'origin', 'key']);
  //   let upName = _sh.get(event, ['node', 'parentNode', 'origin', 'title']);
  //   let oneItem = _sh.get(event, ['dragNode', 'origin', 'datas']);
  //   // let upLink = _sh.get(event, ['node', 'parentNode', 'origin', 'linKey'], []);
  //   // let linKey = _sh.includes(upLink, [oneItem[this.treeDict['key']]]);
  //   oneItem[this.treeDict['serialNumber']] = previousSerialNumber;
  //   oneItem[this.treeDict['level']] = level;
  //   oneItem[this.treeDict['upKey']] = upKey;
  //   oneItem[this.treeDict['upName']] = upName;
  //   this.clearRight();
  //   _sh.each(this.mainForm[0]['columns'], oneColumn => {
  //     oneColumn['model'] = oneItem[oneColumn['column']];
  //   });
  // }

  // beforeDrop(arg: NzFormatBeforeDropEvent): Observable<boolean> {
  //   let children = _sh.get(arg,['dragNode','children'],null);
  //   if (!_sh.isEmpty(children)) {
  //     return of(false);
  //   } else {
  //     return of(true);
  //   }
  // }

}
