import {Component, Input, OnInit} from '@angular/core';
import {UtilsService} from "../../../service/utils.service";
import {DataCenterService} from "../../../service/data-center.service";
import {ConfirmModalService} from "../../../service/confirm-modal.service";
import {JumpCenterService} from "../../../service/jump-center.service";
import {RequestDataService} from "../../../service/request-data.service";

import * as _sh from 'lodash';

@Component({
  selector: 'app-descriptions-edit-lable',
  templateUrl: './descriptions-edit-lable.component.html',
  styleUrls: ['./descriptions-edit-lable.component.scss']
})
export class DescriptionsEditLableComponent implements OnInit {

  @Input() oneColumn: any;
  @Input() oneData:any;
  @Input() aggregateListData: any;
  @Input() sqlFileListData: any;

  constructor(private utils: UtilsService,
              private dataCenterService: DataCenterService,
              private confirmModal: ConfirmModalService,
              private jumpCenterService: JumpCenterService,
              private requestService: RequestDataService) { }

  ngOnInit() {
  }

  doJump(pOneColumn, pData) {
    pData['checked'] = true;
    let simulationParentData = {
      data: {
        data: [pData]
      }
    }
    let readOnlyData = {
      'sqlFileListData': _sh.cloneDeep(this.sqlFileListData),
      'aggregateListData': _sh.cloneDeep(this.aggregateListData)
    }
    this.jumpCenterService.execJumpPage(pOneColumn['jumpConfig'], simulationParentData, null, readOnlyData);
  }

}
