import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {NzModalService} from "ng-zorro-antd/modal";
import {UtilsService} from "../../../service/utils.service";
import {RequestDataService} from "../../../service/request-data.service";
import {forkJoin as observableForkJoin} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {AjaxService} from "../../../service/ajax.service";
import moment from "moment";
import {AssetsJumpServiceService} from "../assets-jump-service.service";
import * as _sh from "lodash";

moment.locale('zh-cn');

@Component({
    selector: 'app-asset-control-map',
    templateUrl: './asset-control-map.component.html',
    styleUrls: ['./asset-control-map.component.scss']
})
export class AssetControlMapComponent implements OnInit {

    @Input() parentData: any;
    @Input() modeType = 'add';

    public searchText;

    constructor(private el: ElementRef,
                private assetsJumpServiceService: AssetsJumpServiceService,
                private activatedRoute: ActivatedRoute,
                private http: HttpClient,
                private modalService: NzModalService,
                private ajaxService: AjaxService,
                private requestService: RequestDataService,
                private utils: UtilsService,
    ) {

    }

    topTenAsset: any = [];
    rentAsset: any = [];
    piaAsset: any = [];
    buildingData: any = {};

    buildingId = "151257953c484338a0cbe9870cc03170";  // 原59厂离退办办公室
    sumData: any = {}; // 总资产面积，数量
    sumLeaseData: any = {};
    groupByLeaseState: any = [];
    groupByType: any = [];
    selectFloorList: any = []; // 用于Select框，所有的楼层都要查询出来
    buildingFloorList: any = []; // 楼层数据
    roomList: any = []; // 房间列表
    selectFloor = null; // 选中的楼层。
    selectFloorDict = "未选中";

    public isfull: any = false;

    public chartAreaHeight: any = '203px';




    doSearchFloor() {
        let condition = {
            'UP_AS_ID': this.buildingId
        };
        if (this.utils.Base_HasValue(this.selectFloor)) {
            condition['AS_FLOOR'] = this.selectFloor
        }
        if (this.utils.Base_HasValue(this.searchText)) {
            condition['AS_NAME'] = this.searchText
        }
        const search = observableForkJoin(
            this.requestService.getDataByCondition('assets/map/assets_room_list.json', condition), //0  资产地图-房间列表
            this.requestService.getDataByCondition('assets/map/assets_building_floor.json', condition),
        );
        search.subscribe(
            values => {
                this.roomList = _sh.get(values, [0, 'data'], []); // 某一层楼的数据。
                this.buildingFloorList = _sh.get(values, [1, 'data'], []); // 建筑物楼层数据
                _sh.each(this.roomList, oneRoom => {
                    if (oneRoom['LEASE_STATE'] == 'LEASED') {
                        //oneRoom['showText'] = "到期：" + (oneRoom['END_DATE'] ? moment(oneRoom['START_DATE']).format('YYYY-MM-DD') : '');
                        let date1 = moment().locale('zh-cn').format('YYYY-MM-DD');
                        let date2 = moment(oneRoom['START_DATE']).format('YYYY-MM-DD');
                        oneRoom['showText'] = "已租时间：" + (oneRoom['START_DATE'] ? moment(date1).diff(date2,'days') : '') + "天";//已租时间
                    }
                    if (oneRoom['LEASE_STATE'] == 'NOT_LEASED') {
                        if (this.utils.Base_HasValue(oneRoom['END_DATE'])) {
                            oneRoom['showText'] = "已闲置：" + (oneRoom['END_DATE'] ? moment(oneRoom['END_DATE']).fromNow(true) : '');

                        } else {
                            oneRoom['showText'] =  "已闲置：" + (oneRoom['END_DATE'] ? moment(oneRoom['END_DATE']).fromNow(true) : '长期');
                        }
                    }
                    if (oneRoom['UNPAID_STATUS'] == 'UNPAID') {
                        oneRoom['showText'] = "已欠缴：" + (oneRoom['ARAP_DATE'] ? moment(oneRoom['ARAP_DATE']).fromNow(true) : '');
                    }
                })
            }
        );
    }

    doSearch() {
        const search = observableForkJoin(
            this.requestService.getDataByCondition('assets/map/assets_city_area_lease.json', {'UP_AS_ID': this.buildingId}), //0 , 按照类型，查询各种类型的面积
            this.requestService.getDataByCondition('assets/map/assets_city_area_type.json', {'UP_AS_ID': this.buildingId}), //1  查询资源类型分析
            this.requestService.getDataByCondition('assets/map/assets_building_floor.json', {'UP_AS_ID': this.buildingId}), //2  资产地图-建筑物楼层
            this.requestService.getDataByCondition('assets/map/assets_top_building.json', {'ID': this.buildingId}) // 3  资产地图-楼宇的数据
        );
        search.subscribe(
            values => {
                this.sumLeaseData = _sh.get(values, [0, 'data'], {}); // 页面上面的汇总数据
                this.groupByLeaseState = _sh.get(values, [0, 'data'], []); // 按照状态的分组数据
                this.groupByType = _sh.get(values, [1, 'data'], []); // 按照类型的分组数据
                this.selectFloorList = _sh.get(values, [2, 'data'], []);
                this.buildingData = _sh.get(values, [3, 'data', 0], {});
                this.doSearchFloor();

                if (this.utils.Base_HasValue(this.sumLeaseData)) {
                    this.sumData['AREA'] = _sh.sumBy(this.sumLeaseData, 'AREA');
                    this.sumData['ACCOUNT'] = _sh.sumBy(this.sumLeaseData, 'ACCOUNT');
                    let leaseData = _sh.get(_sh.filter(this.sumLeaseData, {'LEASE_STATE': 'LEASED'}), [0]);
                    if (this.utils.Base_HasValue(leaseData)) {
                        this.sumData['LEASE_ACCOUNT'] = leaseData['ACCOUNT']; // 已出租数量
                        this.sumData['LEASE_AREA'] = leaseData['AREA']; // 已出租面积
                        if (this.sumData['AREA'] > 0) {
                            _sh.set(this.sumData, ['AREA_PERCENTAGE'], _sh.round(_sh.divide(_sh.multiply(leaseData['AREA'], 100), this.sumData['AREA']), 2));
                        }

                        if (this.sumData['ACCOUNT'] > 0) {
                            _sh.set(this.sumData, ['ACCOUNT_PERCENTAGE'], _sh.round(_sh.divide(_sh.multiply(leaseData['ACCOUNT'], 100), this.sumData['ACCOUNT']), 2));
                        }
                    } else {
                        this.sumData['LEASE_ACCOUNT'] = 0;
                        this.sumData['LEASE_AREA'] = 0;
                        this.sumData['ACCOUNT_PERCENTAGE'] = 0;
                        this.sumData['AREA_PERCENTAGE'] = 0;
                    }
                } else {
                    this.sumData['ACCOUNT'] = 0;
                    this.sumData['AREA'] = 0;
                    this.sumData['LEASE_ACCOUNT'] = 0;
                    this.sumData['LEASE_AREA'] = 0;
                    this.sumData['ACCOUNT_PERCENTAGE'] = 0;
                    this.sumData['AREA_PERCENTAGE'] = 0;
                }
                this.rentAsset = this.setRentAsset();
                this.piaAsset = this.setPiaAsset();
            }
        );
    }

    ngOnInit() {
        this.buildingId = this.activatedRoute.snapshot.params['ID'];
        this.doSearch(); // 查询数据。
    }

    setRentAsset() {
        let leased = _sh.get(_sh.filter(this.groupByLeaseState, {'LEASE_STATE': 'LEASED'}), [0, 'AREA'], 0);
        let notLeased = _sh.get(_sh.filter(this.groupByLeaseState, {'LEASE_STATE': 'NOT_LEASED'}), [0, 'AREA'], 0);
        let nonLeasable = _sh.get(_sh.filter(this.groupByLeaseState, {'LEASE_STATE': 'NON_LEASABLE'}), [0, 'AREA'], 0);
        const option = {
            grid: {
                top: '4%',
                bottom: '2%',
                left: '2%',
                right: '2%'
            },
            tooltip: {
                trigger: 'item',
                formatter: '{b} : {c} ({d}%)'
            },
            legend: {
                x: 'center',
                y: 'bottom',
                data: [
                    {
                        name: '空置中',
                        textStyle: {
                            fontSize: 12,
                            fontWeight: 'bolder',
                            color: '#333'
                        },
                        icon: 'circle'
                    },
                    {
                        name: '已租赁',
                        textStyle: {
                            fontSize: 12,
                            fontWeight: 'bolder',
                            color: '#333'
                        },
                        icon: 'circle'
                    },
                    {
                        name: '不可租',
                        textStyle: {
                            fontSize: 12,
                            fontWeight: 'bolder',
                            color: '#333'
                        },
                        icon: 'circle'
                    }
                ]
            },
            series: [
                {
                    name: '租控比例',
                    type: 'pie',
                    radius: ['25%', '50%'],
                    roseType: 'radius',
                    label: {
                        normal: {
                            show: true,
                            formatter: '{b}\n{c}({d}%)'
                        },
                        emphasis: {
                            show: true
                        }
                    },
                    lableLine: {
                        normal: {
                            show: false
                        },
                        emphasis: {
                            show: true
                        }
                    },
                    data: [
                        {
                            value: notLeased,
                            name: '空置中', itemStyle: {
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 1,
                                    y2: 1,
                                    colorStops: [
                                        {
                                            offset: 0.2, color: '#0bb2f2' // 0% 处的颜色
                                        },
                                        {
                                            offset: 1, color: '#293bf5' // 100% 处的颜色
                                        }],
                                    globalCoord: true // 缺省为 false
                                }
                            }
                        },
                        {
                            value: leased,
                            name: '已租赁', itemStyle: {
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 1,
                                    y2: 1,
                                    colorStops: [
                                        {
                                            offset: 0.2, color: '#ffba63' // 0% 处的颜色
                                        },
                                        {
                                            offset: 1, color: '#ff8400' // 100% 处的颜色
                                        }],
                                    globalCoord: true // 缺省为 false
                                }
                            }
                        },
                        {
                            value: nonLeasable,
                            name: '不可租', itemStyle: {
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 1,
                                    y2: 1,
                                    colorStops: [
                                        {
                                            offset: 0.2, color: '#6ff7ff' // 0% 处的颜色
                                        },
                                        {
                                            offset: 1, color: '#00e3ff' // 100% 处的颜色
                                        }],
                                    globalCoord: true // 缺省为 false
                                }
                            }
                        }
                    ]
                }
            ]
        };
        return option;
    }

    getColor(index) {
        let findIndex = index;
        let colorList = [
            {
                'samllColor': '#0bb2f2',
                'bigColor': '#293bf5'
            },
            {
                'samllColor': '#ffba63',
                'bigColor': '#ff8400'
            },
            {
                'samllColor': '#6ff7ff',
                'bigColor': '#00e3ff'
            }
        ];
        let count = colorList.length;
        if (index <= count) {
            findIndex = index;
        } else {
            let mod = count % index;
            findIndex = mod;
        }
        return colorList[findIndex];
    }

    setPiaAsset() {
        let titleList = [];
        _sh.each(this.groupByType, oneType => {
            let obj = {
                name: oneType['AS_TYPE_LV02_NAME'],
                textStyle: {
                    fontSize: 12,
                    fontWeight: 'bolder',
                    color: '#333'
                },
                icon: 'circle'
            }
            titleList.push(obj);
        });
        let numList = [];
        let dataIndex = 1;
        _sh.each(this.groupByType, oneType => {
            let colorSelect = this.getColor(dataIndex);
            let obj = {
                value: oneType['AREA'], name: oneType['AS_TYPE_LV02_NAME'], itemStyle: {
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 1,
                        y2: 1,
                        colorStops: [
                            {
                                offset: 0.2, color: colorSelect['samllColor'] // 0% 处的颜色
                            },
                            {
                                offset: 1, color: colorSelect['bigColor'] // 100% 处的颜色
                            }],
                        globalCoord: true // 缺省为 false
                    }
                }
            }
            numList.push(obj);
            dataIndex++;
        });


        const option = {
            grid: {
                top: '4%',
                bottom: '2%',
                left: '2%',
                right: '2%'
            },
            tooltip: {
                trigger: 'item',
                formatter: '{b} : {c} ({d}%)'
            },
            legend: {
                x: 'center',
                y: 'bottom',
                data: titleList
            },
            series: [
                {
                    name: '租控比例',
                    type: 'pie',
                    radius: ['25%', '50%'],
                    roseType: 'radius',
                    label: {
                        normal: {
                            show: true,
                            formatter: '{b}\n{c}({d}%)'
                        },
                        emphasis: {
                            show: true
                        }
                    },
                    lableLine: {
                        normal: {
                            show: false
                        },
                        emphasis: {
                            show: true
                        }
                    },
                    data: numList
                }
            ]
        };
        return option;
    }

    doJumpToAsset(oneRoom: any) {
        let assetId = _sh.get(oneRoom,['ID']);
        let assetName = _sh.get(oneRoom,['AS_NAME'])
        this.assetsJumpServiceService.doJumpToAsset(assetId,assetName,"90FEEB1C832C019EFDD5131253DB2A40");
    }

    doJumpToContract(oneRoom: any) {
        let conId = _sh.get(oneRoom,['CON_ID']);
        let conName = _sh.get(oneRoom,['CON_NAME'])
        this.assetsJumpServiceService.doJumpToContract(conId,conName);
    }
}
