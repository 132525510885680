import {Component, OnInit, Input, ViewChild} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {UEditorComponent} from 'ngx-ueditor';
import {NzModalService} from "ng-zorro-antd";
import {FormModalComponent} from "../form-modal/form-modal.component";
import {UtilsService} from "../../service/utils.service";
import * as _sh from 'lodash';

declare const UE: any;

@Component({
  selector: 'app-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.css']
})
export class RichTextEditorComponent implements OnInit {


  @ViewChild('full', {static: false}) full: UEditorComponent;
  @Input() hulkForm: FormGroup;
  @Input() hulkColumn: any;


  constructor(
    private modalService: NzModalService,
    private util: UtilsService) {
  }

  _ready(event) {
    setInterval(() => {
      if (event.Instance) {
        event.Instance.setHeight(400);
      }
    }, 10);
    if (this.hulkColumn.disable) {
      event.Instance.setDisabled('fullscreen');
    }
  }

  uploadPicture() {
    const modal = this.modalService.create({
      nzTitle: '上传图片',
      nzContent: FormModalComponent,
      nzWidth: 800,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        modalFormData: {
          'columns': [
            {
              'column': 'PIC01_EMPTY',
              'name': 'PIC01_EMPTY_NAME',
              'component': 'picture',
              'lable': '图片',
              'comment': '图片',
              'class': 'col-lg-4 col-sm-6 col-xs-12',
              'placeholder': '请选择图片',
              'model': null,
              'visible': true,
              'require': true
            }
          ]
        }
      },
      nzOnOk: (response) => {
        const data = response.modalFormData.columns;
        const fileUrl = _sh.get(data,'0.model');
        if (this.util.Base_HasValue(fileUrl)) {
          this.full.Instance.execCommand('insertimage', {
            src: fileUrl
          });
        }
      }
    });
  }


  onPreReady(comp: UEditorComponent) {
    UE.registerUI('autoclearstyle', function(editor, uiName) {
      //注册按钮执行时的command命令，使用命令默认就会带有回退操作
      editor.registerCommand(uiName, {
        execCommand: function() { }
      });

      //创建一个button
      var btn = new UE.ui.Button({
        //按钮的名字
        name: "autoclearstyle",
        //提示
        title: "自动生成样式",
        //添加额外样式，指定icon图标，这里默认使用一个重复的icon
        cssRules: 'background-position: -200px 0;',
        //点击时执行的命令
        onclick: function() {
          editor.execCommand('autotypeset');
          editor.execCommand('selectall');//选中所有
          editor.execCommand('removeformat');//清除样式
          editor.execCommand( 'unlink');//清除链接
          editor.execCommand('fontfamily','微软雅黑');
          editor.execCommand('rowspacing',15,'top');//上间距
          editor.execCommand('rowspacing',25,'bottom');//下间距
          editor.execCommand('lineheight',2);
          editor.execCommand( 'indent' );//首行缩进
        }
      });
      //当点到编辑内容上时，按钮要做的状态反射
      editor.addListener('selectionchange', function() {
        var state = editor.queryCommandState(uiName);
        if (state == -1) {
          btn.setDisabled(true);
          btn.setChecked(false);
        } else {
          btn.setDisabled(false);
          btn.setChecked(state);
        }
      });
      //因为你是添加button,所以需要返回这个button
      return btn;
    }, 5, comp.id);

    // comp.id 是指当前UEditor实例Id
  }

  ngOnInit() {
  }

}
