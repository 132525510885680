import { Injectable } from '@angular/core';
import mitt from 'mitt';

@Injectable({
  providedIn: 'root'
})
export class MittService {

  public emitter = mitt();
  constructor() {

    // this.emitter.on(`StdListForm-0${activeTabBar + 1}`, that.execMitt);
  }
}
