import {Injectable} from '@angular/core';
import {AppConfigService} from "../../service/app-config.service";
import {DataCenterService} from "../../service/data-center.service";
import {UtilsService} from "../../service/utils.service";
import {RequestDataService} from "../../service/request-data.service";
import {AjaxService} from "../../service/ajax.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {UserInfoService} from "../../service/user-info.service";
import {JumpCenterService} from "../../service/jump-center.service";
import {ConfirmModalService} from "../../service/confirm-modal.service";
import {forkJoin as observableForkJoin} from "rxjs";
import * as _sh from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class AssetsJumpServiceService {

    constructor(private appConfigService: AppConfigService,
                private dataCenterService: DataCenterService,
                private utils: UtilsService,
                private requestService: RequestDataService,
                private ajaxService: AjaxService,
                private notification: NzNotificationService,
                private userInfoService: UserInfoService,
                private jumpCenterService: JumpCenterService,
                private confirmModal: ConfirmModalService) {
    }

    /**
     * 用资产id去找数据，并且跳转到某一个菜单，并且填充数据。
     * @param assetId 资产id
     * @param menuId 菜单id
     */
    doJumpToAsset(assetId, title, menuId) {
        let that = this;
        let id = assetId + menuId;
        let tabIndex = _sh.findIndex(this.dataCenterService._dataObject.list, (item) => {
            return item['parentDataId'] === id;
        });
        if (tabIndex > 0) {
            this.dataCenterService._dataObject.index = tabIndex;
            this.dataCenterService._dataObject.nowMenu = id;
            this.dataCenterService.refreshDataCenterInStorage();
        } else {
            const searchAll = observableForkJoin(
                that.requestService.getDataByCondition(that.appConfigService.config.sql_path + '/menu/system_app_list.json', {'LIST_ID': menuId}),
                that.requestService.getDataByCondition(that.appConfigService.config.sql_path + '/menu/menu_name.json', {'MENU_ID': menuId})
            );
            searchAll.subscribe(
                values => {
                    let nowMenu = _sh.get(values, [1, 'data', 0], {});
                    const searchId = _sh.get(values, [0, 'data', 0, 'SEARCH_ID']);
                    that.dataCenterService.clearData();
                    that.dataCenterService.newTab({
                        id: id, // 主键
                        parentDataId: id, // 唯一标识
                        nowMenu: nowMenu,
                        searchId: searchId,
                        title: title, // 菜单名称 用户tab展示
                        formType: 'readOnlyForm',
                        formClass: 'readOnlyForm',
                        data: {}, // 查询返回数据
                        detailCondition: {
                            'ID': assetId // 传入主键
                        }
                    });
                    window.open('**','_blank');
                });

        }
    }

    doJumpToContract(conId, title) {
        let that = this;
        const searchAll = observableForkJoin(
            that.requestService.getDataByCondition('assets/con_con/con_contracts_con_main_type.json', {'ID': conId})
        );
        searchAll.subscribe(
            values => {
                let menuList = {
                    "RENT": "D70E379D26E94E7EE737E95B7424389A",
                    "OTHER_RENT": "30DEDDF2E635710CDDBE789ABE3CD726",
                    "OPEN": "119A028633C4CC9B090BAA18B8A27798",
                    "OTHER": "9763231D9F3F304476E6A5AAE5656F36"
                }
                let menuId = menuList[_sh.get(values, [0, 'data',0,'CON_MAIN_TYPE'])];
                let id = conId + menuId;
                let tabIndex = _sh.findIndex(this.dataCenterService._dataObject.list, (item) => {
                    return item['parentDataId'] === id;
                });

                if (tabIndex > 0) {
                    this.dataCenterService._dataObject.index = tabIndex;
                    this.dataCenterService._dataObject.nowMenu = id;
                    this.dataCenterService.refreshDataCenterInStorage();
                } else {
                    const searchAll = observableForkJoin(
                        that.requestService.getDataByCondition(that.appConfigService.config.sql_path + '/menu/system_app_list.json', {'LIST_ID': menuId}),
                        that.requestService.getDataByCondition(that.appConfigService.config.sql_path + '/menu/menu_name.json', {'MENU_ID': menuId})
                    );
                    searchAll.subscribe(
                        values => {
                            let nowMenu = _sh.get(values, [1, 'data', 0], {});
                            const searchId = _sh.get(values, [0, 'data', 0, 'SEARCH_ID']);
                            that.dataCenterService.clearData();
                            that.dataCenterService.newTab({
                                id: id, // 主键
                                parentDataId: id, // 唯一标识
                                nowMenu: nowMenu,
                                searchId: searchId,
                                title: title, // 菜单名称 用户tab展示
                                formType: 'readOnlyForm',
                                formClass: 'readOnlyForm',
                                data: {}, // 查询返回数据
                                detailCondition: {
                                    'ID': conId // 传入主键
                                }
                            });
                            window.open('**','_blank');
                        });
                }

            });


    }


}
