///<reference path="../../node_modules/@angular/http/src/http_module.d.ts"/>
import {BrowserModule, Title} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PathLocationStrategy, LocationStrategy} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {NgZorroAntdModule, NZ_I18N, zh_CN} from 'ng-zorro-antd';
import {registerLocaleData} from '@angular/common';
import zh from '@angular/common/locales/zh';
import {BaiduMapModule} from 'angular2-baidu-map';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {EditorModule} from '@tinymce/tinymce-angular';
import {LoginComponent} from './components/login/login.component';
import {MenusComponent} from './components/menus/menus.component';
import {TabsComponent} from './components/tabs/tabs.component';
import {IndexComponent} from './components/index/index.component';
import {ListFormComponent} from './basicForm/list-form/list-form.component';
import {DetailFormComponent} from './basicForm/detail-form/detail-form.component';
import {ReadOnlyFormComponent} from './basicForm/read-only-form/read-only-form.component';
import {CustomFormComponent} from './basicForm/custom-form/custom-form.component';
import {InputComponent} from './basicComponent/input/input.component';
import {SelectComponent} from './basicComponent/select/select.component';
import {DateComponent} from './basicComponent/date/date.component';

import {CheckLoginGuard} from './guard/check-login.guard';
import * as localForage from 'localforage';
import {NgxEchartsModule} from 'ngx-echarts';
import {UEditorModule} from 'ngx-ueditor';
import {InputTrimModule} from 'ng2-trim-directive';
import {CommonFilterPipe} from './filter/common-filter.pipe';
import {FuncFilterPipe} from './filter/func-filter.pipe';
import {FormComponent} from './basicComponent/form/form.component';
import {ParsonalCenterComponent} from './components/parsonal-center/parsonal-center.component';
import {TextareaComponent} from './basicComponent/textarea/textarea.component';
import {InputMultiComponent} from './basicComponent/input-multi/input-multi.component';
import {SelectMultiComponent} from './basicComponent/select-multi/select-multi.component';
import {RichTextEditorComponent} from './basicComponent/rich-text-editor/rich-text-editor.component';
import {PoplistComponent} from './basicComponent/poplist/poplist.component';
import {MenuBtnComponent} from './components/menu-btn/menu-btn.component';
import {MultiPoplistComponent} from './basicComponent/multi-poplist/multi-poplist.component';
import {UploadComponent} from './basicComponent/upload/upload.component';
import {GroupFilterPipe} from './filter/group-filter.pipe';
import {ValidateFormComponent} from './basicComponent/validate-form/validate-form.component';
import {RoleMenuComponent} from './components/role-menu/role-menu.component';
import {UserRoleComponent} from './components/user-role/user-role.component';
import {MultiPopupJsonComponent} from './basicComponent/multi-popup-json/multi-popup-json.component';
import {RoleCustComponent} from './components/role-cust/role-cust.component';
import {DateRangeComponent} from './basicComponent/date-range/date-range.component';
import {SettingColumnsComponent} from './components/setting-columns/setting-columns.component';
import {FormModalComponent} from './basicComponent/form-modal/form-modal.component';
import {ImportComponent} from './basicComponent/import/import.component';
import {TextComponent} from './basicComponent/text/text.component';
import {TimeComponent} from './basicComponent/time/time.component';
import {DateMonthsComponent} from './basicComponent/date-months/date-months.component';
import {DsMultiPoplistComponent} from './basicComponent/ds-multi-poplist/ds-multi-poplist.component';
import {AutoJsonComponent} from './basicForm/auto-json/auto-json.component';
import {TableModalComponent} from './basicComponent/table-modal/table-modal.component';
import {ColumnGroupComponent} from './basicComponent/column-group/column-group.component';
import {SelectJsonComponent} from './basicComponent/select-json/select-json.component';
import {DateWeekComponent} from './basicComponent/date-week/date-week.component';
import {RateComponent} from './basicComponent/rate/rate.component';
import {HrComponent} from './basicComponent/hr/hr.component';
import {YearComponent} from './basicComponent/year/year.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {OverlayModule} from '@angular/cdk/overlay';
import {OnePageReportComponent} from './basicForm/one-page-report/one-page-report.component';
import {PopMapComponent} from './basicComponent/pop-map/pop-map.component';
import {TinymceComponent} from './basicComponent/tinymce/tinymce.component';
import {CascaderComponent} from './basicComponent/cascader/cascader.component';
import {ArrayFilterPipe} from './filter/array-filter.pipe';
import {DescriptionFormComponent} from './basicForm/description-form/description-form.component';
import {SimpleUploadComponent} from './basicComponent/simple-upload/simple-upload.component';
import {RoleFormComponent} from './basicForm/role-form/role-form.component';
import {WorkbenchFormComponent} from './basicForm/workbench-form/workbench-form.component';
import {TreeFormComponent} from './basicForm/tree-form/tree-form.component';
import {WordTemplateComponent} from './basicForm/word-template/word-template.component';
import {ClipboardModule} from 'ngx-clipboard';
import {AssetsContractComponent} from './project/assets/assets-contract/assets-contract.component';
import {WorkflowBranchComponent} from './basicForm/workflow-management/workflow-branch/workflow-branch.component';
import {WorkflowAddBtnComponent} from './basicForm/workflow-management/workflow-add-btn/workflow-add-btn.component';
import {
    WorkflowCheckManComponent
} from './basicForm/workflow-management/workflow-check-man/workflow-check-man.component';
import {WorkflowCopyManComponent} from './basicForm/workflow-management/workflow-copy-man/workflow-copy-man.component';
import {WorkflowStartComponent} from './basicForm/workflow-management/workflow-start/workflow-start.component';
import {
    WorkflowConditionComponent
} from './basicForm/workflow-management/workflow-condition/workflow-condition.component';
import {TreeSelectComponent} from './basicComponent/tree-select/tree-select.component';
import {AssetMapComponent} from './project/assets/asset-map/asset-map.component';
import {AssetControlMapComponent} from './project/assets/asset-control-map/asset-control-map.component';
import {AssetDashboardOneComponent} from './project/assets/asset-dashboard-one/asset-dashboard-one.component';
import {HdbsPipePipe} from './pipe/hdbs-pipe.pipe';
import {AssetMainMapComponent} from './project/assets/asset-main-map/asset-main-map.component';
import {WorkflowManagementComponent} from './basicForm/workflow-management/workflow-management.component';
import {PopAmapComponent} from './basicComponent/pop-amap/pop-amap.component';
import {TodoCenterComponent} from './basicForm/todo-center/todo-center.component';
import {RadioComponent} from './basicComponent/radio/radio.component';
import {DescriptionsComponent} from './basicComponent/descriptions/descriptions.component';
import {DrawerBtnComponent} from './basicForm/drawer-btn/drawer-btn.component';
import {DashboardFormComponent} from './basicForm/dashboard-form/dashboard-form.component';
import {ImagePreviewModuleComponent} from './basicForm/image-preview-module/image-preview-module.component';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import {PrintResultComponent} from './basicForm/print-result/print-result.component';
import {OtherBuContractComponent} from './project/assets/other-bu-contract/other-bu-contract.component';
import {OpenContractComponent} from './project/assets/open-contract/open-contract.component';
import {OtherRentContractComponent} from './project/assets/other-rent-contract/other-rent-contract.component';
import {HelpDocumentComponent} from './basicForm/help-document/help-document.component';
import {DescriptionsFileComponent} from './basicComponent/descriptions/descriptions-file/descriptions-file.component';
import {DescriptionsTimelineComponent} from './basicComponent/descriptions/descriptions-timeline/descriptions-timeline.component';
import {HulkConfigDetailComponent} from './hulkConifg/hulk-config-detail/hulk-config-detail.component';
import {
    HulkConfigPoplistComponentComponent
} from './hulkConifg/hulk-config-poplist-component/hulk-config-poplist-component.component';
import {
    HulkConfigPoplistInsertvalueComponent
} from './hulkConifg/hulk-config-poplist-insertvalue/hulk-config-poplist-insertvalue.component';
import {
    HulkConfigPoplistModelComponent
} from './hulkConifg/hulk-config-poplist-model/hulk-config-poplist-model.component';
import {
    HulkConfigSelectDisableComponent
} from './hulkConifg/hulk-config-select-disable/hulk-config-select-disable.component';
import {
    HulkConfigPoplistTablenameComponent
} from './hulkConifg/hulk-config-poplist-tablename/hulk-config-poplist-tablename.component';
import {HulkConfigSelectComponent} from './hulkConifg/hulk-config-select/hulk-config-select.component';
import {HulkConfigWidthComponent} from './hulkConifg/hulk-config-width/hulk-config-width.component';
import {HulkConfigDictionaryComponent} from './hulkConifg/hulk-config-dictionary/hulk-config-dictionary.component';
import {
    HulkConfigPoplistDictionaryComponent
} from './hulkConifg/hulk-config-poplist-dictionary/hulk-config-poplist-dictionary.component';
import {HulkConfigListSqlfileComponent} from './hulkConifg/hulk-config-list-sqlfile/hulk-config-list-sqlfile.component';
import {HulkConfigDesignListComponent} from './hulkConifg/hulk-config-design-list/hulk-config-design-list.component';
import {HulkConfigInputComponent} from './hulkConifg/hulk-config-input/hulk-config-input.component';
import {
    HulkConfigDesignConditionComponent
} from './hulkConifg/hulk-config-design-condition/hulk-config-design-condition.component';
import {HulkConfigNewFileComponent} from './hulkConifg/hulk-config-new-file/hulk-config-new-file.component';
import {
    HulkConfigPoplistDictionaryInputComponent
} from './hulkConifg/hulk-config-poplist-dictionary-input/hulk-config-poplist-dictionary-input.component';
import {
    HulkConfigDesignReadonlyComponent
} from './hulkConifg/hulk-config-design-readonly/hulk-config-design-readonly.component';
import {HulkConfigBatchBtnComponent} from './hulkConifg/hulk-config-batch-btn/hulk-config-batch-btn.component';
import {
    HulkConfigDesignReadonlyFromvalueComponent
} from './hulkConifg/hulk-config-design-readonly-fromvalue/hulk-config-design-readonly-fromvalue.component';
import {HulkConfigCheckboxComponent} from './hulkConifg/hulk-config-checkbox/hulk-config-checkbox.component';
import {SettlementFormComponent} from './settlement-form/settlement-form.component';
import {ThousandthsFormatPipe} from './pipe/thousandths-format.pipe';
import {HulkConfigColumnsComponent} from './hulkConifg/hulk-config-columns/hulk-config-columns.component';
import {HulkConfigMsgTemplateComponent} from './hulkConifg/hulk-config-msg-template/hulk-config-msg-template.component';
import { NgxQRCodeModule } from 'ngx-qrcode2';
import { HulkConfigWfMoreInfoComponent } from './hulkConifg/hulk-config-wf-more-info/hulk-config-wf-more-info.component';
import { HulkConfigDatasourceCheckComponent } from './hulkConifg/hulk-config-datasource-check/hulk-config-datasource-check.component';
import { DescriptionsEditTableComponent } from './basicComponent/descriptions/descriptions-edit-table/descriptions-edit-table.component';
import { DescriptionsEditInputComponent } from './basicComponent/descriptions/descriptions-edit-input/descriptions-edit-input.component';
import { DescriptionsEditLableComponent } from './basicComponent/descriptions/descriptions-edit-lable/descriptions-edit-lable.component';
import { DescriptionsEditDateComponent } from './basicComponent/descriptions/descriptions-edit-date/descriptions-edit-date.component';
import { DescriptionsEditSelectComponent } from './basicComponent/descriptions/descriptions-edit-select/descriptions-edit-select.component';
import { DescriptionsEditMoreBtnComponent } from './basicComponent/descriptions/descriptions-edit-more-btn/descriptions-edit-more-btn.component';
import { DescriptionsEditTableInnerComponent } from './basicComponent/descriptions/descriptions-edit-table-inner/descriptions-edit-table-inner.component';
import { PaperDesignComponent } from './basicForm/paper-design/paper-design.component';
import { AssetControlMapListComponent } from './project/assets/asset-control-map-list/asset-control-map-list.component';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { HulkConfigRadioComponent } from './hulkConifg/hulk-config-radio/hulk-config-radio.component';
import { HulkConfigTextareaComponent } from './hulkConifg/hulk-config-textarea/hulk-config-textarea.component';
import { PoplistEditComponent } from './basicComponent/poplist-edit/poplist-edit.component';
import { DescriptionsGroupRowComponent } from './basicComponent/descriptions/descriptions-group-row/descriptions-group-row.component';
import { DescriptionsEditRowComponent } from './basicComponent/descriptions/descriptions-edit-row/descriptions-edit-row.component';
import { PlanFromComponent } from './project/assets/plan-from/plan-from.component';
import { CutStringPipe } from './pipe/cut-string.pipe';
import { ImportFormComponent } from './basicForm/import-form/import-form.component';
import { PaperDetailComponent } from './project/assets/paper-detail/paper-detail.component';
import { DescriptionsTableComponent } from './basicComponent/descriptions/descriptions-table/descriptions-table.component';
import { SpecialExportComponent } from './basicComponent/special-export/special-export.component';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { MenuFilterPipe } from './filter/menu-filter.pipe';
import { WhLogContractComponent } from './project/assets/wh-log-contract/wh-log-contract.component';
import { FormFilesComponent } from './basicComponent/form-files/form-files.component';
registerLocaleData(zh);

localForage.config({
    'driver': localForage.INDEXEDDB, // Force WebSQL; same as using setDriver()
    'name': 'antDesign',
    'version': 1.0,
    'size': 4980736,
    'description': 'ant design data center'
});

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        TabsComponent,
        ListFormComponent,
        DetailFormComponent,
        ReadOnlyFormComponent,
        CustomFormComponent,
        InputComponent,
        SelectComponent,
        DateComponent,
        IndexComponent,
        CommonFilterPipe,
        FuncFilterPipe,
        FormComponent,
        ParsonalCenterComponent,
        WorkflowManagementComponent,
        PoplistComponent,
        TextareaComponent,
        InputMultiComponent,
        SelectMultiComponent,
        RichTextEditorComponent,
        MenuBtnComponent,
        MultiPoplistComponent,
        UploadComponent,
        GroupFilterPipe,
        ValidateFormComponent,
        UserRoleComponent,
        MultiPopupJsonComponent,
        RoleMenuComponent,
        RoleCustComponent,
        DateRangeComponent,
        SettingColumnsComponent,
        FormModalComponent,
        ImportComponent,
        TextComponent,
        TimeComponent,
        DateMonthsComponent,
        DsMultiPoplistComponent,
        AutoJsonComponent,
        TableModalComponent,
        ColumnGroupComponent,
        SelectJsonComponent,
        DateWeekComponent,
        RateComponent,
        HrComponent,
        YearComponent,
        OnePageReportComponent,
        PopMapComponent,
        TinymceComponent,
        CascaderComponent,
        ArrayFilterPipe,
        DescriptionFormComponent,
        SimpleUploadComponent,
        RoleFormComponent,
        WorkbenchFormComponent,
        TreeFormComponent,
        WordTemplateComponent,
        AssetsContractComponent,
        WorkflowBranchComponent,
        WorkflowAddBtnComponent,
        WorkflowCheckManComponent,
        WorkflowCopyManComponent,
        WorkflowStartComponent,
        WorkflowConditionComponent,
        TreeSelectComponent,
        AssetMapComponent,
        AssetControlMapComponent,
        AssetDashboardOneComponent,
        HdbsPipePipe,
        AssetMainMapComponent,
        PopAmapComponent,
        TodoCenterComponent,
        RadioComponent,
        DescriptionsComponent,
        DrawerBtnComponent,
        DashboardFormComponent,
        ImagePreviewModuleComponent,
        PrintResultComponent,
        OtherBuContractComponent,
        OpenContractComponent,
        OtherRentContractComponent,
        HelpDocumentComponent,
        DescriptionsFileComponent,
        DescriptionsTimelineComponent,
        HulkConfigDetailComponent,
        HulkConfigPoplistComponentComponent,
        HulkConfigPoplistInsertvalueComponent,
        HulkConfigPoplistModelComponent,
        HulkConfigSelectDisableComponent,
        HulkConfigPoplistTablenameComponent,
        HulkConfigSelectComponent,
        HulkConfigWidthComponent,
        HulkConfigDictionaryComponent,
        HulkConfigPoplistDictionaryComponent,
        HulkConfigListSqlfileComponent,
        HulkConfigDesignListComponent,
        HulkConfigInputComponent,
        HulkConfigDesignConditionComponent,
        HulkConfigNewFileComponent,
        HulkConfigPoplistDictionaryInputComponent,
        HulkConfigDesignReadonlyComponent,
        HulkConfigBatchBtnComponent,
        HulkConfigDesignReadonlyFromvalueComponent,
        SettlementFormComponent,
        HulkConfigCheckboxComponent,
        ThousandthsFormatPipe,
        HulkConfigColumnsComponent,
        HulkConfigMsgTemplateComponent,
        HulkConfigWfMoreInfoComponent,
        HulkConfigDatasourceCheckComponent,
        DescriptionsEditTableComponent,
        DescriptionsEditTableComponent,
        DescriptionsEditInputComponent,
        DescriptionsEditLableComponent,
        DescriptionsEditDateComponent,
        DescriptionsEditSelectComponent,
        DescriptionsEditMoreBtnComponent,
        DescriptionsEditTableInnerComponent,
        PaperDesignComponent,
        HulkConfigRadioComponent,
        HulkConfigTextareaComponent,
        AssetControlMapListComponent,
        PoplistEditComponent,
        DescriptionsGroupRowComponent,
        DescriptionsEditRowComponent,
        PlanFromComponent,
        CutStringPipe,
        ImportFormComponent,
        PaperDetailComponent,
        DescriptionsTableComponent,
        SpecialExportComponent,
        MenusComponent,
        MenuFilterPipe,
        WhLogContractComponent,
        FormFilesComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        NgZorroAntdModule,
        InputTrimModule,
        NgxEchartsModule,
        DragDropModule,
        OverlayModule,
        EditorModule,
        ClipboardModule,
        NgxExtendedPdfViewerModule,
        NgxQRCodeModule,
        NzMenuModule,
        BackButtonDisableModule.forRoot(),
        BaiduMapModule.forRoot({ak: 'Cbn7pXN2kX4u0lcpChRnU5bk7h1WgfrF'}),
        UEditorModule.forRoot({
                js: [
                    `./assets/ueditor/ueditor.config.js`,
                    `./assets/ueditor/ueditor.all.js`,
                ],
                // 默认前端配置项
                options: {
                    UEDITOR_HOME_URL: './assets/ueditor/'
                }
            }
        )
    ],
    providers: [
        {provide: NZ_I18N, useValue: zh_CN}, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }, CheckLoginGuard,TodoCenterComponent,ReadOnlyFormComponent],
    bootstrap: [AppComponent],
    entryComponents: [
        SettingColumnsComponent,
        FormModalComponent,
        ImportComponent,
        TableModalComponent,
        ColumnGroupComponent
    ]
})
export class AppModule {
    constructor(private title: Title) {
        const projectData = require('./../environments/environment.json');
        this.title.setTitle(projectData['title']);
    }
}
