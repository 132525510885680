import {Component, Input, OnInit} from '@angular/core';
import {AutoInsertService} from '../../../service/auto-insert.service';
import {StorageService} from '../../../service/storage.service';
import {UserInfoService} from '../../../service/user-info.service';
import {UploadService} from '../../../service/upload.service';
import {HttpClient} from '@angular/common/http';
import {DataCenterService} from '../../../service/data-center.service';
import {RequestDataService} from '../../../service/request-data.service';
import {UtilsService} from '../../../service/utils.service';
import {AppConfigService} from '../../../service/app-config.service';
import {ConfirmModalService} from '../../../service/confirm-modal.service';
import * as _sh from 'lodash';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ValidatorService} from '../../../service/validator.service';
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";

@Component({
  selector: 'app-workflow-check-man',
  templateUrl: './workflow-check-man.component.html',
  styleUrls: ['./workflow-check-man.component.scss']
})
export class WorkflowCheckManComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private validatorService: ValidatorService,
    private autoInsert: AutoInsertService,
    private storage: StorageService,
    private userInfo: UserInfoService,
    private uploadService: UploadService,
    private httpClient: HttpClient,
    private dataCenterService: DataCenterService,
    private userInfoService: UserInfoService,
    private autoService: AutoInsertService,
    private requestDataService: RequestDataService,
    private util: UtilsService,
    private config: AppConfigService,
    private confirmModal: ConfirmModalService
  ) {
  }

  @Input() workflowData: any;
  @Input() parentNode: any;
  @Input() oneNode: any;
  @Input() oneOption: any;

  conditionformData = null;
  postData = null;
  userData = null;

  selectedType = null;
  selectedTypeName = null;

  selectedPost = null;
  selectedPostName = null;

  selectedUser = null;
  selectedUserName = null;

  conditionAllChecked = false;
  conditionIndeterminate = false;
  conditionModelVisible = false; // 条件之中的模态框显示
  conditionModelAllChecked = false;
  conditionModelIndeterminate = false;
  conditionModelData: any = {
    'data': [],
    'showColumns': {}
  };

  conditionModelCancel() {
    this.conditionModelVisible = false;
  }


  conditionModelOk() {
    if (!this.checkInput()) {
      let obj = {};
      if (this.selectedType == 'POST') {
        obj = {
          'type': this.selectedType,
          'typeName': this.selectedTypeName,
          'checkMan': this.selectedPost,
          'checkManName': this.selectedPostName
        }
      } else if (this.selectedType == 'USER') {
        obj = {
          'type': this.selectedType,
          'typeName': this.selectedTypeName,
          'checkMan': this.selectedUser,
          'checkManName': this.selectedUserName
        }
      }
      this.oneNode['data'].push(obj);
      this.conditionModelVisible = false;
    } else {
      this.conditionModelVisible = false;
    }
  }

  addItem() {
    this.conditionformData = {
      'tablename': 'left',
      'formname': 'left',
      'title': 'left',
      'type': 'normal',
      'add_link': null,
      'columns': [
        {
          'column': 'CO_TYPE',
          'name': 'CO_TYPE_NAME',
          'component': 'select',
          'lableSpan': 4,
          'itemSpan': 20,
          'customize': 24,
          'lable': '类型',
          'comment': '类型',
          'placeholder': '请选择类型',
          'model': null,
          'visible': true,
          'require': true,
          'dictionary': 'workflow/dict/WORKFLOW_CHECK_TYPE.json',
          'dictionaryPF': null,
          'columnGroup': [
            {
              'column': 'CO_TYPE_NAME',
              'name': 'CO_TYPE_NAME_NAME',
              'component': 'input',
              'lable': '一级分类',
              'comment': '一级分类',
              'placeholder': '请输入一级分类',
              'fromValue': ['name'],
              'model': null,
              'visible': false,
              'require': false,
              'disable': [
                'add',
                'modify'
              ]
            }
          ]

        }
      ]
    }
    this.postData = {
      'tablename': 'post',
      'formname': 'post',
      'title': 'post',
      'type': 'normal',
      'add_link': null,
      'columns': [
        {
          'column': 'ORG_ID',
          'name': 'ORG_ID_NAME',
          'component': 'poplist',
          'lableSpan': 4,
          'itemSpan': 20,
          'customize': 24,
          'lable': '岗位',
          'comment': '岗位',
          'placeholder': '请选择岗位',
          'model': null,
          'visible': true,
          'require': true,
          'dictionary': 'workflow/info_post_poplist.json',
          'dictionaryPF': null,
          'columnGroup': [
            {
              'column': 'ORG_ID',
              'name': 'ORG_ID_NAME',
              'component': 'input',
              'lable': '一级分类',
              'comment': '一级分类',
              'placeholder': '请输入一级分类',
              'fromValue': ['ORG_ID'],
              'model': null,
              'visible': false,
              'require': false,
              'disable': [
                'add',
                'modify'
              ]
            }
          ]
        }
      ]
    }
    this.userData = {
      'tablename': 'post',
      'formname': 'post',
      'title': 'post',
      'type': 'normal',
      'add_link': null,
      'columns': [
        {
          'column': 'USER_ID',
          'name': 'USER_ID_NAME',
          'component': 'poplist',
          'lableSpan': 4,
          'itemSpan': 20,
          'customize': 24,
          'lable': '审批人',
          'comment': '审批人',
          'placeholder': '请选择审批人',
          'model': null,
          'visible': true,
          'require': true,
          'dictionary': 'workflow/system_user_poplist.json',
          'dictionaryPF': null,
          'columnGroup': [
            {
              'column': 'USER_NAME',
              'name': 'USER_NAME_NAME',
              'component': 'input',
              'lable': '审批人',
              'comment': '审批人',
              'placeholder': '请输入审批人',
              'fromValue': ['name'],
              'model': null,
              'visible': false,
              'require': false,
              'disable': [
                'add',
                'modify'
              ]
            }
          ]
        }
      ]
    }
    this.conditionModelVisible = true;
  }

  delItem(){
    const checkedList = _sh.filter(this.oneNode['data'], (item) => {
      return item['checked'];
    });
    if (checkedList.length) {
      this.confirmModal.show('confirm', {
        title: '温馨提示',
        content: '是否要删除该数据吗？',
        suc: () => {
          this.oneNode['data'] = this.oneNode['data'].filter((item) => {
            return !item.checked;
          });
          this.conditionRefreshStatus();
        }
      });
    } else {
      this.confirmModal.show('warning', {
        title: '温馨提示',
        content: '请至少选择一条数据！'
      });
    }
  }


  drop(small_, event: CdkDragDrop<string[]>): void {
    moveItemInArray(small_, event.previousIndex, event.currentIndex);
  }

  conditionCheckAll(value: boolean): void {
    this.oneNode['data'].forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.conditionRefreshStatus();
  }

  conditionRefreshStatus(): void {
    const validData = this.oneNode['data'].filter(value => !value.disabled);
    const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
    const allUnChecked = validData.every(value => !value.checked);
    this.conditionAllChecked = allChecked;
    this.conditionIndeterminate = (!allChecked) && (!allUnChecked);
  }

  checkManVisble = false; // 审批人显示
  style = {
    display: 'block',
    height: '30px',
    lineHeight: '30px'
  };

  checkManOpen(): void {
    this.checkManVisble = true;
  }

  checkManClose(): void {
    this.checkManVisble = false;
  }

  ngOnInit() {
    this.oneNode['showCLose'] = 'actions-hide'
  }

  delNode(pOneNode) {
    this.confirmModal.show('confirm', {
      title: '是否删除这个审批项',
      suc: () => {
        if (this.util.Base_HasValue(this.oneOption)) {
          this.oneOption['nodeList'] = _sh.without(this.oneOption['nodeList'], pOneNode);
        } else {
          this.workflowData['nodeList'] = _sh.without(this.workflowData['nodeList'], pOneNode);
        }
      }
    });
  }

  doShowClose() {
    this.oneNode['showCLose'] = 'actions-show';
  }

  doHideClose() {
    this.oneNode['showCLose'] = 'actions-hide'
  }


  checkInput() {
    this.execMove();
    let require = false;
    if ((!this.util.Base_HasValue(this.selectedType)) && (!require)) {
      this.confirmModal.show('error', {title: '请选择类型。'});
    }
    if (this.util.Base_HasValue(this.selectedType)) {
      if (this.selectedType == 'POST') {
        if ((!this.util.Base_HasValue(this.selectedPost)) && (!require)) {
          this.confirmModal.show('error', {title: '请选择岗位。'});
        }
      } else if (this.selectedType == 'USER') {
        if ((!this.util.Base_HasValue(this.selectedUser)) && (!require)) {
          this.confirmModal.show('error', {title: '请选择人员。'});
        }
      }
    }
    return require;
  }

  execMove() {
    this.selectedType = _sh.get(this.conditionformData, ['columns', 0, 'model']);
    this.selectedTypeName = _sh.get(this.conditionformData, ['columns', 0, 'columnGroup',0,'model']);

    this.selectedPost = _sh.get(this.postData, ['columns', 0, 'model']);
    this.selectedPostName = _sh.get(this.postData, ['columns', 0, 'modelName']);

    this.selectedUser = _sh.get(this.userData, ['columns', 0, 'model']);
    this.selectedUserName = _sh.get(this.userData, ['columns', 0, 'modelName']);
  }


}
