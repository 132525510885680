import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-hulk-config-input',
    templateUrl: './hulk-config-input.component.html',
    styleUrls: ['./hulk-config-input.component.scss']
})
export class HulkConfigInputComponent implements OnInit {

    @Input() oneColumn:any;
    @Input() attributes: any;
    @Input() label: any;

    constructor() {
    }

    ngOnInit() {
    }

    removeValue() {
        this.oneColumn[this.attributes] = null;
    }
}
