import {Component, OnInit, Input} from '@angular/core';
import {
    FormGroup
} from '@angular/forms';
import {UploadXHRArgs} from 'ng-zorro-antd';
import {UploadService} from './../../service/upload.service';
import {UtilsService} from './../../service/utils.service';
import {AppConfigService} from './../../service/app-config.service';

@Component({
    selector: 'app-upload',
    templateUrl: './upload.component.html',
    styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {

    @Input() hulkForm: FormGroup;
    @Input() hulkColumn: any;

    private fileList: any = [];


    constructor(private uploadService: UploadService,
                private utils: UtilsService,
                private config: AppConfigService
    ) {
    }


    // @ts-ignore
    fileListRemove = (file): boolean => {
        this.hulkColumn.model = null;
        return true;
    }

    customReq = (item: UploadXHRArgs) => {
        if (this.config.config['userOssOrCos'] === 'COS') {
            return this.uploadService.doCosUpload(item.file, item['progress'], this.hulkColumn.folder, this.hulkColumn.uploadPath, null).then((result) => {
                item.onSuccess({}, item.file, {});
                this.hulkColumn.model = this.config.config['COS']['domain'] + '/' + result['url'];
            });
        } else {
            return this.uploadService.doAliOssUpload(item.file, item['progress'], this.hulkColumn.folder).then((result) => {
                item.onSuccess({}, item.file, {});
                this.hulkColumn.model = this.config.config['OSS']['domain'] + '/' + result.name;
            });
        }
    };

    changeFileList = (result) => {
        if (result.type === 'success' && result.fileList.length === 2) {
            // 只传一个文件
            result.fileList.splice(0, 1);
        }
    };

    ngOnInit() {
        if (this.utils.Base_HasValue(this.hulkColumn.model)) {
            let fileName = this.utils.Base_getFileNameOnly(this.hulkColumn.model);
            this.hulkColumn['fileList'] = [];
            this.hulkColumn['fileList'].push({
                uid: 1,
                name: fileName,
                status: 'done',
                url: this.hulkColumn.model
            });
        }
    }

}
