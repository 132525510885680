import {Component, Input, OnInit} from '@angular/core';
import {UtilsService} from "../../../service/utils.service";
import {DataCenterService} from "../../../service/data-center.service";
import {ConfirmModalService} from "../../../service/confirm-modal.service";
import {JumpCenterService} from "../../../service/jump-center.service";
import {RequestDataService} from "../../../service/request-data.service";

import * as _sh from 'lodash';

@Component({
    selector: 'app-descriptions-edit-select',
    templateUrl: './descriptions-edit-select.component.html',
    styleUrls: ['./descriptions-edit-select.component.scss']
})
export class DescriptionsEditSelectComponent implements OnInit {

    @Input() oneTab: any;
    @Input() oneGroup: any;
    @Input() oneColumn: any;
    @Input() oneData: any;
    @Input() aggregateListData: any;
    @Input() sqlFileListData: any;
    @Input() execButton: any;

    constructor(private utils: UtilsService,
                private dataCenterService: DataCenterService,
                private confirmModal: ConfirmModalService,
                private jumpCenterService: JumpCenterService,
                private requestService: RequestDataService) {
    }

    ngOnInit() {

    }

    removeValue() {

    }

    modelChange(model) {
        // if (this.utils.Base_HasValue(model)) {
        //     this.oneData[this.oneColumn['column']] = model;
        // }
    }
}
