import {Component, OnInit} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import {AjaxService} from '../../service/ajax.service';
import {AppConfigService} from '../../service/app-config.service';
import {UtilsService} from '../../service/utils.service';
import {UserInfoService} from '../../service/user-info.service';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {StorageService} from '../../service/storage.service';
import {DataCenterService} from '../../service/data-center.service';
import {Md5} from 'ts-md5';

import * as _sh from 'lodash';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  validateForm: FormGroup;
  public hasError: any;
  private errmsg: any;
  private tilp: any;
  private hidden: any;
  public userName: any;
  public password: any;
  public companyInfo = this.appConfigService.config.companyInfo;

  public config: any = {};
  sysTitle = '';
  loginLogo = '';
  versionInfo = this.appConfigService.version;

  submitForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
  }

  constructor(private fb: FormBuilder,
              private ajaxService: AjaxService,
              public appConfigService: AppConfigService,
              private util: UtilsService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private storage: StorageService,
              private dataCenterService: DataCenterService,
              private userInfo: UserInfoService) {
  }

  emitErr(pMsg) {
    this.hasError = true;
    this.errmsg = pMsg;
    this.tilp = pMsg;
    this.hidden = {
      'visibility': 'visible'
    };
  }

  emitSysErr(pMsg?) {
    this.emitErr('系统维护中，请联系管理员。' + pMsg);
  }

  emitPswErr() {
    this.emitErr('用户名或密码错误，请重新输入。');
  }

  emitUserErr() {
    this.emitErr('该用户不存在，请重新输入。');
  }

  addTodoCenter() {
    let useTodoCenter = _sh.get(this.appConfigService.config,['useTodoCenter'],false);
    if (useTodoCenter) {
      let tabIndex = _sh.findIndex(this.dataCenterService._dataObject.list, (item) => {
        return item['id'] === "todoCenterForm";
      });
      if (tabIndex > 0) {
        this.dataCenterService._dataObject.index = tabIndex;
        this.dataCenterService.refreshDataCenterInStorage();
      } else {
        this.dataCenterService.newTab({
          id: "todoCenterForm", // 唯一标识
          parentDataId: "todoCenterForm", // 唯一标识
          nowMenu: "todoCenterForm", // 菜单ID 用于详情返回
          title: "待办列表", // 菜单名称 用户tab展示
          formType: 'todoCenterForm', // 菜单类型 用于tab过滤显示模板
          custormFormType: 'app-todo-center',
          data: {}, // 查询返回数据
        });
      }
    }
  }


  /*登录*/
  DoLoginCheck() {
    if (!this.util.Base_HasValue(this.userName) || !this.util.Base_HasValue(this.password)) {
      return;
    }
    this.hasError = false;
    let params = {
      'mongoPF': this.appConfigService.config.mongoPF,
      'PF': this.appConfigService.config.MysqlPF,
      'sqlfile': this.appConfigService.config.login_count,
      'userId': this.userName,
      'userName': this.userName
    };
    this.ajaxService.ajaxPost(this.appConfigService.getServer().NodeMongoSql, params).subscribe(response => {
      if (!this.util.Base_resHasErr(response)) {
        this.emitSysErr();
      } else {
        if (response['data'][0].acount === 0) {
          this.emitUserErr();
        } else {
          let params = {
            'mongoPF': this.appConfigService.config.mongoPF,
            'PF': this.appConfigService.config.MysqlPF,
            'sqlfile': this.appConfigService.config.login_check,
            'userId': this.userName,
            'userName': this.userName,
            'conditions': {
              'PASSWD': Md5.hashStr(this.password)
            }
          };
          this.ajaxService.ajaxPost(this.appConfigService.getServer().NodeMongoSql, params).subscribe(response => {
            if (!this.util.Base_resHasErr(response)) {
              this.emitSysErr();
            } else {
              if (response['data'].length === 0) {
                this.emitPswErr();
              } else {
                this.userInfo.setUserInfo(response['data'][0]);
                this.router.navigate(['/index']);
                this.addTodoCenter();
              }
            }
          }, error => {
            this.emitSysErr();
          });
        }
      }
    }, error => {
      this.emitSysErr();
    });
  }

  uncompileStr(code) {
    code = unescape(code);
    let c = String.fromCharCode(code.charCodeAt(0) - code.length);
    for (let i = 1; i < code.length; i++) {
      c += String.fromCharCode(code.charCodeAt(i) - c.charCodeAt(i - 1));
    }
    return c;
  }

  ngOnInit(): void {
    this.config = this.appConfigService.config.logoConfig;
    if (this.util.Base_HasValue(this.config['sysTitle'])) {
      this.sysTitle = this.config['sysTitle'];
    }
    if (this.util.Base_HasValue(this.config['loginLogo'])) {
      this.loginLogo = this.config['loginLogo'];
    }
    this.validateForm = this.fb.group({
      userName: [null, [Validators.required]],
      password: [null, [Validators.required]],
      remember: [true]
    });
  }
}
