import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ConfirmModalService} from './confirm-modal.service';
import {LoadingService} from './loading.service';
import {Observable} from 'rxjs';
import 'rxjs/Rx';
import * as $ from 'jquery';
import * as _sh from "lodash";



@Injectable({
  providedIn: 'root'
})
export class AjaxService {

  constructor(private httpClient: HttpClient,
              private confirmModal: ConfirmModalService,
              private loading: LoadingService) {
  }

  private serializeData(data) {
    if (!_sh.isObject(data)) {
      return ((data == null) ? '' : data.toString());
    }
    let buffer = [];
    for (let name in data) {
      if (!data.hasOwnProperty(name)) {
        continue;
      }
      let value = data[name];
      if (_sh.isObject(value)) {
        value = JSON.stringify(value);
      }
      buffer.push(encodeURIComponent(name) + '=' + encodeURIComponent((value == null) ? '' : value));
    }
    const source = buffer.join('&').replace(/%20/g, '+');
    return (source);
  };

  ajaxGet(url) {
    this.loading.loading();
    const observable = Observable.create((observer) => {
      this.httpClient.get(url, {observe: 'response'}).subscribe((result) => {
        if (result) {
          this.loading.hide();
          observer.next(result);
        }
        observer.complete();
      }, (error) => {
        this.loading.hide();
        if (error.status === 500) {
          observer.next(error);
        } else {
          this.confirmModal.show('error', {
            title: '温馨提示',
            content: '系统错误，请检查您的网络设置！'
          });
        }
        observer.complete();
      });
    });
    return observable;
  }

  ajaxPost(url, body, hideLoading?) {
    if (hideLoading) {
      this.loading.loading();
    }
    const observable = Observable.create((observer) => {
      this.httpClient.post(url, $.param(body), {
        headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'})
      }).subscribe((result) => {
        if (result) {
          this.loading.hide();
          observer.next(result);
        }
        observer.complete();
      }, (error) => {
        this.loading.hide();
        if (error.status === 500) {
          observer.next(error);
        } else {
          this.confirmModal.show('error', {
            title: '温馨提示',
            content: '系统错误，请检查您的网络设置！'
          });
          observer.next(error);
        }
        observer.complete();
      });
    });
    return observable;
  }

  ajaxPostToJava(url, body, hideLoading?) {
    if (hideLoading) {
      this.loading.loading();
    }
    const observable = Observable.create((observer) => {
      this.httpClient.post(url,body, {
        headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'})
      }).subscribe((result) => {
        if (result) {
          this.loading.hide();
          observer.next(result);
        }
        observer.complete();
      }, (error) => {
        this.loading.hide();
        if (error.status === 500) {
          observer.next(error);
        } else {
          this.confirmModal.show('error', {
            title: '温馨提示',
            content: '系统错误，请检查您的网络设置！'
          });
          observer.next(error);
        }
        observer.complete();
      });
    });
    return observable;
  }


  ajaxUpload(url, body,hideLoading) {
    if (hideLoading) {
      this.loading.loading();
    }
    let formData = new FormData();
    formData.append('file', body, body.name);
    const observable = Observable.create((observer) => {
      this.httpClient.post(url, formData).subscribe((result) => {
        if (result) {
          this.loading.hide();
          observer.next(result);
        }
        observer.complete();
      }, (error) => {
        this.loading.hide();
        if (error.status === 500) {
          observer.next(error);
        } else {
          this.confirmModal.show('error', {
            title: '温馨提示',
            content: '系统错误，请检查您的网络设置！'
          });
        }
        observer.complete();
      });
    });
    return observable;
  }

  ajaxPostJson(url, body, hideLoading?) {
    if (hideLoading) {
      this.loading.loading();
    }
    const observable = Observable.create((observer) => {
      this.httpClient.post(url, body, {
        headers: new HttpHeaders({'Content-Type': 'application/json'})
      }).subscribe((result) => {
        if (result) {
          this.loading.hide();
          observer.next(result);
        }
        observer.complete();
      }, (error) => {
        this.loading.hide();
        if (error.status === 500) {
          observer.next(error);
        } else {
          this.confirmModal.show('error', {
            title: '温馨提示',
            content: '系统错误，请检查您的网络设置！'
          });
        }
        observer.complete();
      });
    });
    return observable;
  }
}
