import {Component, Input, OnInit} from '@angular/core';
import {UtilsService} from "../../../service/utils.service";
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ConfirmModalService} from "../../../service/confirm-modal.service";
import {LoadingService} from "../../../service/loading.service";
import {Observable} from "rxjs";

@Component({
    selector: 'app-descriptions-file',
    templateUrl: './descriptions-file.component.html',
    styleUrls: ['./descriptions-file.component.scss']
})
export class DescriptionsFileComponent implements OnInit {

    fileName: any;
    fileType: any;
    @Input() url: any;
    @Input() hideFileName: Boolean = true;

    constructor(
        private httpClient: HttpClient,
        private utils: UtilsService
    ) {
    }

    ngOnInit() {
        if (this.utils.Base_HasValue(this.url)) {
            this.fileType = this.utils.Base_getFileType(this.url);
            this.fileName = this.utils.Base_getFileNameOnly(this.url);
        }
    }

    // 点击下载
    download(src,fileName): void {
        if (['jpg','jpeg','png','svg','eps','gif','tiff','psd','raw','bmp'].indexOf(this.fileType) >= 0) {
            window.location.href = src;
        } else {
            this.httpClient.get(src, {responseType: 'blob'}).subscribe(blob => {
                const a = document.createElement('a')
                const objectUrl = URL.createObjectURL(blob)
                a.href = objectUrl
                a.download = fileName;
                a.click();
                URL.revokeObjectURL(objectUrl);
            })
        }
    }

}
