import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {UploadXHRArgs} from "ng-zorro-antd";
import {UploadService} from "../../service/upload.service";
import {AppConfigService} from "../../service/app-config.service";
import {UtilsService} from "../../service/utils.service";

@Component({
  selector: 'app-simple-upload',
  templateUrl: './simple-upload.component.html',
  styleUrls: ['./simple-upload.component.css']
})
export class SimpleUploadComponent implements OnChanges {
  @Input() fileUrl: any;
  @Output() setModel = new EventEmitter(); //准备用它来发射事件

  fileList: [];

  constructor(
    private uploadService: UploadService,
    private utils: UtilsService,
    private config: AppConfigService
  ) {
  }

  ngOnInit() {
    if ((this.utils.Base_HasValue(this.fileUrl)) && (!this.utils.Base_HasValue(this.fileList))) {
      this.fileList = []
      const obj = {
        'uid': 1,
        'name': '文件',
        'status': 'done',
        'url': this.fileUrl
      }
      // @ts-ignore
      this.fileList.push(obj);
    }
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['fileUrl'] && changes['fileUrl'].currentValue) {
      this.fileUrl = changes['fileUrl'].currentValue;

    }
  }

  customReq = (item: UploadXHRArgs) => {
    return this.uploadService.doAliOssUpload(item.file, item['progress'], '').then((result) => {
      item.onSuccess({}, item.file, {});
      let fileName = this.config.config['OSS']['domain'] + '/' + result.name;
      this.fileUrl = fileName;
      this.setModel.emit(fileName);
    });
  }

  changeFileList = (result) => {
    if (result.type === 'success' && result.fileList.length === 2) {
      // 只传一个文件
      result.fileList.splice(0, 1);
    }
  };

  doRemove = (file: File) => {
    this.setModel.emit("doRemove");
    return true;
  };

}
