import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-hulk-config-width',
    templateUrl: './hulk-config-width.component.html',
    styleUrls: ['./hulk-config-width.component.scss']
})
export class HulkConfigWidthComponent implements OnInit {

    @Input() oneColumn: any;

    radioWidth = [
        {
            "label": "1/2",
            "value": 12,
        },
        {
            "label": "1行",
            "value": 24,
        },
    ];

    deleteWidth() {
        this.oneColumn['customize'] = null;
    }

    constructor() {
    }

    ngOnInit() {
    }


}
