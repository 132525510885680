import {Component, Input, OnInit} from '@angular/core';
import {RequestDataService} from "../../service/request-data.service";
import {AppConfigService} from "../../service/app-config.service";
import {UtilsService} from "../../service/utils.service";
import * as echarts from 'echarts';
import 'echarts/map/js/province/hubei.js';
import 'echarts/map/js/china.js';
import 'echarts/map/js/world.js';
import * as moment from "moment";
import * as screenfull from 'screenfull';
import {forkJoin as observableForkJoin} from "rxjs";
import * as _sh from 'lodash';
import * as _ from "underscore";
import {ConfirmModalService} from "../../service/confirm-modal.service";
import Swiper from 'swiper';

@Component({
    selector: 'app-dashboard-form',
    templateUrl: './dashboard-form.component.html',
    styleUrls: ['./dashboard-form.component.scss']
})
export class DashboardFormComponent implements OnInit {

    @Input() parentData: any;
    // 年度，4年
    public years: any = [];
    // 月度 ，12个月
    public month: any = [];
    // 日 ， 7 天
    public day: any = [];
    tabList = {};
    swiper: any;

    // section高度计算
    public height = [];

    // 布局定位计算
    public gridColumnLayout = [];
    public gridRowLayout = [];

    // 图表
    chartAreaHeight: any = []
    pieCharts: any = [];
    barCharts: any = [];
    lineCharts: any = [];
    mapCharts: any = [];
    yCategoryStacks: any = [];

    constructor(private requestDataService: RequestDataService,
                private appConfigService: AppConfigService,
                private confirmModal: ConfirmModalService,
                private utils: UtilsService) {
    }


    showError(oneColumn) {
        const fromValue = _sh.get(oneColumn, ['fromValue'], null);
        if (!this.utils.Base_HasValue(fromValue)) {
            const errMsg = oneColumn['lable'] + ';' + oneColumn['column'] + ';' + '没有设置：fromValue ， 请配置';
            this.confirmModal.show('error', {
                'title': '设置错误',
                'content': errMsg
            });
        }

        const fromDataCenter = _sh.get(oneColumn, ['fromDataCenter'], null);
        if (!this.utils.Base_HasValue(fromDataCenter)) {
            const errMsg = oneColumn['lable'] + ';' + oneColumn['column'] + ';' + '没有设置：fromDataCenter ， 请配置';
            this.confirmModal.show('error', {
                'title': '设置错误',
                'content': errMsg
            });
        }
    }

    getOneValue(pData, oneColumn) {
        const fromValue = _sh.get(oneColumn, ['fromValue'], null);
        let datas = []
        _sh.each(pData, pOneRow => {
            datas.push(_sh.get(pOneRow, fromValue, null));
        });
        return datas;
    }

    mergeData(oneGroupData, oneColumn, oneColumnValue) {
        let rowIndex = 0
        _sh.each(oneColumnValue, oneRow => {
            _sh.set(oneGroupData, [rowIndex, oneColumn['column']], oneRow);
            rowIndex++;
        })
        return oneGroupData;
    }

    execAggregate(pAggregateList, pData) {
        let result = {};
        _sh.each(pAggregateList, oneColumn => {
            this.showError(oneColumn);
            const fromDataCenter = _sh.get(oneColumn, ['fromDataCenter'], null);
            const aggregateFunction = _sh.get(oneColumn, ['aggregateFunction'], null);

            if (!this.utils.Base_HasValue(aggregateFunction)) {
                const errMsg = 'aggregateList 之中的，' + oneColumn['lable'] + ';' + oneColumn['column'] + ';' + '没有设置：aggregateFunction ， 请配置';
                this.confirmModal.show('error', {
                    'title': '设置错误',
                    'content': errMsg
                });
            }

            if (fromDataCenter[0] == 'sqlFileList') {
                const oneColumnValue = this.getOneValue(pData[fromDataCenter[1]], oneColumn);
                if (aggregateFunction == 'count') {
                    result[oneColumn['column']] = oneColumnValue.length;
                } else if (aggregateFunction == 'sum') {
                    result[oneColumn['column']] = _sh.round(_sh.sum(oneColumnValue), 2);
                } else if (aggregateFunction == 'min') {
                    result[oneColumn['column']] = _sh.round(_sh.min(oneColumnValue), 2);
                } else if (aggregateFunction == 'max') {
                    result[oneColumn['column']] = _sh.round(_sh.max(oneColumnValue), 2);
                } else if (aggregateFunction == 'avg') {
                    result[oneColumn['column']] = _sh.round(_sh.mean(oneColumnValue), 2);
                } else if (aggregateFunction == 'mean') {
                    result[oneColumn['column']] = _sh.round(_sh.mean(oneColumnValue), 2);
                }
            } else {
                this.confirmModal.show('error', {
                    'title': '设置错误',
                    'content': "聚合函数的值只能在 sqlFileList 里面取值"
                });
            }
        })
        return result;
    }

    getOneGroupData(pData, pAggregateListData, pOneGroup) {
        let oneGroupData: any = [];
        let columns = pOneGroup['columns'];
        const groupShowType = _sh.get(pOneGroup, ['groupShowType'], null);
        const mainData = _sh.get(pOneGroup, ['mainData'], null);
        if (!this.utils.Base_HasValue(mainData)) {
            const errMsg = pOneGroup['groupTitle'] + '; 没有设置：mainData ， 请配置';
            this.confirmModal.show('error', {
                'title': '设置错误',
                'content': errMsg
            });
        }
        _sh.each(columns, oneColumn => {
            this.showError(oneColumn);

            let mainDataLength = 0;
            if (mainData[0] == 'sqlFileList') {
                mainDataLength = pData[mainData[1]].length;
            } else if (mainData[0] == 'aggregateList') {
                mainDataLength = 1;
            }

            const fromDataCenter = _sh.get(oneColumn, ['fromDataCenter'], null);
            if (fromDataCenter[0] == 'sqlFileList') {


                let oneColumnValue = this.getOneValue(pData[fromDataCenter[1]], oneColumn);

                // 如果超过主数据的行数，删除
                if (oneColumnValue.length > mainDataLength) {
                    oneColumnValue = _sh.drop(oneColumnValue, oneColumnValue.length - mainDataLength);
                } else if (oneColumnValue.length < mainDataLength) {
                    // 如果小于主数据的行数，增加。
                    let tempArr = oneColumnValue;
                    if (mainDataLength == 0) {
                        tempArr = [null]
                    } else if (mainDataLength > 0) {
                        for (var i = 0; i < mainDataLength - oneColumnValue.length; i++) {
                            tempArr.push(oneColumnValue[0])
                        }
                    }
                    oneColumnValue = tempArr;
                }
                oneGroupData = this.mergeData(oneGroupData, oneColumn, oneColumnValue);
            } else if (fromDataCenter[0] == 'aggregateList') {
                const oneColumnValue = _sh.get(pAggregateListData, oneColumn['column']);
                let tempArr = [];
                if (mainDataLength == 0) {
                    tempArr = [null]
                } else if (mainDataLength > 0) {
                    for (var i = 0; i < mainDataLength; i++) {
                        tempArr.push(oneColumnValue)
                    }
                }
                oneGroupData = this.mergeData(oneGroupData, oneColumn, tempArr);
            }
        })
        return oneGroupData;
    }


    ngOnInit() {


        const yearData = this.getSearchYearData();
        this.years = yearData.category || [];

        const monthData = this.getSearchMonthMData();
        this.month = monthData.category || [];

        const dayData = this.getSearchDayData();
        this.day = dayData.category || [];

        if (!this.utils.Base_HasValue(this.parentData.data)) {
            const searchId = _sh.get(this.parentData, ['searchId']);
            const menuId = _sh.get(this.parentData, ['nowMenu', 'MENU_ID'], null);
            const search = observableForkJoin(
                this.requestDataService.getDataByJson(searchId),    // 0
            );
            search.subscribe(
                values => {

                    this.parentData.title = _sh.get(values, ['0', 'title'], null) ?
                        _sh.get(values, ['0', 'title'], null) : this.parentData.title;

                    const defaultIndex = _sh.get(values, [0, 'dashboardColumns', 'defaultIndex'], 0);
                    this.tabList = _sh.get(values, [0, 'dashboardColumns', 'showList', defaultIndex, 'tabList'], []);
                    let aggregateList = _sh.get(values, [0, 'dashboardColumns', 'showList', defaultIndex, 'aggregateList'], []);
                    let sqlFileList = _sh.get(values, [0, 'dashboardColumns', 'showList', defaultIndex, 'sqlFileList'], []);
                    let descriptionsRequest = [];
                    _sh.each(sqlFileList, oneSqlFile => {
                        let oneRequest = this.requestDataService.getDataByCondition(oneSqlFile)
                        descriptionsRequest.push(oneRequest);
                    })

                    const searchAll = observableForkJoin(descriptionsRequest);
                    searchAll.subscribe(
                        deValues => {
                            let dataList = [];
                            _sh.each(deValues, oneValue => {
                                const oneData = _sh.get(oneValue, ['data'], []);
                                dataList.push(oneData);
                            });
                            this.tabList['sqlFileListData'] = dataList;
                            this.tabList['aggregateListData'] = this.execAggregate(aggregateList, dataList);

                            _sh.each(this.tabList, oneTab => {

                                let groupHeight = [];
                                let groupChartHeight = [];
                                let gridLayout = [[false, false, false, false], [false, false, false, false], [false, false, false, false]];
                                let i = 1;
                                let j = 1;
                                let grid_col_start = null;
                                let grid_col_end = null;
                                let grid_row_start = null;
                                let grid_row_end = null;
                                let groupGridColumn = [];
                                let groupGridRow = [];

                                let groupPieCharts = [];
                                let groupBarCharts = [];
                                let groupLineCharts = [];
                                let groupMapCharts = [];
                                let groupYCategoryStackCharts = [];

                                _sh.each(oneTab['group'], oneGroup => {
                                    // 数据初始化
                                    const oneGroupData = this.getOneGroupData(dataList, this.tabList['aggregateListData'], oneGroup);
                                    oneGroup['groupData'] = oneGroupData;

                                    // 高度初始化
                                    let wIndex = _sh.split(_sh.split(oneGroup['class'], '_', 2)[1], 'x', 2)[1];
                                    let hIndex = _sh.split(_sh.split(oneGroup['class'], '_', 2)[1], 'x', 1)[0];
                                    if (parseInt(wIndex) > 4) {
                                        this.confirmModal.show('error', {
                                            'title': '布局设置错误',
                                            'content': "超出占位宽度"
                                        });
                                    }
                                    if (parseInt(hIndex) > 3) {
                                        this.confirmModal.show('error', {
                                            'title': '布局设置错误',
                                            'content': "超出占位高度"
                                        });
                                    }
                                    let oneHeight = hIndex ? String(parseInt(hIndex) * 22.5 + (parseInt(hIndex) - 1) * 6) + 'vh' : '22.5vh'   // section高度
                                    let oneChartHeight = oneGroup['chartConfig'] ? String(parseInt(hIndex) * 20 + (parseInt(hIndex) - 1) * 6) + 'vh' : '20vh'// canvas高度
                                    groupHeight.push(oneHeight)
                                    groupChartHeight.push(oneChartHeight)

                                    // 布局初始化
                                    // 1 计算板块占位
                                    let grid_area = this.gridLayoutInit(gridLayout, i, parseInt(wIndex), j, parseInt(hIndex))
                                    grid_col_start = grid_area['grid_col_start']
                                    grid_col_end = grid_area['grid_col_end']
                                    grid_row_start = grid_area['grid_row_start']
                                    grid_row_end = grid_area['grid_row_end']
                                    // 2 格子占位
                                    for (let n = grid_row_start; n < grid_row_end; n++) {
                                        for (let m = grid_col_start; m < grid_col_end; m++) {
                                            gridLayout[n - 1][m - 1] = true;
                                        }
                                    }
                                    // 3 准备下一轮定位点
                                    j = grid_area['j']
                                    if (grid_col_end >= 5) {
                                        i = 1;
                                        j += 1;
                                    } else {
                                        i = grid_col_end
                                    }
                                    groupGridColumn.push(String(grid_col_start) + ' / ' + String(grid_col_end))
                                    groupGridRow.push(String(grid_row_start) + ' / ' + String(grid_row_end))

                                    // 饼图初始化
                                    let onePieChart = {}
                                    if (oneGroup['groupShowType'] == 'pieCharts') {
                                        onePieChart = this.setPieChart(oneGroup['groupData'], oneGroup['columns'], oneGroup['chartConfig']);
                                    }
                                    groupPieCharts.push(onePieChart)

                                    // 条形图初始化
                                    let oneBarChart = {}
                                    if (oneGroup['groupShowType'] == 'barCharts') {
                                        oneBarChart = this.setBarChart(oneGroup['groupData'], oneGroup['columns'], oneGroup['chartConfig']);
                                    }
                                    groupBarCharts.push(oneBarChart)

                                    // 叠加图初始化
                                    let oneYCategoryStackChart = {}
                                    if (oneGroup['groupShowType'] == 'yCategoryStacks') {
                                        oneYCategoryStackChart = this.setYCategoryStackChart(oneGroup['groupData'], oneGroup['columns'], oneGroup['chartConfig']);
                                    }
                                    groupYCategoryStackCharts.push(oneYCategoryStackChart)

                                    // 折线图初始化
                                    let oneLineChart = {}
                                    if (oneGroup['groupShowType'] == 'lineCharts') {
                                        oneLineChart = this.setLineChart(oneGroup['groupData'], oneGroup['columns'], oneGroup['chartConfig']);
                                    }
                                    groupLineCharts.push(oneLineChart)

                                    // 地图初始化
                                    let oneMapChart = {}
                                    if (oneGroup['groupShowType'] == 'mapCharts') {
                                        oneMapChart = this.setMap(oneGroup['groupData'], oneGroup['columns'], oneGroup['chartConfig']);
                                    }
                                    groupMapCharts.push(oneMapChart)

                                })
                                this.height.push(groupHeight)
                                this.chartAreaHeight.push(groupChartHeight)
                                this.gridColumnLayout.push(groupGridColumn)
                                this.gridRowLayout.push(groupGridRow)
                                this.pieCharts.push(groupPieCharts)
                                this.barCharts.push(groupBarCharts)
                                this.lineCharts.push(groupLineCharts)
                                this.mapCharts.push(groupMapCharts)
                                this.yCategoryStacks.push(groupYCategoryStackCharts)

                            });
                        }
                    );
                }
            );

        }

    }

    // 布局初始化
    gridLayoutInit(gridLayout, i, wIndex, j, hIndex) {
        let grid_col_start = null;
        let grid_col_end = null;
        let grid_row_start = null;
        let grid_row_end = null;
        for (; j < 4; j++) {
            i = 1;
            for (; i < 5; i++) {
                if (!gridLayout[j - 1][i - 1]) {
                    // 1 检查大小是否有误
                    if (j + hIndex > 4) {
                        this.confirmModal.show('error', {
                            'title': '布局设置错误',
                            'content': "超出占位高度"
                        });
                        return {};
                    }
                    if (i + wIndex > 5) {
                        break;
                    }
                    // 2 检查占位有无空间
                    let sign = false;
                    for (let t1 = j; t1 < j + hIndex; t1++) {
                        for (let t2 = i; t2 < (i + wIndex >= 5 ? 5 : i + wIndex); t2++) {
                            if (gridLayout[t1 - 1][t2 - 1]) sign = true
                        }
                    }
                    if (sign) { // 已有占位，空间不足，继续找
                        continue;
                    } else {  // 暂无占位，定格子
                        grid_col_start = i
                        grid_col_end = i + wIndex
                        grid_row_start = j
                        grid_row_end = j + hIndex
                        return {
                            'grid_col_start': grid_col_start,
                            'grid_col_end': grid_col_end,
                            'grid_row_start': grid_row_start,
                            'grid_row_end': grid_row_end,
                            'j': j
                        };
                    }
                }
            }
        }
        this.confirmModal.show('error', {
            'title': '布局设置错误',
            'content': i + "," + j + "的位置容不下" + hIndex + "x" + wIndex
        });
        return {};
    }

    // 饼图初始化
    setPieChart(oneGroupData, oneGroupColumns, chartConfig) {
        let legendData = []
        let seriesDate = []
        for (let i = 0; i < oneGroupData.length; i += 1) {
            let name = oneGroupData[i][oneGroupColumns[0]['column']]
            let value = oneGroupData[i][oneGroupColumns[1]['column']]
            let colorSelect = this.getColor(i);
            let oneLegendData = {
                name: name,
                textStyle: {
                    fontSize: 12,
                    fontWeight: 'bolder',
                    color: '#000'//chartConfig && chartConfig['fontColor'] ? chartConfig['fontColor'] : '#000'
                },
                icon: 'circle'
            }
            let oneSeriesDate = {
                value: value,
                name: name,
                itemStyle: {
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 1,
                        y2: 1,
                        colorStops: [
                            {
                                offset: 0.2, color: colorSelect['samllColor'] // 0% 处的颜色
                            },
                            {
                                offset: 1, color: colorSelect['bigColor']  // 100% 处的颜色
                            }],
                        globalCoord: true // 缺省为 false
                    }
                }
            }
            seriesDate.push(oneSeriesDate)
            legendData.push(oneLegendData)
        }
        let option = {
            grid: {
                top: '5%',
                left: '5%',
                right: '5%',
                bottom: '20%',
                containLabel: true
            },
            tooltip: {
                trigger: 'item',
                formatter: '{b} : {c} ({d}%)'
            },
            legend: {
                orient: chartConfig && chartConfig['legendConfig'] ? chartConfig['legendConfig']['orient'] : 'horizontal',
                x: chartConfig && chartConfig['legendConfig'] ? chartConfig['legendConfig']['x'] : 'center',
                y: chartConfig && chartConfig['legendConfig'] ? chartConfig['legendConfig']['y'] : 'bottom',
                data: legendData
            },
            series: [
                {
                    name: '租控比例',
                    type: 'pie',
                    radius: ['25%', '50%'],
                    roseType: chartConfig && chartConfig['chartType'] ? chartConfig['chartType'] : '',
                    label: {
                        color: '#000',//chartConfig && chartConfig['fontColor'] ? chartConfig['fontColor'] : '#000',
                        formatter: '{b}\n{c}({d}%)'
                    },
                    data: seriesDate
                }
            ]
        };
        return option;
    }

    // 条形图初始化
    setBarChart(oneGroupData, oneGroupColumns, chartConfig) {
        // 数据赋值
        let category = []
        let data = []
        for (let i = 0; i < oneGroupData.length; i += 1) {
            let name = oneGroupData[i][oneGroupColumns[0]['column']]
            let value = oneGroupData[i][oneGroupColumns[1]['column']]
            category.push(name)
            data.push(value)
        }
        // 判断横向还是纵向条形图
        let xAxisType = 'category'
        let yAxisType = 'value'
        if (chartConfig && chartConfig['chartType'] && chartConfig['chartType'] == 'Horizontal') {
            xAxisType = 'value'
            yAxisType = 'category'
        }
        let xAxis = {
            type: xAxisType,
            axisTick: {
                show: false
            },
            axisLine: {
                show: true
            },
            axisLabel: {
                textStyle: {
                    fontSize: 14,
                    color: '#000'//chartConfig && chartConfig['fontColor'] ? chartConfig['fontColor'] :'#000'
                },
                formatter: '{value}'
            }
        }
        let yAxis = {
            type: yAxisType,
            axisTick: {
                show: false
            },
            axisLine: {
                show: true
            },
            axisLabel: {
                color: '#000',//chartConfig && chartConfig['fontColor'] ? chartConfig['fontColor'] : '#000',
                margin: 5,
                fontSize: 12
            },
        }
        let itemStyle = {
            normal: {
                barBorderRadius: 5
            }
        }
        if (xAxisType == 'category') {  // 纵向柱状图
            xAxis['data'] = category
            xAxis['splitLine'] = {
                show: true
            }
            yAxis['min'] = chartConfig['min'] ? chartConfig['min']:0;
            yAxis['splitNumber'] = 2
            yAxis["splitLine"] = {
                lineStyle: {
                    color: chartConfig && chartConfig['fontColor'] ? chartConfig['fontColor'] : '#000',
                    width: 1
                }
            }
            itemStyle['normal']['color'] = new echarts.graphic.LinearGradient(1, 1, 0 , 0, [{offset: 0, color: '#AED0EE'}, {offset: 1, color: '#5976C4'}])
        }
        if (yAxisType == 'category') {  // 水平柱状图
            yAxis['data'] = category
            yAxis['splitLine'] = {
                show: true
            }
            xAxis['min'] = chartConfig['min'] ? chartConfig['min']:0;
            xAxis['position'] = 'top'
            xAxis['splitNumber'] = 2
            xAxis['splitLine'] = {
                lineStyle: {
                    color: chartConfig && chartConfig['fontColor'] ? chartConfig['fontColor'] : '#000',
                    width: 1
                }
            }
            itemStyle['normal']['color'] = new echarts.graphic.LinearGradient(0, 0, 1 , 1, [{offset: 0, color: '#AED0EE'}, {offset: 1, color: '#5976C4'}])

        }
        // 配置
        let option = {
            grid: {
                top: '5%',
                left: '5%',
                right: '5%',
                bottom: '20%',
                containLabel: true
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            xAxis: xAxis,
            yAxis: yAxis,
            series: [{
                type: 'bar',
                barWidth: '20px',        // 柱子宽度
                barCategoryGap: '20%',  // 类间间隔
                data: data,
                itemStyle: itemStyle
            }]
        };
        return option;
    }

    // 堆叠图初始化
    setYCategoryStackChart(oneGroupData, oneGroupColumns, chartConfig) {
        let xAxis = []
        let yAxis = []
        let max = oneGroupData[0][oneGroupColumns[1]['column']]
        let min = 0
        for (let i = 0; i < oneGroupData.length; i += 1) {
            let y = oneGroupData[i][oneGroupColumns[0]['column']]
            let x = oneGroupData[i][oneGroupColumns[1]['column']]
            xAxis.push(x)
            yAxis.push(y)
            if (i > 0) {
                if (x > max) max = x;
                if (x < min) min = x;
            }
        }
        let xColumnList = this.utils.filterData(oneGroupColumns, 'coordinate', 'x');
        let xColumnNameList = this.utils.getArrayByPath(xColumnList, 'lable');

        let series = [];
        _sh.each(xColumnList, oneColumn => {
            let oneObj = {
                name: oneColumn['lable'],
                type: 'bar',
                //stack:'a',
                data: this.utils.getArrayByPath(oneGroupData, oneColumn['column']),
                lable: {
                    show: true,
                    position: "top",
                    distance: 7,
                    color: chartConfig && chartConfig['fontColor'] ? chartConfig['fontColor'] : '#000'
                }

            }
            series.push(oneObj);
        })
        let option = {
            grid: {
                top: '15%',
                left: '5%',
                right: '5%',
                bottom: '10%',
                containLabel: true
            },
            colorBy:"series",
            color: this.getBlueColors(),
            visualMap: {
                show: false,
                type: 'continuous',
                seriesIndex:1,
                min: min,
                max: max * 1.1,
                inRange: {
                    color: this.getBlueColors()
                }

            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            legend: {
                backgroundColor: "rgb(139,154,192)",
                data: xColumnNameList
            },
            yAxis: {
                type: 'category',
                data: yAxis,
                axisLine: {
                    lineStyle: {
                        color: '#000'//chartConfig && chartConfig['fontColor'] ? chartConfig['fontColor'] : '#000'
                    }
                },
                axisLabel: {
                    textStyle: {
                        fontSize: 14,
                        color: '#000'//chartConfig && chartConfig['fontColor'] ? chartConfig['fontColor'] : '#000'
                    },
                    formatter: '{value}'
                },
                axisTick: {
                    show: false
                },
                splitLine: {
                    show: true
                }
            },
            xAxis: {
                type: 'value',
                axisLabel: {
                    color: '#000',//chartConfig && chartConfig['fontColor'] ? chartConfig['fontColor'] : '#000',
                    margin: 5,
                    fontSize: 12
                },
                axisLine: {
                    show: true
                },
                axisTick: {
                    show: false
                },
                splitNumber: 3,
                splitLine: {
                    lineStyle: {
                        color: chartConfig && chartConfig['fontColor'] ? chartConfig['fontColor'] : '#000',
                        width: 1
                    }
                }
            },
            series: series
        };
        return option;
    }
    // 折线图初始化
    setLineChart(oneGroupData, oneGroupColumns, chartConfig) {
        let xAxis = []
        let yAxis = []
        let max = oneGroupData[0][oneGroupColumns[1]['column']]
        let min = 0
        for (let i = 0; i < oneGroupData.length; i += 1) {
            let x = oneGroupData[i][oneGroupColumns[0]['column']]
            let y = oneGroupData[i][oneGroupColumns[1]['column']]
            xAxis.push(x)
            yAxis.push(y)
            if (i > 0) {
                if (y > max) max = y;
                if (y < min) min = y;
            }
        }
        let yColumnList = this.utils.filterData(oneGroupColumns,'coordinate','y');
        let yColumnNameList = this.utils.getArrayByPath(yColumnList,'lable') ;

        let series = [];
        _sh.each(yColumnList,oneColumn=>{
            let oneObj = {
                name: oneColumn['lable'],
                type: 'line',
                smooth: true,
                data: this.utils.getArrayByPath(oneGroupData,oneColumn['column']),
                lable: {
                    show: true,
                    position: "top",
                    distance: 7,
                    color: chartConfig && chartConfig['fontColor'] ? chartConfig['fontColor'] : '#000'
                }
            }
            series.push(oneObj);
        })
        let option = {
            grid: {
                top: '15%',
                left: '5%',
                right: '5%',
                bottom: '10%',
                containLabel: true
            },
            visualMap: {
                show: false,
                type: 'continuous',
                seriesIndex: 0,
                min: min,
                max: max * 1.1,
                inRange: {
                    color: this.getBlueColors()
                }
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            legend: {
                backgroundColor: "rgba(139, 154, 192, 1)",
                data: yColumnNameList
            },
            xAxis: {
                type: 'category',
                data: xAxis,
                axisLine: {
                    lineStyle: {
                        color: '#000'//chartConfig && chartConfig['fontColor'] ? chartConfig['fontColor'] : '#000'
                    }
                },
                axisLabel: {
                    textStyle: {
                        fontSize: 14,
                        color: '#000'//chartConfig && chartConfig['fontColor'] ? chartConfig['fontColor'] : '#000'
                    },
                    formatter: '{value}'
                },
                axisTick: {
                    show: false
                },
                splitLine: {
                    show: true
                }
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    color: '#000',//chartConfig && chartConfig['fontColor'] ? chartConfig['fontColor'] : '#000',
                    margin: 5,
                    fontSize: 12
                },
                axisLine: {
                    show: true
                },
                axisTick: {
                    show: false
                },
                splitNumber: 3,
                splitLine: {
                    lineStyle: {
                        color: '#000',//chartConfig && chartConfig['fontColor'] ? chartConfig['fontColor'] : '#000',
                        width: 1
                    }
                }
            },
            series: series
        };
        return option;
    }

    // 地图初始化
    setMap(oneGroupData, oneGroupColumns, chartConfig) {
        // 配置地图
        let mapType = chartConfig && chartConfig['chartType'] ? chartConfig['chartType'] : '中国'
        let data = []
        let dataHuBei = ['武汉市', '黄冈市', '黄石市', '鄂州市', '咸宁市', '仙桃市', '孝感市', '天门市', '潜江市', '荆州市', '荆门市', '随州市', '宜昌市', '襄阳市', '十堰市', '神农架林区', '恩施土家族苗族自治州']
        let dataChina = ['北京市', '广东省', '山东省', '江苏省', '河南省', '上海市', '河北省', '浙江省', '香港特别行政区', '陕西省', '湖南省', '重庆市', '福建省', '天津市', '云南省', '四川省', '广西壮族自治区', '安徽省',
                         '海南省', '江西省', '湖北省', '山西省', '辽宁省', '台湾省', '黑龙江', '内蒙古自治区', '澳门特别行政区', '贵州省', '甘肃省', '青海省', '新疆维吾尔自治区', '西藏区', '吉林省', '宁夏回族自治区']
        switch (mapType) {
            case '湖北' :
                data = createData(dataHuBei);
                break;
            case '中国' :
                mapType = "china"
                data = createData(dataChina);
                break;
            case '世界' :
                mapType = "world"
                data = [];
                break;
            default:
                this.confirmModal.show('error',{
                    'title': '地图配置错误',
                    'content': '暂无'+mapType+'地图'
                })
        }
        function createData(locationData) {
            let data = []
            _sh.each(locationData, oneLocation => {
                let oneData = {name: oneLocation, value: null}
                _sh.each(oneGroupData, one => {
                    let name = one[oneGroupColumns[0]['column']]
                    let value = one[oneGroupColumns[1]['column']]
                    // @ts-ignore
                    if (oneLocation.indexOf(name) != -1) {
                        oneData['value'] = value
                    }
                })
                data.push(oneData)
            })
            return data;
        }

        // 计算图例最大最小值
        let min = 0
        let max = oneGroupData[0][oneGroupColumns[1]['column']]
        let sum = 0
        for (let i = 0; i < oneGroupData.length; i += 1) {
            let v = oneGroupData[i][oneGroupColumns[1]['column']]
            if (i > 0) {
                if (v > max) max = v;
                if (v < min) min = v;
            }
            sum += v;
        }

        // options 配置
        let options = {
            grid: {
                top: '5%',
                left: '5%',
                right: '1%',
                bottom: '5%',
                containLabel: true
            },
            tooltip: {
                trigger: 'item',
                formatter: function (val) {
                    return val.data.value ? `${val.data.name}：${val.data.value}` : `${val.data.name}：无`;
                }
            },
            legend: {
                orient: 'vertical',
                left: 'left',
                data: []
            },
            visualMap: {
                min: min,
                max: max,
                calculable: true,                        //是否显示拖拽用的手柄（手柄能拖拽调整选中范围）
                realtime: false,
                inRange: {
                    color: this.getBlueColors()
                },
                itemWidth: 15,
                itemHeight: 100,
                textStyle: {
                    color: '#000'//chartConfig && chartConfig['fontColor'] ? chartConfig['fontColor'] : '#000'     //图例文字颜色，大小等配置
                },
                show:true
            },
            toolbox: {
                show: true,
                left: 'right',
                top: 'top',
                feature: {
                    dataView: { readOnly: false },
                    restore: {},
                    saveAsImage: {}
                }
            },
            series: [
                {
                    type: 'map',
                    mapType: mapType,
                    roam: false,
                    zoom: 1.2,
                    label: {
                        show: true,
                        color: '#000',//chartConfig && chartConfig['fontColor'] ? chartConfig['fontColor'] : '#000',
                    },
                    emphasis: {   //鼠标浮动的时候，标注和各个地图区域的交互渲染设置
                        itemStyle: {
                            areaColor: '#E5A84B', //'#2B91B7',
                            borderColor: '#CC5D20'//'#0DC9D5',
                        }
                    },
                    itemStyle: {
                        areaColor: '#a4f3f6',
                        borderColor: '#0DC9D5',
                    },
                    data: data
                }
            ]
        }
        return options;
    }


    getBlueColors() {
        return ['#88ABDA','#82a7d9','#80a7dc','#3b67b6']
    }

    // 图表色卡选择
    getColor(index) {
        let findIndex = index;
        let colorList = [
            {
                'samllColor': '#88ABDA',
                'bigColor': '#2E59A7'
            },
            {
                'samllColor': '#ffba63',
                'bigColor': '#ff8400'
            },
            {
                'samllColor': '#6ff7ff',
                'bigColor': '#00e3ff'
            },
            {
                'samllColor': '#E7CAD3',
                'bigColor': '#CE8892'
            },
            {
                'samllColor': '#E67762',
                'bigColor': '#B93A26'
            },
            {
                'samllColor': '#D3CCD6',
                'bigColor': "#7D5284"
            }
        ];
        let count = colorList.length;
        if (index <= count) {
            findIndex = index;
        } else {
            let mod = count % index;
            findIndex = mod;
        }
        return colorList[findIndex];
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.swiper = new Swiper('.swiper-container', {
                loop: false,
                pagination: {
                    el: '.swiper-pagination',
                },
                effect: 'cube',
                speed: 1000,
                autoplay: false
            });
        }, 1000);
    }

    // 获取7天的起始日期
    getSearchDayData() {
        const today = moment().format('YYYY-MM-DD');
        let category = [];
        let data = {
            ADD_TIME_END: today
        };
        data['ADD_TIME_BEGIN'] = moment().subtract(7 - 1, 'days').format('YYYY-MM-DD');
        for (let i = 7 - 1; i >= 0; i--) {
            category.push(moment().subtract(i, 'days').format('YYYY-MM-DD'));
        }
        return {
            searchData: data,
            category: category
        };
    }

    // 获取近四年的起始日期
    getSearchMonthMData() {
        const today = moment().format('YYYY-MM');
        let years = [];
        let data = {
            ADD_TIME_END: today
        };
        data['ADD_TIME_BEGIN'] = moment().subtract(4 - 1, 'month').format('YYYY-MM');
        for (let i = 12 - 1; i >= 0; i--) {
            years.push(moment().subtract(i, 'month').format('YYYY-MM'));
        }
        return {
            searchData: data,
            category: years
        };
    }

    // 获取近四年的起始日期
    getSearchYearData() {
        const today = moment().format('YYYY');
        let years = [];
        let data = {
            ADD_TIME_END: today
        };
        data['ADD_TIME_BEGIN'] = moment().subtract(4 - 1, 'year').format('YYYY');
        for (let i = 4 - 1; i >= 0; i--) {
            years.push(moment().subtract(i, 'year').format('YYYY'));
        }
        return {
            searchData: data,
            category: years
        };
    }

    isfull: any = false;

    fullscreen() {
        const el = document.getElementById('svr');
        if (screenfull.enabled) {
            screenfull.toggle(el);
            this.isfull = true;
            screenfull.on('change', () => {
                this.isfull = screenfull.isFullscreen;
            });
        }
    }

}
