import {Component, Input, OnInit} from '@angular/core';
import {AutoInsertService} from "../../../service/auto-insert.service";
import {StorageService} from "../../../service/storage.service";
import {UserInfoService} from "../../../service/user-info.service";
import {UploadService} from "../../../service/upload.service";
import {HttpClient} from "@angular/common/http";
import {DataCenterService} from "../../../service/data-center.service";
import {RequestDataService} from "../../../service/request-data.service";
import {UtilsService} from "../../../service/utils.service";
import {AppConfigService} from "../../../service/app-config.service";
import {ConfirmModalService} from "../../../service/confirm-modal.service";
import {forkJoin as observableForkJoin} from "rxjs";
import * as _sh from 'lodash';

@Component({
  selector: 'app-workflow-condition',
  templateUrl: './workflow-condition.component.html',
  styleUrls: ['./workflow-condition.component.scss']
})
export class WorkflowConditionComponent implements OnInit {

  constructor(
    private autoInsert: AutoInsertService,
    private storage: StorageService,
    private userInfo: UserInfoService,
    private uploadService: UploadService,
    private httpClient: HttpClient,
    private dataCenterService: DataCenterService,
    private userInfoService: UserInfoService,
    private autoService: AutoInsertService,
    private requestDataService: RequestDataService,
    private util: UtilsService,
    private config: AppConfigService,
    private confirmModal: ConfirmModalService
  ) { }

  @Input() oneCondition;   // 其中的一个条件组
  @Input() selectedOption; // 选择中的选项

  ngOnInit(

  ) {
  }
  rightInput = null;
  conditionAllChecked = false;
  conditionModelDict = null;
  conditionIndeterminate = false;

  conditionModelVisible = false; // 条件之中的模态框显示
  conditionModelAllChecked = false;
  conditionModelIndeterminate = false;
  conditionModelData: any = {
    'data': [],
    'showColumns': {}
  };

  delGroup(pOneCondition) {
    this.confirmModal.show('confirm', {
      title: '是否删除整个分支选择组',
      suc: () => {
        this.selectedOption['conditionList'] = _sh.without(this.selectedOption['conditionList'],pOneCondition);
      }
    });
  }



  delCondition() {
    const checkedList = _sh.filter(this.oneCondition['data'], (item) => {
      return item['checked'];
    });
    if (checkedList.length) {
      this.confirmModal.show('confirm', {
        title: '温馨提示',
        content: '是否要删除该数据吗？',
        suc: () => {
          this.oneCondition['data'] = this.oneCondition['data'].filter((item) => {
            return !item.checked;
          });
          this.conditionRefreshStatus();
        }
      });
    } else {
      this.confirmModal.show('warning', {
        title: '温馨提示',
        content: '请至少选择一条数据！'
      });
    }
  }

  /**
   * 全选条件组
   * @param value
   */
  conditionCheckAll(value: boolean): void {
    this.oneCondition['data'].forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.conditionRefreshStatus();
  }

  conditionRefreshStatus(): void {
    const validData = this.oneCondition['data'].filter(value => !value.disabled);
    const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
    const allUnChecked = validData.every(value => !value.checked);
    this.conditionAllChecked = allChecked;
    this.conditionIndeterminate = (!allChecked) && (!allUnChecked);
  }

  /**
   * 管理弹出框的多选。
   */
  conditionModelRefreshStatus(): void {
    const validData = this.conditionModelData['data'].filter(value => !value.disabled);
    const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
    const allUnChecked = validData.every(value => !value.checked);
    this.conditionModelAllChecked = allChecked;
    this.conditionModelIndeterminate = (!allChecked) && (!allUnChecked);
  }


  conditionModelOk() {
    let requireCheck = true;
    _sh.each(this.conditionformData['columns'], oneColumn => {
      if (!this.util.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
        requireCheck = false;
      }
    });
    if (!requireCheck) {
      this.confirmModal.show('error', {title: '请填写必填信息。'});
      return false;
    }
    let conditionDatas = {};
    _sh.each(this.conditionformData['columns'], oneColumn => {
      conditionDatas[oneColumn['column']] = oneColumn['model'];
      _sh.each(oneColumn['columnGroup'], oneItem => {
        conditionDatas[oneItem['column']] = oneItem['model'];
      });
    });
    const selectList = _sh.filter(this.conditionModelData['data'], (item) => {
      return item['checked'];
    });
    let values = null;
    let names = null;
    if (this.util.Base_HasValue(this.conditionModelDict)) {
      let temp = [];
      let tempName = [];
      _sh.each(selectList,oneItem=>{
        temp.push(oneItem['code']);
        tempName.push(oneItem['name']);
      });
      values = _sh.join(temp,',');
      names = _sh.join(tempName,',');
    } else {
      values = this.util.DeepCopy(this.rightInput);
      names = values;
    }
    conditionDatas['filter_values'] = values;
    conditionDatas['filter_names'] = names;
    conditionDatas['check'] = false;
    this.oneCondition['data'].push(conditionDatas);
    this.conditionModelVisible = false;
  }
  conditionModelCancel() {
    this.conditionModelVisible = false;
  }

  getConditionDict() {
    let dict = _sh.get(this.conditionformData, ['columns', 0, 'columnGroup', 2, 'model'], null);
    if (dict != this.conditionModelDict) {
      this.conditionModelDict = dict;
      if (this.util.Base_HasValue(this.conditionModelDict)) {
        const search = observableForkJoin(
          this.requestDataService.getDataByCondition(this.conditionModelDict, {})
        );
        search.subscribe(
          values => {
            this.conditionModelData = values[0];
          }
        );
      }
    }
  }

  conditionModelCheckAll(value: boolean): void {
    this.conditionModelData['data'].forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.conditionModelRefreshStatus();
  }

  addCondition() {
    _sh.each(this.conditionformData['columns'], oneColumn => {
      oneColumn['model'] = null;
      _sh.each(oneColumn['columnGroup'], oneItem => {
        oneItem['model'] = null;
      });
    });
    this.rightInput = null;
    this.conditionModelVisible = true;
  }

  conditionformData = {
    'tablename': 'as_asset',
    'formname': 'as_asset',
    'title': '建筑物资产管理',
    'type': 'normal',
    'add_link': null,
    'columns': [
      {
        'column': 'CO_TYPE',
        'name': 'CO_TYPE_NAME',
        'component': 'cascader',
        'lableSpan': 4,
        'itemSpan': 20,
        'customize': 12,
        'lable': '条件',
        'comment': '条件',
        'placeholder': '请选择条件',
        'model': null,
        'visible': true,
        'require': true,
        'columnGroup': [
          {
            'type': 'get',
            'column': 'CO_TYPE_LV01',
            'name': 'CO_TYPE_LV01_NAME',
            'component': 'input',
            'lable': '一级分类',
            'comment': '一级分类',
            'placeholder': '请输入一级分类',
            'fromValue': 'name',
            'model': null,
            'dictionary': 'workflow/system_datasource_lv01.json',
            'dictionaryPF': null,
            'visible': false,
            'require': false,
            'disable': [
              'add',
              'modify'
            ]
          },
          {
            'type': 'get',
            'column': 'CO_TYPE_LV02',
            'name': 'CO_TYPE_LV02_NAME',
            'component': 'input',
            'lable': '二级分类',
            'comment': '二级分类',
            'placeholder': '请输入二级分类',
            'fromValue': 'name',
            'model': null,
            'lastLv': true,
            'dictionary': 'workflow/system_datasource_lv02.json',
            'dictionaryPF': null,
            'dictionaryConditionColumn': 'UP_DS_ID',
            'visible': false,
            'require': false,
            'disable': [
              'add',
              'modify'
            ]
          },
          {
            'type': 'readOnly',
            'column': 'DS_DICT',
            'name': 'DS_DICT_NAME',
            'component': 'input',
            'lable': 'DS_DICT',
            'comment': 'DS_DICT',
            'placeholder': '请输入DS_DICT',
            'fromValue': [
              1,
              'DS_DICT'
            ],
            'model': null,
            'visible': false,
            'require': false,
            'disable': [
              'add',
              'modify'
            ]
          },
          {
            "type": "system",
            "column": "CO_TYPE_NAME",
            "name": "CO_TYPE_NAME_NAME",
            "component": "input",
            "lable": "AS_TYPE_NAME",
            "comment": "AS_TYPE_NAME",
            "placeholder": "请输入AS_TYPE_NAME",
            "fromValue": "linkName",
            "model": null,
            "visible": false,
            "require": false,
            "disable": [
              "add",
              "modify"
            ]
          }
        ]
      },
      {
        'column': 'CO_PREDICATE',
        'name': 'CO_PREDICATE_NAME',
        'component': 'select',
        'lable': '判断符',
        'comment': '判断符',
        'placeholder': '请选择判断符',
        'model': null,
        'visible': true,
        'require': true,
        'dictionary': 'workflow/wf_condition.json',
        'dictionaryPF': null,
        'columnGroup': [
          {
            "column": "CO_PREDICATE_NAME",
            "name": "CO_PREDICATE_NAME_NAME",
            "component": "input",
            "lable": "CO_PREDICATE_NAME",
            "comment": "CO_PREDICATE_NAME",
            "placeholder": "请输入CO_PREDICATE_NAME",
            "fromValue": "name",
            "model": null,
            "visible": false,
            "require": false,
            "disable": [
              "add",
              "modify"
            ]
          }
        ]
      }
    ]
  }

}
