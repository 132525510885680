import {forkJoin as observableForkJoin, Observable} from 'rxjs';
import {Component, OnInit, Input} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from './../../service/app-config.service';
import {AjaxService} from './../../service/ajax.service';
import {UserInfoService} from './../../service/user-info.service';
import {DataCenterService} from './../../service/data-center.service';
import {RequestDataService} from './../../service/request-data.service';
import {StorageService} from './../../service/storage.service';
import {UtilsService} from './../../service/utils.service';

import * as _sh from 'lodash';


@Component({
  selector: 'app-menus',
  templateUrl: './menus.component.html',
  styleUrls: ['./menus.component.css']
})

export class MenusComponent implements OnInit {

  @Input() searchText: string;

  public menuList = [];
  public menus = [
    {
      level: 1,
      title: 'Mail Group',
      icon: 'mail',
      open: true,
      selected: false,
      disabled: false,
      children: [
        {
          level: 2,
          title: 'Group 1',
          icon: 'bars',
          open: false,
          selected: false,
          disabled: false,
          children: [
            {
              level: 3,
              title: 'Option 1',
              selected: false,
              disabled: false
            },
            {
              level: 3,
              title: 'Option 2',
              selected: false,
              disabled: true
            }
          ]
        },
        {
          level: 2,
          title: 'Group 2',
          icon: 'bars',
          selected: true,
          disabled: false
        },
        {
          level: 2,
          title: 'Group 3',
          icon: 'bars',
          selected: false,
          disabled: false
        }
      ]
    },
    {
      level: 1,
      title: 'Team Group',
      icon: 'team',
      open: false,
      selected: false,
      disabled: false,
      children: [
        {
          level: 2,
          title: 'User 1',
          icon: 'user',
          selected: false,
          disabled: false
        },
        {
          level: 2,
          title: 'User 2',
          icon: 'user',
          selected: false,
          disabled: false
        }
      ]
    }
  ];

  private menuAllList = [];
  private appList = [];

  constructor(private httpClient: HttpClient,
              private appConfigService: AppConfigService,
              private ajaxService: AjaxService,
              private userInfoService: UserInfoService,
              private dataCenterService: DataCenterService,
              private requestDataService: RequestDataService,
              private storage: StorageService,
              private utils: UtilsService
  ) {
  }

  newTab(item) {
    let menuUrl = _sh.get(item,'MENU_URL',null);
    if (menuUrl == 'index.outForm') {
      let menuUpdateUrl = _sh.get(item,'MENU_UPDATE_URL',null);
      window.open(menuUpdateUrl, '_blank');

    } else {
      this.dataCenterService.newTabWk(item);
    }
  }


  openHandler(value: string): void {
    _sh.each(this.menuList, (item) => {
      item.active = false;
    });
    value['active'] = true;
  }




  ngOnInit() {
    // 获取菜单。菜单json信息 并行调用并放入缓存
    const parallel$ = observableForkJoin(
        this.requestDataService.getDataByCondition(this.appConfigService.config.sql_path + '/menu/system_user_role.json', {}),
        this.requestDataService.getDataByCondition(this.appConfigService.config.app_list, {})
    );

    // // 获取菜单。菜单json信息 并行调用并放入缓存
    // const parallel$ = observableForkJoin(
    //   this.requestDataService.getDataByCondition(this.appConfigService.config.menu_list, {}),
    // );

    parallel$.subscribe(
      async values => {

        let appList = values[1]['data'] || [];
        this.appList = appList;
        this.storage.setData('appList', JSON.stringify(appList));

        let roleIdList = [];
        _sh.each(values[0]['data'] || [], oneRole => {
          roleIdList.push(oneRole['ROLE_ID']);
        })
        let menuList = await this.requestDataService.getMenuListByRole(roleIdList);
        this.storage.setData('menuList', JSON.stringify(_sh.cloneDeep(menuList)));
        this.menuAllList = _sh.cloneDeep(menuList);
        // chatgpt , 我需要一个单独处理
        this.menuList = this.requestDataService.transformMenuList(_sh.cloneDeep(this.menuAllList));

        // 是否配置登陆后跳转至某个菜单
        if (this.utils.Base_HasValue(this.appConfigService.config.defaultGotoMenu)) {
          let gotoMenu = _sh.find(this.menuAllList, (oneData) => {
            return oneData.MENU_URL === this.appConfigService.config.defaultGotoMenu;
          });
          if (this.utils.Base_HasValue(gotoMenu) && gotoMenu['MENU_LV'] === 2) {
            setTimeout(() => {
              this.newTab(gotoMenu);
            }, 200);
          }
        }
      }
    );
  }

  // 过滤菜单
  filterMenuList() {
    this.menuList = [];

    if (this.utils.Base_HasValue(this.searchText)) {
      const searchTextLower = this.searchText.toLowerCase();

      // Recursive function to filter and build the menu list
      const filterRecursive = (menu, level = 1) => {
        let isMatch = menu.MENU_NAME.toLowerCase().includes(searchTextLower);
        let filteredChildren = (menu.children || []).map(child => filterRecursive(child, level + 1)).filter(child => !!child);
        isMatch = isMatch || filteredChildren.length > 0;

        // If there's a match or any child is a match, clone the menu and include filtered children
        if (isMatch) {
          return { ...menu, children: filteredChildren };
        }

        return null; // No match at this level or below
      };

      // Apply the filter to all menus
      this.menuList = this.menuAllList.map(menu => filterRecursive(menu)).filter(menu => !!menu);

      // Remove duplicates and preserve hierarchy
      const addParents = (list, item) => {
        if (item && item.UP_ID) {
          const parent = this.menuAllList.find(menu => menu.MENU_ID === item.UP_ID);
          if (parent && !list.some(menu => menu.MENU_ID === parent.MENU_ID)) {
            list.push(parent);
            addParents(list, parent); // Recursively add parents
          }
        }
      };
      let allMenusWithParents = [...this.menuList];
      this.menuList.forEach(item => addParents(allMenusWithParents, item));
      this.menuList = this.requestDataService.transformMenuList(_sh.uniqBy(allMenusWithParents, 'MENU_ID'));
    } else {
      this.menuList = this.requestDataService.transformMenuList(_sh.cloneDeep(this.menuAllList));
    }
  }


  // 定义过滤函数
  hasSubmenu(item: any): boolean {
    return item.SUBMENU_COUNT > 0;
  }

  hasNotSubmenu(item: any): boolean {
    return item.SUBMENU_COUNT == 0;
  }

}
