import {Component, Input, OnInit} from '@angular/core';
import * as _sh from 'lodash';
import {AutoInsertService} from '../../../service/auto-insert.service';
import {StorageService} from '../../../service/storage.service';
import {UserInfoService} from '../../../service/user-info.service';
import {UploadService} from '../../../service/upload.service';
import {HttpClient} from '@angular/common/http';
import {DataCenterService} from '../../../service/data-center.service';
import {RequestDataService} from '../../../service/request-data.service';
import {UtilsService} from '../../../service/utils.service';
import {AppConfigService} from '../../../service/app-config.service';
import {ConfirmModalService} from '../../../service/confirm-modal.service';

@Component({
  selector: 'app-workflow-branch',
  templateUrl: './workflow-branch.component.html',
  styleUrls: ['./workflow-branch.component.scss']
})
export class WorkflowBranchComponent implements OnInit {

  constructor(
    private autoInsert: AutoInsertService,
    private storage: StorageService,
    private userInfo: UserInfoService,
    private uploadService: UploadService,
    private httpClient: HttpClient,
    private dataCenterService: DataCenterService,
    private userInfoService: UserInfoService,
    private autoService: AutoInsertService,
    private requestDataService: RequestDataService,
    private util: UtilsService,
    private config: AppConfigService,
    private confirmModal: ConfirmModalService
  ) {
  }

  @Input() workflowData: any;
  @Input() parentNode: any;
  @Input() oneNode: any;
  @Input() oneOption: any;

  selectedOption = {};

  addNewConditionGroup() {
    // 如果没有该属性，加这个属性。
    if (!_sh.hasIn(this.selectedOption,'conditionList')) {
      this.selectedOption['conditionList'] = [];
    }
    this.selectedOption['conditionList'].push(
      {
        "conditionId": this.util.Base_getUuid(),
        "data":[]
      }
    )
  }

  delBranch(pOneNode, pOneOption) {
    let optionNum = this.oneNode['optionList'].length;
    if (optionNum == 2) {
      this.confirmModal.show('confirm', {
        title: '是否删除整个分支选择组',
        suc: () => {
          if (_sh.get(this.parentNode, ['nodeList'], [])) {
            this.parentNode['nodeList'] = _sh.without(this.parentNode['nodeList'], pOneNode);
          }
          if (_sh.get(this.parentNode, ['optionList'], [])) {
            this.parentNode['optionList'] = _sh.without(this.parentNode['optionList'], pOneNode);
            _sh.each(this.parentNode['optionList'], tempOption => {
              tempOption['nodeList'] = _sh.without(tempOption['nodeList'], pOneNode);
            })
          }
        }
      });
    } else if (optionNum > 2) {
      this.confirmModal.show('confirm', {
        title: '是否删除这个分支',
        suc: () => {
          this.oneNode['optionList'] = _sh.without(this.oneNode['optionList'], pOneOption);
        }
      });
    }
  }

  ngOnInit() {
    _sh.each(this.oneNode['optionList'], oneOption => {
      oneOption['showCLose'] = 'actions-hide'
    })
  }

  doShowClose(pOneOption) {
    pOneOption['showCLose'] = 'actions-show';
  }

  doHideClose(pOneOption) {
    pOneOption['showCLose'] = 'actions-hide'
  }

  conditionVisible = false;

  conditionOpen(pOneOption): void {
    this.selectedOption = pOneOption;
    this.conditionVisible = true;
  }

  conditionClose(): void {
    this.conditionVisible = false;
  }

  addOption() {
    let nodeId = this.util.Base_getUuid();
    let obj = {
      'nodeId': nodeId,
      'title': '分支' + nodeId,
      'conditionList':[],
      'nodeList':[],
      'showCLose': 'actions-hide'
    }
    this.oneNode['optionList'].push(obj);
  }

}
