import {Component, Input, OnInit} from '@angular/core';
import {RequestDataService} from "../../service/request-data.service";
import {ConfirmModalService} from "../../service/confirm-modal.service";
import {DomSanitizer} from "@angular/platform-browser";
import * as _sh from "lodash";

@Component({
  selector: 'app-help-document',
  templateUrl: './help-document.component.html',
  styleUrls: ['./help-document.component.scss']
})
export class HelpDocumentComponent implements OnInit {

  @Input() parentData: any;

  constructor(
      private requestService: RequestDataService,
      private confirmModal: ConfirmModalService,
      private sanitizer: DomSanitizer
  ) { }

  public innerHtml : any;

  ngOnInit() {
    let queryData = {
      url: _sh.get(this.parentData,['inputData','helpUrl'])
    }
    this.requestService.doActionByHdbsthor('AppApi/HelpDocumentController', 'getHelpDocument', queryData, {}, false, 'hdbsthor_assets', 'appToken').subscribe(response => {
      let status = _sh.get(response, ['status'], 200);
      if (status == 200) {
        let temp01 = _sh.get(response, ['data','body_html'], '')
        this.innerHtml = this.sanitizer.bypassSecurityTrustHtml(temp01)
      } else if (status == 500) {
        this.confirmModal.show('error', {'title': '帮助文档错误', 'content': response.error.message});
      }
    });

  }


}
