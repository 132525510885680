import {Component, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {NzModalService} from "ng-zorro-antd/modal";
import {HulkConfigServiceService} from "../../service/hulk-config-service.service";
import {FileSaverService} from "ngx-filesaver";
import {RequestDataService} from "../../service/request-data.service";
import {AppConfigService} from "../../service/app-config.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {ActivatedRoute} from "@angular/router";
import {ConfirmModalService} from "../../service/confirm-modal.service";
import {ClipboardService} from "ngx-clipboard";
import {UtilsService} from "../../service/utils.service";
import * as _ from "underscore";
import {NzFormatEmitEvent} from "ng-zorro-antd";
import * as _sh from 'lodash';

@Component({
    selector: 'app-hulk-config-design-readonly',
    templateUrl: './hulk-config-design-readonly.component.html',
    styleUrls: ['./hulk-config-design-readonly.component.scss']
})
export class HulkConfigDesignReadonlyComponent implements OnInit {

    public searchId: String = null;
    public mainJson = {};
    public readOnlyColumnsJson = {};
    public mainJsonText: any = '';

    groupShowType = [
        {
            code: 'row',
            name: '普通显示'
        },
        {
            code: 'table',
            name: '表格显示'
        }
    ]

    nzLayout = [
        {
            code: 'horizontal',
            name: '水平显示'
        },
        {
            code: 'vertical',
            name: '垂直显示'
        }
    ]

    aggregateFunction = [
        {
            code: 'sum',
            name: '合计'
        },
        {
            code: 'count',
            name: '计数'
        },
        {
            code: 'avg',
            name: '平均值'
        },
        {
            code: 'max',
            name: '最大值'
        },
        {
            code: 'min',
            name: '最小值'
        }
    ]

    checkAll(loopData, value: boolean, attributes?): void {
        loopData.forEach(data => {
            if (!data.disabled) {
                // 如果是有属性
                if (this.utils.Base_HasValue(attributes)) {
                    if (data[attributes]) {
                        data.checked = value;
                    }
                } else {
                    data.checked = value;
                }
            }
        });
        this.refreshStatus(loopData);
    }

    refreshStatus(loopData): void {
        const validData = loopData.filter(value => !value.disabled);
        const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
        const allUnChecked = validData.every(value => !value.checked);
        loopData.allChecked = allChecked;
        loopData.indeterminate = (!allChecked) && (!allUnChecked);
    }

    constructor(
        private _httpClient: HttpClient,
        private modalService: NzModalService,
        private hulkconfigserviceservice: HulkConfigServiceService,
        private _FileSaverService: FileSaverService,
        private requestDataService: RequestDataService,
        public appConfigService: AppConfigService,
        private notification: NzNotificationService,
        private activatedRoute: ActivatedRoute,
        private confirmModal: ConfirmModalService,
        private clipboardService: ClipboardService,
        private utils: UtilsService
    ) {
    }

    doRefresh() {
        this.confirmModal.show('confirm', {
            title: '是否需要刷新，所有的数据将不会保存。',
            suc: () => {
                this.ngOnInit();
            }
        });
    }


    ngOnInit() {
        this.activatedRoute.queryParams.subscribe((res) => {
            this.searchId = _sh.get(res, ['searchId']);
            this.requestDataService.getDataByJson(this.searchId).subscribe(jsons => {
                this.mainJson = jsons;
                this.readOnlyColumnsJson = _sh.get(this.mainJson, ['readOnlyColumns'], {})
            });
        });

    }

    deleteSqlFile(loopData, oneColumn: any) {
        loopData = _sh.without(loopData, oneColumn);
    }

    createNewSqlFile(oneShow) {
        oneShow['sqlFileList'].push({
            index: 0,
            sqlFile: null,
            remark: "甲乙丙"
        })
    }

    createNewAggregate(oneShow: any) {
        oneShow['aggregateList'].push({
            column: "ABC",
            fromDataCenter: ["sqlFileList", 0],
            fromValue: ["BILL_AMOUNT"],
            aggregateFunction: "sum"
        })
    }

    createNewTab(oneShow: any) {
        oneShow['tabList'].push(
            {
                tabTitle: "甲乙丙",
                group: []
            }
        )
    }

    createNewGroup(oneTab: any) {
        oneTab['group'].push(
            {
                "groupTitle": "甲乙丙",
                "groupShowType": "table",
                "gridConfig": {
                    "nzSpan": 24,
                    "nzOffset": 0,
                    "nzPush": 0,
                    "nzPull": 0
                },
                "scrollWidth": "1200px",
                "mainData": [
                    "sqlFileList",
                    0
                ],
                "columns": [
                ]
            }
        )
    }

    createNewColumn(oneGroup: any) {
        oneGroup['columns'].push(
            {
                "column": "ABC",
                "lable": "甲乙丙",
                "comment": "甲乙丙",
                "fromDataCenter": [
                    "sqlFileList",
                    0
                ],
                "fromValue": [
                    "ABC"
                ],
                "nzSpan": 1
            }
        );

    }

    saveJson() {
        this.confirmModal.show('confirm', {
            title: '是否需要保存，如果原文件存在会覆盖源文件。',
            suc: () => {
                let temp = _sh.cloneDeep(this.readOnlyColumnsJson);
                this.makeUp(temp);
                this.mainJson['detailColumns'] = this.readOnlyColumnsJson;
                this.hulkconfigserviceservice.saveFile(this.mainJson,this.searchId);
            }
        });
    }

    private makeUp(temp: any) {
        return temp;
    }
}
