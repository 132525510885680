import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormComponent} from "../../basicComponent/form/form.component";
import {RequestDataService} from "../../service/request-data.service";
import {AppConfigService} from "../../service/app-config.service";
import {DataCenterService} from "../../service/data-center.service";
import {UtilsService} from "../../service/utils.service";
import {ListActionService} from "../../service/list-action.service";
import {ConfirmModalService} from "../../service/confirm-modal.service";
import {forkJoin as observableForkJoin} from "rxjs";
import * as _sh from 'lodash';

import * as localForage from 'localforage';
import * as alasql from 'alasql';
import {AutoInsertService} from "../../service/auto-insert.service";


@Component({
  selector: 'app-one-page-report',
  templateUrl: './one-page-report.component.html',
  styleUrls: ['./one-page-report.component.css']
})
export class OnePageReportComponent implements OnInit {

  //父级组件的数据对象
  @Input() parentData: any;

  // table 滚动条宽度
  private scrollWidth: string = '';
  // 当前table的表头
  private showColumns: any = [];

  private mergeDatas: any = [];

  // 查询对象
  public conditionColumns: any = {
    columns: []
  };
  private datas: any = [];

  // 查询数据
  public conditionList: any[] = [];

  public noDataVisible = false;

  @ViewChild(FormComponent, {static: false}) childForm !: FormComponent;

  constructor(private requestDataService: RequestDataService,
              private appConfigService: AppConfigService,
              private dataCenterService: DataCenterService,
              private utils: UtilsService,
              private listAction: ListActionService,
              private confirmModal: ConfirmModalService) {
  }

  // 合并查询条件
  transformCondition(columns) {
    let condition = [];
    _sh.each(columns, (item) => {
      if (this.utils.Base_HasValue(item['model'])) {
        /**
         * [obj 查询条件对象]
         * column 字段值对应数据库字段
         * label 标题
         * code 查询字段值
         * name 查询字段显示值用于字典
         * @type {Object}
         */
        let obj = {
          column: item['column'],
          title: item['lable'],
          code: item['model'],
          name: item['model'],
          component: item['component']
        };
        if (_sh.includes(['select', 'poplist', 'inputDataFilter'], item['component'])) {
          // poplist 存在 itemArray 不存在的情况（因为是需要点击在去查询JSON）
          // 所以在查询条件会写的时候需要讲内容赋值
          if (item['itemArray'] && item['itemArray'].length) {
            const selected = _sh.find(item['itemArray'], (v) => {
              return v['code'] === item['model'];
            });
            obj['name'] = selected['name'];
          } else {
            obj['name'] = item['modelName'];
          }
        } else if (_sh.includes(['multiSelect', 'multipopCondition'], item['component'])) {
          let nameList = [];
          _sh.each(item['itemArray'], (v) => {
            if (_sh.includes(item['model'], v['code'])) {
              nameList.push(v['name']);
            }
          });
          obj['name'] = nameList.join(' ');
        } else if (_sh.includes(['dateRange', 'dateTimeRange'], item['component'])) {
          obj['name'] = item['model'].join('~');
          obj['bindColumns'] = item['bindColumns'];
        }
        condition.push(obj);
      }
    });
    return condition;
  }

  searchData(reset: boolean = false, condition?: {}): void {
    if (reset) {
      this.parentData.pageIndex = 1;
    }
    if (this.parentData.passCondition) {
      condition = Object.assign(condition, this.parentData.passCondition);
    }
    this.requestDataService.getDataByCondition(this.parentData.searchId, condition || {}).subscribe((data: any) => {
      this.transformData(data);
      this.noDataVisible = true;
    });
  }


  // 将searchCondition放入localForage
  setSearchCondition(parentData, conditionList) {
    localForage.getItem('conditionForage').then((result: string) => {
      let conditionForage = {};
      if (this.utils.Base_HasValue(result)) {
        conditionForage = JSON.parse(result);
      }
      conditionForage = conditionForage || {};
      conditionForage['searchCondition'] = conditionForage['searchCondition'] || {};
      conditionForage['searchCondition'][parentData.nowMenu.MENU_ID] = conditionList;
      localForage.setItem('conditionForage', JSON.stringify(conditionForage));
    })
  }


  sumResult(data, mergeColumn,merge_name) {
    let compiled = _sh.template('SELECT <%= merge_name %> ,COUNT(1) AS ACOUNT FROM ? GROUP BY <%= mergeColumn %> ');
    let sqlText = compiled({ 'mergeColumn': mergeColumn,'merge_name': merge_name });
    return  alasql(sqlText,
      [data]);
  }

  // 查询后的数据进行封装
  transformResult(data) {
    this.showColumns = data.showColumns.columns || [];
    this.scrollWidth = data.showColumns.scrollWidth || null;
    this.datas = _sh.get(data, 'data', [])
    this.mergeDatas = [] // 初始化
    _sh.each(this.showColumns, (column) => {
      let merge = _sh.get(column, 'merge', null)
      let merge_name = _sh.get(column, 'merge_name', null)
      if (this.utils.Base_HasValue(merge)) {
        this.mergeDatas.push(this.sumResult(this.datas, merge,merge_name))
      }
    })
  }

  // 通用封装数据结果
  transformData(data) {
    this.transformResult(data);
    // 重新覆盖 datacenter 数据
    this.parentData.data = data;
    _sh.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData.id) {
        item = _sh.extend(item, this.parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  /**
   * 清除条件调用方法
   */
  resetFilter() {
    this.childForm.resetForm();
    this.searchFilter();
  }

  searchFilter(reset: boolean = true) {
    let columns = this.utils.DeepCopy(this.childForm._validateForm['columns']);
    let checked = true
    _sh.each(columns, (column) => {
      if (checked) {
        if (column['require'] && this.utils.Base_HasValue(column['model'])) {

        } else {
          this.confirmModal.show('error', {
            title: '温馨提示',
            content: column['lable'] + '不能为空！'
          });
          checked = false
        }
      }
    });
    if (checked) {
      this.conditionList = this.transformCondition(this.childForm._validateForm['columns']);
      this.setSearchCondition(this.parentData, this.conditionList);
      // 覆盖当前数据到data-center并重新查询
      _sh.each(this.dataCenterService._dataObject.list, (item) => {
        if (item['id'] === this.parentData.id) {
          item['searchCondition'] = this.conditionList;
        }
      });
      let condition = {};
      _sh.each(this.conditionList, (item) => {
        // 如果是daterange 类型需要将字段从bindColumns中取出
        if (_sh.includes(['dateRange', 'dateTimeRange'], item['component'])) {
          _sh.each(item.code, (value, index) => {
            const column = item['bindColumns'][index];
            condition[column] = value;
          });
        } else {
          condition[item.column] = item.code;
        }
      });
      this.searchData(reset, condition);
      // 如果重新查询。则清楚掉详情的条件
      this.dataCenterService.refreshDataCenterInStorage();
    }
  }


  handleClose(removedCondition: {}): void {
    // form 中数据清空
    this.conditionList = this.conditionList.filter(tag => tag !== removedCondition);
    _sh.each(this.childForm._validateForm['columns'], (item) => {
      if (removedCondition['column'] === item['column']) {
        item['model'] = null;
        if (_sh.includes(['date', 'datetime', 'dateRange', 'dateTimeRange'], item['component'])) {
          item['modelDate'] = null;
        }
        if (_sh.includes(['poplist', 'multipopCondition'], item['component'])) {
          item['modelName'] = '';
        }
      }
    });
    // 重新查询
    this.searchFilter();
  }

  initSearchCondition(parentData) {
    let data = parentData.data;
    let searchCondition = parentData.searchCondition;
    let searchForm = parentData.conditionColumns;
    // 如果有存储查询条件则需要将值合并到conditionColumns中
    if (this.utils.Base_HasValue(searchCondition)) {
      _sh.each(searchForm.columns, (item) => {
        const condition = _sh.find(searchCondition, (searchItem) => {
          return searchItem['column'] === item['column'];
        });
        if (this.utils.Base_HasValue(condition)) {
          item['model'] = condition['code'];
        }
      });
    }
    this.conditionColumns = searchForm;
    this.conditionList = this.transformCondition(searchForm.columns);
  }

  ngOnInit() {
    if (!this.utils.Base_HasValue(this.parentData.data)) {
      let searchArray = [
        this.requestDataService.getConditionData(this.parentData.searchId),
        this.requestDataService.getBtnListJson(this.parentData.nowMenu.MENU_ID),
        // this.requestDataService.getDataByCondition(this.parentData.searchId, {})
      ];
      if (this.utils.Base_HasValue(this.parentData.nowMenu['MENU_MENU']) && this.parentData.nowMenu['MENU_MENU'] === 'noData') {
        searchArray = [
          this.requestDataService.getConditionData(this.parentData.searchId),
          this.requestDataService.getBtnListJson(this.parentData.nowMenu.MENU_ID)
        ];
      }
      const parallel$ = observableForkJoin(searchArray);
      parallel$.subscribe(
        values => {
          this.conditionColumns = _sh.get(values, '[0].conditionColumns[0]', {})
          this.parentData.actionArray = _sh.get(values, '[1].data', [])
          this.parentData.conditionColumns = this.conditionColumns;
          // if (this.utils.Base_HasValue(values[2])) {
          //   this.noDataVisible = true;
          //   this.transformData(values[2]);
          // }
        }
      );
    } else {
      this.noDataVisible = false;
      this.transformResult(this.parentData.data);
      this.initSearchCondition(this.parentData);
    }
  }

}
