import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {NzModalService} from "ng-zorro-antd/modal";
import {UtilsService} from "../../../service/utils.service";
import {RequestDataService} from "../../../service/request-data.service";
import AMapLoader from '@amap/amap-jsapi-loader';
import {forkJoin as observableForkJoin} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {AjaxService} from "../../../service/ajax.service";
import {AssetsService} from "../assets.service";
import * as q from "q";
import * as _sh from "lodash";

declare var BMap: any;

@Component({
    selector: 'app-asset-main-map',
    templateUrl: './asset-main-map.component.html',
    styleUrls: ['./asset-main-map.component.scss']
})
export class AssetMainMapComponent implements OnInit {

    @Input() parentData: any;
    AMap: any;// 控件
    map: any; //
    cityData: any = [];
    cityAreaData: any = [];
    points: any = [];
    infoWindowList = {};
    noAMapList = [];

    constructor(private el: ElementRef,
                private http: HttpClient,
                private assetsService: AssetsService,
                private modalService: NzModalService,
                private ajaxService: AjaxService,
                private requestService: RequestDataService,
                private utils: UtilsService,
                private requestDataService: RequestDataService
    ) {
        let that = this;
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.setMap(); // 绘画地图
        this.doSearch();
    }


    gotoCityAreaItem1(item) {
        let pParentData = {
            'title': '资产地图：' + item['OPERATING_ID_DICT'],
            'id': item['COM_ID'] + ',' + item['OPERATING_ID'] + ',' + item['AS_CORE_ORG_ID']
        }
        this.assetsService.doAssetMap(null, pParentData);
        console.log(pParentData)
    }

    doSearch() {
        const search = observableForkJoin(
            this.requestService.getDataByCondition('assets/map/assets_com.json', {}),
            this.requestService.getDataByCondition('assets/map/assets_org.json', {}),
            this.requestService.getDataByCondition('assets/map/assets_operating.json', {}),
            this.requestService.getDataByCondition('assets/dashboard/onlyMapPoint/mapPointNoAMap.json', {}),
        );
        search.subscribe(
            async values => {
                this.cityData = _sh.get(values, [0, 'data']);
                let LVO1 = _sh.get(values, [1, 'data']);
                let LVO2 = _sh.get(values, [2, 'data']);
                _sh.each(this.cityData, oneCOM => {
                    oneCOM['mainTitle'] = oneCOM.COM_ID_DICT + ' 租赁面积：' + oneCOM.AREA + '（㎡）' + ' 资产数量：' + oneCOM.ACCOUNT;
                    oneCOM['childData'] = _sh.filter(LVO1, {'COM_ID': oneCOM['COM_ID']});
                    _sh.each(oneCOM['childData'], oneORG => {
                        oneORG['mainTitle'] = oneORG.AS_CORE_ORG_ID_DICT + ' 租赁面积：' + oneORG.AREA + '（㎡）' + ' 资产数量：' + oneORG.ACCOUNT;
                        oneORG['childData'] = _sh.filter(LVO2, {
                            'COM_ID': oneCOM['COM_ID'],
                            'AS_CORE_ORG_ID': oneORG['AS_CORE_ORG_ID']
                        });
                    })
                })
                this.noAMapList = _sh.get(values, [3, 'data'], []);

                // for (let indexLbs = 0; indexLbs < this.noAMapList.length; indexLbs++) {
                //     let onePoint = this.noAMapList[indexLbs];
                //     let lnglatConvert = await this.convertFrom(onePoint['AS_LONGITUDE_LATITUDE'], 'baidu');
                //     let lnglat = _sh.split(lnglatConvert, ',');
                //     let Lng = lnglat[0],
                //         Lat = lnglat[1];
                //     let queryData = {
                //         'id': onePoint['ID'],
                //         'aMapPonint': Lng + ',' + Lat
                //     }
                //     this.requestService.doActionByHdbsthor('AppApi/AsAssetCheckController', 'updateAMapPoint', queryData, null, false, 'hdbsthor_assets', 'appToken').subscribe(response => {
                //     });
                // }

            }
        );
    }


    addAMapControl() {
        var toolbar = new this.AMap.ToolBar({
            position: {
                top: '110px',
                right: '40px'
            }
        });
        var controlBar = new this.AMap.ControlBar({
            position: {
                top: '10px',
                right: '10px'
            }
        });
        var scale = new this.AMap.Scale();

        var overView = new this.AMap.HawkEye();
        this.map.addControl(toolbar);
        this.map.addControl(scale);
        this.map.addControl(controlBar);
        this.map.addControl(overView);
    }

    setMap() {
        AMapLoader.load({ //首次调用 load
            key: '7d4ef5abd3fe75f2496fa1fd717a69fa',//首次load key为必填
            version: '2.0',
            plugins: ['AMap.Scale', 'AMap.ToolBar', 'AMap.DistrictSearch', 'AMap.CircleEditor', 'AMap.MarkerCluster', 'AMap.ControlBar', 'AMap.HawkEye'],
            Loca: {
                version: '2.0.0',
            }
        }).then((AMap) => {
            this.AMap = AMap;
            this.map = new AMap.Map('asset-main-map', {
                zoom: 12,
                center: [110.798921, 32.629057]
            });
            let map = this.map;
            this.addAMapControl();

            //@ts-ignore
            var loca = new Loca.Container({
                map,
            });
            let polygonList = [
                {
                    'name': '襄阳市',
                    'cityCode': 'XY',
                    'level': 'city',
                    'totalArea': 0,
                    'unit': '（㎡）',
                    'center': {
                        'Lng': '112.121743',
                        'Lat': '32.010161'
                    }
                },
                {
                    'name': '丹江口市',
                    'cityCode': 'SY',
                    'level': 'district',
                    'totalArea': 0,
                    'unit': '（㎡）',
                    'center': {
                        'Lng': '111.513318',
                        'Lat': '32.540287'
                    }
                },
                {
                    'name': '房县',
                    'cityCode': 'SY',
                    'level': 'district',
                    'totalArea': 0,
                    'unit': '（㎡）',
                    'center': {
                        'Lng': '110.726754',
                        'Lat': '32.04017'
                    }
                },
                {
                    'name': '郧西县',
                    'cityCode': 'SY',
                    'level': 'district',
                    'totalArea': 0,
                    'unit': '（㎡）',
                    'center': {
                        'Lng': '110.426005',
                        'Lat': '32.993095'
                    }
                },
                {
                    'name': '郧阳区',
                    'cityCode': 'SY',
                    'level': 'district',
                    'totalArea': 0,
                    'unit': '（㎡）',
                    'center': {
                        'Lng': '110.812101',
                        'Lat': '32.834847'
                    }
                },
                {
                    'name': '竹山县',
                    'cityCode': 'SY',
                    'level': 'district',
                    'totalArea': 0,
                    'unit': '（㎡）',
                    'center': {
                        'Lng': '110.229696',
                        'Lat': '32.225039'
                    }
                },
                {
                    'name': '竹溪县',
                    'cityCode': 'SY',
                    'level': 'district',
                    'totalArea': 0,
                    'unit': '（㎡）',
                    'center': {
                        'Lng': '109.715322',
                        'Lat': '32.318257'
                    }
                },
                {
                    'name': '张湾区',
                    'cityCode': 'SY',
                    'areaCode': '05',
                    'level': 'district',
                    'totalArea': 0,
                    'unit': '（㎡）',
                    'center': {
                        'Lng': '110.769133',
                        'Lat': '32.652297'
                    }
                },
                {
                    'name': '茅箭区',
                    'cityCode': 'SY',
                    'areaCode': '09',
                    'level': 'district',
                    'totalArea': 0,
                    'unit': '（㎡）',
                    'center': {
                        'Lng': '110.813478',
                        'Lat': '32.591847'
                    }
                },
                {
                    'name': '花果',
                    'cityCode': 'SY',
                    'level': 'district',
                    'areaCode': '03',
                    'totalArea': 0,
                    'unit': '（㎡）',
                    'center': {
                        'Lng': '110.676483',
                        'Lat': '32.645836'
                    }
                }
            ];

            let showPolygonList = [];

            _sh.each(this.cityAreaData, oneArea => {
                let filterData = _sh.filter(polygonList, {
                    'cityCode': oneArea['URBAN_CODE'],
                    'areaCode': oneArea['AREA_CODE']
                });
                _sh.set(filterData, [0, 'totalArea'], oneArea['AREA'])
                showPolygonList = _sh.includes(showPolygonList, filterData);
            })

            let index = 1;
            _sh.each(showPolygonList, oneItem => {
                // this.addAMapMarker(oneItem);
                index++;
            })

            const search = observableForkJoin(
                this.requestService.getDataByCondition('assets/map/assets_city_lbs.json', {})
            );
            search.subscribe(
                async values => {
                    this.points = _sh.get(values, [0, 'data']);
                    for (var i = 0; i < this.points.length; i++) {
                        this.addAMapMarker(this.points[i]);
                    }

                }
            );

        }).catch((e) => {
            console.error(e);
        });
    }


    public async addAMapMarker(oneItem) {
        let that = this;

        function createInfoWindow() {
            let contentTemplate = `
                <script type="text/javascript">
                    function openNewWin(url) {
                        window.open(url,'_blank');
                    }
                </script>
                <div style="width: 680px;height: 140px;padding: 15px;background-color: #ffffff; ">
                <div style='float:left'>
                    <h4 style='margin:0 0 5px 0;'><%= AS_NAME %></h4>
                    <a id="infoWindowBtn" href="javascript:window.open('/appAssetMap/<%= AS_ID %>','_blank')">租控图</a>
                    <p style='color: rgba(0,0,0,.45);margin-top:20px;font-size:13px'>
                        <%= AS_ADDRESS %></p>
                    <p style='color: rgba(0,0,0,.45);margin-top:10px;float:bottom;font-size:13px;'>
                        <span>资产面积：<%= AREA %>（㎡）</span>
                        <span>房间数量：<%= ROOM_COUNT %>（间）</span>
                    </p>
                </div>
            </div>`;
            let complied = _sh.template(contentTemplate);
            let sContent = complied(
                {
                    'AS_ID': oneItem['ID'],
                    'AS_NAME': oneItem['AS_NAME'],
                    'AS_ADDRESS': oneItem['AS_ADDRESS'],
                    'AREA': oneItem['AREA'],
                    'ROOM_COUNT': oneItem['ROOM_COUNT']
                }
            );

            let infoWindow = new that.AMap.InfoWindow({
                content: sContent
            });
            return infoWindow
        }

        let lnglatConvert = await this.convertFrom(oneItem['AS_LONGITUDE_LATITUDE'], 'baidu');
        oneItem['AS_LONGITUDE_LATITUDE_AFT'] = lnglatConvert;
        let lnglat = _sh.split(lnglatConvert, ',');
        let title = oneItem['UP_AS_NAME'],
            number = oneItem['AREA'] + '（㎡）',
            Lng = lnglat[0],
            Lat = lnglat[1];
        let markerPoint = new this.AMap.Marker({
            anchor: 'bottom-center',
            position: new this.AMap.LngLat(Lng, Lat),
            title: title
        })

        this.map.add(
            markerPoint
        );
        let infoWindow = createInfoWindow();

        _sh.set(that.infoWindowList, [oneItem['ID']], infoWindow);

        markerPoint.on('click', function (ev) {
            let lnglat = oneItem['AS_LONGITUDE_LATITUDE'];
            lnglat = _sh.split(lnglat, ',');
            let Lng = lnglat[0],
                Lat = lnglat[1];
            _sh.each(that.infoWindowList, oneInfoWindow => {
                oneInfoWindow.close();
            })
            infoWindow.open(that.map, [Lng, Lat]);
        });
    }


    // 坐标转换
    async convertFrom(lnglat, type) {
        // type : baidu gps mapbar
        //112.188496,32.122571 襄阳 东风通信技术有限公司襄阳分公司
        let deferred = q.defer();
        deferred.notify('convertFrom');
        this.AMap.convertFrom(lnglat, type, function (status, result) {
            if (result.info === 'ok') {
                let resLnglat = result.locations[0];
                deferred.resolve(resLnglat.lng + ',' + resLnglat.lat);
            } else {
                deferred.reject(result);
            }
        });
        return deferred.promise;

    }


}
