import {Injectable} from '@angular/core';
import {UtilsService} from './utils.service';
import * as localForage from 'localforage';

import * as _sh from 'lodash';
import * as echarts from 'echarts';
import {AppConfigService} from "./app-config.service";
import {StorageService} from "./storage.service";
import {Md5} from "ts-md5";
import {UUID} from "angular2-uuid";
import * as moment from 'moment';
import {ConfirmModalService} from "./confirm-modal.service";

@Injectable({
    providedIn: 'root'
})
export class DataCenterService {

    public _dataObject: any = {
        // tab信息 index为当前选中tab的下标（修改该属性可以动态选中tab）
        index: 0,
        // tab 数组
        list: [],
        // 当前menu
        nowMenu: {}
    };

    public _todoCenter: any = {
        todoSum: 0,            // 全部待办
        todoOverSum: 0,        // 已超时
        todoWillOverSum: 0,    // 即将超时
        todoUrgeSum: 0,        // 催办
        awaitList: [],         // 全部待办数据
        myInit: 0,             // 我发起的
        myFinish: 0,           // 已办事项
        refuseSum: 0,           // 拒绝的
        refuseList: [],   // 拒绝的列表
        moreList: [],           //全部代办更多信息
        conCount: 0,            //临期合同数
        conBillCount: 0,        //临期账单数
        conList: [],            //临期合同列表
        conBillList: [],        //临期账单列表
        myconCount: 0,           //经我手合同数
        mypassedList: []         //经手合同列表
    }

    constructor(
        private storage: StorageService,
        private util: UtilsService,
        private confirmModal: ConfirmModalService,
        private appConfig: AppConfigService,
    ) {

    }

    // 清空dataCenter 数据
    clearData() {
        this._dataObject = {
            index: 0,
            list: [],
            nowTab: {},
            nowMenu: {}
        };
        this.refreshDataCenterInStorage();
    }

    // 清空dataCenter 数据
    clearDataExceptTodo() {
        let listCopy = [];

        _sh.each(_sh.get(this._dataObject, ['list'], []), oneList => {
            if (oneList['id'] == 'todoCenterForm') {
                listCopy.push(oneList);
            }
        });

        this._dataObject = {
            index: 0,
            list: listCopy,
            nowTab: {},
            nowMenu: {}
        };
        this.refreshDataCenterInStorage();
    }


    // 刷新缓存中的data center
    refreshDataCenterInStorage(param?: string) {
        localForage.setItem('dataCenter', JSON.stringify(this._dataObject));
    }

    // 关闭tab
    closeTab(tab: string): void {
        if (this._dataObject.nowMenu === 'todoCenterForm') {
            return;
        }
        let currentIndex = this._dataObject.index;
        let index = this._dataObject.list.indexOf(tab);
        // 如果是选中后删除 则最后一个删除后index-1
        if (currentIndex === index) {
            // 如果是删除掉最后一个tab则选中删除后的最后一个tab
            if (currentIndex === this._dataObject.list.length - 1) {
                this._dataObject.index = currentIndex - 1;
            }
        } else if (currentIndex > index) { //在当前选中tab前面删除则当前dataINdex -1 nowMenu
            this._dataObject.index = currentIndex - 1;
        }
        this._dataObject.list.splice(index, 1);
        if (this._dataObject.list.length) {
            this._dataObject.nowMenu = this._dataObject.list[this._dataObject.index].nowMenu;
        } else {
            this._dataObject.index = 0;
            this._dataObject.nowMenu = {};
        }
        this.refreshDataCenterInStorage();
    }

    // 新增tab
    newTab(item) {
        this._dataObject.list.push(item);
        this._dataObject.index = this._dataObject.list.length - 1;
        this._dataObject.nowMenu = this._dataObject.list[this._dataObject.index].nowMenu;
        this._dataObject.nowTab = this._dataObject.list[this._dataObject.index];
        this.refreshDataCenterInStorage();
    }

    getCurrentData(menuId) {
        const data = _sh.find(this._dataObject.list, (item) => {
            return menuId === item['nowMenu'].MENU_ID;
        });
        return data || {};
    }

    judgeOperatorFuntion(pValueA, judgeOperator, pValueB) {
        function getArr() {
            let tempArr = pValueB;
            if (_sh.isNumber(tempArr)) {
                tempArr = [tempArr.toString()]
            } else if (_sh.isString(tempArr)) {
                tempArr = _sh.split(tempArr, ',');
            }
            let has = _sh.includes(tempArr, pValueA);
            return has;
        }

        let result = false;
        switch (judgeOperator) {
            case '=':
                result = pValueA === pValueB;
                break;
            case '!=':
                result = pValueA !== pValueB;
                break;
            case '>':
                result = parseFloat(pValueA) > parseFloat(pValueB);
                break;
            case '>=':
                result = parseFloat(pValueA) >= parseFloat(pValueB);
                break;
            case '<':
                result = parseFloat(pValueA) < parseFloat(pValueB);
                break;
            case '<=':
                result = parseFloat(pValueA) <= parseFloat(pValueB);
                break;
            case 'in':
                result = getArr();
                break;
            case 'not in':
                result = !getArr();
                break;
            case 'like':
                result = !getArr();
                break;
        }
        return result;
    }


    doCheck(buttonConfig, pModalData, pCheckPoint,pInputForm,pParentData,pReadOnlyData,pShowError) {
        let that = this;
        // pValueA 是数据库值
        // pValueB 是配置文件之中的知道
        function judgmentData(pValueA, judgeOperator, pValueB) {
            let result = false

            function getArr() {
                let tempArr = pValueB;
                if (_sh.isNumber(tempArr)) {
                    tempArr = [tempArr.toString()]
                } else if (_sh.isString(tempArr)) {
                    tempArr = _sh.split(tempArr, ',');
                }
                let has = _sh.includes(tempArr, pValueA);
                return has;
            }

            // pValueA 是数据库值
            // pValueB 是配置文件之中的知道
            pValueA = pValueA + '';
            pValueB = pValueB + '';

            switch (judgeOperator) {
                case '=':
                    result = pValueA === pValueB;
                    break;
                case '!=':
                    result = pValueA !== pValueB;
                    break;
                case '>':
                    result = parseFloat(pValueA) > parseFloat(pValueB);
                    break;
                case '>=':
                    result = parseFloat(pValueA) >= parseFloat(pValueB);
                    break;
                case '<':
                    result = parseFloat(pValueA) < parseFloat(pValueB);
                    break;
                case '<=':
                    result = parseFloat(pValueA) <= parseFloat(pValueB);
                    break;
                case 'in':
                    result = getArr();
                    break;
                case 'not in':
                    result = !getArr();
                    break;
                case 'like':
                    result = !getArr();
                    break;
            }
            return result;
        }

        function execJudgmentGroup(judgeGroupCalc, judgeGroup, oneJudgeRow) {
            let reusltObj = {};
            _sh.each(judgeGroup, oneCheck => {
                reusltObj[oneCheck['column']] = execJudgment(oneCheck, oneJudgeRow,false); //  在执行某一个检查的时候不显示，最后一起显示
            })
            let compiled = _sh.template(judgeGroupCalc);
            let calcText = compiled(reusltObj);
            let result = eval(calcText);
            return result;
        }

        function execJudgment(oneCheck, oneJudgeRow, showError) {
            let judgeGroup = _sh.get(oneCheck, ['judgeGroup'], null);
            let judgeGroupCalc = _sh.get(oneCheck, ['judgeGroupCalc'], null);
            let judgmentResult = false;
            if (judgeGroup) {
                judgmentResult = execJudgmentGroup(judgeGroupCalc, judgeGroup, oneJudgeRow);
            } else {
                let fromValue = that.getSpecialValue(oneCheck['fromValue'], pParentData, pModalData, pReadOnlyData, oneJudgeRow);
                let judgeValue = that.getSpecialValue(oneCheck['judgeValue'], pParentData, pModalData, pReadOnlyData, oneJudgeRow);
                oneCheck['data'] = {
                    'fromValue': fromValue,
                    'judgeValue': judgeValue,
                }
                judgmentResult = judgmentData(fromValue, oneCheck['judgeOperator'], judgeValue);
            }
            if (judgmentResult && showError) { // 如果判断出来，有问题的。
                let compiled = _sh.template(oneCheck['msgTemplate']);
                let msgValue = oneCheck['msgValue'];
                _sh.each(_sh.keys(msgValue), oneKey => {
                    msgValue[oneKey] = that.getSpecialValue(msgValue[oneKey], pParentData, pModalData, pReadOnlyData, oneJudgeRow);
                })
                const msgText = compiled(msgValue);
                if (pShowError)
                    that.confirmModal.show(oneCheck['msgType'], {
                        'title': oneCheck['msgTitle'],
                        'content': msgText
                    });
            }
            return judgmentResult;
        }

        let result = true; // 默认审批是通过的。
        let checkList = _sh.filter(_sh.get(buttonConfig, ['check'], []), (item) => {
            return item['inputForm'] == pInputForm && _sh.get(item, ['checkPoint'], 'beforeOpenModal') == pCheckPoint;
        });
        let judgmentResult = false;
        _sh.each(checkList, oneCheck => {
            if (!judgmentResult) {
                const judgeRowConfig = _sh.get(oneCheck, 'judgeRow', null); // 逐行检查数据
                if (that.util.Base_HasValue(judgeRowConfig)) {
                    let judgeRowData = that.getSpecialValue(judgeRowConfig, pParentData, pModalData, pReadOnlyData);
                    _sh.each(judgeRowData, oneRow => {
                        if (result) {
                            judgmentResult = execJudgment(oneCheck, oneRow,true); // 显示报错
                            result = !judgmentResult;
                        }
                    })
                } else {
                    judgmentResult = execJudgment(oneCheck, null ,true ); // 检查表级的数据
                    result = !judgmentResult;
                }
            }
        })
        return result;
    }

    /**
     * 获取各个页面的特殊值，
     * @param pValue
     * @param pParentData 来源于 list 的值，主要来确定选中的值
     * @param pModalData 来源于 弹窗的值
     * @param pReadOnlyData 来源于，新型只读页面的值
     */
    getSpecialValue(pValue, pParentData, pModalData, pReadOnlyData, pSpecialData?) {
        let that = this;

        function findLast(pData, pTarget) {
            if (_sh.includes(pTarget, 'SYS_LAST')) {
                let findTimes = _sh.filter(pTarget, oneLink => {
                    return oneLink == 'SYS_LAST';
                });
                for (var i = 0; i < findTimes.length; i++) {
                    pTarget = replaceLink(pData, pTarget);
                }
            }
            return pTarget;
        }

        function replaceLink(pData, pTarget) {
            let first = _sh.indexOf(pTarget, 'SYS_LAST');
            let dropLen = pTarget.length - first;
            let link = _sh.dropRight(pTarget, dropLen);
            let findLength = _sh.get(pData, link, []).length - 1;
            pTarget[first] = findLength;
            return pTarget;
        }

        function formatSelectEntity(datas, pColumn) {
            let entity: any = [];
            _sh.each(datas, (OneData) => {
                let obj: any = {};
                const keys = _sh.keys(OneData);
                _sh.each(keys, (oneKeys) => {
                    if (oneKeys === pColumn) {
                        obj['id'] = OneData[oneKeys];
                    }
                });
                entity.push(obj);
            });
            return entity;
        }

        function formatValue(oneOneColum, oneValue) {
            let result = oneValue;
            const format = _sh.get(oneOneColum, ['format'], null);
            if (format == 'md5') {
                result = Md5.hashStr(oneValue);
            }
            return result;
        }

        function getDictValue(oneOneColum, oneValue) {
            let findData = _sh.filter(oneOneColum['itemArray'], oneItem => {
                return oneItem['code'] == oneValue;
            })
            if (that.util.Base_HasValue(findData)) {
                oneValue = _sh.get(findData, [0, 'name'], oneValue);
            }
            return oneValue;
        }

        function findOneColumnValue(pColumns, pColumnName, pDict) {
            const oneOneColum = _sh.find(pColumns, {'column': pColumnName});
            let oneValue = null;
            if (that.util.Base_HasValue(oneOneColum)) {
                oneValue = _sh.get(oneOneColum, 'model');
                if (pDict == 'DICT') { // 如果是字典项
                    oneValue = getDictValue(oneOneColum, oneValue);
                }
                oneValue = formatValue(oneOneColum, oneValue);
            } else {
                _sh.each(pColumns, oneColumn => {
                    _sh.each(oneColumn['columnGroup'], oneGroup => {
                        if (oneGroup['column'] === pColumnName) {
                            oneValue = _sh.get(oneGroup, 'model');
                            if (pDict == 'DICT') { // 如果是字典项
                                oneValue = getDictValue(oneOneColum, oneValue);
                            }
                            oneValue = formatValue(oneOneColum, oneValue);
                        }
                    })
                })
            }

            return oneValue;
        }

        const user = that.storage.getData('userInfo');

        const selectList = _sh.filter(pParentData.data.data, (item) => {
            return item['checked'];
        });
        let result = pValue; // 默认值
        let value01 = _sh.split(pValue, ".")[0];
        let value02 = _sh.split(pValue, ".")[1];
        let value03 = _sh.split(pValue, ".")[2];

        if (value01 == "SYS") {
            if (value02 == "USER_ID") {
                result = user.USER_ID;
      }
      else if (value02 == "PLATFORM") {
        result = navigator.platform;
      }
      else if (value02 == "USER_NAME") {
                result = user.USERNAME;
            } else if (value02 == "pf") {
                result = that.appConfig.config.MysqlPF;
            } else if (value02 == "id") {
                let ids = formatSelectEntity(selectList, 'ID');
                result = _sh.get(ids, [0, 'id'], null);
            } else if (value02 == "ids") {
                let ids = formatSelectEntity(selectList, 'ID');
                result = ids;
            } else if (value02 == "environment") {
                const findLink = _sh.drop(_sh.split(pValue, "."), 2);
                result = _sh.get(that.appConfig.config, findLink, null)
            } else if (value02 == "DATETIME") {
                result = moment().format("YYYY-MM-DD HH:mm:ss");
            } else if (value02 == "TIME") {
                result = moment().format("HH:mm:ss");
            } else if (value02 == "DATE") {
                result = moment().format("YYYY-MM-DD");
            } else if (value02 == "ISNULL") {
                const findLink = _sh.chain(pValue).split(".").drop(3).join(".").value();
                const tempValue = that.getSpecialValue(findLink, pParentData, pModalData, pReadOnlyData, pSpecialData);
                if (that.util.Base_HasValue(tempValue)) {
                    result = tempValue
                } else {
                    result = _sh.split(pValue, ".")[2];
                }
            } else if (value02 == "wfUserAwaitId") {
                let wfUserAwaitId = _sh.get(pParentData, ['workflowData', 'wfUserAwaitId']);
                result = wfUserAwaitId;
            } else if (value02 == "ARRAY") {
                let column = _sh.split(pValue, ".")[2];
                let arr = formatSelectEntity(selectList, column);
                result = arr;
            }
        } else {
            if (value01 == "MODAL") {
                result = findOneColumnValue(pModalData, value02, value03)
            } else if (value01 == "SELECT") {
                let findLink = _sh.chain(pValue).split(".").drop().value();
                findLink = findLast(pParentData, findLink);
                if (findLink.length == 0) {
                    result = selectList;
                } else {
                    result = _sh.get(selectList, findLink, null);
                }
            } else if (value01 == "READONLY") {
                let findLink = _sh.chain(pValue).split(".").drop().value();
                findLink = findLast(pReadOnlyData, findLink);
                if (findLink.length == 0) {
                    result = pReadOnlyData;
                } else {
                    result = _sh.get(pReadOnlyData, findLink, null);
                }
            } else if (value01 == "SPECIAL_DATA") {
                let findLink = _sh.chain(pValue).split(".").drop().value();
                if (findLink.length == 0) {
                    result = pSpecialData;
                } else {
                    result = _sh.get(pSpecialData, findLink, null);
                }
            }
        }
        let msgTemplate = _sh.get(pValue, ['msgTemplate'], null);
        if (that.util.Base_HasValue(msgTemplate)) {
            let msgValue = _sh.get(pValue, ['msgValue'], null);
            let msgValueObj = {}; // 最后的格式化参数的结果
            if (that.util.Base_HasValue(msgValue)) {
                if (_sh.isArray(msgValue)) {
                    // 如果是第二代，数组模式
                    _sh.each(msgValue, oneKey => {
                        msgValueObj[oneKey['column']] = that.getSpecialValue(oneKey['fromValue'], pParentData, pModalData, pReadOnlyData);
                        if (!that.util.Base_HasValue(msgValueObj[oneKey['column']])) { // 当这个值为空值的时候，用这个值替代。
                            msgValueObj[oneKey['column']] = _sh.get(oneKey, ['isNull'], null);
                        }
                        let befFormat = _sh.get(oneKey, ['befFormat'], null); // 格式化, 目前支持转大写，转千分位+小数点
                        if (that.util.Base_HasValue(befFormat)) {
                            if (befFormat == "TO_CHINESE_BLOCK_LETTER") {
                                msgValueObj[oneKey['column']] = that.util.changeToChineseMoney(msgValueObj[oneKey['column']]);
                            } else if (befFormat == "THOUSANDS") {
                                msgValueObj[oneKey['column']] = that.util.thousands(msgValueObj[oneKey['column']], oneKey['dplaces']);
                            }
                        }
                    })
                } else {
                    // 第一代，对象模式。
                    _sh.each(_sh.keys(msgValue), oneKey => {
                        msgValueObj[oneKey] = that.getSpecialValue(msgValue[oneKey], pParentData, pModalData, pReadOnlyData);
                    })
                }
                let compiled = _sh.template(msgTemplate);
                result = compiled(msgValueObj);

            } else {
                result = msgTemplate; // 如果没有格式化参数，直接返回模板的文字。
            }
        }
        return result;
    }

    newTabWk(item, itemTabName?) {
        // itemTabName , 针对工作台页面。
        let formType = this.util.getFormType(item);
        let appObj: any;
        let parentMenu: any;
        if (!this.util.Base_HasValue(formType)) {
            return false;
        }
        // 根据菜单ID 获取菜单json
        let menuId = item.MENU_ID;
        if (!_sh.includes(['customForm'], formType)) {
            appObj = this.util.getAppList(menuId);
        }
        parentMenu = this.util.getMenulist(item.UP_ID);
        // 如果该菜单已存在。直接选中该菜单。
        let tabIndex = _sh.findIndex(this._dataObject.list, (item) => {
            return item['nowMenu'].MENU_ID === menuId;
        });
        if (tabIndex >= 0) {
            this._dataObject.index = tabIndex;
            this._dataObject.nowTab = this._dataObject.list[tabIndex];
            this._dataObject.nowMenu = this._dataObject.list[tabIndex].nowMenu;
            this.refreshDataCenterInStorage();
        } else {
            let menuObj = _sh.extend(item, {
                MENU_UP_NAME: parentMenu.MENU_NAME
            });
            let uuid = UUID.UUID();
            this.newTab({
                id: uuid, // 唯一标识
                nowMenu: menuObj, // 菜单ID 用于详情返回
                title: item.MENU_NAME, // 菜单名称 用户tab展示
                formType: formType, // 菜单类型 用于tab过滤显示模板
                searchId: appObj ? appObj.SEARCH_ID : '', // 查询json 用户列表分页查询
                data: {}, // 查询返回数据
                listHelpUrl: appObj ? appObj.HELP_URL : '',
                addHelpUrl: appObj ? appObj.ADD_HELP_URL : '',
                itemTabName: itemTabName ? itemTabName : '' // 针对工作台页面，控制页面
            });
        }
    }


    getBlueColors() {
        return ['#88ABDA', '#82a7d9', '#80a7dc', '#3b67b6']
    }

    setEchartOptions(oneGroup) {
        let echartOptions = {}
        if (oneGroup['groupShowType'] == 'lineCharts') {
            echartOptions = this.setLineChart(oneGroup['groupData'], oneGroup['columns'], oneGroup['chartConfig']);
        } else if (oneGroup['groupShowType'] == 'barCharts') {
            echartOptions = this.setBarChart(oneGroup['groupData'], oneGroup['columns'], oneGroup['chartConfig']);
        } else if (oneGroup['groupShowType'] == 'pieCharts') {
            echartOptions = this.setPieChart(oneGroup['groupData'], oneGroup['columns'], oneGroup['chartConfig']);
        } else if (oneGroup['groupShowType'] == 'yCategoryStacks') {
            echartOptions = this.setPieChart(oneGroup['groupData'], oneGroup['columns'], oneGroup['chartConfig']);
        }
        return echartOptions;
    }

    setLineChart(oneGroupData, oneGroupColumns, chartConfig) {
        let xAxis = []
        let yAxis = []
        let yColumName = _sh.get(oneGroupColumns, [1, 'column'], null);
        let max = _sh.get(oneGroupData, [0, yColumName], 0);
        let min = 0
        for (let i = 0; i < oneGroupData.length; i += 1) {
            let x = oneGroupData[i][oneGroupColumns[0]['column']]
            let y = oneGroupData[i][oneGroupColumns[1]['column']]
            xAxis.push(x)
            yAxis.push(y)
            if (i > 0) {
                if (y > max) max = y;
                if (y < min) min = y;
            }
        }
        let yColumnList = this.util.filterData(oneGroupColumns, 'coordinate', 'y');
        let yColumnNameList = this.util.getArrayByPath(yColumnList, 'lable');
        let series = [];
        _sh.each(yColumnList, oneColumn => {
            let oneObj = {
                name: oneColumn['lable'],
                type: 'line',
                smooth: true,
                data: this.util.getArrayByPath(oneGroupData, oneColumn['column']),
                lable: {
                    show: true,
                    position: "top",
                    distance: 7,
                    color: chartConfig && chartConfig['fontColor'] ? chartConfig['fontColor'] : '#000'
                }

            }
            series.push(oneObj);
        })
        let option = {
            grid: {
                top: '15%',
                left: '5%',
                right: '5%',
                bottom: '10%',
                containLabel: true
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            color:['#E67762','#ffba63','#00e3ff','#88ABDA','#B93A26','#7D5284','#CE8892'],
            legend: {
                backgroundColor: "#ffffff",
                data: yColumnNameList,
                itemStyle: {
                    color:"inherit"
                }
            },
            xAxis: {
                type: 'category',
                data: xAxis,
                axisLine: {
                    lineStyle: {
                        color: '#000'//chartConfig && chartConfig['fontColor'] ? chartConfig['fontColor'] : '#000'
                    }
                },
                axisLabel: {
                    textStyle: {
                        fontSize: 14,
                        color: '#000'//chartConfig && chartConfig['fontColor'] ? chartConfig['fontColor'] : '#000'
                    },
                    formatter: '{value}'
                },
                axisTick: {
                    show: true
                },
                splitLine: {
                    show: true
                }
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    color: '#000',//chartConfig && chartConfig['fontColor'] ? chartConfig['fontColor'] : '#000',
                    margin: 5,
                    fontSize: 12
                },
                axisLine: {
                    show: true
                },
                axisTick: {
                    show: false
                },
                splitNumber: 3,
                splitLine: {
                    lineStyle: {
                        color: '#000',//chartConfig && chartConfig['fontColor'] ? chartConfig['fontColor'] : '#000',
                        width: 1
                    }
                }
            },
            series: series
        };
        return option;
    }

    // 条形图初始化
    setBarChart(oneGroupData, oneGroupColumns, chartConfig) {
        // 数据赋值
        let category = []
        let data = []
        for (let i = 0; i < oneGroupData.length; i += 1) {
            let name = oneGroupData[i][oneGroupColumns[0]['column']]
            let value = oneGroupData[i][oneGroupColumns[1]['column']]
            category.push(name)
            data.push(value)
        }
        // 判断横向还是纵向条形图
        let xAxisType = 'category'
        let yAxisType = 'value'
        if (chartConfig && chartConfig['chartType'] && chartConfig['chartType'] == 'Horizontal') {
            xAxisType = 'value'
            yAxisType = 'category'
        }
        let xAxis = {
            type: xAxisType,
            axisTick: {
                show: false
            },
            axisLine: {
                show: true
            },
            axisLabel: {
                textStyle: {
                    fontSize: 14,
                    color: '#000'//chartConfig && chartConfig['fontColor'] ? chartConfig['fontColor'] :'#000'
                },
                formatter: '{value}'
            }
        }
        let yAxis = {
            type: yAxisType,
            axisTick: {
                show: false
            },
            axisLine: {
                show: true
            },
            axisLabel: {
                color: '#000',//chartConfig && chartConfig['fontColor'] ? chartConfig['fontColor'] : '#000',
                margin: 5,
                fontSize: 12
            },
        }
        let itemStyle = {
            normal: {
                barBorderRadius: 5
            }
        }
        if (xAxisType == 'category') {  // 纵向柱状图
            xAxis['data'] = category
            xAxis['splitLine'] = {
                show: true
            }
            yAxis['min'] = chartConfig['min'] ? chartConfig['min'] : 0;
            yAxis['splitNumber'] = 2
            yAxis["splitLine"] = {
                lineStyle: {
                    color: chartConfig && chartConfig['fontColor'] ? chartConfig['fontColor'] : '#000',
                    width: 1
                }
            }
            itemStyle['normal']['color'] = new echarts.graphic.LinearGradient(1, 1, 0, 0, [{
                offset: 0,
                color: '#AED0EE'
            }, {offset: 1, color: '#5976C4'}])
        }
        if (yAxisType == 'category') {  // 水平柱状图
            yAxis['data'] = category
            yAxis['splitLine'] = {
                show: true
            }
            xAxis['min'] = chartConfig['min'] ? chartConfig['min'] : 0;
            xAxis['position'] = 'top'
            xAxis['splitNumber'] = 2
            xAxis['splitLine'] = {
                lineStyle: {
                    color: chartConfig && chartConfig['fontColor'] ? chartConfig['fontColor'] : '#000',
                    width: 1
                }
            }
            itemStyle['normal']['color'] = new echarts.graphic.LinearGradient(0, 0, 1, 1, [{
                offset: 0,
                color: '#AED0EE'
            }, {offset: 1, color: '#5976C4'}])

        }
        // 配置
        let option = {
            grid: {
                top: '5%',
                left: '5%',
                right: '5%',
                bottom: '20%',
                containLabel: true
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            xAxis: xAxis,
            yAxis: yAxis,
            series: [{
                type: 'bar',
                barWidth: '20px',        // 柱子宽度
                barCategoryGap: '20%',  // 类间间隔
                data: data,
                itemStyle: itemStyle
            }]
        };
        return option;
    }

    getLineColor(){
        return ['#E7CAD3','#ffba63','#00e3ff','#88ABDA','#B93A26','#7D5284','#CE8892'];
    }

    // 图表色卡选择
    getColor(index) {
        let findIndex = index;
        let colorList = [
            {
                'samllColor': '#88ABDA',
                'bigColor': '#2E59A7'
            },
            {
                'samllColor': '#ffba63',
                'bigColor': '#ff8400'
            },
            {
                'samllColor': '#6ff7ff',
                'bigColor': '#00e3ff'
            },
            {
                'samllColor': '#E7CAD3',
                'bigColor': '#CE8892'
            },
            {
                'samllColor': '#E67762',
                'bigColor': '#B93A26'
            },
            {
                'samllColor': '#D3CCD6',
                'bigColor': "#7D5284"
            }
        ];
        let count = colorList.length;
        if (index <= count) {
            findIndex = index;
        } else {
            let mod = count % index;
            findIndex = mod;
        }
        return colorList[findIndex];
    };

    // 堆叠图初始化
    setYCategoryStackChart(oneGroupData, oneGroupColumns, chartConfig) {
        let xAxis = []
        let yAxis = []
        let max = oneGroupData[0][oneGroupColumns[1]['column']]
        let min = 0
        for (let i = 0; i < oneGroupData.length; i += 1) {
            let y = oneGroupData[i][oneGroupColumns[0]['column']]
            let x = oneGroupData[i][oneGroupColumns[1]['column']]
            xAxis.push(x)
            yAxis.push(y)
            if (i > 0) {
                if (x > max) max = x;
                if (x < min) min = x;
            }
        }
        let xColumnList = this.util.filterData(oneGroupColumns, 'coordinate', 'x');
        let xColumnNameList = this.util.getArrayByPath(xColumnList, 'lable');

        let series = [];
        _sh.each(xColumnList, oneColumn => {
            let oneObj = {
                name: oneColumn['lable'],
                type: 'bar',
                //stack:'a',
                data: this.util.getArrayByPath(oneGroupData, oneColumn['column']),
                lable: {
                    show: true,
                    position: "top",
                    distance: 7,
                    color: chartConfig && chartConfig['fontColor'] ? chartConfig['fontColor'] : '#000'
                }

            }
            series.push(oneObj);
        })
        let option = {
            grid: {
                top: '15%',
                left: '5%',
                right: '5%',
                bottom: '10%',
                containLabel: true
            },
            colorBy:"series",
            color: this.getBlueColors(),
            visualMap: {
                show: false,
                type: 'continuous',
                seriesIndex:1,
                min: min,
                max: max * 1.1,
                inRange: {
                    color: this.getBlueColors()
                }

            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            legend: {
                backgroundColor: "rgb(139,154,192)",
                data: xColumnNameList
            },
            yAxis: {
                type: 'category',
                data: yAxis,
                axisLine: {
                    lineStyle: {
                        color: '#000'//chartConfig && chartConfig['fontColor'] ? chartConfig['fontColor'] : '#000'
                    }
                },
                axisLabel: {
                    textStyle: {
                        fontSize: 14,
                        color: '#000'//chartConfig && chartConfig['fontColor'] ? chartConfig['fontColor'] : '#000'
                    },
                    formatter: '{value}'
                },
                axisTick: {
                    show: false
                },
                splitLine: {
                    show: true
                }
            },
            xAxis: {
                type: 'value',
                axisLabel: {
                    color: '#000',//chartConfig && chartConfig['fontColor'] ? chartConfig['fontColor'] : '#000',
                    margin: 5,
                    fontSize: 12
                },
                axisLine: {
                    show: true
                },
                axisTick: {
                    show: false
                },
                splitNumber: 3,
                splitLine: {
                    lineStyle: {
                        color: chartConfig && chartConfig['fontColor'] ? chartConfig['fontColor'] : '#000',
                        width: 1
                    }
                }
            },
            series: series
        };
        return option;
    }

    // 饼图初始化
    setPieChart(oneGroupData, oneGroupColumns, chartConfig) {
        let legendData = []
        let seriesDate = []
        for (let i = 0; i < oneGroupData.length; i += 1) {
            let name = oneGroupData[i][oneGroupColumns[0]['column']]
            let value = oneGroupData[i][oneGroupColumns[1]['column']]
            let colorSelect = this.getColor(i);
            let oneLegendData = {
                name: name,
                textStyle: {
                    fontSize: 12,
                    fontWeight: 'bolder',
                    color: '#000'//chartConfig && chartConfig['fontColor'] ? chartConfig['fontColor'] : '#000'
                },
                icon: 'circle'
            }
            let oneSeriesDate = {
                value: value,
                name: name,
                itemStyle: {
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 1,
                        y2: 1,
                        colorStops: [
                            {
                                offset: 0.2, color: colorSelect['samllColor'] // 0% 处的颜色
                            },
                            {
                                offset: 1, color: colorSelect['bigColor']  // 100% 处的颜色
                            }],
                        globalCoord: true // 缺省为 false
                    }
                }
            }
            seriesDate.push(oneSeriesDate)
            legendData.push(oneLegendData)
        }
        let option = {
            grid: {
                top: '5%',
                left: '5%',
                right: '5%',
                bottom: '20%',
                containLabel: true
            },
            tooltip: {
                trigger: 'item',
                formatter: '{b} : {c} ({d}%)'
            },
            legend: {
                orient: chartConfig && chartConfig['legendConfig'] ? chartConfig['legendConfig']['orient'] : 'horizontal',
                x: chartConfig && chartConfig['legendConfig'] ? chartConfig['legendConfig']['x'] : 'center',
                y: chartConfig && chartConfig['legendConfig'] ? chartConfig['legendConfig']['y'] : 'bottom',
                data: legendData
            },
            series: [
                {
                    name: '租控比例',
                    type: 'pie',
                    radius: ['25%', '50%'],
                    roseType: chartConfig && chartConfig['chartType'] ? chartConfig['chartType'] : '',
                    label: {
                        color: '#000',//chartConfig && chartConfig['fontColor'] ? chartConfig['fontColor'] : '#000',
                        formatter: '{b}\n{c}({d}%)'
                    },
                    data: seriesDate
                }
            ]
        };
        return option;
    }

}
