import {forkJoin as observableForkJoin, Observable} from 'rxjs';
import {Component, OnInit, Input, ViewChild, Output, EventEmitter} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {AppConfigService} from './../../service/app-config.service';
import {RequestDataService} from './../../service/request-data.service';
import {UtilsService} from './../../service/utils.service';
import {ConfirmModalService} from './../../service/confirm-modal.service';
import * as _sh from "lodash";


@Component({
  selector: 'app-multi-poplist',
  templateUrl: './multi-poplist.component.html',
  styleUrls: ['./multi-poplist.component.css']
})
export class MultiPoplistComponent implements OnInit {

  @Input() hulkForm: FormGroup;
  @Input() hulkColumn: any;

  @Output('selectModel') childFun = new EventEmitter();

  // 改变绑定对象
  @Input() bindModel: any;
  @Input() forms: any;
  @Input() oneForms: any;
  // 是否显示弹框
  public isVisible: boolean = false;

  @ViewChild('childForm', {static: false}) childForm;

  // 绑定当前table的数组数据
  public dataSet: any = [];
  public totalDataSet: any = [];

  // 当前table的表头
  public showColumns: any = [];

  // 查询对象
  public conditionColumns: any = {
    columns: []
  };
  private tag = true; // 判断是否弹出页面 选择

  // 查询数据
  public conditionList: any[] = [];

  public allChecked = false;
  public indeterminate = false;
  public conditions;
  // 分页下标
  public pageIndex: any = 1;
  // 每页显示数据
  public pageSize: any = this.appConfigService.config.pageCount;

  public nzPageSizeOptions: any = [10, 20, 30, 50, 100, 200]

  // 总条数
  public total: any = 1;

  constructor(private requestDataService: RequestDataService,
              private appConfigService: AppConfigService,
              private utils: UtilsService,
              private confirmModal: ConfirmModalService) {
  }

  // checkbox 选中或未选中之后刷新总checkbox的状态
  refreshStatus(data?): void {
    const validData = this.dataSet.filter(value => !value.disabled);
    const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
    const allUnChecked = validData.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
  }

  // 将total选中数据和搜索彀中数据合并
  mergeTotalSelect() {
    _sh.each(this.totalDataSet, total => {
      const current = _sh.find(this.dataSet, data => total['code'] === data['code']);
      if (current) {
        total['checked'] = current['checked'];
      }
    });
  }

  checkAll(value: boolean): void {
    this.dataSet.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
    this.mergeTotalSelect();
  }

  // 合并查询条件
  transformCondition(columns) {
    let condition = [];
    _sh.each(columns, (item) => {
      if (this.utils.Base_HasValue(item['model'])) {
        /**
         * [obj 查询条件对象]
         * column 字段值对应数据库字段
         * label 标题
         * code 查询字段值
         * name 查询字段显示值用于字典
         * @type {Object}
         */
        let obj = {
          column: item['column'],
          title: item['lable'],
          code: item['model'],
          name: item['model'],
          component: item['component']
        };
        if (_sh.includes(['select', 'poplist', 'inputDataFilter'], item['component'])) {
          const selected = _sh.find(item['itemArray'], (v) => {
            return v['code'] === item['model'];
          });
          obj['name'] = selected['name'];
        } else if (_sh.includes(['multiSelect', 'multipopCondition'], item['component'])) {
          let nameList = [];
          _sh.each(item['itemArray'], (v) => {
            if (_sh.includes(item['model'], v['code'])) {
              nameList.push(v['name']);
            }
          });
          obj['name'] = nameList.join(' ');
        } else if (_sh.includes(['dateRange', 'dateTimeRange'], item['component'])) {
          obj['name'] = item['model'].join('~');
          obj['bindColumns'] = item['bindColumns'];
        }
        condition.push(obj);
      }
    });
    return condition;
  }

  //查找目标值
  findValue(pInsertValue, pErrormsg) {
    const that = this;

    function findTarget(targetTable, targetColum) {
      let tmpValues = null;
      for (let i = 0; i < that.forms.length; i++) {
        if (that.utils.Base_compare(that.forms[i].tablename, targetTable)) {
          tmpValues = that.utils.Base_getJsonValue(that.forms[i].data[0], targetColum, null);
          if (that.utils.Base_HasValue(tmpValues)) {
            break;
          }
        }
      }
      return tmpValues;
    }

    let tmp = pInsertValue;
    if (that.utils.Base_HasValue(pInsertValue)) {
      const targetParam = pInsertValue.split('.');
      if (_sh.isArray(targetParam)) {
        if (that.utils.Base_compare(targetParam[0], 'SYS')) {
          const targetTable = targetParam[1];
          const targetColum = targetParam[2];
          const tmpValues = findTarget(targetTable, targetColum);
          if (that.utils.Base_HasValue(tmpValues)) {
            tmp = tmpValues;
            that.tag = true;
          } else {
            that.confirmModal.show('warning', {
              'title': '温馨提示',
              'content': pErrormsg
            });
            that.tag = false;
            return false;
          }
        }
      }
    }
    return tmp;
  }

  makeConditions() {
    const dictionaryParam = this.utils.Base_getJsonValue(this.hulkColumn, 'dictionaryParam', null);
    let intputCondition = _sh.get(this.hulkColumn, 'inputCondition', null);
    let conditions = {};
    if (this.utils.Base_HasValue(dictionaryParam)) {
      _sh.each(this.hulkColumn.dictionaryParam, OneGroup => {
        const dicColumn = this.utils.Base_getJsonValue(OneGroup, 'column', null);
        const dicErrormsg = this.utils.Base_getJsonValue(OneGroup, 'errormsg', null);
        if (this.utils.Base_HasValue(dicColumn) && this.utils.Base_HasValue(dicErrormsg)) {
          const tmpValue = this.findValue(OneGroup['value'], OneGroup['errormsg']);
          if (this.utils.Base_HasValue(tmpValue)) {
            conditions[OneGroup['column']] = tmpValue;
          }
        }
      });
    }
    conditions = _sh.assign(conditions, intputCondition);
    if (this.utils.Base_HasValue(conditions)) {
      return conditions;
    } else {
      return null;
    }
  }

  searchFilter(reset: boolean = true) {
    this.conditionList = this.transformCondition(this.childForm._validateForm['columns']);
    let condition = {};
    _sh.each(this.conditionList, (item) => {
      // 如果是daterange 类型需要将字段从bindColumns中取出
      if (_sh.includes(['dateRange', 'dateTimeRange'], item['component'])) {
        _sh.each(item.code, (value, index) => {
          const column = item['bindColumns'][index];
          condition[column] = value;
        });
      } else {
        condition[item.column] = item.code;
      }
    });
    let intputCondition = _sh.get(this.hulkColumn, 'inputCondition', null);
    condition = _sh.assign(condition, intputCondition);
    condition = _sh.assign(condition, this.conditions);

    this.searchData(reset, condition);
  }

  resetFilter() {
    this.childForm.resetForm();
    this.searchFilter();
  }

  // 查询后的数据进行封装
  transformResult(data) {
    let dataSet = data.data || [];
    // 如果是修改。则之前选的数据要选中
    if (this.bindModel) {
      if (this.utils.Base_HasValue(this.bindModel[this.hulkColumn.column])) {
        _sh.each(dataSet, set => {
          if (this.bindModel[this.hulkColumn.column].indexOf(set['code']) > -1) {
            set['checked'] = true;
          }
        });
      }
    } else {
      if (this.utils.Base_HasValue(this.hulkColumn.model)) {
        _sh.each(dataSet, set => {
          if (this.hulkColumn.model.indexOf(set['code']) > -1) {
            set['checked'] = true;
          }
        });
      }
    }

    this.dataSet = dataSet;
    this.refreshStatus();
    this.showColumns = data.showColumns.columns || [];
    this.total = data.page.rowCount;
    this.pageIndex = data.page.pageNum;
  }

  // 将total选中数据和搜索彀中数据合并
  mergeDataSelect() {
    _sh.each(this.dataSet, data => {
      const current = _sh.find(this.totalDataSet, total => total['code'] === data['code']);
      if (current) {
        data['checked'] = current['checked'];
      }
    });
  }

  searchData(reset: boolean = false, condition?: {}): void {
    if (reset) {
      this.pageIndex = 1;
    }
    this.requestDataService.getPaginationData(this.hulkColumn.dictionary, condition || {}, this.pageIndex, this.pageSize).subscribe((data: any) => {
      this.transformResult(data);
      this.mergeDataSelect();
    });
  }

  // 移动数据 model - > data
  moveModelToData(): void {
    _sh.each(this.forms, OneGroup => {
      if (this.utils.Base_compare(OneGroup['type'], 'normal')) {
        if (!_sh.hasIn(OneGroup, 'data')) {
          OneGroup['data'] = [];
        }
        OneGroup['data'][0] = {}; // 清空
        _sh.each(OneGroup['columns'], function (OneColumn) {
          OneGroup['data'][0][OneColumn['column']] = OneColumn['model'];
        });
      } else {
        const oneForm = this.oneForms;
        if (this.utils.Base_compare(OneGroup['tablename'], oneForm.tablename)) {
          if (!_sh.hasIn(OneGroup, 'data')) {
            OneGroup['data'] = [];
          }
          OneGroup['data'][0] = {}; // 清空
          _sh.each(oneForm.columns, function (OneColumn) {
            OneGroup['data'][0][OneColumn['column']] = OneColumn['model'];
          });
        }
      }
    });
  }

  showModal() {
    if (this.hulkColumn.disable) {
      return false;
    }
    this.moveModelToData(); // 移动数据 model - > data
    this.conditions = this.makeConditions();
    if (this.tag) {
      const parallel$ = observableForkJoin(
        this.requestDataService.getPaginationData(this.hulkColumn.dictionary, this.conditions, this.pageIndex, this.pageSize),
        this.requestDataService.getConditionData(this.hulkColumn.dictionary)
      );
      parallel$.subscribe(
        values => {
          let dataObject = values[0];
          let itemData = dataObject['data'] || [];
          if (this.utils.Base_HasValue(itemData)) {
            if (this.utils.Base_HasValue(this.hulkColumn.itemArray)) {
              _sh.each(this.hulkColumn.itemArray, (item) => {
                let oneData = _sh.find(itemData, (value) => {
                  return item['code'] === value['code'];
                });
                if (this.utils.Base_HasValue(oneData)) {
                  itemData = _sh.without(itemData, oneData);
                }
              });
              this.hulkColumn.itemArray = this.hulkColumn.itemArray.concat(itemData);
            } else {
              this.hulkColumn.itemArray = itemData;
            }
          }
          this.conditionColumns = values[1]['conditionColumns'][0] || {};
          this.conditionColumns.needInit = true;
          this.transformResult(dataObject);
          this.isVisible = true;
          // 缓存所有数据
          this.totalDataSet = this.utils.DeepCopy(this.dataSet);
        }
      );
    }
  }

  removeValue() {
    if (this.bindModel) {
      this.bindModel[this.hulkColumn.column] = null;
      this.bindModel[this.hulkColumn.column + 'Name'] = '';
    } else {
      this.hulkColumn.model = null;
      this.hulkColumn.modelName = '';
    }
    this.childFun.emit({
      modelList: []
    });
  }


  // 多选点击确定按钮事件
  selectMulti() {
    this.mergeTotalSelect();
    let modelList = [];
    let nameList = [];
    let choiceObjects = [];
    _sh.each(this.totalDataSet, (item) => {
      if (item['checked']) {
        modelList.push(item['code']);
        nameList.push(item['name']);
        choiceObjects.push(item);
      }
    });
    if (this.hulkColumn.limitLength && modelList.length > this.hulkColumn.limitLength) {
      this.confirmModal.show('warning', {
        title: '温馨提示',
        content: '最多选择' + this.hulkColumn.limitLength + '条数据'
      });
      return;
    }
    if (this.hulkColumn.component === 'multipopCondition') {
      if (this.bindModel) {
        this.bindModel[this.hulkColumn.column] = modelList;
      } else {
        this.hulkColumn.model = modelList;
      }
    } else {
      if (this.bindModel) {
        this.bindModel[this.hulkColumn.column] = modelList.join(',');
      } else {
        this.hulkColumn.model = modelList.join(',');
      }
    }
    if (this.bindModel) {
      this.bindModel[this.hulkColumn.column + 'Name'] = nameList.join(' ');
    } else {
      this.hulkColumn.modelName = nameList.join(' ');
    }
    if (this.hulkColumn.columnGroup) {
      _sh.each(this.hulkColumn.columnGroup, oneGroup => {
        let fromValue = oneGroup['fromValue'];
        let values = [];
        _sh.each(choiceObjects, item => {
          values.push(item[fromValue]);
        });
        oneGroup['model'] = values.join(';');
      });
    }
    this.isVisible = false;
    this.childFun.emit({
      modelList: _sh.filter(this.dataSet, item => item['checked'])
    });
  }

  ngOnInit() {
    this.hulkColumn.lableSpan = this.hulkColumn.lableSpan || 9;
    this.hulkColumn.itemSpan = this.hulkColumn.itemSpan || 15;
  }

  // 监听model值得改变
  ngDoCheck(): void {
    if (this.bindModel) {
      if (this.bindModel[this.hulkColumn.column] !== this.bindModel['old' + this.hulkColumn.column]) {
        if (this.utils.Base_HasValue(this.bindModel[this.hulkColumn.column])) {
          if (this.hulkColumn.itemArray && this.hulkColumn.itemArray.length) {
            let nameList = [];
            _sh.each(this.hulkColumn.itemArray, (item) => {
              if (this.hulkColumn.component === 'multipopCondition') {
                if (_sh.includes(this.bindModel[this.hulkColumn.column], item['code'])) {
                  nameList.push(item['name']);
                }
              } else {
                if (this.bindModel[this.hulkColumn.column].indexOf(item['code']) >= 0) {
                  nameList.push(item['name']);
                }
              }

            });
            this.bindModel[this.hulkColumn.column + 'Name'] = nameList.join(' ');
          }
        }
        this.bindModel['old' + this.hulkColumn.column] = this.bindModel[this.hulkColumn.column];
      }
    } else {
      if (this.hulkColumn.model !== this.hulkColumn.oldModel) {
        if (this.utils.Base_HasValue(this.hulkColumn.model)) {
          if (this.hulkColumn.itemArray && this.hulkColumn.itemArray.length) {
            let nameList = [];
            _sh.each(this.hulkColumn.itemArray, (item) => {
              if (this.hulkColumn.component === 'multipopCondition') {
                if (_sh.includes(this.hulkColumn.model, item['code'])) {
                  nameList.push(item['name']);
                }
              } else {
                if (this.hulkColumn.model.indexOf(item['code']) >= 0) {
                  nameList.push(item['name']);
                }
              }

            });
            this.hulkColumn.modelName = nameList.join(' ');
          }
        }
        this.hulkColumn.oldModel = this.hulkColumn.model;
      }
    }
  }
}
