import {forkJoin as observableForkJoin, Observable, Subject} from 'rxjs';
import {Component, OnInit, Input, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {AppConfigService} from './../../service/app-config.service';
import {RequestDataService} from './../../service/request-data.service';
import {UtilsService} from './../../service/utils.service';
import {ConfirmModalService} from '../../service/confirm-modal.service';
import * as _sh from 'lodash';

import {debounceTime, distinctUntilChanged} from "rxjs/internal/operators";

@Component({
    selector: 'app-poplist',
    templateUrl: './poplist.component.html',
    styleUrls: ['./poplist.component.css']
})
export class PoplistComponent implements OnInit {

    @Input() isMulti = false;
    ax = true;
    @Input() hulkForm: FormGroup;
    @Input() hulkColumn: any;

    // 改变绑定对象
    @Input() bindModel: any;

    @Input() forms: any;
    @Input() oneForms: any;

    // 是否显示弹框
    public isVisible = false;

    @ViewChild('childForm', {static: false}) childForm;

    // 分页下标
    public pageIndex: any = 1;
    // 每页显示数据
    public pageSize: any = this.appConfigService.config.pageCount;
    // 总条数
    public total: any = 1;
    public nzPageSizeOptions: any = [10, 20, 30, 50, 100, 200];

    // 绑定当前table的数组数据
    public dataSet: any = [];

    // 当前table的表头
    public showColumns: any = [];

    // 查询对象
    public conditionColumns: any = {
        columns: []
    };
    private tag = true; // 判断是否弹出页面 选择
    // 查询数据
    public conditionList: any[] = [];

    public allChecked = false;
    public indeterminate = false;
    public conditions;
    public $filter = new Subject<any>();

    loadingFinished = false;
    previousValue = '';

    constructor(private requestDataService: RequestDataService,
                private appConfigService: AppConfigService,
                private utils: UtilsService,
                private confirmModal: ConfirmModalService) {
    }

    refreshStatus(data?): void {
        const validData = this.dataSet.filter(value => !value.disabled);
        const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
        const allUnChecked = validData.every(value => !value.checked);
        this.allChecked = allChecked;
        this.indeterminate = (!allChecked) && (!allUnChecked);
    }

    checkAll(value: boolean): void {
        this.dataSet.forEach(data => {
            if (!data.disabled) {
                data.checked = value;
            }
        });
        this.refreshStatus();
    }

    // 合并查询条件
    transformCondition(columns) {
        const condition = [];
        _sh.each(columns, (item) => {
            if (this.utils.Base_HasValue(item['model'])) {
                /**
                 * [obj 查询条件对象]
                 * column 字段值对应数据库字段
                 * label 标题
                 * code 查询字段值
                 * name 查询字段显示值用于字典
                 * @type {Object}
                 */
                const obj = {
                    column: item['column'],
                    title: item['lable'],
                    code: item['model'],
                    name: item['model'],
                    component: item['component']
                };
                if (_sh.includes(['select', 'poplist', 'inputDataFilter'], item['component'])) {
                    const selected = _sh.find(item['itemArray'], (v) => {
                        return v['code'] === item['model'];
                    });
                    obj['name'] = selected['name'];
                } else if (_sh.includes(['multiSelect', 'multipopCondition'], item['component'])) {
                    const nameList = [];
                    _sh.each(item['itemArray'], (v) => {
                        if (_sh.includes(item['model'], v['code'])) {
                            nameList.push(v['name']);
                        }
                    });
                    obj['name'] = nameList.join(' ');
                } else if (_sh.includes(['dateRange', 'dateTimeRange'], item['component'])) {
                    obj['name'] = item['model'].join('~');
                    obj['bindColumns'] = item['bindColumns'];
                }
                condition.push(obj);
            }
        });
        return condition;
    }

    searchFilter(reset: boolean = true) {
        this.conditionList = this.transformCondition(this.childForm._validateForm['columns']);
        let condition = {};
        _sh.each(this.conditionList, (item) => {
            // 如果是daterange 类型需要将字段从bindColumns中取出
            if (_sh.includes(['dateRange', 'dateTimeRange'], item['component'])) {
                _sh.each(item.code, (value, index) => {
                    const column = item['bindColumns'][index];
                    condition[column] = value;
                });
            } else {
                condition[item.column] = item.code;
            }
        });
        let intputCondition = _sh.get(this.hulkColumn, 'inputCondition', null);
        condition = _sh.assign(condition, intputCondition);
        condition = _sh.assign(condition, this.conditions);
        this.searchData(reset, condition);
    }

    resetFilter() {
        this.childForm.resetForm();
        this.searchFilter();
    }

    // 查询后的数据进行封装
    transformResult(data) {
        this.dataSet = data.data || [];
        this.showColumns = data.showColumns.columns || [];
        this.total = data.page.rowCount;
        this.pageIndex = data.page.pageNum;
    }

    searchData(reset: boolean = false, condition?: {}): void {
        if (reset) {
            this.pageIndex = 1;
        }
        this.requestDataService.getPaginationData(this.hulkColumn.dictionary, condition || {}, this.pageIndex, this.pageSize).subscribe((data: any) => {
            let itemData = data['data'] || [];
            if (this.utils.Base_HasValue(itemData)) {
                if (this.utils.Base_HasValue(this.hulkColumn.itemArray)) {
                    _sh.each(this.hulkColumn.itemArray, (item) => {
                        const oneData = _sh.find(itemData, (value) => {
                            return item['code'] === value['code'];
                        });
                        if (this.utils.Base_HasValue(oneData)) {
                            itemData = _sh.without(itemData, oneData);
                        }
                    });
                    this.hulkColumn.itemArray = this.hulkColumn.itemArray.concat(itemData);
                } else {
                    this.hulkColumn.itemArray = itemData;
                }
            }
            this.transformResult(data);
        });
    }

    // 移动数据 model - > data
    moveModelToData(): void {
        _sh.each(this.forms, OneGroup => {
            if (this.utils.Base_compare(OneGroup['type'], 'normal')) {
                if (!_sh.hasIn(OneGroup, 'data')) {
                    OneGroup['data'] = [];
                }
                OneGroup['data'][0] = {}; // 清空
                _sh.each(OneGroup['columns'], function (OneColumn) {
                    OneGroup['data'][0][OneColumn['column']] = OneColumn['model'];
                });
            } else {
                const oneForm = this.oneForms;
                if (this.utils.Base_compare(OneGroup['tablename'], oneForm.tablename)) {
                    if (!_sh.hasIn(OneGroup, 'data')) {
                        OneGroup['data'] = [];
                    }
                    OneGroup['data'][0] = {}; // 清空
                    _sh.each(oneForm.columns, function (OneColumn) {
                        OneGroup['data'][0][OneColumn['column']] = OneColumn['model'];
                    });
                }
            }
        });
    }

    //查找目标值
    findValue(pInsertValue, pErrormsg) {
        const that = this;

        function findTarget(targetTable, targetColum) {
            if (!that.utils.Base_HasValue(that.forms)) {
                that.forms.push(that.oneForms);
            }
            let tmpValues = null;
            for (let i = 0; i < that.forms.length; i++) {
                if (that.utils.Base_compare(that.forms[i].tablename, targetTable)) {
                    _sh.each(that.forms[i]['columns'], oneColumn => {
                        if (oneColumn['column'] == targetColum && !that.utils.Base_HasValue(tmpValues)) {
                            tmpValues = _sh.get(oneColumn, ['model'], null);
                        }
                    })
                }
            }
            return tmpValues;
        }

        function findOneFormTarget(targetColum) {
            let tmpValues = null;
            _sh.each(that.oneForms['columns'], oneColumn => {
                if (oneColumn['column'] == targetColum && !that.utils.Base_HasValue(tmpValues)) {
                    tmpValues = _sh.get(oneColumn, ['model'], null);
                }
            })
            return tmpValues;
        }

        let tmp = pInsertValue;
        if (that.utils.Base_HasValue(pInsertValue)) {
            const targetParam = pInsertValue.split('.');
            if (_sh.isArray(targetParam)) {
                if (that.utils.Base_compare(targetParam[0], 'SYS')) {
                    const targetTable = targetParam[1];
                    const targetColum = targetParam[2];
                    let tmpValues = null;
                    if (targetTable == 'SYS-ROW') {
                        tmpValues = findOneFormTarget(targetColum);
                    } else {
                        tmpValues = findTarget(targetTable, targetColum);
                    }
                    if (that.utils.Base_HasValue(tmpValues)) {
                        tmp = tmpValues;
                        that.tag = true;
                    } else {
                        that.confirmModal.show('warning', {
                            'title': '温馨提示',
                            'content': pErrormsg
                        });
                        that.tag = false;
                        return false;
                    }
                }
            }
        }
        return tmp;
    }

    // 变更上级，自动清空下级字段值
    clearChildColumnValue(clearValue): void {
        const that = this;

        function clearForm(targetTable, targetColum) {
            for (let i = 0; i < that.forms.length; i++) {
                if (that.utils.Base_compare(that.forms[i].tablename, targetTable)) {
                    _sh.each(that.forms[i].columns, function (oneColumn) {
                        if (that.utils.Base_compare(oneColumn['column'], targetColum)) {
                            oneColumn['model'] = null;
                            oneColumn['modelName'] = null;
                            oneColumn['dicVal'] = null;
                            if (that.utils.Base_HasValue(oneColumn['columnGroup'])) { //如果被清空字段有子级字段,一并清空
                                _sh.each(oneColumn['columnGroup'], function (oneCol) {
                                    oneCol['model'] = null;
                                    oneColumn['modelName'] = null;
                                });
                            }
                        }
                    });
                }
            }
        }

        if (that.utils.Base_HasValue(clearValue)) {
            const targetParam = clearValue.split('.');
            if (_sh.isArray(targetParam)) {
                if (that.utils.Base_compare(targetParam[0], 'SYS')) {
                    const targetTable = targetParam[1];
                    const targetColum = targetParam[2];
                    clearForm(targetTable, targetColum);
                }
            }
        }
    }

    makeConditions() {
        const dictionaryParam = this.utils.Base_getJsonValue(this.hulkColumn, 'dictionaryParam', null);
        let intputCondition = _sh.get(this.hulkColumn, 'inputCondition', null);
        let conditions = {};
        if (this.utils.Base_HasValue(dictionaryParam)) {
            _sh.each(this.hulkColumn.dictionaryParam, OneGroup => {
                const dicColumn = this.utils.Base_getJsonValue(OneGroup, 'column', null);
                const dicErrormsg = this.utils.Base_getJsonValue(OneGroup, 'errormsg', null);
                if (this.utils.Base_HasValue(dicColumn) && this.utils.Base_HasValue(dicErrormsg)) {
                    const tmpValue = this.findValue(OneGroup['value'], OneGroup['errormsg']);
                    if (this.utils.Base_HasValue(tmpValue)) {
                        conditions[OneGroup['column']] = tmpValue;
                    }
                }
            });
        }
        conditions = _sh.assign(conditions, intputCondition);
        if (this.utils.Base_HasValue(conditions)) {
            return conditions;
        } else {
            return null;
        }
    }

    clearDictionaryValue() {
        // 先判断是否有属性配置，如果有下一级，直接清空
        const dictParam = this.utils.Base_getJsonValue(this.hulkColumn, 'dictionaryParam', null);
        if (this.utils.Base_HasValue(dictParam)) {
            _sh.each(this.hulkColumn.dictionaryParam, oneDict => {
                const clearValue = this.utils.Base_getJsonValue(oneDict, 'clearValues', null);
                if (this.utils.Base_HasValue(clearValue)) {
                    _sh.each(oneDict['clearValues'], oneClearColumn => {
                        this.clearChildColumnValue(oneClearColumn);
                    });
                }
            });
        }
    }

    //清空poplist值
    removeValue(): void {
        if (!this.hulkColumn.disable) {
            if (this.bindModel) {
                this.bindModel[this.hulkColumn.column] = null;
                this.bindModel[this.hulkColumn.column + 'Name'] = '';
            } else {
                this.hulkColumn.model = null;
                this.hulkColumn.modelName = '';
            }
            this.clearDictionaryValue();
            // 判断columnGroup
            if (this.utils.Base_HasValue(this.hulkColumn['columnGroup']) && this.hulkColumn['columnGroup'].length > 0) {
                _sh.each(this.hulkColumn.columnGroup, oneData => {
                    oneData['model'] = null;
                });
            }
        }

    }

    // 弹出框
    showModal() {
        if (this.hulkColumn.disable) {
            return false;
        }
        this.moveModelToData(); // 移动数据 model - > data
        this.conditions = this.makeConditions();
        if (this.tag) {
            const parallel$ = observableForkJoin(
                this.requestDataService.getPaginationData(this.hulkColumn.dictionary, this.conditions, this.pageIndex, this.pageSize),
                this.requestDataService.getConditionData(this.hulkColumn.dictionary)
            );
            parallel$.subscribe(
                values => {
                    const dataObject = values[0];
                    let itemData = dataObject['data'] || [];
                    if (this.utils.Base_HasValue(itemData)) {
                        if (this.utils.Base_HasValue(this.hulkColumn.itemArray)) {
                            _sh.each(this.hulkColumn.itemArray, (item) => {
                                const oneData = _sh.find(itemData, (value) => {
                                    return item['code'] === value['code'];
                                });
                                if (this.utils.Base_HasValue(oneData)) {
                                    itemData = _sh.without(itemData, oneData);
                                }
                            });
                            this.hulkColumn.itemArray = this.hulkColumn.itemArray.concat(itemData);
                        } else {
                            this.hulkColumn.itemArray = itemData;
                        }
                    }
                    // this.hulkColumn.itemArray = dataObject['data'] || [];
                    this.conditionColumns = values[1]['conditionColumns'][0] || {};
                    this.conditionColumns.needInit = true;
                    this.transformResult(dataObject);
                    this.isVisible = true;
                }
            );
        }
    }


    // 单选框 点击行事件
    selectData(data) {
        if (!this.isMulti) {
            this.isVisible = false;
            if (this.bindModel) {
                this.bindModel[this.hulkColumn.column] = data.code;
                this.bindModel[this.hulkColumn.column + 'Name'] = data.name;
            } else {
                this.hulkColumn.model = data.code;
                this.hulkColumn.modelName = data.name;
            }
            if (this.utils.Base_HasValue(this.hulkColumn.columnGroup)
                && this.hulkColumn.columnGroup.length) {
                _sh.each(this.hulkColumn.columnGroup, (item) => {
                    item['model'] = data[item['fromValue']];
                });
            }
        }
    }

    onChange($event: any) {
        if (this.loadingFinished) {
            if (!this.utils.Base_HasValue(this.previousValue)) {
                this.previousValue = $event;
                this.$filter.next(Object.assign({}, $event));
            } else {
                if (this.previousValue != $event) {
                    this.$filter.next(Object.assign({}, $event));
                }
            }
        }
    }


    ngOnInit() {
        this.hulkColumn.lableSpan = this.hulkColumn.lableSpan || 9;
        this.hulkColumn.itemSpan = this.hulkColumn.itemSpan || 15;

        this.$filter.pipe(
            debounceTime(1000),
            distinctUntilChanged(
                (pre: any, curr: any) => {
                    console.log(pre, curr);
                    return pre === curr;
                }
            )
        ).subscribe(valueList => {
            console.log("Begin clearDictionaryValue");
            this.clearDictionaryValue();
            console.log("End clearDictionaryValue");
        })

    }

    ngDoCheck(): void {
        if (this.bindModel) {
            if (this.bindModel[this.hulkColumn.column] !== this.bindModel['old' + this.hulkColumn.column]) {
                if (this.utils.Base_HasValue(this.bindModel[this.hulkColumn.column])) {
                    const currentData = _sh.find(this.hulkColumn.itemArray, (item) => {
                        return item['code'] === this.bindModel[this.hulkColumn.column];
                    });
                    if (this.utils.Base_HasValue(currentData)) {
                        this.bindModel[this.hulkColumn.column + 'Name'] = currentData['name'];
                    }
                } else {
                    this.removeValue();
                }
                this.bindModel['old' + this.hulkColumn.column] = this.bindModel[this.hulkColumn.column];
            }
        } else {
            if (this.hulkColumn.model !== this.hulkColumn.oldModel) {
                if (this.utils.Base_HasValue(this.hulkColumn.model)) {
                    const currentData = _sh.find(this.hulkColumn.itemArray, (item) => {
                        return item['code'] === this.hulkColumn.model;
                    });
                    if (this.utils.Base_HasValue(currentData)) {
                        this.hulkColumn.modelName = currentData['name'];
                    }
                } else {
                    this.removeValue();
                }
                this.hulkColumn.oldModel = this.hulkColumn.model;
            }
        }
    }


    ngAfterViewInit() {
        this.loadingFinished = true;
    }
}
