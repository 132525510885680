
import {forkJoin as observableForkJoin, Observable} from 'rxjs';
import {Component, OnInit, Input, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {AppConfigService} from './../../service/app-config.service';
import {RequestDataService} from './../../service/request-data.service';
import {UtilsService} from './../../service/utils.service';
import {ConfirmModalService} from './../../service/confirm-modal.service';
import * as _sh from 'lodash';



@Component({
  selector: 'app-ds-multi-poplist',
  templateUrl: './ds-multi-poplist.component.html',
  styleUrls: ['./ds-multi-poplist.component.css']
})
export class DsMultiPoplistComponent implements OnInit {

  @Input() hulkForm: FormGroup;
  @Input() hulkColumn: any;

  // 改变绑定对象
  @Input() bindModel: any;

  // 是否显示弹框
  public isVisible: any = false;

  @ViewChild('childForm',{static: false}) childForm;

  // 绑定当前table的数组数据
  public dataSet: any = [];

  // 当前table的表头
  public showColumns: any = [];

  // 查询对象
  public conditionColumns: any = {
    columns: []
  };

  // 查询数据
  public conditionList: any[] = [];

  public allChecked = false;
  public indeterminate = false;


  constructor(private requestDataService: RequestDataService,
              private appConfigService: AppConfigService,
              private utils: UtilsService,
              private confirmModal: ConfirmModalService) {
  }

  // checkbox 选中或未选中之后刷新总checkbox的状态
  refreshStatus(data?): void {
    const validData = this.dataSet.filter(value => !value.disabled);
    const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
    const allUnChecked = validData.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
  }

  checkAll(value: boolean): void {
    this.dataSet.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }

  // 合并查询条件
  transformCondition(columns) {
    let condition = [];
    _sh.each(columns, (item) => {
      if (this.utils.Base_HasValue(item['model'])) {
        /**
         * [obj 查询条件对象]
         * column 字段值对应数据库字段
         * label 标题
         * code 查询字段值
         * name 查询字段显示值用于字典
         * @type {Object}
         */
        let obj = {
          column: item['column'],
          title: item['lable'],
          code: item['model'],
          name: item['model'],
          component: item['component']
        };
        if (_sh.includes(['select', 'poplist', 'inputDataFilter'], item['component'])) {
          const selected = _sh.find(item['itemArray'], (v) => {
            return v['code'] === item['model'];
          });
          obj['name'] = selected['name'];
        } else if (_sh.includes(['multiSelect', 'multipopCondition'], item['component'])) {
          let nameList = [];
          _sh.each(item['itemArray'], (v) => {
            if (_sh.includes(item['model'], v['code'])) {
              nameList.push(v['name']);
            }
          });
          obj['name'] = nameList.join(' ');
        } else if (_sh.includes(['dateRange', 'dateTimeRange'], item['component'])) {
          obj['name'] = item['model'].join('~');
          obj['bindColumns'] = item['bindColumns'];
        }
        condition.push(obj);
      }
    });
    return condition;
  }

  searchFilter() {
    this.conditionList = this.transformCondition(this.childForm._validateForm['columns']);
    let condition = {};
    _sh.each(this.conditionList, (item) => {
      // 如果是daterange 类型需要将字段从bindColumns中取出
      if (_sh.includes(['dateRange', 'dateTimeRange'], item['component'])) {
        _sh.each(item.code, (value, index) => {
          const column = item['bindColumns'][index];
          condition[column] = value;
        });
      } else {
        condition[item.column] = item.code;
      }
    });
    this.searchData(condition);
  }

  resetFilter() {
    this.childForm.resetForm();
    this.searchFilter();
  }

  // 查询后的数据进行封装
  transformResult(data) {
    let dataSet = data.data || [];
    // 如果是修改。则之前选的数据要选中
    if (this.bindModel) {
      if (this.utils.Base_HasValue(this.bindModel[this.hulkColumn.column])) {
        _sh.each(dataSet, set => {
          if (this.bindModel[this.hulkColumn.column].indexOf(set['code']) > -1) {
            set['checked'] = true;
          }
        });
      }
    } else {
      if (this.utils.Base_HasValue(this.hulkColumn.model)) {
        _sh.each(dataSet, set => {
          if (this.hulkColumn.model.indexOf(set['code']) > -1) {
            set['checked'] = true;
          }
        });
      }
    }

    this.dataSet = dataSet;
    this.refreshStatus();
    this.showColumns = data.showColumns.columns || [];
  }

  searchData(condition?: {}): void {
    this.requestDataService.getDataByCondition(this.hulkColumn.dictionary, condition || {}).subscribe((data: any) => {
      this.transformResult(data);
    });
  }


  showModal() {
    const parallel$ = observableForkJoin(
      this.requestDataService.getDataByCondition(this.hulkColumn.dictionary, {}),
      this.requestDataService.getConditionData(this.hulkColumn.dictionary)
    );

    parallel$.subscribe(
      values => {
        let dataObject = values[0];
        this.hulkColumn.itemArray = dataObject['data'] || [];
        this.conditionColumns = values[1]['conditionColumns'][0] || {};
        this.conditionColumns.needInit = true;
        this.transformResult(dataObject);
        this.isVisible = true;
      }
    );
  }

  removeValue() {
    if (this.bindModel) {
      this.bindModel[this.hulkColumn.column] = null;
      this.bindModel[this.hulkColumn.column + 'Name'] = '';
      this.bindModel[this.hulkColumn.column + 'showName'] = '';
    } else {
      this.hulkColumn.model = null;
      this.hulkColumn.modelName = '';
      this.hulkColumn.showName = '';
    }
  }


  // 多选点击确定按钮事件
  selectMulti() {
    let modelList = [];
    let nameList = [];
    _sh.each(this.dataSet, (item) => {
      if (item['checked']) {
        modelList.push(item['code']);
        nameList.push(item['name']);
      }
    });
    if (this.hulkColumn.limitLength && modelList.length > this.hulkColumn.limitLength) {
      this.confirmModal.show('warning', {
        title: '温馨提示',
        content: '最多选择' + this.hulkColumn.limitLength + '条数据'
      });
      return;
    }
    if (this.hulkColumn.component === 'multipopCondition') {
      if (this.bindModel) {
        this.bindModel[this.hulkColumn.column] = modelList;
      } else {
        this.hulkColumn.model = modelList;
      }
    } else {
      if (this.bindModel) {
        this.bindModel[this.hulkColumn.column] = modelList.join('<,>');
      } else {
        this.hulkColumn.model = modelList.join('<,>');
      }
    }
    if (this.bindModel) {
      this.bindModel[this.hulkColumn.column + 'Name'] = nameList.join('<,>');
      this.bindModel[this.hulkColumn.column + 'showName'] = nameList.join(' ');
    } else {
      this.hulkColumn.modelName = nameList.join('<,>');
      this.hulkColumn.showName = nameList.join(' ');
    }
    this.isVisible = false;
  }

  ngOnInit() {
    this.hulkColumn.lableSpan = this.hulkColumn.lableSpan || 9;
    this.hulkColumn.itemSpan = this.hulkColumn.itemSpan || 15;
  }

  // 监听model值得改变
  ngDoCheck(): void {
    if (this.bindModel) {
      if (this.bindModel[this.hulkColumn.column] !== this.bindModel['old' + this.hulkColumn.column]) {
        if (this.utils.Base_HasValue(this.bindModel[this.hulkColumn.column])) {
          if (this.hulkColumn.itemArray && this.hulkColumn.itemArray.length) {
            let nameList = [];
            _sh.each(this.hulkColumn.itemArray, (item) => {
              if (this.hulkColumn.component === 'multipopCondition') {
                if (_sh.includes(this.bindModel[this.hulkColumn.column], item['code'])) {
                  nameList.push(item['name']);
                }
              } else {
                if (this.bindModel[this.hulkColumn.column].indexOf(item['code']) >= 0) {
                  nameList.push(item['name']);
                }
              }

            });
            this.bindModel[this.hulkColumn.column + 'Name'] = nameList.join('<,>');
            this.bindModel[this.hulkColumn.column + 'showName'] = nameList.join(' ');
          } else {
            if (this.utils.Base_HasValue(this.bindModel[this.hulkColumn.column + 'Name'])) {
              this.bindModel[this.hulkColumn.column + 'showName'] = this.bindModel[this.hulkColumn.column + 'Name'].split('<,>').join(' ');
            }
          }
        }
        this.bindModel['old' + this.hulkColumn.column] = this.bindModel[this.hulkColumn.column];
      }
    } else {
      if (this.hulkColumn.model !== this.hulkColumn.oldModel) {
        if (this.utils.Base_HasValue(this.hulkColumn.model)) {
          if (this.hulkColumn.itemArray && this.hulkColumn.itemArray.length) {
            let nameList = [];
            _sh.each(this.hulkColumn.itemArray, (item) => {
              if (this.hulkColumn.component === 'multipopCondition') {
                if (_sh.includes(this.hulkColumn.model, item['code'])) {
                  nameList.push(item['name']);
                }
              } else {
                if (this.hulkColumn.model.indexOf(item['code']) >= 0) {
                  nameList.push(item['name']);
                }
              }

            });
            this.hulkColumn.modelName = nameList.join('<,>');
            this.hulkColumn.showName = nameList.join(' ');
          }
        }
        this.hulkColumn.oldModel = this.hulkColumn.model;
      }
    }
  }

}
