import {Component, Input, OnInit, ViewChildren} from '@angular/core';
import {DataCenterService} from "../../service/data-center.service";
import {DetailActionService} from "../../service/detail-action.service";
import {forkJoin as observableForkJoin} from "rxjs";
import {RequestDataService} from "../../service/request-data.service";
import * as _sh from 'lodash';
import {UtilsService} from "../../service/utils.service";
import {ConfirmModalService} from "../../service/confirm-modal.service";
import {UserInfoService} from "../../service/user-info.service";

@Component({
    selector: 'app-paper-design',
    templateUrl: './paper-design.component.html',
    styleUrls: ['./paper-design.component.scss']
})
export class PaperDesignComponent implements OnInit {

    @ViewChildren('mainForm') mainForm;
    @Input() parentData: any;
    title = "新增";
    baseForm: any = {
        'type': 'normal',
        'columns': [
            {
                'column': 'ID',
                'iscondition': true,
                'pk_column': true,
                'name': 'ID_NAME',
                'component': 'input',
                'lable': '主键',
                'comment': '主键',
                'placeholder': '主键',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'insertValue': 'SYS-ACTION-UUID',
                'model': null,
                'visible': false,
                'require': true
            },
            {
                'column': 'HR',
                'component': 'hr',
                'lable': '试卷基本信息',
                'customize': 24,
                'comment': '试卷基本信息',
                'visible': true,
                'wechatVisable': true,
                'line': false,
                'commitPoint': [
                    'detail'
                ],
                'placeholder': '试卷基本信息',
                'model': null
            },
            {
                'column': 'PQ_STATE',
                'name': 'PQ_STATE_NAME',
                'component': 'select',
                'lable': '试卷状态',
                'comment': '试卷状态',
                'placeholder': '请输入试卷状态',
                'insertValue': null,
                'model': 'INIT',
                'visible': false,
                'require': false,
                'dictionary': 'assets/basic/dict/PQ_STATE.json',
                'dictionaryPF': null
            },
            {
                'column': 'QP_TYPE',
                'name': 'QP_TYPE_NAME',
                'component': 'select',
                'lable': '试卷类型',
                'comment': '试卷类型',
                'placeholder': '请选择试卷类型',
                'insertValue': null,
                'model': null,
                'visible': true,
                'require': true,
                'dictionary': 'assets/basic/dict/QP_TYPE.json',
                'dictionaryPF': null
            },
            {
                'column': 'PERSON_TYPE',
                'name': 'PERSON_TYPE_NAME',
                'component': 'select',
                'lable': '人员类型',
                'comment': '人员类型',
                'placeholder': '请选择人员类型',
                'insertValue': null,
                'model': null,
                'visible': true,
                'require': true,
                'dictionary': 'assets/basic/dict/PERSON_TYPE.json',
                'dictionaryPF': null
            },
            {
                'column': 'TITLE',
                'name': 'TITLE_NAME',
                'component': 'input',
                'lable': '试卷标题',
                'comment': '试卷标题',
                'placeholder': '请输入试卷标题',
                'lableSpan': 3,
                'itemSpan': 21,
                'customize': 24,
                'insertValue': null,
                'model': null,
                'visible': true,
                'require': true,
            },
            {
                'column': 'CONTENT',
                'name': 'CONTENT_NAME',
                'component': 'textarea',
                'lable': '注意事项',
                'comment': '注意事项',
                'placeholder': '请输入注意事项',
                'lableSpan': 3,
                'itemSpan': 21,
                'customize': 24,
                'insertValue': null,
                'model': null,
                'visible': true,
                'require': false,
            },
            {
                'column': 'START_TIME',
                'name': 'START_TIME_NAME',
                'component': 'datetime',
                'lable': '开始时间',
                'comment': '开始时间',
                'placeholder': '请输入开始时间',
                'insertValue': null,
                'model': null,
                'visible': true,
                'require': true,
            },
            {
                'column': 'END_TIME',
                'name': 'END_TIME_NAME',
                'component': 'datetime',
                'lable': '结束时间',
                'comment': '结束时间',
                'placeholder': '请输入结束时间',
                'insertValue': null,
                'model': null,
                'visible': true,
                'require': true,
            },
            {
                'column': 'ADD_USERID',
                'name': 'ADD_USERID_NAME',
                'component': 'input',
                'lable': '添加人账户ID',
                'comment': '添加人账户ID',
                'placeholder': '请输入添加人账户ID',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'insertValue': 'SYS_USER_ID',
                'model': null,
                'visible': false,
                'require': false
            },
            {
                'column': 'ADD_TIME',
                'name': 'ADD_TIME_NAME',
                'component': 'input',
                'lable': '添加时间',
                'comment': '添加时间',
                'placeholder': '添加时间',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'insertValue': 'SYS-DATETIME',
                'disable': [
                    'add',
                    'modify'
                ],
                'commitPoint': [
                    'add'
                ],
                'model': null,
                'visible': false
            },
            {
                'column': 'ADD_NAME',
                'name': 'ADD_NAME_NAME',
                'component': 'input',
                'lable': '添加人',
                'comment': '添加人',
                'placeholder': '请输入添加人姓名',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'insertValue': 'SYS_USER_NAME',
                'disable': [
                    'add',
                    'modify'
                ],
                'commitPoint': [
                    'add'
                ],
                'model': null,
                'visible': false
            },
            {
                'column': 'UPD_USERID',
                'name': 'UPD_USERID_NAME',
                'component': 'input',
                'lable': '修改人',
                'comment': '修改人',
                'placeholder': '修改人',
                'insertValue': 'SYS_USER_ID',
                'model': null,
                'visible': false,
                'disable': [
                    'add',
                    'modify'
                ],
                'commitPoint': [
                    'modify'
                ]
            },
            {
                'column': 'UPD_NAME',
                'name': 'UPD_NAME_NAME',
                'component': 'input',
                'lable': '修改人',
                'comment': '修改人',
                'placeholder': '修改人',
                'insertValue': 'SYS_USER_NAME',
                'model': null,
                'visible': false,
                'disable': [
                    'add',
                    'modify'
                ],
                'commitPoint': [
                    'modify'
                ]
            },
            {
                'column': 'UPD_TIME',
                'name': 'UPD_TIME_NAME',
                'component': 'input',
                'lable': '更新时间',
                'comment': '更新时间',
                'placeholder': '更新时间',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'insertValue': 'SYS-DATETIME',
                'disable': [
                    'add',
                    'modify'
                ],
                'commitPoint': [
                    'modify'
                ],
                'model': null,
                'visible': false
            },
            {
                'column': 'U_TIME',
                'name': 'U_TIME_NAME',
                'component': 'input',
                'lable': '更新时间',
                'comment': '更新时间',
                'placeholder': '请选择更新时间',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'insertValue': 'SYS-DATETIME',
                'disable': [
                    'add',
                    'modify'
                ],
                'commitPoint': [
                    'add'
                ],
                'model': null,
                'visible': false
            },
            {
                'column': 'U_DELETE',
                'name': 'U_DELETE_NAME',
                'component': 'input',
                'lable': '删除表识',
                'comment': '删除表识',
                'placeholder': '请输入删除表识',
                'class': 'col-lg-4 col-sm-6 col-xs-12',
                'model': 1,
                'visible': false,
                'require': false
            }
        ],
        'qaQuestionList': []
    };
    prvForms: any;

    public user: any;
    qa_id = null;
    opList: any = ['A.', 'B.', 'C.', 'D.', 'E.', 'F.', 'G.', 'H.', 'I.', 'J.', 'K.', 'L.', 'M.', 'N.']
    // 当前table的表头
    public showColumns: any = [
        {
            "column": "ID",
            "title": "主键",
            "visible": false,
            "pk_column": true,
            "iscondition": true,
            "conditionColumn": "ID",
            "type": "head",
            "class": ""
        },
        {
            "column": "QQ_TYPE_DICT",
            "title": "题型",
            "visible": true,
            "export": true,
            "type": "head",
            "class": ""
        },
        {
            "column": "TITLE",
            "title": "题目标题",
            "visible": true,
            "export": true,
            "type": "head",
            "class": ""
        },
        {
            "column": "SCORE",
            "title": "分数",
            "visible": true,
            "export": true,
            "type": "head",
            "class": ""
        }
    ];

    // 查询对象
    public conditionColumns: any = {
        "columns": [
            {
                "column": "TITLE",
                "name": "TITLE_NAME",
                "component": "input",
                "lable": "题目标题",
                "comment": "题目标题",
                "placeholder": "请输入题目标题",
                "model": null,
                "visible": true
            }
        ]
    };

    questionModalVisible: Boolean = false;
    public dataSet: any = [];
    condition = {};
    // 总条数checkAll
    public total: any = 1;
    // 分页下标
    public pageIndex: any = 1;
    // 每页显示数据
    public pageSize: any = 5;
    public nzPageSizeOptions: any = [5, 10, 20, 50, 100, 200];
    public allChecked = false;
    public indeterminate = false;

    constructor(
        private dataCenterService: DataCenterService,
        private detailAction: DetailActionService,
        private requestService: RequestDataService,
        private utils: UtilsService,
        private confirmModal: ConfirmModalService,
        private userInfoService: UserInfoService,
    ) {
        this.user = userInfoService.getUserInfo();
    }


    gotoBack(): void {
        this.parentData.data = {};
        this.parentData.conditionColumns = {};
        this.parentData.formType = 'listForm';
        this.parentData.actionArray = [];
        // 覆盖当前数据到data-center;
        _sh.each(this.dataCenterService._dataObject.list, (item) => {
            if (item['id'] === this.parentData.id) {
                item = _sh.extend(item, this.parentData);
            }
        });
        this.dataCenterService.refreshDataCenterInStorage();
    }


    addNewQuestion() {
        this.dataSet = [];
        this.questionModalVisible = true;
        this.searchFilter(true);
    }

    // 多选点击确定按钮事件
    selectMulti() {
        const selectedQuestions = this.dataSet.filter(data => data.checked);
        const duplicateQuestions = selectedQuestions.filter(question =>
            this.baseForm.qaQuestionList.some(q => q.ID === question.ID)
        );

        if (duplicateQuestions.length > 0) {
            const duplicateTitles = duplicateQuestions.map(q => q.TITLE).join(', ');
            this.confirmModal.show('error', {
                title: '监察重复项',
                content: `以下题目已经被选中，请确认后再操作：${duplicateTitles}`
            });
            return;
        }

        // 如果没有重复，添加到试卷题目列表中
        this.baseForm.qaQuestionList.push(...selectedQuestions);
        this.questionModalVisible = false;
    }


    doCancel() {
        this.questionModalVisible = false;
    }

    closeQuestion(pOneQa) {
        this.confirmModal.show('confirm', {
            title: '是否将该试题，从试题列表之中删除',
            suc: () => {
                this.baseForm.qaQuestionList = _sh.without(this.baseForm.qaQuestionList, pOneQa);
            }
        });
    }

    findValue(pForm, pColumnName) {
        let bFind = false;
        let value = null;
        _sh.each(pForm['columns'], oneColumn => {
            if ((oneColumn['column'] == pColumnName) && (!bFind)) {
                value = oneColumn['model'];
                bFind = true;
            }
        });
        return value;
    }

    searchFilter(reset: boolean = true) {
        this.condition = this.makeConditions();
        this.searchData(reset);
    }

    makeConditions() {
        let conditions = {};
        _sh.each(this.conditionColumns['columns'], oneColum => {
            if (this.utils.Base_HasValue(oneColum['model'])) conditions[oneColum['column']] = oneColum['model'];
        })
        conditions['ID'] = ''
        if (this.utils.Base_HasValue(conditions)) {
            return conditions;
        } else {
            return null;
        }
    }

    searchData(reset: boolean = false): void {
        if (reset) {
            this.pageIndex = 1;
        }
        this.requestService.getPaginationData('assets/qa_question/qa_question.json', this.condition || {}, this.pageIndex, this.pageSize).subscribe((data: any) => {
            this.transformResult(data);
        });
    }

    // 查询后的数据进行封装
    transformResult(data) {
        let dataSet = data.data || [];
        this.dataSet = dataSet;
        this.refreshStatus();
        this.total = data.page.rowCount;
        this.pageIndex = data.page.pageNum;
    }

    // checkbox 选中或未选中之后刷新总checkbox的状态
    refreshStatus(data?): void {
        const validData = this.dataSet.filter(value => !value.disabled);
        const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
        const allUnChecked = validData.every(value => !value.checked);
        this.allChecked = allChecked;
        this.indeterminate = (!allChecked) && (!allUnChecked);
    }

    resetFilter() {
        _sh.each(this.conditionColumns['columns'], oneColum => {
            oneColum['model'] = null;
        })
        this.searchFilter();
    }

    checkAll(value: boolean): void {
        this.dataSet.forEach(data => {
            if (!data.disabled) {
                data.checked = value;
            }
        });
        this.refreshStatus();
    }

    //检查试题
    checkInputItem() {
        let bHasValue = true;
        bHasValue = this.baseForm.qaQuestionList.length > 0;
        if (!bHasValue) {
            this.confirmModal.show('error', {title: '试题内容不能为空。'});
        } else {
            _sh.each(this.baseForm.qaQuestionList, oneQa => {
                _sh.each(oneQa['columns'], oneQuestion => {
                    if (bHasValue && oneQuestion['require']) {
                        bHasValue = this.utils.Base_HasValue(oneQuestion['model']) || this.utils.Base_HasValue(oneQuestion['insertValue']);
                        if (!bHasValue) {
                            this.confirmModal.show('error', {title: '请填写必填信息。', content: oneQuestion['lable']});
                        }
                    }
                })
            });
        }
        return bHasValue;
    }

    //检查主表信息输入
    checkInputMain() {
        let bHasValue = true;
        _sh.each(this.baseForm['columns'], oneColumn => {
            if (bHasValue && oneColumn['require']) {
                bHasValue = this.utils.Base_HasValue(oneColumn['model']) || this.utils.Base_HasValue(oneColumn['insertValue']);
                if (!bHasValue) {
                    this.confirmModal.show('error', {title: '请填写必填信息。', content: oneColumn['lable']});
                }
            }
        })
        return bHasValue;
    }

    formatToEntity(datas) {
        let entity: any = {};
        _sh.each(datas, (item) => {
            const column = _sh.camelCase(_sh.get(item, 'column'));
            const value = _sh.get(item, 'model');
            entity[column] = value;
            let columnGroup = _sh.get(item, 'columnGroup', null);
            _sh.each(columnGroup, (oneGroupColumn) => {
                const itemColumn = _sh.camelCase(_sh.get(oneGroupColumn, 'column'));
                const itemValue = _sh.get(oneGroupColumn, 'model');
                entity[itemColumn] = itemValue;
            });
        });
        return entity;
    }


    doSave(): void {
        if (this.checkInputMain() && this.checkInputItem()) {
            if (this.parentData.modeType == 'add') {
                // 如果是添加状态，增加一个UUID
                _sh.each(this.baseForm['columns'], oneColumn => {
                    if (oneColumn['column'] == 'ID') {
                        oneColumn['model'] = this.utils.Base_getUuid();
                    }
                });
            }
            let qaPaperQuestionList = [];
            let qaPaperQuestionOptionList = [];
            let ID = this.findValue(this.baseForm, 'ID')

            _sh.each(this.baseForm.qaQuestionList, oneQa => {
                let template1 = {
                    'number': this.baseForm.qaQuestionList.indexOf(oneQa),
                    'id': this.utils.Base_getUuid(),
                    'paperId': ID,
                    'qqId': oneQa['ID'],
                    'qqType': oneQa['QQ_TYPE'],
                    'title': oneQa['TITLE'],
                    'content': oneQa['CONTENT'],
                    'score': oneQa['SCORE']
                };
                qaPaperQuestionList.push(template1);
                let pqId = _sh.get(qaPaperQuestionList[this.baseForm.qaQuestionList.indexOf(oneQa)], 'id');
                _sh.each(oneQa['ID_DICT_LIST'], oneOp => {
                    let template2 = {
                        'number': oneQa['ID_DICT_LIST'].indexOf(oneOp),
                        'id': this.utils.Base_getUuid(),
                        'paperId': ID,
                        'pqId': pqId,
                        'qqId': oneQa['ID'],
                        'qaOption': this.opList[oneQa['ID_DICT_LIST'].indexOf(oneOp)],
                        'opContent': oneOp['OP_CONTENT'],
                        'answer': oneOp['ANSWER']
                    }
                    qaPaperQuestionOptionList.push(template2);
                })
            })

            let queryData = {
                'userId': this.user.USER_ID,
            };
            const bodyData = {
                'qaPaper': this.formatToEntity(this.baseForm['columns']),
                'qaPaperQuestionList': qaPaperQuestionList,
                'qaPaperQuestionOptionList': qaPaperQuestionOptionList
            };
            this.requestService.doActionByHdbsthor('AppApi/QaPaperController', 'doInputQaPaper', queryData, bodyData, false, 'hdbsthor_assets', 'appToken').subscribe(response => {
                let status = _sh.get(response, ['status'], 200);
                if (status == 200) {
                    this.qa_id = _sh.get(response, ['qaPaperInerface', 'qaPaper', 'id'], this.qa_id);
                    _sh.set(this.parentData, ['modeType'], 'modify');
                    this.confirmModal.show('success', {'title': '执行成功'});
                    this.gotoBack();
                } else if (status == 500) {
                    this.confirmModal.show('error', {'title': '执行成功错误如下', 'content': response.error.message});
                }
            });
        }
    }

    doSearch() {
        const search = observableForkJoin(
            this.requestService.getDataByCondition('assets/qa_paper/qa_paper.json', {'ID': this.qa_id}), // 0
            this.requestService.getDataByCondition('assets/qa_paper/qa_paper_question.json', {'PAPER_ID': this.qa_id}), // 1
        );
        search.subscribe(values => {
            //获取试卷
            this.backfillData(this.baseForm, values[0]['data']);
            //获取该试卷题目
            this.baseForm.qaQuestionList = [];
            this.baseForm.qaQuestionList = _sh.get(values, [1, 'data'], []);
        })
    }

    backfillData(pForm, pDataArray) {
        _sh.each(pDataArray, oneData => {
            _sh.each(pForm['columns'], oneColumn => {
                oneColumn['model'] = oneData[oneColumn['column']];
                _sh.each(oneColumn['columnGroup'], oneColumnGroup => {
                    oneColumnGroup['model'] = oneData[oneColumnGroup['column']]
                })

            })
        })
    }

    ngOnInit() {
        let modeType = _sh.get(this.parentData, ['modeType']);
        if (modeType == 'modify') {
            this.title = "修改";
            this.qa_id = _sh.cloneDeep(_sh.get(this.parentData, ['id']));
            this.doSearch();
        } else {
            this.title = "新增";
        }
    }

}
