import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-descriptions-edit-more-btn',
  templateUrl: './descriptions-edit-more-btn.component.html',
  styleUrls: ['./descriptions-edit-more-btn.component.scss']
})
export class DescriptionsEditMoreBtnComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
