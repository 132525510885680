import {Component, Input, OnInit} from '@angular/core';
import * as _sh from 'lodash';
import {UtilsService} from "../../service/utils.service";
import {UploadXHRArgs} from "ng-zorro-antd";
import {AppConfigService} from "../../service/app-config.service";
import {UploadService} from "../../service/upload.service";
import * as moment from "moment/moment";

@Component({
    selector: 'app-form-files',
    templateUrl: './form-files.component.html',
    styleUrls: ['./form-files.component.scss']
})
export class FormFilesComponent implements OnInit {


    @Input() modeType: string = '';

    @Input() forms: any = [];

    // form 组件数据
    @Input() formData: any = {
        columns: []
    };

    constructor(
        private utils: UtilsService,
        private config: AppConfigService,
        private uploadService: UploadService
    ) {
    }

    ngOnInit() {
        console.log("form-files.component.ts 01", this.formData);
        console.log("form-files.component.ts 02", this.forms);
        _sh.each(this.formData['fileList'], oneRow => {
            oneRow.FILE_TYPE = this.utils.Base_getFileType(oneRow.FILE_URL);
        })
    }

    handleDbClick() {
        console.log(this.formData['fileList']);
    }

    handleDelete(data: any) {
        data['FILE_NAME'] = null;
        data['FILE_URL'] = null;
        data['IS_EXPIRATION'] = false;
        data['EXPIRATION_DATE'] = null;
    }

    handleDownload(data: any) {
        window.location.href = data['FILE_URL'];
    }

    handleIsExpirationChange(data: any, $event: any) {
        if (!$event) {
            data['EXPIRATION_DATE'] = "2099-01-01";
            data['EXPIRATION_TYPE'] = "NO";
        } else {
            data['EXPIRATION_TYPE'] = "YES";
        }
    }

    handleChange(data: any, $event: any) {
        if ($event) {
            data['EXPIRATION_DATE'] = moment($event).format('YYYY-MM-DD');
        } else {
            data['EXPIRATION_DATE'] = null;
        }
    }

    execExtra(_oneRow) {
        _oneRow['FILE_TYPE'] = this.utils.Base_getFileType(_oneRow['FILE_URL']);
        _oneRow['FILE_NAME'] = this.utils.Base_getFileNameOnly(_oneRow['FILE_URL']);
        _oneRow['IS_EXPIRATION'] = false;
        _oneRow['EXPIRATION_DATE'] = null;
    }

    customReq = (item: UploadXHRArgs) => {
        if (this.config.config['userOssOrCos'] === 'COS') {
            return this.uploadService.doCosUpload(item.file, item['progress'], "folder", "uploadPath", null).then((result) => {
                item.onSuccess({}, item.file, {});
                item.data['FILE_URL'] = this.config.config['COS']['domain'] + '/' + result['url'];
                this.execExtra(item.data);
            });
        } else {
            return this.uploadService.doAliOssUpload(item.file, item['progress'], "folder").then((result) => {
                item.onSuccess({}, item.file, {});
                item.data['FILE_URL'] = this.config.config['OSS']['domain'] + '/' + result.name;
                this.execExtra(item.data);
            });
        }
    };
}
