import {Component, OnInit, Input} from '@angular/core';
import {UtilsService} from './../../service/utils.service';
import {ConfirmModalService} from './../../service/confirm-modal.service';
import {DataCenterService} from './../../service/data-center.service';
import {AppConfigService} from './../../service/app-config.service';
import {UserInfoService} from './../../service/user-info.service';
import {AjaxService} from './../../service/ajax.service';
import {RequestDataService} from './../../service/request-data.service';

import * as _sh from 'lodash';

@Component({
    selector: 'app-role-menu',
    templateUrl: './role-menu.component.html',
    styleUrls: ['./role-menu.component.css']
})
export class RoleMenuComponent implements OnInit {
    public listRole: any = [];//所有角色
    public listMenu: any = [];//所有菜单
    public listAction: any = [];//所有按钮
    public roleId: string = "";//被选中的角色id
    public uniqIndex: any;
    public helpUrl: any;//帮助文档地址

    public allMenuActionList: any = [];

    public menuActionList: any = [];
    public checkedMenu: any = [];
    public checkedAction: any;
    public searchRole: any;
    public searchMenu: any;
    public user: any;
    public dataPreparationCompleted: boolean = false;

    constructor(private utils: UtilsService,
                private confirmModal: ConfirmModalService,
                private dataCenter: DataCenterService,
                private appConfigService: AppConfigService,
                private userInfo: UserInfoService,
                private ajaxService: AjaxService,
                private requestData: RequestDataService) {
        this.user = userInfo.getUserInfo();
    }

    /**
     * 选中角色之后获取对应菜单数据和按钮数据进行选中或者取消CheckBox
     * @param pOneRole
     */
    doCheckRole(pOneRole) {
        this.falseSelected();
        // 获取角色对应菜单数据
        this.requestData.getDataByCondition(this.appConfigService.config.sql_path + '/system/system_role_menu.json', {"ROLE_ID": pOneRole.ROLE_ID}).subscribe((response) => {
            this.checkedMenu = response["data"] || [];
            console.log("role-menu.component.ts checkedMenu", this.checkedMenu);
            // 获取菜单对应按钮数据
            return this.requestData.getDataByCondition(this.appConfigService.config.sql_path + '/system/system_role_menu_action.json', {"ROLE_ID": pOneRole.ROLE_ID}).subscribe(response => {
                this.checkedAction = response["data"] || [];
                console.log("role-menu.component.ts checkedAction", this.checkedAction);
                this.selectedActionAndMenu();
            });
        }, (error) => {
            this.utils.Base_Errored(error);
        });
    };



    selectedActionAndMenu() {
        // 循环 checkedMenu
        this.checkedMenu.forEach(checkedMenuItem => {
            // 遍历 menuActionList 的所有子菜单
            const checkAndMarkMenu = (menuItems) => {
                menuItems.forEach(menuItem => {
                    if (menuItem.MENU_ID === checkedMenuItem.MENU_ID) {
                        menuItem.checked = true;
                    }

                    // 如果有子菜单，继续递归检查
                    if (menuItem.children && menuItem.children.length) {
                        checkAndMarkMenu(menuItem.children);
                    }
                });
            };
            checkAndMarkMenu(this.menuActionList);
        });

        // 循环 checkedAction
        this.checkedAction.forEach(checkedActionItem => {
            // 遍历 menuActionList 的所有按钮
            const checkAndMarkAction = (menuItems) => {
                menuItems.forEach(menuItem => {
                    menuItem.buttons.forEach(button => {
                        if (button.ACTION_ID === checkedActionItem.ACTION_ID && menuItem.MENU_ID === checkedActionItem.MENU_ID) {
                            button.checked = true;
                            button.ACTION_ORDER = checkedActionItem.ACTION_ORDER;
                        }
                    });

                    // 如果有子菜单，继续递归检查
                    if (menuItem.children && menuItem.children.length) {
                        checkAndMarkAction(menuItem.children);
                    }
                });
            };
            checkAndMarkAction(this.menuActionList);
        });
    }


    /**
     * 反选checkbox的递归方法
     * @param list
     */
    falseSelected() {
        // 递归函数用于将菜单项及其子项的 checked 设置为 false
        const setCheckedFalse = (menuItems) => {
            menuItems.forEach(item => {
                item.checked = false; // 将当前菜单项的 checked 设置为 false

                // 如果有子菜单，递归处理
                if (item.children && item.children.length > 0) {
                    setCheckedFalse(item.children);
                }

                // 将所有按钮的 checked 设置为 false
                item.buttons.forEach(button => {
                    button.checked = false;
                });
            });
        };

        // 对 menuActionList 应用递归函数
        setCheckedFalse(this.menuActionList);
    }



    /**
     * 循环按钮选中
     * @param menu
     */
    doSelectAction = (menu) => {
        if (this.utils.Base_HasValue(menu.children)) {
            _sh.each(menu.children, (sMenu) => {
                sMenu["selected"] = menu.selected;
            });
        }
    };

    /**
     *  保存数据
     */
    menuSave() {
        let roleId = this.roleId; // 选中角色ID
        // 没有角色弹出提示
        if (!this.utils.Base_HasValue(roleId)) {
            return this.confirmModal.show('error', {title: '请选择角色'})
        }
        const queryData = {
            'userId': this.user.USER_ID,
        };
        const bodyData = this.getAjaxParam();
        this.requestData.doActionByHdbsthor('RoleController', 'inputRole', queryData, bodyData, true, 'hdbsthor_right', 'appToken').subscribe(response => {
            if (response) {
                this.utils.Base_doShowConfirm("success", "执行成功");
            } else {
                this.utils.Base_doShowConfirm("error", response['message']);
            }
        });
    }


    async ngOnInit() {
        let currentMenu = this.utils.Base_getApplist(this.dataCenter._dataObject.nowMenu.MENU_ID);
        if (this.utils.Base_HasValue(currentMenu)) {
            this.helpUrl = currentMenu.HELP_URL;
        }

        const userInfo = this.userInfo.getUserInfo();
        const sqlPath = this.appConfigService.config.sql_path;
        let menuBtnJson = sqlPath + '/system/system_menu_action.json';//菜单按钮
        let menuJson = sqlPath + '/system/system_app_menu.json';//菜单
        let roleJson = sqlPath + '/system/system_role_ctrl_list.json';//角色
        if (sqlPath == "otms") {
            var comId = userInfo.COM_ID;
            roleJson = sqlPath + '/system/system_menu_role.json';
            if (comId != "public") {
                menuBtnJson = sqlPath + '/system_user_menu_action.json';
                menuJson = sqlPath + '/user_menu.json';
            }
        }

        this.requestData.getDataByCondition(roleJson, {"ROLE_TYPE": "MENUROLE"}).subscribe(response => {
            this.listRole = response["data"];
        })


        const resMenu = await this.requestData.getDataByCondition(menuJson, {}).toPromise();
        const resButton = await this.requestData.getDataByCondition(menuBtnJson, {}).toPromise();

        let allMenus = resMenu["data"];
        let allButtons = resButton["data"];
        const buttonsByMenuId = allButtons.reduce((acc, button) => {
            button.checked = false;
            const menuId = button.MENU_ID;
            if (!acc[menuId]) {
                acc[menuId] = [];
            }
            acc[menuId].push(button);

            // Sort the buttons within each MENU_ID group
            acc[menuId].sort((a, b) => {
                const orderA = a.ACTION_ORDER === null ? 0 : a.ACTION_ORDER;
                const orderB = b.ACTION_ORDER === null ? 0 : b.ACTION_ORDER;
                return orderA - orderB;
            });

            return acc;
        }, {});

        allMenus.forEach(menu => {
            menu.buttons = buttonsByMenuId[menu.MENU_ID] || [];
        });

        const submenuCounts = allMenus.reduce((acc, menu) => {
            acc[menu.MENU_ID] = 0;
            return acc;
        }, {});

        allMenus.forEach(menu => {
            if (menu.UP_ID && submenuCounts.hasOwnProperty(menu.UP_ID)) {
                submenuCounts[menu.UP_ID]++;
            }
        });

        allMenus.forEach(menu => {
            menu.SUBMENU_COUNT = submenuCounts[menu.MENU_ID];
            menu.HAS_SUBMENU = submenuCounts[menu.MENU_ID] > 0;
            menu.checked = false;
        });

        this.menuActionList = this.requestData.transformMenuList(_sh.cloneDeep(allMenus));
        this.allMenuActionList = _sh.cloneDeep(allMenus);
        this.dataPreparationCompleted = true;
        console.log("role-menu.component.ts 03", this.menuActionList);
    }

    Lv1MenuChange(Lv1Menu) {
        console.log("1级菜单", Lv1Menu.checked);
        // Lv1Menu.checked = !Lv1Menu.checked;
    }

    Lv2MenuChange(Lv2Menu) {
        console.log("2级菜单", Lv2Menu.checked);
        // Lv2Menu.checked = !Lv2Menu.checked;
    }

    Lv3MenuChange(Lv3Menu) {
        console.log("3级菜单", Lv3Menu.checked);
        // Lv3Menu.checked = !Lv3Menu.checked;
    }

    selectAll(menuItem, checked) {
        // 更新当前菜单项的全选状态
        menuItem.allChecked = checked;

        // 更新所有子菜单和按钮的选中状态
        this.updateCheckedProperty(menuItem, checked);
    }


    // 通用更新方法
    updateCheckedProperty(menuItem, checked) {
        menuItem.checked = checked;

        if (menuItem.children) {
            menuItem.children.forEach(child => {
                this.updateCheckedProperty(child, checked);
            });
        }

        if (menuItem.buttons) {
            menuItem.buttons.forEach(button => {
                button.checked = checked;
            });
        }
    }

    // 反选方法
    inverseCheck(menuItem) {
        // 反转当前菜单项的选中状态
        menuItem.checked = !menuItem.checked;

        // 反转所有子菜单和按钮的选中状态
        this.inverseCheckedProperty(menuItem);
    }

    // 通用反转选中状态方法
    inverseCheckedProperty(menuItem) {
        if (menuItem.children) {
            menuItem.children.forEach(child => {
                child.checked = !child.checked;
                this.inverseCheckedProperty(child); // 递归反转子菜单
            });
        }

        if (menuItem.buttons) {
            menuItem.buttons.forEach(button => {
                button.checked = !button.checked; // 反转按钮选中状态
            });
        }
    }


    onSearch() {
        console.log('搜索: ', this.searchMenu);
        if (this.searchMenu) {
            const menuList = this.allMenuActionList.filter(menu => {
                return menu.MENU_NAME.indexOf(this.searchMenu) > -1;
            });
            console.log('搜索: ', menuList);

        } else {
            this.menuActionList = this.allMenuActionList;
        }
    }

    getAjaxParam() {
        let systemAppMenuList = [];
        let systemMenuActionList = [];

        const collectCheckedItems = (menuItems) => {
            menuItems.forEach(menuItem => {
                // 只有当菜单项被勾选时，才添加到systemAppMenuList
                if (menuItem.checked) {
                    systemAppMenuList.push({
                        menuId: menuItem.MENU_ID,
                        upId: menuItem.UP_ID
                    });
                }

                // 对按钮执行相同的检查
                menuItem.buttons.forEach(button => {
                    if (button.checked) {
                        systemMenuActionList.push({
                            menuId: menuItem.MENU_ID,
                            actionId: button.ACTION_ID,
                            actionOrder: button.ACTION_ORDER
                        });
                    }
                });

                // 如果有子菜单，递归处理
                if (menuItem.children && menuItem.children.length) {
                    collectCheckedItems(menuItem.children);
                }
            });
        };

        collectCheckedItems(this.menuActionList);

        let ajaxParam = {
            roleId: this.roleId,
            systemAppMenuList: systemAppMenuList,
            systemMenuActionList: systemMenuActionList
        };

        return ajaxParam;
    }


}
