import {Component, Input, OnInit} from '@angular/core';
import * as screenfull from 'screenfull';
import {RequestDataService} from "../../../service/request-data.service";
import {AppConfigService} from "../../../service/app-config.service";
import {UtilsService} from "../../../service/utils.service";
import * as moment from "moment";

@Component({
  selector: 'app-asset-dashboard-one',
  templateUrl: './asset-dashboard-one.component.html',
  styleUrls: ['./asset-dashboard-one.component.scss']
})
export class AssetDashboardOneComponent implements OnInit {

  @Input() parentData: any;
  // 年度，4年
  public years: any = [];
  // 月度 ，12个月
  public month: any = [];
  // 日 ， 7 天
  public day: any = [];

  constructor(private requestDataService: RequestDataService,
              private appConfigService: AppConfigService,
              private utils: UtilsService) { }

  ngOnInit() {
    const yearData = this.getSearchYearData();
    this.years = yearData.category || [];

    const monthData = this.getSearchMonthMData();
    this.month = monthData.category || [];

    const dayData = this.getSearchDayData();
    this.day = dayData.category || [];


  }

  // 获取7天的起始日期
  getSearchDayData() {
    const today = moment().format('YYYY-MM-DD');
    let category = [];
    let data = {
      ADD_TIME_END: today
    };
    data['ADD_TIME_BEGIN'] = moment().subtract(7 - 1, 'days').format('YYYY-MM-DD');
    for (let i = 7 - 1; i >= 0; i--) {
      category.push(moment().subtract(i, 'days').format('YYYY-MM-DD'));
    }
    return {
      searchData: data,
      category: category
    };
  }

  // 获取近四年的起始日期
  getSearchMonthMData() {
    const today = moment().format('YYYY-MM');
    let years = [];
    let data = {
      ADD_TIME_END: today
    };
    data['ADD_TIME_BEGIN'] = moment().subtract(4 - 1, 'month').format('YYYY-MM');
    for (let i = 12 - 1; i >= 0; i--) {
      years.push(moment().subtract(i, 'month').format('YYYY-MM'));
    }
    return {
      searchData: data,
      category: years
    };
  }

  // 获取近四年的起始日期
  getSearchYearData() {
    const today = moment().format('YYYY');
    let years = [];
    let data = {
      ADD_TIME_END: today
    };
    data['ADD_TIME_BEGIN'] = moment().subtract(4 - 1, 'year').format('YYYY');
    for (let i = 4 - 1; i >= 0; i--) {
      years.push(moment().subtract(i, 'year').format('YYYY'));
    }
    return {
      searchData: data,
      category: years
    };
  }

  isfull: any = false;

  fullscreen() {
    const el = document.getElementById('svr');
    if (screenfull.enabled) {
      screenfull.toggle(el);
      this.isfull = true;
      screenfull.on('change', () => {
        this.isfull = screenfull.isFullscreen;
      });
    }
  }

}
