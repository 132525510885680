import {Component, Input, OnInit} from '@angular/core';
import {UtilsService} from '../../service/utils.service';
import * as _sh from "lodash";

@Component({
    selector: 'app-image-preview-module',
    templateUrl: './image-preview-module.component.html',
    styleUrls: ['./image-preview-module.component.scss']
})
export class ImagePreviewModuleComponent implements OnInit {

    @Input() src: String      // 接受地址
    @Input() title: String    // 接受模态框标题

    public modalVisible = false        // 模态框可视化
    public isConfirmLoading = false    // 模态框加载
    public fileType = ''               // 文件类型

    constructor(private utils: UtilsService) {
    }

    ngOnInit() {
        let type = 'other';
        if (this.src) {
            // 1 取文件名后缀
            let suffix = this.utils.Base_getFileType(this.src);

            // 2 判断文件类型
            let picture = ['jpg', 'jpeg', 'png', 'svg', 'eps', 'gif', 'tiff', 'psd', 'raw', 'bmp'];
            let pdf = ['pdf'];
            let word = ['doc', 'docx']
            let excel = ['xls', 'xlsx']

            if (picture.includes(suffix)) {
                type = 'picture'
            } else if (pdf.includes(suffix)) {
                type = 'pdf'
            } else if (word.includes(suffix)) {
                type = 'word'
            } else if (excel.includes(suffix)) {
                type = 'excel'
            }
        }
        this.fileType = type
    }

    // 点击预览图片
    previewImg(src) {
        if (_sh.includes(['pdf','word','excel'],this.fileType)) {
            // @ts-ignore
            window.open(this.src);
        } else if (_sh.includes(['picture'],this.fileType)) {
            this.modalVisible = true;
        }
    }

    // 点击右上角取消
    modalCancel(){
        this.modalVisible = false;
    }

    // 点击下载
    download(src): void {
        this.isConfirmLoading = true;
        window.location.href = src;
        this.isConfirmLoading = false;
    }
}
