import {Component, Input, OnInit} from '@angular/core';
import {AppConfigService} from "../../service/app-config.service";
import {UploadService} from "../../service/upload.service";
import {UserInfoService} from "../../service/user-info.service";
import {UtilsService} from "../../service/utils.service";
import {DataCenterService} from "../../service/data-center.service";
import {AutoInsertService} from "../../service/auto-insert.service";
import {NzMessageService, UploadXHRArgs} from "ng-zorro-antd";
import {ConfirmModalService} from "../../service/confirm-modal.service";
import {RequestDataService} from "../../service/request-data.service";
import {AjaxService} from "../../service/ajax.service";
import {forkJoin as observableForkJoin} from "rxjs";
import * as _sh from 'lodash';
import {ErrorMsgService} from "../../service/error-msg.service";
import PubSub from 'pubsub-js';

@Component({
  selector: 'app-import-form',
  templateUrl: './import-form.component.html',
  styleUrls: ['./import-form.component.scss']
})
export class ImportFormComponent implements OnInit {


  constructor(private appConfigService: AppConfigService,
              private uploadService: UploadService,
              private userInfo: UserInfoService,
              private utils: UtilsService,
              private dataCenter: DataCenterService,
              private autoInsert: AutoInsertService,
              private msg: NzMessageService,
              private confirmModal: ConfirmModalService,
              private requestDataService: RequestDataService,
              private errorMsgService: ErrorMsgService,
              private ajaxService: AjaxService) {
    this.user = userInfo.getUserInfo();

  }

  @Input() parentData: any;
  public data: Object;
  public fileList: any = [];
  public fileUrl: string = null;
  public modelDownloadUrl: string = null;
  public user: any;
  public errorUrl: string;
  public importJsonFile: null;
  public mainJson: any = [];
  public cutOption = {
    'used': false,
    'zhCutLength': 5,
    'zhBegin': 3,
    'zhEnd': 3,
    'enCutLength': 5,
    'enBegin': 3,
    'enEnd': 3,
  }
  public userIsDeveloper: boolean = false; // 默认不是开发者
  public developerModal: boolean = false; // 默认不是开发者
  public filterError: boolean = false; // 默认不是开发者

  customReq = (item: UploadXHRArgs) => {
    if (this.appConfigService.config['userOssOrCos'] === 'COS') {
      return this.uploadService.doCosUpload(item.file, item['progress'], null, null, null).then((result) => {
        item.onSuccess({}, item.file, event);
        this.fileUrl = this.appConfigService.config['COS']['domain'] + '/' + result['url'];
        this.getImportData(this.fileUrl);
      });
    } else {
      return this.uploadService.doAliOssUpload(item.file, item['progress'], null).then((result) => {
        item.onSuccess({}, item.file, event);
        this.fileUrl = this.appConfigService.config['OSS']['domain'] + '/' + result.name;
        this.getImportData(this.fileUrl);
      });
    }
  }

  buildPageInfo() {
    let that = this;
    _sh.each(this.mainJson, oneTable => {
      let insertArray = _sh.get(oneTable, ['insertArray'], []);
      oneTable.total = insertArray.length;
      // 分页下标
      oneTable.pageIndex = 1;
      // 每页显示数据
      oneTable.pageSize = 10;
      oneTable.nzPageSizeOptions = [5, 10, 20, 50, 100, 200];
      this.paginationItemData(oneTable);
    });
  }

  checkError() {
    let that = this;
    _sh.each(this.mainJson, oneTable => {
      _sh.each(oneTable['showColumns'], oneColumn => {
        let dictionary = _sh.get(oneColumn, 'dictionary', null);
        _sh.each(oneTable['insertArray'], oneRow => {
          if (that.utils.Base_HasValue(dictionary)) {
            let hasValue = _sh.get(oneRow, [oneColumn['column']], null);
            let dictFound = _sh.get(oneRow, [oneColumn['column'] + '_DICT_FOUND'], false);
            if (hasValue && !dictFound) {
              _sh.set(oneRow, [oneColumn['column'] + '_ERROR'], true);
              _sh.set(oneRow, [oneColumn['column'] + '_ERROR_MSG'], '未找到相关数据');
              _sh.set(oneRow, ['SYS_IMPORT_ERROR'], true);
            }

          } else {
            if (_sh.get(oneColumn, ['requireExcel'], false)) {
              let value = _sh.get(oneRow, oneColumn['column'], null);
              if (!that.utils.Base_HasValue(value)) {
                _sh.set(oneRow, [oneColumn['column'] + '_ERROR'], true);
                _sh.set(oneRow, [oneColumn['column'] + '_ERROR_MSG'], '该项是必填项');
                _sh.set(oneRow, ['SYS_IMPORT_ERROR'], true);
              }
            }
          }
        })
      })
    })
  }


  paginationItemData(pTable) {
    let index = _sh.cloneDeep(pTable.pageIndex);
    let begin = (index - 1) * pTable.pageSize;
    let end = index * pTable.pageSize;
    pTable['showDataList'] = _sh.slice(pTable['insertArray'], begin, end);
  }

  getImportData(fileUrl) {

    const tmpUrl = this.appConfigService.getServer().importServlet + '?random=' + Math.random();
    const logId = this.utils.Base_getUuid();
    const myParam = {
      'PF': this.appConfigService.config.MysqlPF,
      'configJsonFileName': this.importJsonFile,
      'excelFileName': fileUrl,
      'comId': '',
      'userId': this.user.USER_ID,
      'empId': this.user.EMP_ID,
      'userName': this.user.USERNAME,
      'logId': logId,
      'method': 'showData'
    };
    this.insertImportLog(fileUrl, logId).subscribe(response => {
      if (response.state === 'success') {
        this.ajaxService.ajaxPost(tmpUrl, myParam).subscribe(rep => {
          if (rep.status == 200) {
            let tableIndex = 0;
            _sh.each(this.mainJson, oneTable => {
              oneTable['insertArray'] = _sh.get(rep, ['transArr', tableIndex, 'insertArray']);
              tableIndex++;
            })
            this.buildPageInfo();
            this.checkError(); // 检查错误
            this.aggregateFunction();
          } else {
            let msg = rep.error.text;
            msg = _sh.get(rep, ['error', 'message'], msg);
            msg = this.errorMsgService.translateMsg(msg);
            this.errorUrl = rep.error.errorUrl;
            this.confirmModal.show('error', {
              'title': '导入失败',
              content: msg
            });
            this.fileUrl = null;
          }
        }, error => {
          this.fileUrl = null;
          this.confirmModal.show('error', {
            'title': '温馨提示',
            content: error
          });
        });
      }
      else if (response.state === 'error') {
        this.confirmModal.show('error', {
          'title': '导入失败',
          content: _sh.get(response,['ErrorText'],null)
        });
      }
    });
  }

  importData() {
    let check = true;
    if (!this.utils.Base_HasValue(this.fileUrl)) {
      check = false;
      this.confirmModal.show('error',
        {title: '请先上传Excel。', content: '请先上传Excel'});
    }
    if (check) {
      let errorCount = 0;
      _sh.each(this.mainJson, oneTable => {
        let errorList = _sh.filter(oneTable['insertArray'], oneRow => {
          return oneRow['SYS_IMPORT_ERROR'];
        });
        errorCount += errorList.length;
      });
      if (errorCount > 0) {
        this.confirmModal.show('error',
          {title: '请先处理错误。', content: '请先处理错误，目前有' + errorCount + '条错误数据需要处理。'});
      }
    }

    if (check) {
      const tmpUrl = this.appConfigService.getServer().importServlet + '?random=' + Math.random();
      const logId = this.utils.Base_getUuid();
      const myParam = {
        'PF': this.appConfigService.config.MysqlPF,
        'configJsonFileName': this.importJsonFile,
        'excelFileName': this.fileUrl,
        'comId': '',
        'userId': this.user.USER_ID,
        'empId': this.user.EMP_ID,
        'userName': this.user.USERNAME,
        'logId': logId,
        'method': 'import'
      };
      this.insertImportLog(this.fileUrl, logId).subscribe(response => {
        if (response.state === 'success') {
          this.ajaxService.ajaxPost(tmpUrl, myParam).subscribe(rep => {
            if (rep.status == 200) {
              this.confirmModal.show('success', {
                'title': '温馨提示',
                content: '导入成功'
              });
              this.closePage(); // 关闭本页面，并刷新原有菜单。
            } else {
              let msg = rep.error.text;
              msg = _sh.get(rep, ['error', 'message'], msg);
              msg = this.errorMsgService.translateMsg(msg);
              this.errorUrl = rep.error.errorUrl;
              this.confirmModal.show('error', {
                'title': '导入失败',
                content: msg
              });
              this.fileUrl = null;
            }
          }, error => {
            this.fileUrl = null;
            this.confirmModal.show('error', {
              'title': '温馨提示',
              content: error
            });
          });
        }
      });
    }

  }

  beforeUpload = (file: File) => {
    const isJPG = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    if (!isJPG) {
      this.msg.error('You can only upload xlsx file!');
    }
    return isJPG;
  }

  changeFileList = (result) => {
    if (result.type === 'success' && result.fileList.length === 2) {
      // 只传一个文件
      result.fileList.splice(0, 1);
    }
  }


  insertImportLog(pExcelUrl, logId) {
    const templateJson = {
      'LIST': [{
        'TABLE': 'system_import_log',
        'ACTION': 'Insert',
        'WHEREGROUP': [],
        'WHERE': [],
        'COLUMNS': [
          {'COLUMN': 'LOG_ID'},
          {'COLUMN': 'LOG_MENU_ID'},
          {'COLUMN': 'LOG_MENU_NAME'},
          {'COLUMN': 'LOG_IMPORT_URL'},
          {'COLUMN': 'ACTION_TEXT'},
          {'COLUMN': 'ADD_USERID'},
          {'COLUMN': 'ADD_NAME'},
          {'COLUMN': 'ADD_DATE'}
        ],
        'RECORDS': [
          {
            'LOG_ID': logId,
            'LOG_MENU_ID': this.dataCenter._dataObject.nowMenu.MENU_ID,
            'LOG_MENU_NAME': this.dataCenter._dataObject.nowMenu.MENU_NAME,
            'LOG_IMPORT_URL': pExcelUrl,
            'ACTION_TEXT': this.dataCenter._dataObject.nowMenu.MENU_NAME + '_导入',
            'ADD_DATE': 'SYS-DATETIME',
            'ADD_NAME': this.user.USERNAME,
            'ADD_USERID': this.user.USER_ID
          }
        ]
      }
      ]
    };
    return this.autoInsert.ExecJson(templateJson);
  }

  clacWidthConfig(pShowColumns) {
    let arr = [];
    let unitPx = 15; // 一个单位的宽度
    let sumWidth = 0
    _sh.each(pShowColumns, oneColumn => {
      let comment = _sh.get(oneColumn, ['comment'], '');
      let commentLength = comment.length;
      if (oneColumn['visible'] || this.developerModal) {
        let oneWidth = 200;
        let strOneWidth = oneWidth + 'px';

        sumWidth += oneWidth;
        arr.push(strOneWidth);
      }
    });
    return {arr, sumWidth};
  }

  ngOnInit() {
    let that = this;
    function addOneShowColumn(_column,_oneTable) {
      let visible = _sh.get(_column, ['visible'], true);
      _sh.set(_column, ['visible'], visible);

      let cutOp = _sh.get(_column, ['cutOption'], that.cutOption);
      _sh.set(_column, ['cutOption'], cutOp);
      _oneTable['showColumns'].push(_column);
    }

    //hulkServer/assets/import/info_company_import.xlsx
    //http://127.0.0.1:3000/assets/import/info_company_import.xlsx
    this.modelDownloadUrl = _sh.get(this.parentData, ['inputData', 'importModelUrl']);
    const user = this.userInfo.getUserInfo();
    this.userIsDeveloper = _sh.get(user, 'POSITION', null) == "hdbs_developer";

    let seed = 'hulkServer'
    let bFind = _sh.startsWith(this.modelDownloadUrl, seed);
    if (bFind) {
      this.modelDownloadUrl = _sh.replace(this.modelDownloadUrl, seed, this.appConfigService.config.NodeJsServer);
    }
    this.importJsonFile = _sh.get(this.parentData, ['inputData', 'importJsonFile']);

    if (this.utils.Base_HasValue(this.importJsonFile)) {
      const search$ = observableForkJoin(
        this.requestDataService.getDataByJson(this.importJsonFile),
      );
      search$.subscribe(
        values => {
          this.mainJson = _sh.get(values, [0], []);

          _sh.each(this.mainJson, oneTable => {
            oneTable['showColumns'] = [];
            _sh.each(oneTable['columns'], oneColumn => {
              addOneShowColumn(oneColumn,oneTable);
              let oneColumnGroup = _sh.get(oneColumn, ['columnGroup'], []);
              if (this.utils.Base_HasValue(oneColumnGroup)) {
                _sh.each(oneColumnGroup, oneGroupColumn => {
                  addOneShowColumn(oneGroupColumn,oneTable);
                });
              }
            });
            let widthConfigObj = this.clacWidthConfig(oneTable['showColumns']);
            oneTable['widthConfig'] = widthConfigObj['arr'];
            oneTable['widthConfig'].unshift('100px'); // 序号
            oneTable['scrollWidth'] = widthConfigObj['sumWidth'] + 'px';
          })
        })
    }
  }

  changeShowColumns() {
    _sh.each(this.mainJson, oneTable => {
      let widthConfigObj = this.clacWidthConfig(oneTable['showColumns']);
      oneTable['widthConfig'] = widthConfigObj['arr'];
      oneTable['widthConfig'].unshift('100px'); // 序号
      oneTable['scrollWidth'] = widthConfigObj['sumWidth'] + 'px';
    });
  }

  aggregateFunction() {
    _sh.each(this.mainJson, oneTable => {
      let datas = oneTable['insertArray'];
      _sh.each(oneTable['aggregateColumns'], oneAgg => {
        let tempArr = this.utils.getArrayByPath(datas, [oneAgg['column']]);
        if (oneAgg['aggregateFunction'] == 'sum') {
          let tempSum = 0
          _sh.each(tempArr, oneValue => {
            tempSum = tempSum + Number(oneValue);
          })
          oneAgg['aggregateValue'] = tempSum;
        } else if (oneAgg['aggregateFunction'] == 'count') {
          oneAgg['aggregateValue'] = tempArr.length;
        }
      });
    })
  }

  closePage() {
    let menuId = _sh.get(this.parentData, ['nowMenu', 'MENU_ID'], null);
    if (this.utils.Base_HasValue(menuId)) {
      PubSub.publish('listRefresh', menuId);
    }
    this.dataCenter.closeTab(this.parentData);
  }
}
