import {Component, OnInit, Input} from '@angular/core';
import {
  FormGroup
} from '@angular/forms';
import {UtilsService} from './../../service/utils.service';
import * as moment from 'moment';
import * as _sh from "lodash";

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.css']
})
export class DateRangeComponent implements OnInit {

  @Input() hulkForm: FormGroup;
  @Input() hulkColumn: any;

  // 改变绑定对象
  @Input() bindModel: any;

  private dateFormat = 'YYYY-MM-DD';

  constructor(private utils: UtilsService) {
  }


  // 修改model之后格式化为string后赋值model
  changeModel() {
    if (this.bindModel) {
      const date = this.bindModel[this.hulkColumn.column + 'Date'];
      if (this.utils.Base_HasValue(date)) {
        let model = [];
        _sh.each(date, item => {
          model.push(moment(item).format(this.dateFormat));
        });
        this.bindModel[this.hulkColumn.column] = model;
      } else {
        this.bindModel[this.hulkColumn.column] = null;
      }
    } else {
      const date = this.hulkColumn.modelDate;
      if (this.utils.Base_HasValue(date)) {
        let model = [];
        _sh.each(date, item => {
          model.push(moment(item).format(this.dateFormat));
        });
        this.hulkColumn.model = model;
      } else {
        this.hulkColumn.model = null;
      }
    }

  }

  ngOnInit() {
    this.hulkColumn.lableSpan = this.hulkColumn.lableSpan || 9;
    this.hulkColumn.itemSpan = this.hulkColumn.itemSpan || 15;
    // 加载数据时候讲model数据格式化为date类型
    if (this.utils.Base_HasValue(this.hulkColumn.model)) {
      this.hulkColumn.modelDate = this.hulkColumn.model;
    }
    // 加载数据时候讲model数据格式化为date类型
    if (this.utils.Base_HasValue(this.bindModel)) {
      this.bindModel[this.hulkColumn.column + 'Date'] = this.bindModel[this.hulkColumn.column];
    }
    if (this.hulkColumn.component === 'dateTimeRange') {
      this.dateFormat = 'YYYY-MM-DD HH:mm:ss';
    }
  }

}
