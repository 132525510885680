import {Injectable} from '@angular/core';
import {UtilsService} from "./utils.service";
import {AppConfigService} from "./app-config.service";
import {RequestDataService} from "./request-data.service";
import {DataCenterService} from "./data-center.service";

import * as _sh from 'lodash';
import {forkJoin as observableForkJoin} from "rxjs";
import {ListActionService} from "./list-action.service";

@Injectable({
    providedIn: 'root'
})
export class JumpCenterService {

    constructor(
        private util: UtilsService,
        private appConfig: AppConfigService,
        private requestService: RequestDataService,
        private dataCenterService: DataCenterService,
    ) {
    }

    execJumpPage(jumpConfig, pParentData, pModalData, pReadOnlyData, pItemTabName?) {
        let that = this;
        let route = _sh.get(jumpConfig, ['route'], null);
        let routeTo = "default";
        if (that.util.Base_HasValue(route)) {
            for (let i = 0; i < route.length; i += 1) {
                let pValueA = that.dataCenterService.getSpecialValue(route[i]['fromValue'], pParentData, pModalData, pReadOnlyData);
                let pValueB = that.dataCenterService.getSpecialValue(route[i]['judgeValue'], pParentData, pModalData, pReadOnlyData);
                let judgeOperator = route[i]['operator']
                let result = that.dataCenterService.judgeOperatorFuntion(pValueA, judgeOperator, pValueB);
                if (result) {
                    routeTo = _sh.get(route, [i, 'routeTo']);
                    break;
                }
            }
        }

        let newPage = _sh.get(jumpConfig, ['list', routeTo, 'jumpPage', 'newPage'], false);
        let menuId = _sh.get(jumpConfig, ['list', routeTo, 'jumpPage', 'menuId'], null);
        let jumpData = getJumpData(_sh.get(jumpConfig, ['list', routeTo, 'jumpData']));
        const modeType = _sh.get(jumpConfig, ['list', routeTo, 'jumpPage', 'modeType']);
        const component = _sh.get(jumpConfig, ['list', routeTo, 'jumpPage', 'component']);
        const formFlag = _sh.get(jumpConfig, ['list', routeTo, 'jumpPage', 'formFlag']);
        const formType = _sh.get(jumpConfig, ['list', routeTo, 'jumpPage', 'formType']);
        const formClass = _sh.get(jumpConfig, ['list', routeTo, 'jumpPage', 'formClass']);
        const actionValue = _sh.join(['systemCustom', modeType, component, formFlag], '.');

        function getJumpData(jumpData) {
            let bodyData = {};
            let keyList = _sh.keys(jumpData);
            _sh.each(keyList, oneKey => {
                let value = jumpData[oneKey];
                bodyData[oneKey] = that.dataCenterService.getSpecialValue(value, pParentData, pModalData, pReadOnlyData);
            });
            return bodyData;
        }

        function execOldPage(jumpConfig, pParentData, pModalData, pReadOnlyData) {
            pParentData['btnName'] = actionValue;
            pParentData['conditionColumns'] = {};
            pParentData['formType'] = formType;
            pParentData['modeType'] = modeType;
            pParentData['formFlag'] = formFlag;

            pParentData['actionArray'] = [];
            if (that.util.Base_HasValue(jumpData)) {
                pParentData['id'] = jumpData['id'];
                pParentData['inputData'] = jumpData;
            }
            // 如果是默认详情页面，将值父级值设置为空。
            if (component == 'app-detail-form') {
                pParentData['data'] = [];
            }
            that.dataCenterService.refreshDataCenterInStorage();
        }

        function execNewPage(jumpConfig, pParentData, pModalData, pReadOnlyData) {

            const searchAll = observableForkJoin(
                that.requestService.getDataByCondition(that.appConfig.config.sql_path + '/menu/system_app_list.json', {'LIST_ID': menuId}),
                that.requestService.getDataByCondition(that.appConfig.config.sql_path + '/menu/menu_name.json', {'MENU_ID': menuId})
            );
            searchAll.subscribe(
                values => {
                    let nowMenu = _sh.get(values, [1, 'data', 0], {});
                    const searchId = _sh.get(values, [0, 'data', 0, 'SEARCH_ID']);

                    let msgValue = _sh.get(jumpConfig, ['list', routeTo, 'jumpPage', 'msgValue']);
                    let msgKeys = _sh.keys(msgValue, {});
                    let titleTemplate = _sh.template(_sh.get(jumpConfig, ['list', routeTo, 'jumpPage', 'titleTemplate'], null));
                    let visibleBtn = _sh.get(jumpConfig, ['list', routeTo, 'jumpPage', 'visibleBtn'], null);
                    let obj = {};
                    _sh.each(msgKeys, oneMsg => {
                        obj[oneMsg] = that.dataCenterService.getSpecialValue(msgValue[oneMsg], pParentData, pModalData, pReadOnlyData);
                    })
                    let title = titleTemplate(obj);
                    let id = jumpData['id'];
                    let tabIndex = _sh.findIndex(that.dataCenterService._dataObject.list, (item) => {
                        return item['parentDataId'] === jumpData['id'];
                    });
                    if (tabIndex > 0) {
                        that.dataCenterService._dataObject.index = tabIndex;
                        that.dataCenterService._dataObject.nowMenu = nowMenu;
                        that.dataCenterService.refreshDataCenterInStorage();
                    } else {
                        that.dataCenterService.newTab({
                            id: id, // 唯一标识
                            btnName: actionValue,
                            parentDataId: id, // 唯一标识
                            nowMenu: nowMenu,
                            searchId: searchId,
                            title: title, // 菜单名称 用户tab展示
                            modeType:modeType,
                            formType: formType, // 菜单类型 用于tab过滤显示模板
                            formClass: formClass, // 工作流审批页面
                            formFlag: formFlag,
                            visibleBtn: visibleBtn,
                            data: {}, // 查询返回数据
                            inputData: jumpData,
                            detailCondition: {
                                'ID': id
                            }
                        });
                    }
                });
        }

        if (newPage) {
            execNewPage(jumpConfig, pParentData, pModalData, pReadOnlyData);
        } else {
            execOldPage(jumpConfig, pParentData, pModalData, pReadOnlyData);
        }
    }


}
