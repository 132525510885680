
import {forkJoin as observableForkJoin, Observable} from 'rxjs';
import {Component, OnInit} from '@angular/core';
import {UtilsService} from './../../service/utils.service';
import {ConfirmModalService} from './../../service/confirm-modal.service';
import {DataCenterService} from './../../service/data-center.service';
import {AppConfigService} from './../../service/app-config.service';
import {UserInfoService} from './../../service/user-info.service';
import {AjaxService} from './../../service/ajax.service';
import {RequestDataService} from './../../service/request-data.service';
import * as _sh from 'lodash';



@Component({
  selector: 'app-user-role',
  templateUrl: './user-role.component.html',
  styleUrls: ['./user-role.component.css']
})
export class UserRoleComponent implements OnInit {

  constructor(private utils: UtilsService,
    private confirmModal: ConfirmModalService,
    private dataCenter: DataCenterService,
    private appConfigService: AppConfigService,
    private userInfo: UserInfoService,
    private ajaxService: AjaxService,
    private requestData: RequestDataService) {
    this.user = userInfo.getUserInfo();
  }

  public listEmp: any = [];
  public listRole: any = [];
  public helpUrl: any;//帮助文档地址
  public doCheckRole: boolean;
  public uniqIndex: number;
  public selectAll: boolean;
  public searchUser:string;//过滤用户
  public searchRole:string;//过滤角色
  public userId;
  public user: any;

  // 总条数
  public total: any = 1;
  // 分页下标
  public pageIndex: any = 1;
  // 每页显示数据
  public pageSize: any = 10;
  public nzPageSizeOptions: any = [5, 10, 20, 50, 100, 200];
  public allChecked = false;
  public indeterminate = false;

  doTest() {
  }

  checkAll(value: boolean): void {
    this.listEmp.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }

  // 查询后的数据进行封装
  transformResult(data) {
    let dataSet = data.data || [];
    this.listEmp = dataSet;
    this.refreshStatus();
    this.total = data.page.rowCount;
    this.pageIndex = data.page.pageNum;
  }

  // checkbox 选中或未选中之后刷新总checkbox的状态
  refreshStatus(data?): void {
    const validData = this.listEmp.filter(value => !value.disabled);
    const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
    const allUnChecked = validData.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
  }

  searchData(reset: boolean = false, clearText: boolean = false): void {
    if (reset) {
      this.pageIndex = 1;
    }
    if (clearText) {
      this.searchUser = null;
      this.searchRole = null;
    }
    let SEARCH_ID = this.appConfigService.config.sql_path + '/system/system_user_emp_list.json';
    this.requestData.getPaginationData(SEARCH_ID, {'USER_REAL_NAME':this.searchUser}, this.pageIndex, this.pageSize).subscribe((data: any) => {
      this.transformResult(data);
    });
  }

  // 查询所有用户
  getDataListEmpJson() {
    let appConfig = this.appConfigService.config;
    let SEARCH_ID = appConfig.sql_path + '/system/system_user_emp_list.json';
    return this.requestData.getDataByCondition(SEARCH_ID, {});
  }
  // 查询所有角色
  getDataListRoleForUser() {
    let appConfig = this.appConfigService.config;
    let SEARCH_ID = appConfig.sql_path + '/system/system__user_role_ctrl_list.json';
    return this.requestData.getDataByCondition(SEARCH_ID, {});
  }

  //通过 ajax 得到选中的用户有的权限列表
  doCheckEmp(pOneEmp) {
    this.doCheckRole = true;
    let jsonPath = this.appConfigService.config.sql_path + '/system/system_user_role_selected.json';
    this.requestData.getDataByCondition(jsonPath, {"USER_ID": pOneEmp.USER_ID}).subscribe(response => {
      _sh.each(this.listRole, OneRole => {
        OneRole['selected'] = false;
        //[ 循环用户有的权限列表 ]
        for (let i = 0; i < response['data'].length; i++) {
          let OneData = response['data'][i];
          // 如果发现用户有这个权限，那么打上对勾，并且推出 [ 循环用户有的权限列表 ] 循环体。
          if (this.utils.Base_compare(OneData.ROLE_ID, OneRole['ROLE_ID'])) {
            OneRole['selected'] = true;
            if (this.utils.Base_HasValue(OneData.UNIQ_INDEX)) {
              this.uniqIndex = OneData.UNIQ_INDEX
            }
            break;
          }
        }
      });
    }, error => {
      this.utils.Base_doShowConfirm("error", '网络异常');
    });
  }

  doSelectAll() {
    _sh.each(this.listRole, OneData => {
      OneData['selected'] = this.selectAll;
    })
  };

  roleSaveNew() {
    const selectEmpList = _sh.filter(this.listEmp, (item) => {
      return item['checked'];
    });

    if (!this.utils.Base_HasValue(selectEmpList)) {
      this.utils.Base_doShowConfirm("error", "请选择用户");
      return;
    }

    const selectRoleList = _sh.filter(this.listRole, (item) => {
      return item['selected'];
    });

    if (!this.utils.Base_HasValue(selectRoleList)) {
      this.utils.Base_doShowConfirm("error", "请选择角色");
      return;
    }

    const queryData = {
      'userId': this.user.USER_ID,
    };

    let entityUserList = [];
    _sh.each(selectEmpList,oneUser=>{
     let obj = {
       'userId': oneUser['USER_ID']
     }
     entityUserList.push(obj);
    })
    let entityRoleList = []

    _sh.each(selectRoleList,oneUser=>{
      let obj = {
        'roleId': oneUser['ROLE_ID']
      }
      entityRoleList.push(obj);
    })

    const bodyData = {
      'systemUserList': entityUserList,
      'systemRoleList': entityRoleList
    };


    this.requestData.doActionByHdbsthor('RoleController', 'userRole', queryData, bodyData, true, 'hdbsthor_right', 'appToken').subscribe(response => {
    // this.requestData.doActionByHdbsthor('hdbsthor-right/RoleController', 'userRole', queryData, bodyData, true, 'hdbsthor', 'appToken').subscribe(response => {
      if (response) {
        this.utils.Base_doShowConfirm("success", "执行成功");
      } else {
        this.utils.Base_doShowConfirm("error", response['message']);
      }
    });


  }



  roleSave() {
    let roleId = '';
    _sh.each(this.listRole, OneRole => {
      if (OneRole['selected'] == true) {
        roleId += OneRole['ROLE_ID'] + ",";
      }
    });
    if (!this.doCheckRole) {
      if (this.uniqIndex === 0) {
        this.uniqIndex = 1;
      } else if (this.uniqIndex === 1) {
        this.uniqIndex = 0;
      }
    }
    if (!this.utils.Base_HasValue(this.uniqIndex)) {
      this.uniqIndex = 0;
    }
    if (!this.utils.Base_HasValue(this.userId)) {
      this.utils.Base_doShowConfirm("error", "请选择用户");
      return;
    }
    if (!this.utils.Base_HasValue(roleId)) {
      this.utils.Base_doShowConfirm("error", "请选择角色");
      return;
    }
    let data = {
      dataObject: {
        roleIds: roleId,
        userId: this.userId,
        uniqIndex: this.uniqIndex
      }
    };
    this.requestData.doActionByHdbsthorJson('hdbsthor-right', 'assignRightController', this.appConfigService.config.MysqlPF + '/userToRole', data).subscribe((response) => {
      if (response['suc'] === 'success') {
        this.doCheckRole = false;
        this.utils.Base_doShowConfirm("success", "执行成功");
      } else {
        this.utils.Base_doShowConfirm("error", response['message']);
      }
    }, error => {
      this.utils.Base_doShowConfirm("error", error.message);
    });
  };

  ngOnInit() {
    let currentMenu = this.utils.Base_getApplist(this.dataCenter._dataObject.nowMenu.MENU_ID);
    if (this.utils.Base_HasValue(currentMenu)) {
      this.helpUrl = currentMenu.HELP_URL;
    }
    const parallel$ = observableForkJoin(
      // this.getDataListEmpJson(),
      this.getDataListRoleForUser()
      );
    parallel$.subscribe(response => {
      // this.listEmp = response[0]["data"] || [];
      this.listRole = response[0]["data"] || [];
    })

    this.searchData(true);
  }

}
