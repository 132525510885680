import {Component, Input, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {NzModalService} from "ng-zorro-antd/modal";
import {HulkConfigServiceService} from "../../service/hulk-config-service.service";
import {FileSaverService} from "ngx-filesaver";
import {RequestDataService} from "../../service/request-data.service";
import {AppConfigService} from "../../service/app-config.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {ActivatedRoute} from "@angular/router";
import {ConfirmModalService} from "../../service/confirm-modal.service";
import {ClipboardService} from "ngx-clipboard";
import {UtilsService} from "../../service/utils.service";
import * as _ from "underscore";
import {forkJoin as observableForkJoin} from "rxjs";
import * as _sh from 'lodash';

@Component({
    selector: 'app-hulk-config-datasource-check',
    templateUrl: './hulk-config-datasource-check.component.html',
    styleUrls: ['./hulk-config-datasource-check.component.scss']
})
export class HulkConfigDatasourceCheckComponent implements OnInit {

    isVisible = false;

    //父级组件的数据对象
    @Input() parentData: any = {
        conditionColumns: {
            columns: []
        },
        pageSize: null,
        nzPageSizeOptions: null
    };

    selectDatas = [];
    listDatas = [];
    mainJson = {
        datasourceId: null
    }
    id = null;

    constructor(private _httpClient: HttpClient,
                private modalService: NzModalService,
                private hulkconfigserviceservice: HulkConfigServiceService,
                private _FileSaverService: FileSaverService,
                private requestDataService: RequestDataService,
                public appConfigService: AppConfigService,
                private notification: NzNotificationService,
                private activatedRoute: ActivatedRoute,
                private confirmModal: ConfirmModalService,
                private clipboardService: ClipboardService,
                private utils: UtilsService) {
    }

    ngOnInit() {
    }

    selectMore(pListData, multiple?): number {
        //计数，有多少条记录被勾选了。
        let iCount = 0;
        _sh.each(pListData, oneData => {
            if (oneData['checked']) {
                iCount++;
            }
        });

        // tslint:disable-next-line:triple-equals
        if (multiple == true) {
            if (iCount === 0) {
                this.confirmModal.show('warning', {
                    'title': '温馨提示',
                    'content': '请至少选择一条纪录！'
                });
                return iCount;
            }
        } else {
            if (iCount === 0) {
                this.confirmModal.show('warning', {
                    'title': '温馨提示',
                    'content': '请选择一条纪录！'
                });
                return iCount;
            } else if (iCount > 1) {
                this.confirmModal.show('warning', {
                    'title': '温馨提示',
                    'content': '只能选择一条纪录！'
                });
                return iCount;
            }
        }
        return iCount;
    }


    // 弹出框
    showModal() {
        let temp = _sh.cloneDeep(this.parentData.data.data);
        if (this.selectMore(temp, false) == 1) {
            const selectList = _sh.filter(temp, (item) => {
                return item['checked'];
            });
            this.isVisible = true;
            this.id = _sh.get(selectList, [0, 'ID'], null);

            const wordProcess$ = observableForkJoin(
                this.requestDataService.getDataByCondition(this.appConfigService.config.sql_path + '/basic/dict/SYSTEM_DATASOURCE.json', {}),
            );
            wordProcess$.subscribe(
                values => {
                    this.selectDatas = _sh.get(values, ['0', 'data'], []);
                    this.doRefresh();
                }
            );
        }
    }

    getMoreInfoJson() {
        let that = this;
        let check = true;
        let array = _sh.get(this.mainJson, ['datasourceId']);
        let dsJsonPath = _sh.split(array,';')[0]
        let dsId = _sh.split(array,';')[1]

        if (!this.utils.Base_HasValue(dsJsonPath) && check) {
            check = false;
            this.confirmModal.show('error', {
                title: '请先选择数据源'
            });
        }
        if (check) {
            let datasourcePath = dsJsonPath;
            const search = observableForkJoin(
                this.requestDataService.getDataByJson(datasourcePath)
            );
            search.subscribe(
                values => {
                    let status = _sh.get(values, [0, 'status'], 200);
                    if (status == 200) {
                        const build$ = observableForkJoin(
                            this.requestDataService.getDataByCondition(datasourcePath, {'ID': this.id}),
                            this.requestDataService.doActionByHdbsHulk(
                                'wordProcessServlet',
                                'getWordProcess',
                                {}, {
                                    'PF':that.appConfigService.config.MysqlPF,
                                    'dsJsonPath': dsJsonPath,
                                    'dsId':dsId,
                                    'documentsId':this.id
                                })
                        );
                        build$.subscribe(
                            jsons => {
                            }
                        );
                    } else if (status == 500) {
                        this.confirmModal.show('error', {
                            title: '请确认文件是否存在：' + datasourcePath
                        });
                    }


                }
            );



        }

    }

    doRefresh() {
        this.mainJson['datasourceId'] = null;
    }


}
