import {Component, Input, OnInit} from '@angular/core';
import {JumpCenterService} from "../../../service/jump-center.service";

import * as _sh from 'lodash';
import {UtilsService} from "../../../service/utils.service";

@Component({
  selector: 'app-descriptions-table',
  templateUrl: './descriptions-table.component.html',
  styleUrls: ['./descriptions-table.component.scss']
})
export class DescriptionsTableComponent implements OnInit {

  @Input() oneTab: any;
  @Input() oneGroup:any;
  @Input() aggregateListData: any;
  @Input() sqlFileListData: any;
  @Input() execButton: any;

  conditionColumns: [];

  constructor(
    private utilsService: UtilsService,
    private jumpCenterService: JumpCenterService
  ) { }

  ngOnInit() {
    this.conditionColumns = _sh.get(this.oneGroup,['conditionColumns'],[]);
  }

  doJump(pOneColumn, pData) {
    pData['checked'] = true;
    let simulationParentData = {
      data: {
        data: [pData]
      }
    }
    let readOnlyData = {
      'sqlFileListData': _sh.cloneDeep(this.sqlFileListData),
      'aggregateListData': _sh.cloneDeep(this.aggregateListData)
    }

    this.jumpCenterService.execJumpPage(pOneColumn['jumpConfig'], simulationParentData, null, readOnlyData);
  }

  doJumpUrl(url){
    window.open(url,'_blank')
  }

}
