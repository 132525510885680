import {Injectable} from '@angular/core';
import {UtilsService} from './utils.service';
import {ConfirmModalService} from './confirm-modal.service';
import {DataCenterService} from './data-center.service';
import {AppConfigService} from './app-config.service';
import {UserInfoService} from './user-info.service';
import {AjaxService} from './ajax.service';
import {Md5} from 'ts-md5/dist/md5';

import * as _sh from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class AutoInsertService {
    private templateJson: any = null;
    private ListInfo: any = null;

    constructor(private utils: UtilsService,
                private confirmModal: ConfirmModalService,
                private dataCenter: DataCenterService,
                private appConfigService: AppConfigService,
                private userInfo: UserInfoService,
                private ajaxService: AjaxService,
                private userInfoService: UserInfoService) {
    }

    findTarget(targetTable, targetColum): void {
        //修改为向templateJson ，找值，但是只能处理后面的表找前面的表，切前面的表必须为主表。
        let tmpValues = null;
        for (let i = 0; i < this.templateJson.LIST.length; i++) {
            if (this.utils.Base_compare(this.templateJson.LIST[i].TABLE, targetTable)) {
                tmpValues = this.utils.Base_getJsonValue(this.templateJson.LIST[i].RECORDS[0], targetColum, null);
                if (this.utils.Base_HasValue(tmpValues)) {
                    break;
                }
            }
        }
        return tmpValues;
    }

    findValue(pInsertValue): void {
        let tmp = pInsertValue;
        if (this.utils.Base_HasValue(pInsertValue)) {
            let targetParam = pInsertValue.split('.');
            if (_sh.isArray(targetParam)) {
                if (this.utils.Base_compare(targetParam[0], 'SYS')) {
                    let targetTable = targetParam[1];
                    let targetColum = targetParam[2];
                    let tmpValues = this.findTarget(targetTable, targetColum);
                    if (this.utils.Base_HasValue(tmpValues)) {
                        tmp = tmpValues;
                    } else {
                        this.confirmModal.show('info', {
                            title: '该值未找到' + pInsertValue
                        });
                        return;
                    }
                }
            }
        }
        return tmp;
    }

    getValue(pTmpValue, pOneColumn, pCommitPoint) {
        // 获取原值
        let tmpValue = pTmpValue;
        let insertValue = this.utils.Base_getJsonValue(pOneColumn, 'insertValue', null);
        insertValue = this.findValue(insertValue);
        let formatColumn = this.utils.Base_getJsonValue(pOneColumn, 'format', null);
        const component = _sh.get(pOneColumn, 'component', null);


        // 如果有原值，不再次赋予默认值
        if (!this.utils.Base_HasValue(tmpValue)) {
            if (this.utils.Base_HasValue(insertValue)) {
                tmpValue = insertValue;
            }
        }
        //如果经过如上描述还是为 Undefined 修改为NULL值。
        if (_sh.isUndefined(tmpValue)) {
            tmpValue = null;
        }

        if (formatColumn) {
            if (formatColumn === 'md5') {
                tmpValue = Md5.hashStr(pOneColumn.model);
            }
        }
        return tmpValue;
    }

    makeListInfo(pDataJson, pModel) {
        let that = this;
        let i = 0;
        let j = 0;
        let ListInfo = [];

        function getUpdateTemplate() {
            //主表模版
            return [
                {
                    'TABLE': '',
                    'ACTION': 'Update',
                    'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
                    'WHERE': [],
                    'COLUMNS': [],
                    'RSA_COLUMNS': [],
                    'RECORDS': []
                }
            ];
        }

        function getInsertTemplate() {
            //明细表模版
            return [
                {
                    'TABLE': '',
                    'ACTION': 'Insert',
                    'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
                    'WHERE': [],
                    'COLUMNS': [],
                    'RSA_COLUMNS': [],
                    'RECORDS': []
                }
            ];
        }

        function getDeleteTemplate() {
            return [
                {
                    'TABLE': '',
                    'ACTION': 'Delete',
                    'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
                    'WHERE': [],
                    'COLUMNS': [],
                    'RECORDS': []
                }
            ];
        }

        function addToList(OneForm, pAction) {
            let tmp = {
                'i': i,
                'ListIndex': j,
                'type': OneForm.type,
                'tablename': OneForm.tablename,
                'action': pAction,
                'form': OneForm
            };
            ListInfo.push(tmp);
            j++;
            if (pAction === 'Insert') {
                Array.prototype.push.apply(that.templateJson.LIST, getInsertTemplate());
            } else if (pAction === 'Update') {
                Array.prototype.push.apply(that.templateJson.LIST, getUpdateTemplate());
            } else if (pAction === 'Delete') {
                Array.prototype.push.apply(that.templateJson.LIST, getDeleteTemplate());
            }
        }

        //准备基础模版
        _sh.each(pDataJson, (OneForm) => {
            if (pModel === 'add') { // 如果是新增操作，都是 Insert
                addToList(OneForm, 'Insert');
            } else if (pModel === 'modify') { //如果是修改操作，都是更新主表；删除明细表，再次插入明细表
                if (OneForm['type'] === 'normal') {
                    addToList(OneForm, 'Update');
                } else if (OneForm['type'] === 'tablelist' || OneForm['type'] === 'formFiles') {
                    if (this.utils.Base_HasValue(OneForm['showPage']) && OneForm['showPage'][0] === 'detail') {
                        // 控制form在详情页面显示，但在修改页面隐藏并且不能修改
                    } else {
                        addToList(OneForm, 'Delete');
                        addToList(OneForm, 'Insert');
                    }
                }
            }
            i++;
        });
        return ListInfo;
    }

    makeTable() {
        let i = 0;
        _sh.each(this.ListInfo, (OneGroup) => {
            this.templateJson.LIST[i].TABLE = OneGroup['form'].tablename; // 处理模板之中的表
            this.templateJson.LIST[i].ACTION = OneGroup['action']; // 执行动作 Insert ， Update ， Delete
            i++;
        });
    }

    moveModelToData() {
        _sh.each(this.ListInfo, (OneGroup) => {
            if (this.utils.Base_compare(OneGroup['type'], 'normal')) {
                if (!_sh.hasIn(OneGroup['form'], 'data')) {
                    OneGroup['form'].data = [];
                }
                OneGroup['form'].data[0] = {}; // 清空
                _sh.each(OneGroup['form'].columns, (OneColumn) => {
                    if (_sh.includes(['dateRange', 'dateTimeRange'], OneColumn['component'])) {
                        _sh.each(OneColumn['bindColumns'], (item: string, index) => {
                            OneGroup['form'].data[0][item] = OneColumn['model'][index];
                        });
                    } else {
                        OneGroup['form'].data[0][OneColumn['column']] = OneColumn['model'];
                    }
                });
            }
        });
    }

    moveColumnGruop() {
        _sh.each(this.ListInfo, (OneGroup) => {
            if (OneGroup['action'] === 'Delete') {
                return;
            }
            let tmpColumns = [];
            _sh.each(OneGroup['form'].columns, (OneColumn) => {
                let columnGroup = this.utils.Base_getJsonValue(OneColumn, 'columnGroup', null);
                if (this.utils.Base_HasValue(columnGroup)) {
                    Array.prototype.push.apply(tmpColumns, columnGroup);
                }
            });
            if (this.utils.Base_HasValue(tmpColumns)) {
                Array.prototype.push.apply(OneGroup['form'].columns, tmpColumns);
            }
        });
    }

    makeColumn(pModel) {
        let i = 0;
        _sh.each(this.ListInfo, (OneGroup) => {
            if (_sh.includes(['Update', 'Insert'], OneGroup['action'])) {
                _sh.each(OneGroup['form'].columns, (OneColumn) => {
                    //获取 OneColumn.commitPoint 的数据，如果数据为Null , 给默认值 ["add", "modify"]
                    let commitPoint = this.utils.Base_getJsonValue(OneColumn, 'commitPoint', this.utils.Base_commitPoint_Def());
                    let bAddColumn = true;

                    if (this.utils.Base_compare(OneGroup['action'], 'Update')) {
                        //如果是Update操作，那么PK字段，不能参与更新
                        bAddColumn = !this.utils.Base_getJsonValue(OneColumn, 'pk_column', false);
                    } else if (this.utils.Base_compare(OneGroup['action'], 'Insert')) {
                        //如果是Insert操作，那么所有字段都的加入。
                        bAddColumn = true;
                    }
                    if (bAddColumn) {
                        if (_sh.includes(commitPoint, pModel)) {
                            if (_sh.includes(['dateRange', 'dateTimeRange'], OneColumn['component'])) {
                                _sh.each(OneColumn['bindColumns'], item => {
                                    this.templateJson.LIST[i].COLUMNS.push({
                                        'COLUMN': item
                                    });
                                });
                            } else {
                                this.templateJson.LIST[i].COLUMNS.push({
                                    'COLUMN': OneColumn['column']
                                });
                            }
                        }
                    }
                });

                // 加密字段
                if (this.utils.Base_HasValue(OneGroup['form'].rsa_columns)) {
                    _sh.each(OneGroup['form'].rsa_columns, (OneColumn) => {
                        let tmpColumn = {
                            'COLUMN': OneColumn['column']
                        };
                        this.templateJson.LIST[i].RSA_COLUMNS.push(tmpColumn);
                    });
                }
            }
            i++;
        });
    }

    makeData(pModel) {
        //处理主表类型
        let i = 0;
        _sh.each(this.ListInfo, (OneGroup) => {
            if (_sh.includes(['Update', 'Insert'], OneGroup['action'])) {
                if (OneGroup['type'] === 'normal') {
                    let tmpRECORD = {};
                    _sh.each(OneGroup['form'].columns, (OneColumn) => {
                        let commitPoint = this.utils.Base_getJsonValue(OneColumn, 'commitPoint', this.utils.Base_commitPoint_Def());
                        if (_sh.includes(commitPoint, pModel)) {
                            if (_sh.includes(['dateRange', 'dateTimeRange'], OneColumn['component'])) {
                                _sh.each(OneColumn['bindColumns'], (item: string, index) => {
                                    tmpRECORD[item] = OneColumn['model'][index];
                                });
                            } else if (_sh.includes(['multiSelect'], OneColumn['component'])) {
                                tmpRECORD[OneColumn['column']] = OneColumn['model'] ? OneColumn['model'].join(';') : OneColumn['model'];
                            } else {
                                let tmpValue = OneColumn['model'];
                                tmpRECORD[OneColumn['column']] = this.getValue(tmpValue, OneColumn, pModel);
                            }
                        }
                    });
                    this.templateJson.LIST[i].RECORDS.push(tmpRECORD);
                } else if (OneGroup['type'] === 'tablelist') {
                    //处理数据
                    _sh.each(OneGroup['form'].data, (OneData) => {
                        let tmpRECORD = {};
                        _sh.each(OneGroup['form'].columns, (OneColumn) => {
                            let tmpValue = OneData[OneColumn['column']];
                            tmpRECORD[OneColumn['column']] = this.getValue(tmpValue, OneColumn, pModel);
                        });
                        this.templateJson.LIST[i].RECORDS.push(tmpRECORD);
                    });
                } else if (OneGroup['type'] === 'formFiles') {
                    _sh.each(OneGroup['form']['fileList'], (OneData) => {
                        let tmpRECORD = {};
                        _sh.each(OneGroup['form'].columns, (OneColumn) => {
                            let tmpValue = OneData[OneColumn['column']];
                            tmpRECORD[OneColumn['column']] = this.getValue(tmpValue, OneColumn, pModel);
                        });
                        this.templateJson.LIST[i].RECORDS.push(tmpRECORD);
                    });
                }
            }
            i++;
        });
    }

    makeWhere() {
        let i = 0;
        _sh.each(this.ListInfo, (OneGroup) => {
            _sh.each(OneGroup['form'].columns, (OneColumn) => {
                if (_sh.includes(['Update'], OneGroup['action'])) {
                    //处理where条件
                    let bPK_Column = this.utils.Base_getJsonValue(OneColumn, 'pk_column', false);
                    if (bPK_Column) {
                        let tmpWHERE = {
                            'COLUMN': OneColumn['column'],
                            'VALUE': '?',
                            'OPERATOR': '=',
                            'VALUE_TYPE': 'string',
                            'CONDITION_OPERATOR': 'AND',
                            'GROUP': 'GROUP_01'
                        };
                        this.templateJson.LIST[i].WHERE.push(tmpWHERE);
                    }
                }
            });
            i++;
        });
    }

    makeDeleteParam() {
        let i = 0;
        _sh.each(this.ListInfo, (OneGroup) => {
            if (this.utils.Base_compare(OneGroup['action'], 'Delete')) {
                _sh.each(OneGroup['form'].deleteParam, (OneColumn) => {
                    //处理where条件
                    let tmpColumn = {
                        'COLUMN': OneColumn['column']
                    };
                    this.templateJson.LIST[i].COLUMNS.push(tmpColumn);

                    let tmpWHERE = {
                        'COLUMN': OneColumn['column'],
                        'VALUE': '?',
                        'OPERATOR': '=',
                        'VALUE_TYPE': 'string',
                        'CONDITION_OPERATOR': 'AND',
                        'GROUP': 'GROUP_01'
                    };
                    this.templateJson.LIST[i].WHERE.push(tmpWHERE);
                });
            }
            i++;
        });

        i = 0;
        _sh.each(this.ListInfo, (OneGroup) => {
            console.log("auto-insert.service.ts 01",OneGroup);
            if (this.utils.Base_compare(OneGroup['action'], 'Delete')) {
                console.log("auto-insert.service.ts 02",OneGroup);
                let tmpRECORD = {};
                _sh.each(OneGroup['form'].deleteParam, (OneColumn) => {
                    if (this.utils.Base_HasValue(OneColumn['value'])) {
                        let deleteTarget = OneColumn['value'];
                        let deleteTargetTable = deleteTarget.split('.')[0]; //basic_service
                        let deleteTargetColumn = deleteTarget.split('.')[1]; // SERVICE_ID
                        if (deleteTargetTable === 'SYS_CONSTANT') {
                            tmpRECORD[OneColumn['column']] = deleteTargetColumn;
                        } else {
                            _sh.each(this.ListInfo, (OneTargetGroup) => {
                                if (this.utils.Base_compare(OneTargetGroup['form'].tablename, deleteTargetTable)) {
                                    _sh.each(OneTargetGroup['form'].columns, (OneTargetColumn) => {
                                        if (this.utils.Base_compare(OneTargetColumn['column'], deleteTargetColumn)) {
                                            tmpRECORD[OneColumn['column']] = OneTargetColumn['model'];
                                        }
                                    });
                                }
                            });
                        }
                    }
                });
                this.templateJson.LIST[i].RECORDS.push(tmpRECORD);
                console.log("auto-insert.service.ts 03",this.templateJson.LIST[i].RECORDS);

            }
            i++;
        });

    }


    checkValidate() {
        function checkData(pColumn, pData) {
            _sh.each(pData, (OneData) => {
                let value = this.utils.Base_getJsonValue(OneData, pColumn.name, null);
                if (!this.utils.Base_HasValue(value)) {
                    this.confirmModal.show('info', {
                        title: '请填写值'
                    });
                }
            });
        }

        _sh.each(this.ListInfo, (OneInfo) => {
            _sh.each(OneInfo['form'].columns, (OneColumn) => {
                let require = this.utils.Base_getJsonValue(OneColumn, 'require', false);
                if (require) {
                    checkData(OneColumn, OneInfo['form'].data);
                }
            });
        });
    }

    ExecJson(pJSON) {
        let userData = this.userInfo.getUserInfo();
        let MENU_SEARCH_ID = '';
        if (this.utils.Base_HasValue(this.dataCenter._dataObject.nowMenu) && this.dataCenter._dataObject.nowMenu.MENU_ID) {
            MENU_SEARCH_ID = this.utils.Base_getMenulist(this.dataCenter._dataObject.nowMenu.MENU_ID).MENU_SEARCH_ID;
        }
        let tmpUrl = this.appConfigService.getServer().AutoInsertAPi + '?random=' + Math.random();
        let myParam = {
            'empId': userData['EMP_ID'],
            'userId': userData['USER_ID'],
            'userName': userData['USERNAME'],
            'comId': userData['COM_ID'],
            'JSON': JSON.stringify(pJSON),
            'PF': this.utils.Base_HasValue(MENU_SEARCH_ID) ? MENU_SEARCH_ID : this.appConfigService.config.MysqlPF,
            'debug': 1
        };
        return this.ajaxService.ajaxPost(tmpUrl, myParam);
    }


    AdapterLogicDelete(DataJson) {
        let templateJson = {
            'LIST': [
                {
                    'TABLE': '',
                    'ACTION': 'Update',
                    'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
                    'WHERE': [],
                    'COLUMNS': [],
                    'RSA_COLUMNS': [],
                    'RECORDS': []
                }
            ]
        };
        let i = 0;
        templateJson.LIST[i].TABLE = DataJson.showColumns.tablename;
        let tmpColumn = {
            'COLUMN': 'U_DELETE'
        };
        templateJson.LIST[i].COLUMNS.push(tmpColumn);

        let iWhereCount = 0;
        _sh.each(DataJson.showColumns.columns, (OneColumn) => {
            //处理where条件
            if (_sh.hasIn(OneColumn, 'pk_column')) {
                if (OneColumn['pk_column']) {
                    let tmpWHERE = {
                        'COLUMN': OneColumn['column'],
                        'VALUE': '?',
                        'OPERATOR': '=',
                        'VALUE_TYPE': 'string',
                        'CONDITION_OPERATOR': 'AND',
                        'GROUP': 'GROUP_01'
                    };
                    templateJson.LIST[i].WHERE.push(tmpWHERE);
                    iWhereCount++;
                }
            }
        });
        if (iWhereCount === 0) {
            console.error('LogicDelete action must have where conditions , not found pk_column');
            return 'LogicDelete action must have where conditions , not found pk_column ';
        }
        //处理数据
        i = 0;
        _sh.each(DataJson.data, (OneData) => {
            if (OneData['checked']) {
                let tmpRECORD = {};
                _sh.each(DataJson.showColumns.columns, (OneColumn) => {
                    if (_sh.hasIn(OneColumn, 'pk_column')) {
                        if (OneColumn['pk_column']) {
                            tmpRECORD[OneColumn['column']] = OneData[OneColumn['column']];
                        }
                    }
                });
                tmpRECORD['U_DELETE'] = 0;
                templateJson.LIST[i].RECORDS.push(tmpRECORD);
            }
        });
        let j = 0;
        let ifound = 0;
        _sh.each(templateJson.LIST[i].RECORDS, (pRECORDS) => {
            if (_sh.isEmpty(pRECORDS)) {
                ifound++;
            }
            j++;
        });

        if (ifound > 0) {
            console.error('delete action must have where conditions');
            return 'delete action must have where conditions';
        } else {
            return templateJson;
        }
    }

    AdapterDelete(DataJson) {
        let templateJson: any = {
            'LIST': [
                {
                    'TABLE': '',
                    'ACTION': 'Delete',
                    'WHEREGROUP': [{'GROUP': 'GROUP_01', 'CONDITION_OPERATOR': 'AND'}],
                    'WHERE': [],
                    'COLUMNS': [],
                    'RSA_COLUMNS': [],
                    'RECORDS': []
                }
            ]
        };
        let i = 0;
        templateJson.LIST[i].TABLE = DataJson.showColumns.tablename;
        _sh.each(DataJson.showColumns.columns, (OneColumn) => {
            let tmpColumn: any = {
                'COLUMN': OneColumn['column']
            };
            templateJson.LIST[i].COLUMNS.push(tmpColumn);
            //处理where条件
            if (_sh.hasIn(OneColumn, 'pk_column')) {
                if (OneColumn['pk_column']) {
                    let tmpWHERE: any = {
                        'COLUMN': OneColumn['column'],
                        'VALUE': '?',
                        'OPERATOR': '=',
                        'VALUE_TYPE': 'string',
                        'CONDITION_OPERATOR': 'AND',
                        'GROUP': 'GROUP_01'
                    };
                    templateJson.LIST[i].WHERE.push(tmpWHERE);
                }
            }
        });

        //处理数据
        i = 0;
        _sh.each(DataJson.data, (OneData) => {
            if (OneData['checked']) {
                let tmpRECORD = {};
                _sh.each(DataJson.showColumns.columns, (OneColumn) => {
                    if (_sh.hasIn(OneColumn, 'pk_column')) {
                        if (OneColumn['pk_column']) {
                            tmpRECORD[OneColumn['column']] = OneData[OneColumn['column']];
                        }
                    }
                });
                templateJson.LIST[i].RECORDS.push(tmpRECORD);
            }
        });
        let j = 0;
        let ifound = 0;
        _sh.each(templateJson.LIST[i].RECORDS, (pRECORDS) => {
            if (_sh.isEmpty(pRECORDS)) {
                ifound++;
            }
            j++;
        });

        if (ifound > 0) {
            console.error('delete action must have where conditions');
            return 'delete action must have where conditions';
        } else {
            return templateJson;
        }
    }

    AdapterInsert(DataJson) {
        this.templateJson = {
            'LIST': []
        };
        this.ListInfo = this.makeListInfo(DataJson, 'add'); //获取模版；
        this.moveColumnGruop(); //将 ColumnGruop 移动到平级目录
        this.moveModelToData();// 移动数据 model - > data
        this.makeTable();//处理表和执行动作
        this.makeColumn('add');//处理字段
        this.makeData('add'); // 处理数据
        return this.templateJson;
    }

    AdapterUpdate(DataJson) {
        console.log("detail-action.service.ts 03", DataJson);

        this.templateJson = {
            'LIST': []
        };
        this.ListInfo = this.makeListInfo(DataJson, 'modify'); //获取模版；
        this.moveColumnGruop(); //将 ColumnGruop 移动到平级目录
        this.moveModelToData();// 移动数据 model - > data
        this.makeTable();//处理表和执行动作
        this.makeColumn('modify');//处理字段
        this.makeData('modify'); // 处理数据
        this.makeWhere(); //处理Where
        this.makeDeleteParam(); //处理DeleteParam
        return this.templateJson;
    }

    ExecJsonNoUser(pJSON) {
        const tmpUrl = this.appConfigService.getServer().AutoInsertAPi + '?random=' + Math.random();
        const userInfo = this.userInfoService.userInfo;
        const myParam = {
            'JSON': JSON.stringify(pJSON),
            'PF': this.appConfigService.config.MysqlPF,
            'debug': 1
        };
        return this.ajaxService.ajaxPost(tmpUrl, myParam);
    }

}
