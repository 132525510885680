import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { UtilsService } from '../../service/utils.service';
import { UserInfoService } from '../../service/user-info.service';
import { StorageService } from '../../service/storage.service';
import { AutoInsertService } from '../../service/auto-insert.service';
import { ConfirmModalService } from '../../service/confirm-modal.service';
import {Md5} from 'ts-md5/dist/md5';
import * as localForage from "localforage";

@Component({
  selector: 'app-parsonal-center',
  templateUrl: './parsonal-center.component.html',
  styleUrls: ['./parsonal-center.component.css']
})
export class ParsonalCenterComponent implements OnInit {
  validateForm: FormGroup;
  public editForm: any;
  private hasError: boolean;
  private tilp: any;
  private newPwd: any;
  private confirmPwd: any;
  public userData: any;

  constructor(private fb: FormBuilder,
  	private util: UtilsService,
  	private router: Router,
    private userInfo: UserInfoService,
    private localStorage: StorageService,
    private autoInsert: AutoInsertService,
    private confirmModal: ConfirmModalService) { }

  submitForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[ i ].markAsDirty();
      this.validateForm.controls[ i ].updateValueAndValidity();
    }
  }

  emitErr(pMsg) {
    this.hasError = true;
    this.tilp = pMsg;
  }

  editPassword(): boolean {
  	return this.editForm = true;
  }

  clearCache() {
    this.confirmModal.show('confirm', {
      title: "温馨提示",
      content: "确认要清除缓存吗？",
      suc: ()=>{
        this.userInfo.clearAll();
      }
    })
  }

  doUpate(): void {
  	if(!this.util.Base_HasValue(this.newPwd) || !this.util.Base_HasValue(this.confirmPwd)){
  		return;
  	}
  	if(this.newPwd != this.confirmPwd){
  		this.emitErr("两次输入的密码不相同");
  		return;
  	}
    let pwd = Md5.hashStr(this.newPwd);
    let templateJson = {
      "LIST":[
      {
        "TABLE": "system_user",
        "ACTION": "Update",
        "WHEREGROUP": [{"GROUP": "GROUP_01", "CONDITION_OPERATOR": "AND"}],
        "WHERE": [{
          "COLUMN": "USER_ID",
          "VALUE": "?",
          "OPERATOR": "=",
          "VALUE_TYPE": "string",
          "CONDITION_OPERATOR": "AND",
          "GROUP": "GROUP_01"
        }],
        "COLUMNS": [
        {"COLUMN": "PASSWD"}
        ],
        "RECORDS": [
        {
          "USER_ID": this.userData.USER_ID,
          "PASSWD": pwd
        }
        ]
      }
      ]
    };
    this.autoInsert.ExecJson(templateJson).subscribe((response) => {
      if(response["state"] === "success"){
        this.confirmModal.show('success', {
          title: '修改成功',
          suc: () => {
            this.userInfo.gotoLogin();
          }
        })
      } else {
        this.confirmModal.show('error', {title: '修改失败' })
      }
    })
  }

  ngOnInit(): void {
  	this.editForm = false;
  	this.userData =  this.userInfo.getUserInfo();
  	this.validateForm = this.fb.group({
      formLayout: [ 'horizontal' ],
      fieldA    : [ null, [ Validators.required ] ],
      filedB    : [ null, [ Validators.required ] ]
    });
  }

}
