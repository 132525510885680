import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'menuFilter'
})
export class MenuFilterPipe implements PipeTransform {

  transform(menuList: any[], filterCondition: (item: any) => boolean): any[] {
    return menuList.filter(filterCondition);
  }

}
