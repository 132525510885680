import {Component, Injectable} from '@angular/core';
import {DataCenterService} from "../../service/data-center.service";
import {RequestDataService} from "../../service/request-data.service";
import {UserInfoService} from "../../service/user-info.service";
import {UtilsService} from "../../service/utils.service";
import {AppConfigService} from "../../service/app-config.service";
import {ConfirmModalService} from "../../service/confirm-modal.service";
import {NzModalService} from "ng-zorro-antd";
import * as _sh from 'lodash';
import {UUID} from "angular2-uuid";
import {forkJoin as observableForkJoin, Observable} from "rxjs";
import {FormModalComponent} from "../../basicComponent/form-modal/form-modal.component";
import {MittService} from "../../service/mitt.service";


@Injectable({
    providedIn: 'root'
})
export class AssetsService {

    // public user: any;/

    constructor(
        private mittService: MittService,
        private dataCenterService: DataCenterService,
        private requestData: RequestDataService,
        private userInfoService: UserInfoService,
        private requestService: RequestDataService,
        private config: AppConfigService,
        private confirmModal: ConfirmModalService,
        private modalService: NzModalService,
        private utils: UtilsService
    ) {
        // this.user = userInfoService.getUserInfo();
    }

    selectMore(pListData, multiple): number {
        //计数，有多少条记录被勾选了。
        let iCount = 0;
        _sh.each(pListData, oneData => {
            if (oneData['checked']) {
                iCount++;
            }
        });
        // tslint:disable-next-line:triple-equals
        if (multiple == true) {
            if (iCount === 0) {
                this.confirmModal.show('warning', {
                    'title': '温馨提示',
                    'content': '请至少选择一条纪录！'
                });
                return iCount;
            }
        } else {
            if (iCount === 0) {
                this.confirmModal.show('warning', {
                    'title': '温馨提示',
                    'content': '请选择一条纪录！'
                });
                return iCount;
            } else if (iCount > 1) {
                this.confirmModal.show('warning', {
                    'title': '温馨提示',
                    'content': '只能选择一条纪录！'
                });
                return iCount;
            }
        }
        return iCount;
    }

    selectOneData(pListData): number {
        let iCount = 0;
        _sh.each(pListData, oneData => {
            if (oneData['checked']) {
                iCount++;
            }
        });
        if (iCount === 0) {
            this.confirmModal.show('warning', {
                'title': '温馨提示',
                'content': '请至少选择一条纪录！'
            });
            console.error('this action must select only one record ');
            return iCount;
        } else if (iCount > 1) {
            this.confirmModal.show('warning', {
                'title': '温馨提示',
                'content': '请只选择一条纪录！'
            });
            console.error('this action must select only one record ');
            return iCount;
        }
        return iCount;
    }

    formatSelectEntity(datas, pColumn) {
        let entity: any = [];
        _sh.each(datas, (OneData) => {
            let obj: any = {};
            const keys = _sh.keys(OneData);
            _sh.each(keys, (oneKeys) => {
                if (oneKeys === pColumn) {
                    obj['id'] = OneData[oneKeys];
                }
            });
            entity.push(obj);
        });
        return entity;
    }

    findOneColumnValue(pColumns, pColumnName) {
        const oneOneColum = _sh.find(pColumns, {'column': pColumnName});
        let oneValue = null;
        if (this.utils.Base_HasValue(oneOneColum)) {
            oneValue = _sh.get(oneOneColum, 'model');
        } else {
            _sh.each(pColumns, oneColumn => {
                _sh.each(oneColumn['columnGroup'], oneGroup => {
                    if (oneGroup['column'] === pColumnName) {
                        oneValue = _sh.get(oneGroup, 'model');
                    }
                })
            })
        }
        return oneValue;
    }

    setOneColumnValue(pColumns, pColumnName, pValue) {
        _sh.each(pColumns['columns'], oneOneColum => {
            if (oneOneColum['column'] === pColumnName) {
                _sh.set(oneOneColum, 'model', pValue);
            }
        })
    }

    public doAction(pBtn, pParentData) {
        switch (pBtn.ACTION_VALUE) {
            case 'newRentContract': // 等待分配的订单:分配按钮
                this.doNewRentContract(pBtn, pParentData);
                break;
            case 'btnSpWorkflowApproval': // 特殊的，工作流，审批，按钮
                this.doBtnSpWorkflowApproval(pBtn, pParentData);
                break;
        }
    }

    doBtnSpWorkflowApproval(pBtn, pParentData) {
        console.log(pBtn);
        console.log(pParentData);
        const documentId = _sh.get(pParentData, 'id', null);
        console.log(documentId);
        console.log("消息。", "1");

        const searchAll = observableForkJoin(
            this.requestService.getDataByCondition("assets/descriptions/con_con_wf_user_await_descriptions_finish.json", {'ID': documentId})
        );
        searchAll.subscribe(value => {
            const workFlowList = _sh.get(value, [0, 'data'], []);
            const upUrgeFlag = _sh.get(workFlowList, [workFlowList.length - 1, 'URGE_FLAG'], 'GENERALLY');

            let nzComponentParams = {
                "modalFormData": {
                    "tablename": "wf_await",
                    "formname": "wf_await",
                    "columns": [
                        {
                            "column": "ID",
                            "name": "ID_NAME",
                            "component": "hr",
                            "lableSpan": 6,
                            "itemSpan": 16,
                            "customize": 24,
                            "lable": "紧急",
                            "comment": "",
                            "placeholder": "",
                            "model": null,
                            "visible": false,
                            "require": false,
                            "dictionary": "",
                            "dictionaryPF": null
                        },
                        {
                            "column": "APPROVAL_RESULT",
                            "name": "APPROVAL_RESULT_NAME",
                            "component": "radio",
                            "lableSpan": 6,
                            "itemSpan": 16,
                            "customize": 24,
                            "lable": "是否同意",
                            "comment": "是否同意",
                            "placeholder": "请选择是否同意",
                            "model": null,
                            "visible": true,
                            "require": true,
                            "dictionary": "assets/basic/dict/AGREE.json",
                            "dictionaryPF": null
                        },
                        {
                            "column": "URGE_FLAG",
                            "name": "URGE_FLAG_NAME",
                            "component": "select",
                            "lableSpan": 6,
                            "itemSpan": 16,
                            "customize": 24,
                            "lable": "紧急",
                            "comment": "是否紧急",
                            "placeholder": "请选择是否紧急",
                            "model": "GENERALLY",
                            "visible": true,
                            "require": true,
                            "dictionary": "workflow/dict/WF_LV.json",
                            "dictionaryPF": null
                        },
                        {
                            "column": "APPROVAL_CONTENT",
                            "name": "APPROVAL_CONTENT_NAME",
                            "component": "inputMulti",
                            "lableSpan": 6,
                            "itemSpan": 16,
                            "customize": 24,
                            "lable": "审批意见",
                            "comment": "审批意见",
                            "placeholder": "请选择审批意见",
                            "model": null,
                            "visible": true,
                            "require": false,
                            "dictionaryPF": null
                        }
                    ],
                    "changeList": [
                        {
                            "_comment": "该功能是选择 是否同意 之后，将审批意见填写上",
                            "changeMethod": "approvalResult",
                            "triggerColumn_comment": "触发条件：指的是，当这些字段的值有变化的时候，启动",
                            "triggerColumn": [
                                "SYS.wf_await.APPROVAL_RESULT"
                            ],
                            "valueColumn_comment": "满足值条件：启动后，当这些都有值的时候，才执行动作",
                            "valueColumn": [
                                "SYS.wf_await.APPROVAL_RESULT"
                            ],
                            "execList": [
                                {
                                    "execType": "normal",
                                    "datas": [
                                        {
                                            "column": "APPROVAL_RESULT",
                                            "fromValue": {
                                                "msgTemplate": "<%=APPROVAL_RESULT%>",
                                                "msgValue": [
                                                    {
                                                        "column": "APPROVAL_RESULT",
                                                        "fromValue": "MODAL.APPROVAL_RESULT.DICT",
                                                        "isNull": "同意"
                                                    }
                                                ]
                                            }
                                        }
                                    ]
                                }
                            ],
                            "fillback": [
                                {
                                    "toValue": "SYS.wf_await.APPROVAL_CONTENT",
                                    "msgTemplate": "<%=APPROVAL_RESULT%>",
                                    "msgValue": [
                                        {
                                            "column": "APPROVAL_RESULT",
                                            "fromValue": "execList.0.APPROVAL_RESULT",
                                            "isNull": "同意"
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            };
            let nzTitle = '确定执行合同审批么？';

            if (upUrgeFlag == 'URGENT') {
                nzComponentParams = {
                    "modalFormData": {
                        "tablename": "wf_await",
                        "formname": "wf_await",
                        "columns": [
                            {
                                "column": "ID",
                                "name": "ID_NAME",
                                "component": "hr",
                                "lableSpan": 6,
                                "itemSpan": 16,
                                "customize": 24,
                                "lable": "紧急",
                                "comment": "",
                                "placeholder": "",
                                "model": null,
                                "visible": true,
                                "require": false,
                                "dictionary": "",
                                "dictionaryPF": null
                            },
                            {
                                "column": "APPROVAL_RESULT",
                                "name": "APPROVAL_RESULT_NAME",
                                "component": "radio",
                                "lableSpan": 6,
                                "itemSpan": 16,
                                "customize": 24,
                                "lable": "是否同意",
                                "comment": "是否同意",
                                "placeholder": "请选择是否同意",
                                "model": null,
                                "visible": true,
                                "require": true,
                                "dictionary": "assets/basic/dict/AGREE.json",
                                "dictionaryPF": null
                            },
                            {
                                "column": "URGE_FLAG",
                                "name": "URGE_FLAG_NAME",
                                "component": "select",
                                "lableSpan": 6,
                                "itemSpan": 16,
                                "customize": 24,
                                "lable": "紧急",
                                "comment": "是否紧急",
                                "placeholder": "请选择是否紧急",
                                "model": "URGENT",
                                "visible": false,
                                "require": true,
                                "dictionary": "workflow/dict/WF_LV.json",
                                "dictionaryPF": null
                            },
                            {
                                "column": "APPROVAL_CONTENT",
                                "name": "APPROVAL_CONTENT_NAME",
                                "component": "inputMulti",
                                "lableSpan": 6,
                                "itemSpan": 16,
                                "customize": 24,
                                "lable": "审批意见",
                                "comment": "审批意见",
                                "placeholder": "请选择审批意见",
                                "model": null,
                                "visible": true,
                                "require": false,
                                "dictionaryPF": null
                            }
                        ],
                        "changeList": [
                            {
                                "_comment": "该功能是选择 是否同意 之后，将审批意见填写上",
                                "changeMethod": "approvalResult",
                                "triggerColumn_comment": "触发条件：指的是，当这些字段的值有变化的时候，启动",
                                "triggerColumn": [
                                    "SYS.wf_await.APPROVAL_RESULT"
                                ],
                                "valueColumn_comment": "满足值条件：启动后，当这些都有值的时候，才执行动作",
                                "valueColumn": [
                                    "SYS.wf_await.APPROVAL_RESULT"
                                ],
                                "execList": [
                                    {
                                        "execType": "normal",
                                        "datas": [
                                            {
                                                "column": "APPROVAL_RESULT",
                                                "fromValue": {
                                                    "msgTemplate": "<%=APPROVAL_RESULT%>",
                                                    "msgValue": [
                                                        {
                                                            "column": "APPROVAL_RESULT",
                                                            "fromValue": "MODAL.APPROVAL_RESULT.DICT",
                                                            "isNull": "同意"
                                                        }
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                ],
                                "fillback": [
                                    {
                                        "toValue": "SYS.wf_await.APPROVAL_CONTENT",
                                        "msgTemplate": "<%=APPROVAL_RESULT%>",
                                        "msgValue": [
                                            {
                                                "column": "APPROVAL_RESULT",
                                                "fromValue": "execList.0.APPROVAL_RESULT",
                                                "isNull": "同意"
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                };
                nzTitle = '确定执行合同审批么？';
            }
            this.modalService.create({
                nzTitle,
                nzContent: FormModalComponent,
                nzWidth: 700,
                nzComponentParams: nzComponentParams,
                nzOnOk: (res) => {
                    const data = res.modalFormData.columns;

                    // 1 表单校验
                    let requireCheck = true;
                    _sh.each(data, oneColumn => {
                        if (!this.utils.Base_HasValue(oneColumn['model']) && oneColumn['require'] === true) {
                            requireCheck = false;
                        }
                    })
                    if (!requireCheck) {
                        this.confirmModal.show('error', {title: '请填写必填信息。'});
                        return false
                    }

                    // 2 参数准备
                    let queryData = {}
                    const user = this.userInfoService.getUserInfo();

                    let bodyData = {
                        "pf": this.config.config.MysqlPF,
                        "jsonId": this.config.config.jsonId,
                        "execUserId": user['USER_ID'],
                        "execUserName": user['USERNAME'],
                        "wfUserAwaitId": pParentData['workflowData'].wfUserAwaitId,
                        "approvalResult": data[1]['model'],
                        "URGE_FLAG": data[2]['model'],
                        "approvalContent": data[3]['model']
                    }
                    console.log(bodyData);
                    this.requestService.doActionByHdbsHulk("workflowSpServlet", "execWorkflowAgree", {}, bodyData).subscribe(res => {
                        console.log(res);
                        let status = _sh.get(res, ['status'], 200);
                        if (status == 200) {
                            this.mittService.emitter.emit("descriptionActionRefresh", {
                                'afterCompletionWindow': 'close',
                                'afterCompletionAction': 'refreshTodoCenter',
                                'id': pParentData['id']
                            })
                            this.confirmModal.show('success', {'title': '执行成功'});
                        } else if (status == 500) {
                            this.confirmModal.show('error', {'title': '执行错误如下', 'content': res.error});
                        }
                    })
                }
            })

        });
    }

    doNewRentContract(pBtn, pParentData) {
        // this.utils.printLog(pBtn,pParentData);
    }

    public doAssetMap(pBtn, pParentData) {
        let tabIndex = _sh.findIndex(this.dataCenterService._dataObject.list, (item) => {
            return item['parentDataId'] === pParentData['id'];
        });
        if (tabIndex > 0) {
            this.dataCenterService._dataObject.index = tabIndex;
            this.dataCenterService._dataObject.nowMenu = pParentData['id'];
            this.dataCenterService.refreshDataCenterInStorage();
        } else {
            let title = pParentData['title'];
            this.dataCenterService.newTab({
                id: UUID.UUID(), // 唯一标识
                parentDataId: pParentData['id'], // 唯一标识
                nowMenu: pParentData['id'], // 菜单ID 用于详情返回
                title: title, // 菜单名称 用户tab展示
                formType: 'customForm', // 菜单类型 用于tab过滤显示模板
                custormFormType: 'app-asset-map',
                data: {}, // 查询返回数据
            });
        }
    }

    public doAssetControlMap(pBtn, pParentData) {
        let tabIndex = _sh.findIndex(this.dataCenterService._dataObject.list, (item) => {
            return item['parentDataId'] === pParentData['id'];
        });
        if (tabIndex > 0) {
            this.dataCenterService._dataObject.index = tabIndex;
            this.dataCenterService._dataObject.nowMenu = pParentData['id'];
            this.dataCenterService.refreshDataCenterInStorage();
        } else {
            let title = pParentData['title'] + ":" + pParentData['selectData']['AS_NAME'];
            this.dataCenterService.newTab({
                id: UUID.UUID(), // 唯一标识
                parentDataId: pParentData['id'], // 唯一标识
                nowMenu: pParentData['id'], // 菜单ID 用于详情返回
                title: title, // 菜单名称 用户tab展示
                formType: 'customForm', // 菜单类型 用于tab过滤显示模板
                custormFormType: 'app-asset-control-map',
                data: {}, // 查询返回数据
            });
        }
    }

}
