import {Component, Input, OnInit} from '@angular/core';
import * as _sh from 'lodash';
import {forkJoin as observableForkJoin} from "rxjs";
import {UtilsService} from "../../../service/utils.service";
import {ConfirmModalService} from "../../../service/confirm-modal.service";
import {RequestDataService} from "../../../service/request-data.service";
import {DataCenterService} from "../../../service/data-center.service";


@Component({
  selector: 'app-paper-detail',
  templateUrl: './paper-detail.component.html',
  styleUrls: ['./paper-detail.component.scss']
})
export class PaperDetailComponent implements OnInit {
  @Input() parentData: any;

  modeType : any;
  qaPaperId : any;
  ansPaperId : any;
  paperList : any [];
  paperQuestionList : any [];
  ansPaperList :any [];
  ansPaperQuestionList : any [];
  constructor(
      private utils: UtilsService,
      private confirmModal: ConfirmModalService,
      private requestService: RequestDataService,
      private dataCenterService: DataCenterService,
  ) {

  }

  ngOnInit() {
    this.modeType = _sh.get(this.parentData, ['modeType']);
    if(this.modeType == "qa_detail"){
      this.qaPaperId = _sh.cloneDeep(_sh.get(this.parentData,['id']));
      this.doSearchQa();
    }
    else if(this.modeType == "ans_detail"){
      this.ansPaperId = _sh.cloneDeep(_sh.get(this.parentData,['id']));
      this.doSearchAns();
    }

  }

  doSearchQa(){
    const search = observableForkJoin(
        this.requestService.getDataByCondition('assets/qa_paper/qa_paper.json',{'ID':this.qaPaperId}),
        this.requestService.getDataByCondition('assets/qa_paper/qa_paper_question.json',{'PAPER_ID':this.qaPaperId})
    );
    search.subscribe(values=>{
      console.log(values);
      this.paperList = _sh.get(values,[0,'data',0]);
      console.log(this.paperList);
      this.paperQuestionList = _sh.get(values,[1,'data']);
      console.log(this.paperQuestionList)
    })
  }

  doSearchAns(){
    const search = observableForkJoin(
        this.requestService.getDataByCondition('assets/qa_ans_paper/qa_ans_paper.json',{'ID':this.ansPaperId}),
        this.requestService.getDataByCondition('assets/qa_ans_paper/qa_ans_paper_question.json',{'ANSWER_PAPER_ID':this.ansPaperId})
    );
    search.subscribe(values=>{
      console.log(values);
      this.ansPaperList = _sh.get(values,[0,'data',0]);
      console.log(this.ansPaperList);
      this.ansPaperQuestionList = _sh.get(values,[1,'data']);
      console.log(this.ansPaperQuestionList)
    })
  }


  gotoBack() {
    this.parentData.data = {};
    this.parentData.conditionColumns = {};
    this.parentData.formType = 'listForm';
    this.parentData.actionArray = [];
    // 覆盖当前数据到data-center;
    _sh.each(this.dataCenterService._dataObject.list, (item) => {
      if (item['id'] === this.parentData.id) {
        item = _sh.extend(item, this.parentData);
      }
    });
    this.dataCenterService.refreshDataCenterInStorage();
  }

  doRefresh() {
    if(this.modeType == "qa_detail"){
      this.qaPaperId = _sh.cloneDeep(_sh.get(this.parentData,['id']));
      this.doSearchQa();
    }
    else if(this.modeType == "ans_detail"){
      this.ansPaperId = _sh.cloneDeep(_sh.get(this.parentData,['id']));
      this.doSearchAns();
    }
  }

  printWindows() {
    window.print();
  }
}

