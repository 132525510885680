import {Component, Input, OnInit} from '@angular/core';
import {StorageService} from "../../../service/storage.service";
import {UserInfoService} from "../../../service/user-info.service";
import {UploadService} from "../../../service/upload.service";
import {HttpClient} from "@angular/common/http";
import {DataCenterService} from "../../../service/data-center.service";
import {RequestDataService} from "../../../service/request-data.service";
import {UtilsService} from "../../../service/utils.service";
import {AppConfigService} from "../../../service/app-config.service";
import {ConfirmModalService} from "../../../service/confirm-modal.service";
import * as _sh from 'lodash';

@Component({
  selector: 'app-workflow-add-btn',
  templateUrl: './workflow-add-btn.component.html',
  styleUrls: ['./workflow-add-btn.component.scss']
})
export class WorkflowAddBtnComponent implements OnInit {

  constructor(
    private storage: StorageService,
    private userInfo: UserInfoService,
    private uploadService: UploadService,
    private httpClient: HttpClient,
    private dataCenterService: DataCenterService,
    private userInfoService: UserInfoService,
    private requestDataService: RequestDataService,
    private util: UtilsService,
    private config: AppConfigService,
    private confirmModal: ConfirmModalService
  ) {
  }

  @Input() workflowData: any;
  @Input() parentNode: any;
  @Input() oneNode: any;
  @Input() oneOption: any;
  @Input() btnType: any;

  ngOnInit() {
  }

  addNodeModelVisible = false; // 增加节点的模态框显示

  addNodeModelCancel() {
    this.addNodeModelVisible = false;
  }

  addNodeModelOk() {
    this.addNodeModelVisible = false;
  }

  showAddNodeModel() {
    this.addNodeModelVisible = true;
  }

  doFindIndex(pList, pId) {
    let find = false;
    let index = 1;
    let findIndex = 0;
    _sh.each(pList, oneItem => {
      if (oneItem['nodeId'] == pId && (!find)) {
        findIndex = index;
        find = true;
      }
      index++;
    })
    return findIndex;
  }

  fillInData(pOneNode, pParentNode, pObj) {
    let find = false;
    let findNode = null;
    let parentNode = null;
    _sh.each(pParentNode, oneNode => {
      if (!find) {
        find = oneNode['nodeId'] == pOneNode['nodeId'];
        parentNode = pParentNode;
        findNode = oneNode;
        if (find) {
          let findIndex = this.doFindIndex(parentNode, this.oneNode['nodeId']);
          parentNode.splice(findIndex, 0, pObj);
        }
      }
      if (!find) {
        let findObj = this.fillInData(pOneNode, _sh.get(oneNode, ['optionList'], []), pObj);
        find = findObj['find'];
        findNode = findObj['findNode'];
      }
      if (!find) {
        let findObj = this.fillInData(pOneNode, _sh.get(oneNode, ['nodeList'], []), pObj);
        find = findObj['find'];
        findNode = findObj['findNode'];
      }

    })
    return {
      'find': find,
      'findNode': findNode,
      'parentNode': parentNode
    };
  }


  execAdd(pObj) {
    if (this.btnType == 'endStart') {
      this.workflowData['nodeList'].splice(0, 0, pObj);
    } else if (this.btnType == 'endCheckMan') {
      // 在父节点，找到
      if (this.parentNode == this.workflowData) {
        // 如果在主线节点上
        const findIndex = this.doFindIndex(this.workflowData['nodeList'], this.oneNode['nodeId']);
        this.workflowData['nodeList'].splice(findIndex, 0, pObj);
      } else {
        // 如果在非主线节点上。
        if (this.util.Base_HasValue(this.oneOption)) {
          const findIndex = this.doFindIndex(this.oneOption['nodeList'], this.oneNode['nodeId']);
          this.oneOption['nodeList'].splice(findIndex, 0, pObj);
        }
      }
    } else if (this.btnType == 'inBranch') {
      if (this.util.Base_HasValue(this.oneOption)) {
        const findIndex = this.doFindIndex(this.oneOption['nodeList'], this.oneNode['nodeId']);
        this.oneOption['nodeList'].splice(findIndex, 0, pObj);
      }
    } else if (this.btnType == 'endBranch') {
      this.fillInData(this.oneNode, this.workflowData['nodeList'], pObj);
    }
    this.addNodeModelVisible = false;
  }

  addCheckMan() {
    let nodeId = this.util.Base_getUuid();
    let obj = {
      "nodeId": nodeId,
      "title": "审核人" + nodeId,
      "nodeType": "checkMan",
      "checkType": "orderBy",
      "disagreeType": "completelyReturned",
      "data":[]
    }
    this.execAdd(obj);
  }

  addBranch() {
    let nodeId = this.util.Base_getUuid();
    let condition01Id = this.util.Base_getUuid();
    let condition02Id = this.util.Base_getUuid();
    let obj = {
      "nodeId": nodeId,
      "title": "审核人" + nodeId,
      "nodeType": "branch",
      "optionList": [
        {
          "nodeId": condition01Id,
          "title": "条件" + condition01Id,
          "conditionList":[],
          'nodeList': []
        },
        {
          "nodeId": condition02Id,
          "title": "条件" + condition02Id,
          "conditionList":[],
          'nodeList': []
        },
      ]
    }
    this.execAdd(obj);
  }
}
