import {forkJoin as observableForkJoin, Observable} from 'rxjs';
import {Component, Input, OnInit, ViewChild, ViewChildren, QueryList} from '@angular/core';
import {
    FormBuilder
} from '@angular/forms';
import {AppConfigService} from './../../service/app-config.service';
import {AjaxService} from './../../service/ajax.service';
import {UserInfoService} from './../../service/user-info.service';
import {DataCenterService} from '../../service/data-center.service';
import {DetailActionService} from '../../service/detail-action.service';
import {AutoInsertService} from '../../service/auto-insert.service';
import {ConfirmModalService} from '../../service/confirm-modal.service';
import {RequestDataService} from '../../service/request-data.service';
import {UtilsService} from '../../service/utils.service';
import {FormComponent} from '../../basicComponent/form/form.component';
import {MultiPopupJsonComponent} from '../../basicComponent/multi-popup-json/multi-popup-json.component';

import * as _sh from 'lodash';


@Component({
    selector: 'app-detail-form',
    templateUrl: './detail-form.component.html',
    styleUrls: ['./detail-form.component.css']
})
export class DetailFormComponent implements OnInit {


    @ViewChildren('mainForm') mainForm;
    @ViewChildren(FormComponent) childListForm: QueryList<FormComponent>;
    @ViewChildren(MultiPopupJsonComponent) popupComponent: QueryList<MultiPopupJsonComponent>;
    @Input() parentData: any;
    public helpUrl: string;
    public userIsDeveloper: boolean = false; // 默认不是开发者

    // 用来判断当前明细是添加还是修改
    private itemIndex: any;
    // 将原始数据备份一下,为修改日志判断服务
    prvForms: any;
    menuId: String = null; // 当前菜单ID
    constructor(private formBuilder: FormBuilder,
                private ajaxService: AjaxService,
                private requestDataService: RequestDataService,
                private appConfigService: AppConfigService,
                private userInfoService: UserInfoService,
                private dataCenterService: DataCenterService,
                private utils: UtilsService,
                private detailAction: DetailActionService,
                private autoService: AutoInsertService,
                private confirmModal: ConfirmModalService) {
    }

    // 刷新checkbox状态
    refreshStatus(oneForm): void {
        const validData = oneForm.data.filter(value => !value.disabled);
        const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
        const allUnChecked = validData.every(value => !value.checked);
        oneForm.allChecked = allChecked;
        oneForm.indeterminate = (!allChecked) && (!allUnChecked);
    }

    // 全选
    checkAll(oneForm, value: boolean): void {
        oneForm.data.forEach(data => {
            if (!data.disabled) {
                data.checked = value;
            }
        });
        this.refreshStatus(oneForm);
    }

    // 格式化数据
    transformData(data) {
        // 重新覆盖 datacenter 数据
        this.parentData.data = data;
        _sh.each(this.parentData.data.detailColumns, (oneForm) => {
            oneForm['button_add_visible'] = _sh.get(oneForm, 'button_add_visible', ['add', 'modify']);
            oneForm['button_delete_visible'] = _sh.get(oneForm, 'button_delete_visible', ['add', 'modify']);
            if (_sh.includes(oneForm['button_add_visible'], this.parentData.modeType)) {
                oneForm['button_add_visible_html'] = 'show';
            } else {
                oneForm['button_add_visible_html'] = 'hide';
            }
            if (_sh.includes(oneForm['button_delete_visible'], this.parentData.modeType)) {
                oneForm['button_delete_visible_html'] = 'show';
            } else {
                oneForm['button_delete_visible_html'] = 'hide';
            }
            if ((oneForm['button_add_visible_html'] === 'hide') && (oneForm['button_delete_visible_html'] === 'hide')) {
                oneForm['popDetail'] = false;
            } else {
                oneForm['popDetail'] = true;
            }
        });


        this.prvForms = this.utils.DeepCopy(this.parentData);
        // 覆盖当前数据到data-center;
        _sh.each(this.dataCenterService._dataObject.list, (item) => {
            if (item['id'] === this.parentData.id) {
                item = _sh.extend(item, this.parentData);
            }
        });
        this.dataCenterService.refreshDataCenterInStorage();
    }


    // 返回
    gotoBack(): void {
        this.parentData.data = {};
        this.parentData.conditionColumns = {};
        this.parentData.formType = 'listForm';
        this.parentData.actionArray = [];
        // 覆盖当前数据到data-center;
        _sh.each(this.dataCenterService._dataObject.list, (item) => {
            if (item['id'] === this.parentData.id) {
                item = _sh.extend(item, this.parentData);
            }
        });
        this.dataCenterService.refreshDataCenterInStorage();
    }

    // 按钮保存事件
    doAction(pBtn): void {
        let that = this;
        // 校验，明细表的数据，是否需要。
        console.log("parentData:",this.parentData);
        console.log("mainForm:",this.mainForm);
        console.log("prvForms:",this.prvForms);
        // data.detailColumns[1].data
        // data.detailColumns[1].reuqire
        let formIndex = 0;
        let check = true;
        _sh.each(_sh.get(this.prvForms,['data','detailColumns'],[]), oneForm =>{
            if (formIndex >=1) {
                let reuqire = _sh.get(oneForm,['require'],false);
                if (check && reuqire) {
                    let formRowCount = _sh.get(this.prvForms,['data','detailColumns',formIndex,'data'],[]);
                    formRowCount = formRowCount.length;
                    console.log(formRowCount);
                    if (formRowCount == 0) {
                        check = false;
                        that.confirmModal.show('error', {
                            'title': '温馨提示',
                            'content': _sh.get(this.prvForms,['data','detailColumns',formIndex,'title'],'') + '：该表是必填项，不能为空。'
                        });
                    }
                }
            }
            formIndex ++;
        })
        if (check) this.detailAction.doAction(pBtn, this.parentData, this.mainForm, this.prvForms);
    }

    // 明细table删除
    itemRemove(oneForm): void {
        const checkedList = _sh.filter(oneForm.data, (item) => {
            return item['checked'];
        });
        if (checkedList.length) {
            this.confirmModal.show('confirm', {
                title: '温馨提示',
                content: '是否要删除该数据吗？',
                suc: () => {
                    oneForm.data = oneForm.data.filter((item) => {
                        return !item.checked;
                    });
                    this.refreshStatus(oneForm);
                    this.transformData(this.parentData.data);
                }
            });
        } else {
            this.confirmModal.show('warning', {
                title: '温馨提示',
                content: '请至少选择一条数据！'
            });
        }

    }

    // 该方法将popupJson中对应配置的column值获取到。没有就弹出提示。有的话放入condition
    transParamsToData(params) {
        let dataObj = {};
        let errorList = [];
        _sh.each(params, (item) => {
            let tablename = item['value'].split('.')[1];
            let column = item['value'].split('.')[2];
            let form = _sh.find(this.childListForm['_results'], v => v['formData']['type'] === 'normal' && v['formData']['tablename'] === tablename);
            if (this.utils.Base_HasValue(form)) {
                let data = _sh.find(form['_validateForm'].columns, (c) => {
                    return c['column'] === column;
                });
                if (this.utils.Base_HasValue(data)) {
                    if (this.utils.Base_HasValue(data['model'])) {
                        dataObj[item['column']] = data['model'];
                    } else {
                        errorList.push('请先选择' + data['lable']);
                    }
                }
            }
        });
        return {
            dataObj: dataObj,
            errorList: errorList
        };
    }

    // 明细table添加
    itemAdd(oneForm, data?, i?): void {
        // tslint:disable-next-line:triple-equals
        if (oneForm['popDetail'] == true) {
            if (this.utils.Base_HasValue(data)) {
                _sh.each(oneForm.columns, (item) => {
                    item['model'] = data[item['column']];
                });
                this.itemIndex = i;
            } else {
                let childForm = this.getChildForm(oneForm);
                _sh.set(childForm, ['forms'], this.utils.DeepCopy(_sh.get(this.parentData, ['data', 'detailColumns'])));
                childForm.resetForm();
                this.itemIndex = null;
            }
            // 如果是点击按钮直接弹出poplist的情况
            if (this.utils.Base_HasValue(oneForm.popupJson)) {
                let params = oneForm.popupJson.params;
                let transResult = this.transParamsToData(params);
                if (transResult.errorList && transResult.errorList.length) {
                    this.confirmModal.show('error', {
                        title: '温馨提示',
                        content: transResult.errorList[0]
                    });
                } else {
                    // 点击确认方法
                    oneForm.confirmPopupModel = (selectedList) => {
                        oneForm.data = oneForm.data || [];
                        _sh.each(selectedList, (selected) => {
                            let checkColumn = _sh.find(oneForm.columns, item => item['fromValue'] === oneForm.popupJson.muliKey || item['column'] === oneForm.popupJson.muliKey);
                            let unique = _sh.find(oneForm.data, (form) => {
                                return form[checkColumn['column']] === selected[oneForm.popupJson.muliKey];
                            });
                            if (!this.utils.Base_HasValue(unique)) {
                                let data = {};
                                _sh.each(oneForm.columns, (column) => {
                                    let columnName = column['fromValue'] || column['column'];
                                    data[column['column']] = selected[columnName];
                                });
                                // 对table的dataset进行操作。不能使用push splice 需要用 concat filter
                                oneForm.data = [...oneForm.data, data];
                            }
                        });
                        this.transformData(this.parentData.data);
                    };
                    oneForm.popupJson.condition = transResult.dataObj;

                    const childPopup: MultiPopupJsonComponent = _sh.find(this.popupComponent['_results'], (popup) => {
                        return popup['form']['tablename'] === oneForm.tablename;
                    });
                    childPopup.showPopupModal();
                }
            } else {
                oneForm.showItemMode = true;
            }
        }
    }

    getChildForm(oneForm): FormComponent {
        return _sh.find(this.childListForm['_results'], (item) => {
            return item.formData['formname'] === oneForm.formname;
        });
    }

    // 关闭
    closeItemModel(oneForm): void {
        this.getChildForm(oneForm).resetForm();
        oneForm.showItemMode = false;
    }

    // 明细添加框确认
    confirmItemModel(oneForm): void {
        let childForm = this.getChildForm(oneForm);
        if (childForm._validateForm['invalid']) {
            for (const key in childForm._validateForm.controls) {
                childForm._validateForm.controls[key].markAsDirty();
                childForm._validateForm.controls[key].updateValueAndValidity();
            }
            return;
        }
        oneForm.data = oneForm.data || [];
        let data = {};
        _sh.each(oneForm.columns, (item) => {
            if (item['visible']) {
                data[item['column']] = item['model'];
                if (item['columnGroup'] && item['columnGroup'].length) {
                    _sh.each(item['columnGroup'], group => {
                        data[group['column']] = group['model'];
                    });
                }
            }
        });
        childForm.resetForm();
        if (this.utils.Base_HasValue(this.itemIndex)) {
            _sh.map(data, (item, key) => {
                oneForm.data[this.itemIndex][key] = item;
            });
        } else {
            if (this.utils.Base_HasValue(oneForm.uniqueItems) && oneForm.uniqueItems.length) {
                let hasRepeat = false;
                _sh.each(oneForm.data, (itemData) => {
                    let uniqueCheck = true;
                    _sh.each(oneForm.uniqueItems, (item: string) => {
                        uniqueCheck = (itemData[item] === data[item]) && uniqueCheck;
                    });
                    // 唯一性check
                    if (uniqueCheck) {
                        itemData = data;
                        hasRepeat = true;
                    }
                });
                if (!hasRepeat) {
                    oneForm.data = [...oneForm.data, data];
                } else {
                    this.confirmModal.show('warning', {
                        title: '温馨提示',
                        content: '该数据已存在'
                    });
                }
            } else {
                // 对table的dataset进行操作。不能使用push splice 需要用 concat filter
                oneForm.data = [...oneForm.data, data];
            }
        }
        oneForm.showItemMode = false;
        this.transformData(this.parentData.data);
    }


    buildData(pData, pType, pRoleIdList) {
        let data = _sh.uniqBy(pData, 'ACTION_ID');
        let arr = [];
        _sh.each(data, oneData => {
            let obj = {}
            let oneColumnData = _sh.get(oneData, 'ACTION_ID_DICT_ARRAY');
            let columns = _sh.keys(oneColumnData);
            _sh.each(columns, oneColumn => {
                obj[oneColumn] = oneColumnData[oneColumn];
            });
            let actionTypeList = _sh.split(obj['ACTION_TYPE'], ',');
            if (_sh.includes(actionTypeList, pType)) {
                obj['MENU_ID'] = oneData['MENU_ID'];
                obj['MENU_NAME'] = oneData['MENU_ID_DICT'];
                obj['ROLE_ID'] = _sh.join(pRoleIdList, ',');
                arr.push(obj);
            }
        });
        return arr;
    }

    ngOnInit(): void {
        // 如果该tab数据不存在则需要查询
        const user = this.userInfoService.getUserInfo();
        this.menuId = _sh.cloneDeep(this.parentData.nowMenu.MENU_ID);
        this.userIsDeveloper = _sh.get(user, 'POSITION', null) == "hdbs_developer";
        if (!this.utils.Base_HasValue(this.parentData.data)) {
            const role$ = observableForkJoin(
                this.requestDataService.getDataByCondition(this.appConfigService.config.sql_path + '/menu/system_user_role.json', {}),
            );
            role$.subscribe(
                roleValues => {
                    let roleIdList = [];
                    _sh.each(roleValues[0]['data'] || [], oneRole => {
                        roleIdList.push(oneRole['ROLE_ID']);
                    })
                    const parallel$ = observableForkJoin(
                        this.requestDataService.getFormJson(this.parentData.modeType, this.parentData),
                        this.requestDataService.getDataByCondition(this.appConfigService.config.sql_path + '/menu/system_role_menu_action.json', {
                            'ROLE_ID': roleIdList,
                            'MENU_ID': this.menuId
                        })
                    );
                    parallel$.subscribe(
                        values => {
                            let dataObject = values[0];
                            console.log(dataObject);
                            this.parentData.actionArray = this.buildData(_sh.get(values, ['1', 'data'], []), 'detail', roleIdList);
                            this.transformData(dataObject);
                        }
                    );
                }
            );


        } else {
            this.prvForms = this.utils.DeepCopy(this.parentData);
        }
        this.helpUrl = this.parentData.addHelpUrl;
    }

    gotoDesign(): void {
        let searchId = this.parentData.searchId;
        window.open('HulkConfigDetail/?searchId=' + searchId ,'_blank');
    }

}
