import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './components/login/login.component';
import {IndexComponent} from './components/index/index.component';
import {AssetControlMapComponent} from './project/assets/asset-control-map/asset-control-map.component';
import {CheckLoginGuard} from './guard/check-login.guard';
import {HulkConfigDetailComponent} from './hulkConifg/hulk-config-detail/hulk-config-detail.component';
import { HulkConfigListSqlfileComponent } from './hulkConifg/hulk-config-list-sqlfile/hulk-config-list-sqlfile.component';
import { HulkConfigDesignListComponent } from './hulkConifg/hulk-config-design-list/hulk-config-design-list.component';
import { HulkConfigDesignReadonlyComponent } from './hulkConifg/hulk-config-design-readonly/hulk-config-design-readonly.component';
import {AssetControlMapListComponent} from "./project/assets/asset-control-map-list/asset-control-map-list.component";

const routes: Routes = [
    {
        path: 'index',
        component: IndexComponent,
        canActivate: [CheckLoginGuard]
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'appAssetMap/:ID',
        component: AssetControlMapListComponent
    },
    {
        path: 'HulkConfigDetail',
        component: HulkConfigDetailComponent
    },
    {
        path: 'HulkConfigDesignReadOnly',
        component: HulkConfigDesignReadonlyComponent
    },
    {
        path: 'HulkConfigDesignList',
        component: HulkConfigDesignListComponent
    },
    {
        path: 'HulkConfig',
        component: HulkConfigListSqlfileComponent
    },
    {
        path: '',
        redirectTo: '/index',
        pathMatch: 'full'
    },
    {
        path: '**',
        redirectTo: '/index'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
